import React, { useState, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";

import api from "../../util/api"
import { requiresRole, policies } from '../../util/accessControl';
import LoadingPanel from '../common/Grid/LoadingPanel';
import cellWithEditing from "../common/Grid/CellWithEditing";
import CardIcon from "../common/MuiKit/Card/CardIcon";
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";
import AccountIcon from "@material-ui/icons/AccountBalance";

import AccountEditor from "./AccountEditor";
import GenericHeader from "../common/Header/GenericHeader"; 
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { ConfirmDelete } from "../common/Dialog";
import { Button } from '@progress/kendo-react-buttons';

import { withSnackbar } from 'notistack';
import handleErrors from '../../util/handleErrors';
import KendoGridDateCell from "../../util/KendoGridDateCell";
import KendoGridArrayCell from "../../util/KendoGridArrayCell";

const Account = (props) => {

    const [matterId] = useState(props.match.params.id || 0);
    const [gridItems, setGridItems] = useState([]);
    const [total, setTotal] = useState(0);
    const initPage = { take: matterId > 0 ? 9999 : 10, skip: 0 };
    const [ page, setPage] = useState(initPage)
    const defaultSort = {object: [], query: ''};
    const [ sort, setSort ] = useState(defaultSort); 
    const [loading, setLoading] = useState(false);
    const [itemInEdit, setItemInEdit] = useState(null);
    const [itemInDelete, setItemInDelete] = useState(null);

    useEffect(() => {
        setLoading(true);
        api.get(`/account/${matterId}/paged/${(page.skip / page.take) + 1}/${page.take}${sort.query ? `?${sort.query}` : ``}`, {
            accept: 'application/json',
        }) 
        .then(json => {
            setGridItems(json.data);
            setTotal(json.totalRecords);
            setLoading(false);
        })
        .catch(handleErrors(props))
    }, [page, sort]);

    const edit = (dataItem, editMode = true) => {
        
        const item = Object.assign({}, dataItem);

        Object.keys(item).forEach(key => {

            if (item[key] == null) {

                if (key === 'originalAmountAdvanced' ||
                    key === 'loanValueRatio' ||
                    key === 'currentInterestRate' ||
                    key === 'currentPaymentAmount' ||
                    key === 'currentBalance' ||
                    key === 'accountFeesAccrued' ||
                    key === 'governmentCharges' ||
                    key === 'daysDelinquent' ||
                    key === 'dailyInterestAmount'||
                    key === 'matterStageId'
                ) {
                    item[key] = null;
                } else {
                    item[key] = '';
                }
            }
        })
        item.selectMatter = (matterId === 0);
        item.editMode = editMode;
        
        setItemInEdit(item);
    };

    const remove = (dataItem) => {
        setItemInDelete(dataItem);
    };

    const deleteItem = (dataItem) => {
        setLoading(true);
        
        api.delete(`/account/${dataItem.accountId}`, {
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
        .then(json => {
            
            setLoading(false);
            setItemInEdit(null);
            setPage({ take: 10, skip: 0 });
        })
        .catch(handleErrors(props))
    };

    const save = (dataItem) => {
        setLoading(true);
        let matter = dataItem.matter;
        dataItem.matter = null;

        api.request({
            url: `/account`,
            method: dataItem.accountId === 0 ? 'POST' : 'PUT',
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(dataItem)
        }) 
        .then(json => {
            let items = gridItems.slice();
            if (dataItem.accountId === 0) {
                dataItem.accountId = json.accountId;
                dataItem.matter = matter;
                items = [dataItem, ...items];
            } else {
                var index = gridItems.findIndex(v => v.accountId === json.accountId);
                items.splice(index, 1, dataItem);
            }

            setGridItems(items);
            setTotal(items.length);
            setItemInEdit(null);
            setLoading(false);
        })
        .catch(handleErrors(props))
    }

    const cancel = () => {
        setItemInEdit(null);
        setItemInDelete(null);
        setLoading(false);
    }

    const insert = () => {
        setItemInEdit({
            accountId: 0,
            matterId: parseInt(matterId),
            selectMatter: matterId === 0,
            editMode: true,
        });
    }

    const renderCard = () => {
        return (<>{!props.cards && <GenericHeader />}
            <Card style={{ height: props.cardHeight, marginTop: !props.cards ? '35px' : '5px' }}>
                <CardHeader>
                    <CardIcon color='primary' style={{ marginTop: '-30px' }}>
                        <AccountIcon style={{ color: 'white' }} />
                    </CardIcon>

                    <h4 className='jm-card-title' style={{ color: '#777' }}>Accounts</h4>

                    {requiresRole(<Button
                        primary={true}
                        className='rounded-circle ml-3 mb-1'
                        icon='plus'
                        title='Add New Account'
                        onClick={insert}
                    ></Button>, policies.edit)}     

                    {/* {matterId > 0 && <span style={{ float: 'right', marginTop:'5px' }}>
                            <Link to={`/account`}>View All</Link>
                        </span>}            */}
                    
                    {/* <span className='font-italic' style={{ fontSize: '12px', marginLeft: '5px' }}>Add New account</span>  */}

                </CardHeader>
                <CardBody>
                    {renderGrid()}
                </CardBody>
            </Card></>
        );
    }

    const renderGrid = () => {
        return (
            <>
                <Grid
                    filterable={false}
                    sortable={{
                        allowUnsort: true,
                        mode: 'single'
                    }}
                    sort={sort ? sort.object: []}
                    onSortChange={({ sort }) => {        
                        if (sort && sort.length > 0) {
                            var arr = sort.map((o, i) => {
                                return `sort[${i}].Property=${o.field}&sort[${i}].Direction=${o.dir == 'asc' ? 'Ascending' : 'descending'}`;
                            });
                            var str = arr.join('&');
                            setSort({object: sort, query: str});
                        } else {
                            setSort(defaultSort);
                        }

                    }}
                    pageable={matterId > 0 ? false : props.pagerSettings}
                    className={`clickableRow ${matterId > 0 ? '' : ''}`}
                    data={gridItems}
                    total={total}
                    take={page.take}
                    skip={page.skip}
                    onPageChange={(ev) => setPage(ev.page)}
                    onRowClick={({ dataItem }) => { edit(dataItem, false) }}
                    style={{ height: props.gridHeight }}
                >
                    {matterId === 0 && <GridColumn field="matterNumber" title="Matter Number" />}
                    <GridColumn field="accountNumber" title="Account No." />
                    <GridColumn field="accountName" title="Description" />
                    <GridColumn field="borrowers" title="Borrower" sortable={false} cell={KendoGridArrayCell} />
                    <GridColumn field="security" title="Security" sortable={false} cell={(cellProps, cxt) => {
                        //console.log('cellProps: ', cellProps,cxt);
                        return (
                            <td style={{whiteSpace:'pre'}}>
                                {cellProps.dataItem.security == null ? null : cellProps.dataItem.security.split(';').join('\r\n')}
                            </td>
                        );
                    }} />
                    <GridColumn field="originalAmountAdvanced" title="Original Advance" format="{0:C2}" />                    
                    <GridColumn field="varianceType.lookupValue" title="Shortfall/Surplus" />
                    {/* 
                    <GridColumn field="dateOfAgreement" title="Date of loan Agreement" cell={KendoGridDateCell}/>
                    <GridColumn cell={cellWithEditing(edit, remove)} width={90} /> 
                     */}

                </Grid>
                

                {loading && <LoadingPanel />}
                {itemInEdit && <AccountEditor dataItem={itemInEdit} save={save} cancel={cancel} editMode={itemInEdit.editMode ?? false} delete={deleteItem} />}            
                {/* {itemInDelete && <ConfirmDelete title={`Delete Account ${itemInDelete.accountNumber}?`} cancel={cancel} deleteItem={deleteItem} />} */}
            </>
        );
    }

    return props.view == "grid" ? renderGrid() : renderCard();

    // return (
    //     <Card style={{ height: props.cardHeight }}>
    //         <CardHeader>
    //             <CardIcon color='primary' style={{ marginTop: '-30px' }}>
    //                 <AccountIcon style={{ color: 'white' }} />
    //             </CardIcon>

    //             <h4 className='jm-card-title' style={{ color: '#777' }}>Accounts</h4>

    //             {requiresRole(<Button
    //                 primary={true}
    //                 className='rounded-circle float-right'
    //                 icon='plus'
    //                 title='Add New Account'
    //                 onClick={insert}
    //             ></Button>, policies.edit)}                
                
    //             {/* <span className='font-italic' style={{ fontSize: '12px', marginLeft: '5px' }}>Add New account</span>  */}

    //         </CardHeader>
    //         <CardBody>
    //             <Grid
    //                 filterable={false}
    //                 sortable={true}
    //                 pageable={matterId > 0 ? false : props.pagerSettings}
    //                 className={`clickableRow ${matterId > 0 ? 'no-pager' : ''}`}
    //                 data={gridItems}
    //                 total={total}
    //                 take={page.take}
    //                 skip={page.skip}
    //                 onPageChange={(ev) => setPage(ev.page)}
    //                 onRowClick={({ dataItem }) => { edit(dataItem, false) }}
    //                 style={{ height: props.gridHeight }}
    //             >
    //                 {matterId === 0 && <GridColumn field="matterNumber" title="Matter Number" />}
    //                 <GridColumn field="accountNumber" title="Account No." />
    //                 <GridColumn field="borrowers" title="Borrower" cell={KendoGridArrayCell} />
    //                 <GridColumn field="security" title="Security" />
    //                 <GridColumn field="originalAmountAdvanced" title="Original Advance" format="{0:C2}" />                    
    //                 <GridColumn field="dateOfAgreement" title="Date of loan Agreement" cell={KendoGridDateCell}/>

    //                 <GridColumn field="varianceType.lookupValue" title="Sortfall/Surplus" />
    //                 {/* <GridColumn cell={cellWithEditing(edit, remove)} width={90} /> */}

    //             </Grid>
    //             {matterId > 0 &&<div className='grid-footer'>
    //                 <span style={{ float: 'right', marginTop:'5px' }}>
    //                     <Link to={`/account`}>View All</Link>
    //                 </span>
    //             </div>}
    //         </CardBody>

    //         {loading && <LoadingPanel />}
    //         {itemInEdit && <AccountEditor dataItem={itemInEdit} save={save} cancel={cancel} editMode={itemInEdit.editMode ?? false} delete={deleteItem} />}            
    //         {/* {itemInDelete && <ConfirmDelete title={`Delete Account ${itemInDelete.accountNumber}?`} cancel={cancel} deleteItem={deleteItem} />} */}
    //     </Card>
    // )
}
export default withSnackbar(withRouter(Account));