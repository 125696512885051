import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { withSnackbar } from 'notistack';

import LoadingPanel from '../common/Grid/LoadingPanel';
import api from "../../util/api";
import handleErrors from '../../util/handleErrors';

import CardIcon from "../common/MuiKit/Card/CardIcon";
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";

import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import qs from 'query-string';

import PersonIcon from "@material-ui/icons/Person";

const Tick = (props) => {

    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
            <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
            <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
        </svg>
    )
}

const Cross = (props) => {

    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
            <circle class="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
            <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
            <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
        </svg>
    )
}

const ConfirmEmail = (props) => {
    const [ userConfirmation, setUserConfirmation ] = useState(qs.parse(props.location.search));
    const [ loading, setLoading ] = useState(false);

    const [ regex, setRegex ] = useState({
        requireDigit: '[0-9]',
        requireLowercase: '[a-z]',
        requireUppercase: '[A-Z]',
        requireNonAlphanumeric: '[^0-9a-zA-Z]',
        requiredLength: '.{8,}',
        requiredUniqueChars: ''
    });

    const [ validation, setValidation ] = useState({
        requireDigit: false,
        requireLowercase: false,
        requireUppercase: false,
        requireNonAlphanumeric: false,
        requiredLength: false,
        requiredUniqueChars: true,
        confirmPassword: false
    });

    const [ options, setOptions ] = useState({});
    useEffect(() => {        
        api.get(`/auth/options`, {
            accept: 'application/json', 
        }) 
        .then(json => {            
            setOptions(json);
            setRegex({ ...regex, requiredLength: `.{${json.requiredLength},}` })
        })
        .catch(handleErrors(props));
    }, [])

    const handleInputChange = ({ value, target: { name } }) => {
        setUserConfirmation({...userConfirmation, [name]: value });

        const nextValidationState = { ...validation };

        nextValidationState.requireDigit = RegExp(regex.requireDigit).test(value);

        nextValidationState.requireLowercase = RegExp(regex.requireLowercase).test(value);

        nextValidationState.requireUppercase = RegExp(regex.requireUppercase).test(value);

        nextValidationState.requireNonAlphanumeric = RegExp(regex.requireNonAlphanumeric).test(value);

        nextValidationState.requiredLength = RegExp(regex.requiredLength).test(value);

        setValidation(nextValidationState);
    }
    const handleConfirmInputChange = ({ value, target: { name } }) => {
        setUserConfirmation({...userConfirmation, [name]: value });

        const nextValidationState = { ...validation };

        nextValidationState.confirmPassword = (userConfirmation.password === value);

        setValidation(nextValidationState);
    }

    const handleSave = () => {
        setLoading(true)
        api.post(`/auth/confirmemail`, userConfirmation) 
        .then(json => {
            props.enqueueSnackbar(json, { variant: 'info' });
            props.history.push('/login');
        })
        .catch(handleErrors(props))
        .finally(() => setLoading(false));
    }

    const getClass = (prop) => {
        return validation[prop] ? 'valid' : 'invalid';
    }

    const getIcon = (prop) => {
        return validation[prop] ? <Tick /> : <Cross />;
    }

    return (
        <div style={{height: 'calc(100vh - 100px)',display:'flex',alignItems:'center'}}>
            <Card style={{maxWidth: '500px', margin: '0 auto'}}>
                <CardHeader>
                    <CardIcon color='info' style={{marginTop: '-30px'}}>
                        <PersonIcon style={{color:'white'}} />
                    </CardIcon>
                    <h4 style={{color: '#777'}}>Create Password</h4>
                </CardHeader>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} className='mt-2'>
                            <Input
                                width='100%'
                                label='Password'
                                value={userConfirmation.password}
                                onChange={handleInputChange}
                                name="password"
                                id="password"
                                required
                                type='password'
                            />
                        </GridItem>
                        <GridItem xs={12} className='mt-2'>
                            <Input
                                width='100%'
                                label='Confirm Password'
                                value={userConfirmation.confirmPassword}
                                onChange={handleConfirmInputChange}
                                name="confirmPassword"
                                id="confirmPassword"
                                required
                                type='password'
                            />
                        </GridItem>
                        <GridItem xs={12}>
                            <ul className='password-validation'>
                                <li className={`${getClass('requiredLength')}`}>{getIcon('requiredLength')} Must be at least {options.requiredLength} characters</li>
                                {options.requireLowercase && <li className={`${getClass('requireLowercase')}`}>{getIcon('requireLowercase')} Must have at least one lowercase character ('a'-'z')</li>}
                                {options.requireUppercase && <li className={`${getClass('requireUppercase')}`}>{getIcon('requireUppercase')} Must have at least one uppercase character ('A'-'Z')</li>}
                                {options.requireDigit && <li className={`${getClass('requireDigit')}`}>{getIcon('requireDigit')} Must have at least one digit ('0'-'9')</li>}
                                {options.requireNonAlphanumeric && <li className={`${getClass('requireNonAlphanumeric')}`}>{getIcon('requireNonAlphanumeric')} Must have at least one non alphanumeric character</li>}
                                <li className={`${getClass('confirmPassword')}`}>{getIcon('confirmPassword')} Confirmed password</li>
                            </ul>
                        </GridItem>
                    </GridContainer>
                    <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: '10px' }}>
                        <Button onClick={handleSave} style={{ fontSize: '12px' }}>Confirm</Button>
                    </div>
                </CardBody>
            </Card>
            {loading && <LoadingPanel/>}
        </div>
    );
}

export default withRouter(withSnackbar(ConfirmEmail));