import React, { useState, useEffect } from 'react';
import { findDOMNode } from "react-dom";
import { Button } from '@progress/kendo-react-buttons';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import Dialog from "../common/Dialog";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import moment from "moment";
import { withSnackbar } from 'notistack';
import handleErrors from '../../util/handleErrors';
import api from "../../util/api"
import { editableField } from "../../util/editableField";
import { requiresRole, policies } from '../../util/accessControl';
import RelatedAccountsEditor from "./RelatedAccountsEditor";
import RelatedSecuritiesEditor from "./RelatedSecuritiesEditor";

const HoldEditor = (props) => {
    const originalItem = {
        ...props.dataItem,
        holdDate: (props.dataItem.holdDate == null || props.dataItem.holdDate === "") ? null : moment(props.dataItem.holdDate).toDate(),
        resolvedDate: (props.dataItem.resolvedDate == null || props.dataItem.resolvedDate === "") ? null : moment(props.dataItem.resolvedDate).toDate(),
        proceedDate: (props.dataItem.proceedDate == null || props.dataItem.proceedDate === "") ? null : moment(props.dataItem.proceedDate).toDate(),
    };
    const [itemData, setItemData] = useState(originalItem);
    const [originalItemData, setOriginalItemData] = useState(originalItem);
    const initLookupObject = { loading: false, list: [] };
    const [holdReasons, setHoldReasons] = useState(initLookupObject);
    const [editMode, setEditMode] = useState(!!props.editMode);

    const editStageQuery = `/stage/hold/${itemData.stageId}`;
    const newStageQuery = `/stage/block/${itemData.stage.blockId}`;

    const handleSelectChange = ({ value, target: { name } }) => {
        const idField = `${name}Id`;
        setItemData({ ...itemData, [name]: value, [idField]: value ? value.lookupId : null });
    }

    const handleDateChange = ({ value, target: { name } }) => {
        setItemData({ ...itemData, [name]: value == null ? null : moment(value).local().toDate() });
    }

    const handleTextAreaChange = ({ target: { value, name } }) => {
        setItemData({ ...itemData, [name]: value });
    }

    const updateRelatedEditorsData = (updatedData) => {
        setItemData(updatedData);
    }
    const validateForm = (ev) => {

        let account = itemData.stage.matterAccounts.find(v => v.noticeSelected === true);
        if (!account) {
            props.enqueueSnackbar('Related Account/s required.', { variant: 'error' });
            return false;
        }
        let security = itemData.stage.matterSecurities.find(v => v.noticeSelected === true);
        if (!security) {
            props.enqueueSnackbar('Related Security Properties required.', { variant: 'error' });
            return false;
        }

        return true;
    }
   
    const setLookup = (setFunc) => (json) => {
        setFunc({ loading: false, list: json });
        return json;
    }

    useEffect(() => {
        const axiosConfig = { accept: 'application/json' };
        const loading = { loading: true, list: [] };
        setHoldReasons(loading);

        let onLoadTasks = [
            api.get((itemData.stageId > 0) ? editStageQuery : newStageQuery, axiosConfig),
            api.get(`/lookup/holdreason`, axiosConfig).then(setLookup(setHoldReasons)),
        ];

        Promise.all(onLoadTasks)
            .then(responses => {
                let tempData = { ...itemData };
                let stage = responses[0];
                let holdReasons = responses[1]

                if (tempData.stageId === 0) {
                    tempData = {
                        ...itemData,
                        holdReason: holdReasons[0],
                        holdReasonId: holdReasons[0].lookupId,
                        stage: stage,
                    };
                } else {
                    tempData = {
                        ...stage,
                        holdDate: (stage.holdDate == null || stage.holdDate === "") ? null : moment(stage.holdDate).toDate(),
                        resolvedDate: (stage.resolvedDate == null || stage.resolvedDate === "") ? null : moment(stage.resolvedDate).toDate(),
                        proceedDate: (stage.proceedDate == null || stage.proceedDate === "") ? null : moment(stage.proceedDate).toDate(),
                    };
                }
                setItemData(tempData);
                setOriginalItemData(tempData);
            })
            .catch(handleErrors(props))

    }, []);

    return (
        <Dialog
            validate={validateForm}
            original={originalItemData}
            current={itemData}
            saveChanges={props.save}
            cancelChanges={props.cancel}
            width={1200}
            createMode={itemData.stageId === 0}
            title={
                <>
                    {`${itemData.accountId === 0 ? 'Add' : editMode ? 'Edit' : 'View'} Hold`}
                    {requiresRole(<Button className='rounded-circle ml-2' primary={true} look="bare" icon={editMode ? 'lock' : 'edit'} onClick={(ev) => setEditMode(!editMode)} />, policies.edit)}
                </>}
        >
            <form onSubmit={(ev) => ev.preventDefault()}>
                <GridContainer>

                    {editableField(<GridItem sm={4}>
                        <span className='k-textbox-container'>
                            <label className='k-label'>Date instructed to hold</label>
                            <DatePicker
                                defaultValue={new Date()}
                                format="dd/MM/yyyy"
                                name='holdDate'
                                id='holdDate'
                                onChange={handleDateChange}
                                value={itemData.holdDate}
                                label=""
                            />
                        </span>
                    </GridItem>, editMode, 'Contract date', (itemData.holdDate) ? moment(itemData.holdDate).format('D/MM/YYYY') : '', [2, 2])}

                    {editableField(<GridItem sm={4}>
                        <ComboBox
                            data={holdReasons.list}
                            loading={holdReasons.loading}
                            onFocus={(ev) => {if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click()}}
                            textField='lookupValue'
                            dataItemKey='lookupId'
                            value={itemData.holdReason}
                            onChange={handleSelectChange}
                            label="Reason for hold"
                            name='holdReason'
                            id='holdReason'
                        />
                    </GridItem>, editMode, 'Reason for hold', (itemData.holdReason) ? itemData.holdReason.lookupValue : '', [2, 2])}

                    {editableField(<GridItem sm={4}>
                        <span className='k-textbox-container'>
                            <label className='k-label'>Date resolved</label>
                            <DatePicker
                                defaultValue={new Date()}
                                format="dd/MM/yyyy"
                                name='resolvedDate'
                                id='resolvedDate'
                                onChange={handleDateChange}
                                value={itemData.resolvedDate}
                                label=""
                            />
                        </span>
                    </GridItem>, editMode, 'Date resolved', (itemData.resolvedDate) ? moment(itemData.resolvedDate).format('D/MM/YYYY') : '', [2, 2])}

                    {editableField(<GridItem sm={4}>
                        <span className='k-textbox-container'>
                            <label className='k-label'>Date instructed to proceed</label>
                            <DatePicker
                                defaultValue={new Date()}
                                format="dd/MM/yyyy"
                                name='proceedDate'
                                id='proceedDate'
                                onChange={handleDateChange}
                                value={itemData.proceedDate}
                                label=""
                            />
                        </span>
                    </GridItem>, editMode, 'Date instructed to proceed', (itemData.proceedDate) ? moment(itemData.proceedDate).format('D/MM/YYYY') : '', [2, 2])}

                    {editableField(<GridItem sm={12}>
                        <span className='k-textbox-container'>
                            <label className='k-label'>Further information</label>
                            <textarea
                                className='k-textarea w-100'
                                value={itemData.furtherInformation}
                                onChange={handleTextAreaChange}
                                name="furtherInformation"
                                id="furtherInformation"
                                rows={2}
                            />
                        </span>
                    </GridItem>, editMode, 'Further information', itemData.furtherInformation ?? '', [2, 2])}

                    {itemData.stage.matterAccounts && <RelatedAccountsEditor stageData={itemData} updateRelatedAccounts={updateRelatedEditorsData} editMode={editMode} />}

                    {itemData.stage.matterSecurities && <RelatedSecuritiesEditor stageData={itemData} updateRelatedSecurities={updateRelatedEditorsData} editMode={editMode} />}

                </GridContainer>
            </form>
        </Dialog>
    )
}

export default withSnackbar(HoldEditor);