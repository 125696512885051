import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import CardIcon from "../common/MuiKit/Card/CardIcon";
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";
import ChatIcon from "@material-ui/icons/Chat";
import handleErrors from '../../util/handleErrors';
import api from "../../util/api";
import { Badge } from "@material-ui/core";
import _ from 'lodash';
import ConversationSummary from "./ConversationSummary";
import 'react-perfect-scrollbar/dist/css/styles.css';
import { SignalCellularConnectedNoInternet0BarSharp } from '@material-ui/icons';


const ConversationListView = (props) => {
    const [ notes, setNotes ] = useState([]);
    const [ viewed, setSetViewed ] = useState(0);
    const [ loading, setLoading ] = useState(false);
    //const [ itemSort, setItemSort] = useState('oldest'); 

    const loadNotes = (sort = 'ascending') => {
        setLoading(true);

        const itemSort = sort === 'descending' ? 'newest' : 'oldest';
        let request;
        if (props.team) {
            request = api.get(`/conversation/team/${props.team}/${itemSort}`, {
                accept: 'application/json',
            });
        } else {
            request = api.get(`/conversation/all/${itemSort}`, {
                accept: 'application/json',
            }) 
        }
        
        request.then(json => {
            let count = 0;
            json.forEach(dataItem => {
                var bold = (dataItem.responseRequired && !dataItem.responseRecieved) || (!dataItem.responseRequired && !dataItem.acknowledged) ? false : true;
                count += bold ? 0 : 1;
            });
            setSetViewed(count);
            setNotes(json);
            setLoading(false);
        })
        .catch(handleErrors(props))
    }

    useEffect(() => {    
        loadNotes();
    }, []);
    
    return (
        <Card style={{height: props.cardHeight, marginTop: '35px'}}>
            <CardHeader>
                <CardIcon color='info' style={{marginTop: '-30px', position: 'relative'}}>
                {/* viewed > 0 && <Badge badgeContent={viewed} color="primary" style={{position: 'absolute', right: '2px', top: '2px'}}></Badge> */}                 
                    <ChatIcon style={{color:'white'}} />
                </CardIcon>
                    <h4 className='jm-card-title' style={{color: '#777'}}>Notes {`(${notes.length})`}</h4>
                <span  className='float-right'>
                    <Link to={`/notes`}>View All</Link>
                </span>
            </CardHeader>
            <CardBody style={{height: 'calc(100% - 45px)', overflow:'hidden'}}>
                <ConversationSummary {...props} load={loadNotes} notes={notes} onRowClick={(id) => {props.history.push(`/files/${id}`);}} />               
            </CardBody>
        </Card>
    );
}

export default withRouter(ConversationListView);