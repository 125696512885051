import React from "react";
import PerfectScrollbar from "perfect-scrollbar";

let ps;

class SidebarWrapper extends React.Component {
    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
                suppressScrollX: true,
                suppressScrollY: false
            });
        }
    }
    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
    }
    render() {
        const { className, user, headerLinks, links } = this.props;
        return (
            <div className={className} ref="sidebarWrapper">
                {user}
                {headerLinks}
                {links}
            </div>
        );
    }
}

export default SidebarWrapper;
