import React, { useState, useEffect } from 'react';
import { findDOMNode } from "react-dom";

import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';

import Dialog from "../common/Dialog";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";

import handleErrors from '../../util/handleErrors';
import api from "../../util/api";
import { requiresRole, policies } from '../../util/accessControl';

import moment from "moment";
import { withSnackbar } from 'notistack';

import RelatedAccountsEditor from "./RelatedAccountsEditor";
import RelatedSecuritiesEditor from "./RelatedSecuritiesEditor";
import RelatedInstructionEditor from "./RelatedInstructionEditor";
import RelatedPartyAddressesEditor from "./RelatedPartyAddressesEditor";
import AddressFormatter from "../../util/formatAddress";

const CourtActionEditor = (props) => {

    const originalItem = {
        ...props.dataItem,
        claimFiledDate: (props.dataItem.claimFiledDate == null || props.dataItem.claimFiledDate === "") ? null : moment(props.dataItem.claimFiledDate).toDate(),
        expiryDate: (props.dataItem.expiryDate == null || props.dataItem.expiryDate === "") ? null : moment(props.dataItem.expiryDate).toDate(),
        judgmentServedDate: (props.dataItem.judgmentServedDate == null || props.dataItem.judgmentServedDate === "") ? null : moment(props.dataItem.judgmentServedDate).toDate(),
        writIndorsed: (props.dataItem.writIndorsed == null || props.dataItem.writIndorsed === "") ? null : moment(props.dataItem.writIndorsed).toDate(),
        appearanceFiledDate: (props.dataItem.appearanceFiledDate == null || props.dataItem.appearanceFiledDate === "") ? null : moment(props.dataItem.appearanceFiledDate).toDate(),
        defenceFiledDate: (props.dataItem.defenceFiledDate == null || props.dataItem.defenceFiledDate === "") ? null : moment(props.dataItem.defenceFiledDate).toDate(),
    };
    const [itemData, setItemData] = useState(originalItem);
    const [originalItemData, setOriginalItemData] = useState(originalItem);
    const initLookupObject = { loading: false, list: [] };
    const [courtActionTypes, setCourtActionTypes] = useState(initLookupObject);
    const [editMode, setEditMode] = useState(!!props.editMode);
    const [errors, setErrors] = useState({});

    const editStageQuery = `/stage/courtaction/${itemData.stageId}`;
    const newStageQuery = `/stage/block/${itemData.stage.blockId}`;

    const handleSelectChange = ({ value, target: { name } }) => {
        const idField = `${name}Id`;
        setItemData({ ...itemData, [name]: value, [idField]: value ? value.lookupId : null });
    }

    const handleDateChange = ({ value, target: { name } }) => {
        setItemData({ ...itemData, [name]: value == null ? null : moment(value).local().toDate() });
    }

    const handleInputChange = ({ value, target: { name } }) => {
        setItemData({ ...itemData, [name]: value });
    }

    const handleCheckSelected = (element) => {
        let isChecked = !itemData[element];
        setItemData({ ...itemData, [element]: isChecked });
    }

    const updateRelatedEditorsData = (updatedData) => {
        setItemData(updatedData);
    }

    const validateForm = () => {
        var validForm = true;
        var formErrors = {};

        let account = itemData.stage.matterAccounts.find(v => v.noticeSelected === true);
        if (!account) {
            props.enqueueSnackbar('Related Account/s required.', { variant: 'error' });
            return false;
        }
        let security = itemData.stage.matterSecurities.find(v => v.noticeSelected === true);
        if (!security) {
            props.enqueueSnackbar('Related Security Properties required.', { variant: 'error' });
            return false;
        }

        let matterParties = itemData.stage.matterParties.find(v => v.noticeSelected === true);
        if (!matterParties) {
            props.enqueueSnackbar('Service Instructions required.', { variant: 'error' });
            return false;
        }

        let courtActionAddresses = itemData.courtActionAddresses;
        if (!courtActionAddresses) {
            props.enqueueSnackbar('Party Address(es) required.', { variant: 'error' });
            return false;
        }

        formErrors.claimFiledDate = (!itemData.claimFiledDate || itemData.claimFiledDate === '');
        formErrors.expiryDate = (!itemData.expiryDate || itemData.expiryDate === '');
        formErrors.actionNumber = (!itemData.actionNumber || itemData.actionNumber === '');
        formErrors.writIndorsed = (!itemData.writIndorsed || itemData.writIndorsed === '');
        formErrors.appearanceFiledDate = (!itemData.appearanceFiledDate || itemData.appearanceFiledDate === '');
        formErrors.defenceFiledDate = (!itemData.defenceFiledDate || itemData.defenceFiledDate === '');

        setErrors(formErrors);

        const item = Object.assign({}, formErrors)
        validForm = !Object.keys(item).some(x => item[x]);

        return validForm;
    }

    const setLookup = (setFunc) => (json) => {
        setFunc({ loading: false, list: json });
        return json;
    }

    useEffect(() => {
        const axiosConfig = { accept: 'application/json' };
        const loading = { loading: true, list: [] };
        setCourtActionTypes(loading);

        let onLoadTasks = [
            api.get((itemData.stageId > 0) ? editStageQuery : newStageQuery, axiosConfig),
            api.get(`/lookup/courtactiontype`, axiosConfig).then(setLookup(setCourtActionTypes)),
        ];

        Promise.all(onLoadTasks)
            .then(responses => {

                let tempData = { ...itemData };
                let stage = responses[0];
                let courtActionTypes = responses[1]

                if (itemData.stageId === 0) {
                    tempData = {
                        ...itemData,
                        courtActionType: courtActionTypes[0],
                        courtActionTypeId: courtActionTypes[0].lookupId,
                        stage: stage,
                    };
                } else {
                    tempData = {
                        ...stage,
                        claimFiledDate: (stage.claimFiledDate == null || stage.claimFiledDate === "") ? null : moment(stage.claimFiledDate).toDate(),
                        expiryDate: (stage.expiryDate == null || stage.expiryDate === "") ? null : moment(stage.expiryDate).toDate(),                        
                        judgmentServedDate: (stage.judgmentServedDate == null || stage.judgmentServedDate === "") ? null : moment(stage.judgmentServedDate).toDate(),
                        writIndorsed: (stage.writIndorsed == null || stage.writIndorsed === "") ? null : moment(stage.writIndorsed).toDate(),
                        appearanceFiledDate: (stage.appearanceFiledDate == null || stage.appearanceFiledDate === "") ? null : moment(stage.appearanceFiledDate).toDate(),
                        defenceFiledDate: (stage.defenceFiledDate == null || stage.defenceFiledDate === "") ? null : moment(stage.defenceFiledDate).toDate(),
                    };
                }
                setItemData(tempData);
                setOriginalItemData(tempData);
            })
            .catch(handleErrors(props))
    }, []);

    return (
        <Dialog 
            validate={validateForm}
            original={originalItemData}
            current={itemData}
            saveChanges={props.save}
            cancelChanges={props.cancel}
            width={1200}
            createMode={itemData.stageId === 0}
            
            title={
                <>
                    {`${itemData.accountId === 0 ? 'Add' : editMode ? 'Edit' : 'View'} Court Action`}
                    {requiresRole(<Button className='rounded-circle ml-2' primary={true} look="bare" icon={editMode ? 'lock' : 'edit'} onClick={(ev) => setEditMode(!editMode)} />, policies.edit)}
                </>}
        >    
            <form onSubmit={(ev) => ev.preventDefault()}>
                <GridContainer>

                    {editMode ? (
                    <>
                        <GridItem sm={4}>
                            <ComboBox
                                data={courtActionTypes.list}
                                loading={courtActionTypes.loading}
                                onFocus={(ev) => {if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click()}}
                                textField='lookupValue'
                                dataItemKey='lookupId'
                                value={itemData.courtActionType || courtActionTypes[0] }
                                onChange={handleSelectChange}
                                label="Court Action Type"
                                name='courtActionType'
                                id='courtActionType'
                            />
                        </GridItem>
                        {itemData.stage.matterAccounts && <RelatedAccountsEditor stageData={itemData} updateRelatedAccounts={updateRelatedEditorsData} editMode={editMode} />}
                        {itemData.stage.matterSecurities && <RelatedSecuritiesEditor stageData={itemData} updateRelatedSecurities={updateRelatedEditorsData} editMode={editMode} />}
                        <GridItem sm={4}>
                            <span className='k-textbox-container'>
                                <label className='k-label'>Date Claim filed</label>
                                <DatePicker
                                    defaultValue={new Date()}
                                    format="dd/MM/yyyy"
                                    name='claimFiledDate'
                                    id='claimFiledDate'
                                    onChange={handleDateChange}
                                    value={itemData.claimFiledDate}
                                    label=""
                                    required={true}
                                />
                            </span>
                            {errors.claimFiledDate && <span className='text-danger'>Date Claim filed is Required.</span>}
                        </GridItem>
                        <GridItem sm={4}>
                            <span className='k-textbox-container'>
                                <label className='k-label'>Valid for service by</label>
                                <DatePicker
                                    defaultValue={new Date()}
                                    format="dd/MM/yyyy"
                                    name='expiryDate'
                                    id='expiryDate'
                                    onChange={handleDateChange}
                                    value={itemData.expiryDate}
                                    label=""
                                    required={true}
                                />
                            </span>
                            {errors.expiryDate && <span className='text-danger'>Valid for service by is Required.</span>}
                        </GridItem>
                        <GridItem sm={4}>
                            <Input
                                width='100%'
                                label='Court Action Number'
                                value={itemData.actionNumber}
                                onChange={handleInputChange}
                                name="actionNumber"
                                id="actionNumber"
                                required={true}
                            />
                            {errors.actionNumber && <span className='text-danger'>Court Action Number is Required.</span>}
                        </GridItem>

                        <RelatedInstructionEditor stageData={itemData} updateRelatedInstructions={updateRelatedEditorsData} instructionsList={['substitutedService', 'personalService', 'serviceDetails', 'servedDate']} editMode={editMode} />
                        <RelatedPartyAddressesEditor stageData={itemData} updateRelatedPartyAddresses={updateRelatedEditorsData} editMode={editMode} />

                        <GridItem sm={4}>
                            <span className='k-textbox-container'>
                                <label className='k-label'>Writ indorsed</label>
                                <DatePicker
                                    defaultValue={new Date()}
                                    format="dd/MM/yyyy"
                                    name='writIndorsed'
                                    id='writIndorsed'
                                    onChange={handleDateChange}
                                    value={itemData.writIndorsed}
                                    label=""
                                    required={true}
                                />
                            </span>
                            {errors.writIndorsed && <span className='text-danger'>Writ indorsed is Required.</span>}
                        </GridItem>
                        <GridItem sm={4}>

                        </GridItem>
                        <GridItem sm={4} className='mt-3'>
                            <input type="checkbox" className='k-checkbox' checked={!!itemData.appearanceFiled} onChange={() => handleCheckSelected('appearanceFiled')} name="appearanceFiled" id="appearanceFiled" />
                            <label className="k-checkbox-label" htmlFor="appearanceFiled">Appearance filed</label>
                        </GridItem>
                        <GridItem sm={4} className='mt-3'>
                            <span className='k-textbox-container'>
                                <label className='k-label'>Appearance filed date</label>
                                <DatePicker
                                    defaultValue={new Date()}
                                    format="dd/MM/yyyy"
                                    name='appearanceFiledDate'
                                    id='appearanceFiledDate'
                                    onChange={handleDateChange}
                                    value={itemData.appearanceFiledDate}
                                    label=""
                                    required={true}
                                />
                            </span>
                            {errors.appearanceFiledDate && <span className='text-danger'>Appearance filed date is Required.</span>}
                        </GridItem>
                        <GridItem sm={4}>

                        </GridItem>

                        <GridItem sm={4} className='mt-3'>
                            <input type="checkbox" className='k-checkbox' checked={!!itemData.defenceFiled} onChange={() => handleCheckSelected('defenceFiled')} name="defenceFiled" id="defenceFiled" />
                            <label className="k-checkbox-label" htmlFor="defenceFiled">Defence filed</label>
                        </GridItem>

                        <GridItem sm={4} className='mt-3'>
                            <span className='k-textbox-container'>
                                <label className='k-label'>Defence filed date</label>
                                <DatePicker
                                    defaultValue={new Date()}
                                    format="dd/MM/yyyy"
                                    name='defenceFiledDate'
                                    id='defenceFiledDate'
                                    onChange={handleDateChange}
                                    value={itemData.defenceFiledDate}
                                    label=""
                                    required={true}
                                />
                            </span>
                            {errors.defenceFiledDate && <span className='text-danger'>Defence filed date is Required.</span>}
                        </GridItem>
                    </>
                    ) : (
                    <>
                        <GridItem sm={12} className=''>
                            <span className='k-textbox-container'>
                                <label className='ro-label'>Court Action Type:</label>
                                <div className="font-size-14">{itemData.courtActionType ? itemData.courtActionType.lookupValue : ''}</div>
                            </span>
                        </GridItem>
                        <GridItem sm={12} className=''>
                            <span className='k-textbox-container'>
                                <label className='ro-label'>Related Accounts:</label>
                                <div className="font-size-14">{itemData.stage.matterAccounts && itemData.stage.matterAccounts.length > 0 ? itemData.stage.matterAccounts.filter(val => !!val.noticeSelected).map((val, ind, arr) => val.accountNumber + ((ind + 1) < arr.length ? ', ' : '')) : ''}</div>
                            </span>
                        </GridItem>
                        <GridItem sm={12} className=''>
                            <span className='k-textbox-container'>
                                <label className='ro-label'>Related Security Properties:</label>
                                <div className="font-size-14">{itemData.stage.matterSecurities && itemData.stage.matterSecurities.length > 0 ? itemData.stage.matterSecurities.filter(val => !!val.noticeSelected).map((val, ind, arr) => AddressFormatter.format(val).address + ((ind + 1) < arr.length ? ', ' : '')) : ''}</div>
                            </span>
                        </GridItem>
                        <GridItem sm={4} className=''>
                            <span className='k-textbox-container'>
                                <label className='ro-label'>Date Claim filed:</label>
                                <div className="font-size-14">{moment(itemData.claimFiledDate).format('D/MM/YYYY')}</div>
                            </span>
                        </GridItem>
                        <GridItem sm={4} className=''>
                            <span className='k-textbox-container'>
                                <label className='ro-label'>Valid for service by:</label>
                                <div className="font-size-14">{moment(itemData.expiryDate).format('D/MM/YYYY')}</div>
                            </span>
                        </GridItem>
                        <GridItem sm={4} className=''>
                            <span className='k-textbox-container'>
                                <label className='ro-label'>Court Action Number:</label>
                                <div className="font-size-14">{itemData.actionNumber}</div>
                            </span>
                        </GridItem>
                        <GridItem sm={12} className=''>
                            <span className='k-textbox-container'>
                                <label className='ro-label'>Details of service:</label>
                                <div className="font-size-14">{itemData.serviceDetails}</div>
                            </span>
                        </GridItem>
                        <GridItem sm={12} className=''>
                            <span className='k-textbox-container'>
                                <label className='ro-label'>Service Instructions:</label>
                                {(itemData.stage.matterParties && itemData.stage.matterParties.length > 0) 
                                            ? itemData.stage.matterParties.filter(val => !!val.noticeSelected).map((val, ind, arr) =>
                                                <div key={`party-service-instruction-${val.party.partyId}`} className="font-size-14">
                                                    {val.party.firstName} {val.party.lastName}: {!!val.personalService && 'Personal Service'}{!!val.personalService && !!val.substitutedService && ' and '}{!!val.substitutedService && 'Substituted Service'} 
                                                    <br />{val.serviceDetails}
                                                    <br />{val.servedDate ? moment(val.servedDate).format('D/MM/YYYY') : '' }
                                                </div>)
                                : null}                                
                            </span>
                        </GridItem>
                        <GridItem sm={12} className=''>
                            <span className='k-textbox-container'>
                                <label className='ro-label'>Posted to:</label>
                                {(itemData.stage.matterParties && itemData.stage.matterParties.length > 0) ? itemData.stage.matterParties.map((val, ind, arr) => {
                                    let partyAddresses = val.party.partyAddresses ? val.party.partyAddresses.slice() : [];
                                    const addresses = partyAddresses.filter(val => !!val.noticeSelected).map(b => [
                                            b.addressName,
                                            b.addressUnitNumber,
                                            b.addressStreetNumber,
                                            b.addressStreetName,
                                            b.addressSuburb,
                                            b.addressState,
                                            b.addressPostCode].joinNonEmpty(" ")
                                        ).join(', ');
                                    if (addresses === "") {
                                        return null;
                                    } else {
                                        return <div key={`party-addresses-${val.party.partyId}`} className="font-size-14">{val.party.firstName} {val.party.lastName}: {addresses.substr(0, addresses.length - 2)}</div>;
                                    }                                    
                                }) : null }
                                
                            </span>
                        </GridItem>
                        <GridItem sm={3} className=''>
                            <span className='k-textbox-container'>
                                <label className='ro-label'>Judgment Served:</label>
                                <div className="font-size-14">{moment(itemData.judgmentServedDate).format('D/MM/YYYY')}</div>
                            </span>
                        </GridItem>
                        <GridItem sm={3} className=''>
                            <span className='k-textbox-container'>
                                <label className='ro-label'>Writ Indorsed:</label>
                                <div className="font-size-14">{moment(itemData.writIndorsed).format('D/MM/YYYY')}</div>
                            </span>
                        </GridItem>
                        <GridItem sm={3} className=''>
                            <span className='k-textbox-container'>
                                <label className='ro-label'>Appearance Filed:</label>
                                <div className="font-size-14">{!!itemData.appearanceFiled && moment(itemData.appearanceFiledDate).format('D/MM/YYYY')}</div>
                            </span>
                        </GridItem>
                        <GridItem sm={3} className=''>
                            <span className='k-textbox-container'>
                                <label className='ro-label'>Defence Filed:</label>
                                <div className="font-size-14">{!!itemData.defenceFiled && moment(itemData.defenceFiledDate).format('D/MM/YYYY')}</div>
                            </span>
                        </GridItem>
                    </>
                    )}
                </GridContainer>
            </form>
        </Dialog>
    )
}

export default withSnackbar(CourtActionEditor);