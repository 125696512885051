import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Dialog as KendoDialog } from '@progress/kendo-react-dialogs';
import { Window as KendoWindow } from '@progress/kendo-react-dialogs';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { requiresRole, policies } from '../../../util/accessControl';
import { Button } from '@progress/kendo-react-buttons';

const Dialog = (props) => {
    const dialogRoot = document.getElementById('dialog-root');
    const [savingInProgress, setSavingInProgress] = useState(false);
    const [ itemInDelete, setItemInDelete ] = useState(false);
    const { children, inEditMode, createMode, hideActions, deleteItem, deleteItemMessage, ...rest } = props;
    const hasChanges = () => {
        return !_.isEqual(props.original, props.current)
    };
    const onClose = (ev) => {
        const changed = hasChanges();
        if (!changed || window.confirm(process.env.REACT_APP_HAS_CHANGES_MSG)) {
            props.cancelChanges(ev, false);
        }        
    }
    // const handleCancelForm = (ev) => {
    //     const changed = hasChanges();
    //     if (!changed || (changed && window.confirm(process.env.REACT_APP_HAS_CHANGES_MSG))) {
    //         props.cancelChanges(ev, false);
    //     }
    // }
    const handleSaveForm = (ev) => {
        //console.log('handleSaveForm', savingInProgress);
        if (savingInProgress) return;
        setSavingInProgress(true);

        if (!hasChanges()) {
            props.cancelChanges(ev, false);
            setSavingInProgress(false);
            return;
        }
        
        if (props.validate()) {
            props.saveChanges(props.current);
        }
        else {
            setSavingInProgress(false);
        }
    }
    const handleDeleteForm = (ev) => {
        setItemInDelete(true);
    }

    return ReactDOM.createPortal (
        <KendoWindow
            {...rest}
            style={{ paddingBottom: inEditMode ? '0' : '16px', height: 'auto', top: '50%', transform: 'translateY(-50%)'}}
            className='jm-window'
            onClose={onClose}
            
            >
            {children}
            {!hideActions && <DialogActionsBar>
                <div className='d-flex w-100 mt-2'>
                    
                    {!createMode && requiresRole(inEditMode && <Button primary={false} className='jm-action-btn ml-3 mt-0 mb-3 mr-auto' style={{maxWidth: '150px'}} onClick={handleDeleteForm}>Delete</Button>, policies.edit)}
                    {!!createMode && inEditMode && <Button primary={true} className="jm-action-btn mt-0 mr-3 mb-3 ml-auto" style={{ maxWidth: '150px' }} onClick={handleSaveForm} disabled={savingInProgress}> {savingInProgress ? 'Saving' : 'Save'} </Button> }
                    {!createMode && requiresRole(inEditMode && <Button primary={true} className="jm-action-btn mt-0 mr-3 mb-3 ml-auto" style={{ maxWidth: '150px' }} onClick={handleSaveForm} disabled={savingInProgress}> {savingInProgress ? 'Saving' : 'Save' }  </Button>, policies.edit)}

                </div>                
            </DialogActionsBar>}
            {itemInDelete && <ConfirmDelete 
                title={deleteItem ? deleteItemMessage : `Delete not yet implemented here.`} 
                cancel={() => setItemInDelete(false)} 
                deleteItem={() => {
                    if (deleteItem) {
                        deleteItem(props.current);
                    }
                    setItemInDelete(false);
                }} 
            />}
        </KendoWindow>
    , dialogRoot);
}

Dialog.propTypes = {
    original: PropTypes.any.isRequired,
    current: PropTypes.any.isRequired,
    validate: PropTypes.func.isRequired,
    saveChanges: PropTypes.func.isRequired,
    cancelChanges: PropTypes.func.isRequired,
    deleteItem: PropTypes.func,
    deleteItemMessage: PropTypes.string,
    inEditMode: PropTypes.bool,
    hideActions: PropTypes.bool
}

Dialog.defaultProps = {
    inEditMode: true,
    hideActions: false
}

const ConfirmDelete = (props) => {
    const { cancel, deleteItem, title } = props;
    return (
        <KendoDialog title={"Delete confirmation"} onClose={cancel}>
            <p style={{ margin: "25px", textAlign: "center" }}>{title}</p>
            <DialogActionsBar>
                <button className="k-button" onClick={cancel}>No</button>
                <button className="k-button" onClick={deleteItem}>Yes</button>
            </DialogActionsBar>
        </KendoDialog>
    );
}

ConfirmDelete.propTypes = {
    title: PropTypes.any.isRequired,
    cancel: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
}

export default Dialog;
export {
    ConfirmDelete
}