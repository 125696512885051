import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import { withSnackbar } from 'notistack';
import moment from 'moment';

import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { Switch } from '@progress/kendo-react-inputs';
import { ExcelExport } from '@progress/kendo-react-excel-export';

import api from "../../util/api";
//import KendoGridDateCell from "../../util/KendoGridDateCell";
import withRouterAndRef from '../../util/withRouterAndRef';
import handleErrors from '../../util/handleErrors';

import ConversationEditor from "./ConversationEditor";
import ConversationSummary from "./ConversationSummary";

import LoadingPanel from '../common/Grid/LoadingPanel';
import GenericHeader from "../common/Header/GenericHeader"; 
import CardIcon from "../common/MuiKit/Card/CardIcon";
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";

import ChatIcon from "@material-ui/icons/Chat";

const saveConversation = (dataItem, props, afterSave, setLoading) => {
    setLoading(true);   
    if (dataItem.matterId === undefined) {
        dataItem.matterId = null;
    }
    api.request({
        url: `/conversation`,
        method: dataItem.conversationId === 0 ? 'POST' : 'PUT', 
        accept: 'application/json',
        headers: {
            'Content-Type': 'application/json'
        },
        data: JSON.stringify(dataItem)
    }) 
    .then(json => {
        afterSave(json);
        
    })
    .catch(handleErrors(props))
}

const Conversation = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        insert,
    }));

    const [ conversations, setConversations ] = useState([]);
    const [ total, setTotal ] = useState(0);
    const initPage = { take: 10, skip: 0 };
    const [ page, setPage] = useState(initPage)
    const defaultSort = {object: [], query: ''};
    const [ sort, setSort ] = useState(defaultSort);
    const [ loading, setLoading ] = useState(false);
    const [ itemInEdit, setItemInEdit] = useState(null);
    const [matterId] = useState(props.match.params.id ?? 0);
    const [ incomplete, setIncomplete ] = useState(true);
    const _export = useRef(null);
    const _grid = useRef(null);
    const conversationsSummaryRef = useRef();
    const msgRe = new RegExp(process.env.REACT_APP_NOTE_MSG_RE, 'mg');

    useEffect(() => {    
        if (!props.summary) {
            setLoading(true);   
    
            api.get(`/conversation/${matterId}/paged/${(page.skip / page.take) + 1}/${page.take}/${incomplete}${sort.query ? `?${sort.query}` : ``}`, {
                accept: 'application/json',
            }) 
            .then(json => {
                json.data.forEach(item => {
                    if (item.createdOn) {
                        item.createdOn = moment(item.createdOn).format('d/MM/YYYY');
                    }
                });
                setConversations(json.data);
                setTotal(json.totalRecords);
                setLoading(false);
            })
            .catch(handleErrors(props))

        }
    }, [page, sort, incomplete]);

    const edit = (dataItem) => {
        console.log('dataItem: ', dataItem);
        const conversation = Object.assign({}, dataItem);
        Object.keys(conversation).forEach(key => { if (conversation[key] == null) { conversation[key] = '' } });
        setItemInEdit(conversation);
        console.log('conversation: ', conversation);
    };

    const deleteItem = (dataItem) => {
        setLoading(true);

        api.delete(`/conversation/${dataItem.conversationId}`, {
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
        .then(json => {
            setLoading(false);
            setItemInEdit(null);
            if (conversationsSummaryRef && conversationsSummaryRef.current) {
                props.load();
            } else {
                setPage({ take: 10, skip: 0 });
            }
        })
        .catch(handleErrors(props))
    };

    const patch = (data, id, close = true) => {
        setLoading(true);
        api.request({
            url: `/conversation/updates/${id}`,
            method: 'POST',
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        }) 
        .then(json => { 
            if (close) {
                if (props.load) {
                    props.load();
                } else {
                    setPage({ take: 10, skip: 0 });
                }
                
                setItemInEdit(null);
            }
            
            setLoading(false);             
        })
        .catch(handleErrors(props));
    }

    const save = (dataItem) => {
        saveConversation(dataItem, props, (json) => {
             if (conversationsSummaryRef && conversationsSummaryRef.current) {
                props.load();
                setItemInEdit(null);
             } else {
                let items = conversations.slice();
                if (dataItem.conversationId === 0) {
                    dataItem.conversationId = json.conversationId;
                    items = [dataItem, ...items];
                } else {
                    var index = conversations.findIndex(v => v.conversationId === json.conversationId);            
                    items.splice(index, 1, dataItem);
                }
                
                setConversations(items);
                setTotal(items.length);
                setItemInEdit(null);
                setLoading(false);
            }
        }, setLoading);
    }

    const cancel = () => {
        setItemInEdit(null);
        setLoading(false);
    }

    const insert = () => {
        setItemInEdit({ conversationId: 0, matterId: props.match.params.id });
    }

    const renderEditors = (forceEdit = false) => {
        return (
            <React.Fragment>
                {itemInEdit && <ConversationEditor dataItem={itemInEdit} save={save} cancel={cancel} patch={patch} editMode={itemInEdit.conversationId === 0 || forceEdit} delete={deleteItem} />}
            </React.Fragment>
        )
    }

    const cleanUpMessage = (p) => {
        const { message } = p.dataItem;
        const result = (message ? [...message.matchAll(msgRe)].map(value => value[0].replace('&nbsp;', ' ')).join(" ") : '');
        return result;
    }

    const renderCard = () => {
        return (<>{!props.cards && <GenericHeader />}
            <Card style={{height: props.cardHeight, marginTop: !props.cards ? '35px' : '5px'}}>
                <CardHeader>
                    <CardIcon color='info' style={{marginTop: '-30px'}}>
                        <ChatIcon style={{color:'white'}} />
                    </CardIcon>
                    <h4 className='jm-card-title' style={{color: '#777'}}>Notes</h4>

                    <GridContainer className='my-3'>
                        <GridItem xs={1}>
                            <Button
                                primary={true}
                                className='jm-action-btn jm-header-btn rounded-circle'
                                icon='plus'
                                title='Add New'
                                onClick={insert}
                                style={{ marginTop: '-4px' }}
                            />
                        </GridItem>

                        <GridItem xs={1}>
                            <Switch onLabel='Incomplete' offLabel='All' className='mb-1 w-100 mr-5'
                                checked={incomplete}
                                onChange={(e) => {
                                    setIncomplete(!incomplete);
                                }}
                            />
                        </GridItem>

                        <GridItem xs={3}>
                            <ExcelExport ref={_export} />
                            <Button
                                primary={false}
                                className='jm-action-btn k-title'
                                onClick={() => {
                                    api.get(`/conversation/${matterId}/paged/1/10000/${incomplete}${sort.query ? `?${sort.query}` : ``}`, {
                                        accept: 'application/json',
                                    }).then(json => {
                                        json.data.forEach(item => {
                                            if (item.createdOn) {
                                                item.createdOn = moment(item.createdOn).format('d/MM/YYYY');
                                            }

                                            item.cMsg = cleanUpMessage({ dataItem: item });
                                        });
                                        _export.current.save(json, _grid.current.columns)
                                    })
                                }}
                                style={{ borderRadius: '8px' }}
                            >
                                EXPORT TO EXCEL
                                </Button>
                            <Grid ref={_grid} style={{ display: 'none' }}>
                                {matterId === 0 && <GridColumn field="matter.matterName" title="Matter" />}
                                <GridColumn field="createdOn" title="Added" />
                                <GridColumn field="to" title="To" />
                                <GridColumn field="from" title="From" />
                                <GridColumn field="cMsg" title="Message" />
                            </Grid>
                        </GridItem>
                    </GridContainer>
                </CardHeader>
                <CardBody>
                    {renderGrid()}
                </CardBody>
            </Card></>
            
        )
    }
    
    const renderGrid = () => {
        return (
            <React.Fragment>
                <Grid
                    filterable={false}
                    sortable={{
                        allowUnsort: true,
                        mode: 'single'
                    }}
                    sort={sort ? sort.object: []}
                    onSortChange={({ sort }) => {        
                        if (sort && sort.length > 0) {
                            var arr = sort.map((o, i) => {
                                return `sort[${i}].Property=${o.field}&sort[${i}].Direction=${o.dir === 'asc' ? 'Ascending' : 'descending'}`;
                            });
                            var str = arr.join('&');
                            setSort({object: sort, query: str});
                        } else {
                            setSort(defaultSort);
                        }

                    }}
                    pageable={props.pagerSettings}
                    data={conversations}
                    total={total}
                    take={page.take}
                    skip={page.skip}
                    onPageChange={(ev) => setPage(ev.page)}
                    style={{ height: 'calc(100vh - 275px)' }}
                    onRowClick={({ dataItem }) => { 
                        edit(dataItem, false) 
                    }}
                    className='clickableRow'
                >
                    { matterId === 0 && <GridColumn field="matter.matterName" title="Matter" width={180} /> }
                    <GridColumn field="createdOn" title="Added" format="{0:dd/MM/yyyy}"   width={180} />
                    <GridColumn field="to" title="To" width={240} />
                    <GridColumn field="from" title="From" width={240} />
                    {/* <GridColumn field="message" title="Message" cell={(colProps) => <KendoGridHtmlCell {...colProps} onClick={(ev) => edit(colProps.dataItem, false) } />} width={800} /> */}                    
                    {/* <GridColumn field="acknowledged" title="Acknowledged" cell={KendoGridBooleanCell} /> */}
                    <GridColumn title='Message' cell={(p) => {
                        return (
                            <td onClick={() => edit(p.dataItem)} style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>                                
                                {cleanUpMessage(p)}
                            </td>
                        )
                    }} />
                    {/* <GridColumn title='Acknowlege/Respond' width={200} cell={(p) => {
                        const { responseRequired, responseRecieved, acknowledged } = p.dataItem;
                        return (
                            <td onClick={() => edit(p.dataItem)}>                                
                                {
                                    (responseRequired && responseRecieved) ?
                                    'Response Recieved' :
                                    (responseRequired  && !responseRecieved) ?
                                    'Response Required' :
                                    (!responseRequired  && acknowledged) ?
                                    'Acknowledged' :
                                    (!responseRequired  && !acknowledged) ?
                                    'Unacknowledged' : ''
                                }
                            </td>
                        );
                    }} /> */}
                </Grid>
                {loading && <LoadingPanel/>}
                {renderEditors(true)}
            </React.Fragment>
        )
    }
    return props.summary ? <ConversationSummary {...props} edit={edit} load={props.load} deleteItem={deleteItem} renderEditors={renderEditors} ref={conversationsSummaryRef} /> : props.matterStageId ? renderGrid() : renderCard();
});

export default withSnackbar(withRouterAndRef(Conversation));
export { saveConversation }