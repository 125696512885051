import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import _ from 'lodash';
import { withSnackbar } from 'notistack';

import { requiresRole, policies } from '../../util/accessControl';
import LoadingPanel from '../common/Grid/LoadingPanel';
import { ConfirmDelete } from "../common/Dialog";
import cellWithEditing from '../common/Grid/CellWithEditing';

import api from "../../util/api";
import handleErrors from '../../util/handleErrors';
import KendoGridDateCell from "../../util/KendoGridDateCell";

import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';

import NoticeEditor from "../blockStage/NoticeEditor";
import CourtActionEditor from "../blockStage/CourtActionEditor";
import DischargeEditor from "../blockStage/DischargeEditor";
import EnforcementEditor from "../blockStage/EnforcementEditor";
import HoldEditor from "../blockStage/HoldEditor";
import MIPSaleEditor from "../blockStage/MIPSaleEditor";
import CustomerSaleEditor from "../blockStage/CustomerSaleEditor";

const BlockDetail = forwardRef((props, ref) => {

    const [loading, setLoading] = useState(false);
    const [blockTypes, setBlockTypes] = useState({});
    const [gridItems, setGridItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [ itemInDelete, setItemInDelete ] = useState(null);

    useImperativeHandle(ref, () => ({
        addBlockStage,
    }));

    useEffect(() => {
        setLoading(true);
        const detailCell = document.querySelector("tr.k-detail-row td.k-detail-cell");
        const colSpan = detailCell.getAttribute("colSpan") * 1;
        const type = 'blockType';

        detailCell.setAttribute("colSpan", colSpan + 1);

        Promise.all([
            api.get(`stage/${props.dataItem.blockId}/${props.dataItem.blockType.lookupValue}`),
            api.get(`lookup/${type}`)])
        .then(responses => {
            setGridItems(responses[0].data);
            setTotal(responses[0].totalRecords);

            const types = _.keyBy(responses[1], (item) => `${type}${item.lookupValue.replace(/\s/g,'')}`);
            _.every(types, item => types[`${type}${item.lookupValue.replace(/\s/g,'')}`] = item.lookupId);

            setBlockTypes(types)
        })
        .catch(handleErrors(props))
        .finally(setLoading(false))
    }, []);

    const [itemInStageNotice, setItemInStageNotice] = useState(null);
    const [itemInStageCourtAction, setItemInStageCourtAction] = useState(null);
    const [itemInStageEnforcement, setItemInStageEnforcement] = useState(null);
    const [itemInStageHold, setItemInStageHold] = useState(null);
    const [itemInStageDischarge, setItemInStageDischarge] = useState(null);
    const [itemInStageMIPSale, setItemInStageMIPSale] = useState(null);
    const [itemInStageCustomerSale, setItemInStageCustomerSale] = useState(null);

    const cancel = () => {
        if (itemInStageNotice)          { setItemInStageNotice(null); }
        if (itemInStageCourtAction)     { setItemInStageCourtAction(null); }
        if (itemInStageEnforcement)     { setItemInStageEnforcement(null); }
        if (itemInStageHold)            { setItemInStageHold(null); }
        if (itemInStageDischarge)       { setItemInStageDischarge(null); }
        if (itemInStageNotice)          { setItemInStageNotice(null); }
        if (itemInStageMIPSale)         { setItemInStageMIPSale(null); }
        if (itemInStageCustomerSale)    { setItemInStageCustomerSale(null); }
        setItemInDelete(null);
        setLoading(false);
    }

    const addBlockStage = (dataItem, editMode = true) => {
        
        if (dataItem.blockTypeId === blockTypes.blockTypeNotice) {
            setItemInStageNotice({ stageId: 0, editMode: editMode, stage: { stageId: 0, blockId: dataItem.blockId, block:dataItem } });

        } else if (dataItem.blockTypeId === blockTypes.blockTypeCourtAction) {
            setItemInStageCourtAction({ stageId: 0, editMode: editMode, stage: { stageId: 0, blockId: dataItem.blockId, block: dataItem }, courtActionAddresses: [] });

        } else if (dataItem.blockTypeId === blockTypes.blockTypeEnforcement) {
            setItemInStageEnforcement({ stageId: 0, editMode: editMode, stage: { stageId: 0, blockId: dataItem.blockId,  block: dataItem } });

        } else if (dataItem.blockTypeId === blockTypes.blockTypeHold) {
            setItemInStageHold({ stageId: 0, editMode: editMode, stage: { stageId: 0, blockId: dataItem.blockId,  block: dataItem } });

        } else if (dataItem.blockTypeId === blockTypes.blockTypeDischarge) {
            setItemInStageDischarge({ stageId: 0, editMode: editMode, stage: { stageId: 0, blockId: dataItem.blockId,  block: dataItem } });

        } else if (dataItem.blockTypeId === blockTypes.blockTypeMIPSale) {
            setItemInStageMIPSale({ stageId: 0, editMode: editMode, stage: { stageId: 0, blockId: dataItem.blockId, block: dataItem } });

        } else if (dataItem.blockTypeId === blockTypes.blockTypeCustomerSale) {
            setItemInStageCustomerSale({ stageId: 0, editMode: editMode, stage: { stageId: 0, blockId: dataItem.blockId, block: dataItem } });

        } 

    }

    const save = (url) => (dataItem) => {
        setLoading(true);
        api.request({
            url: `/stage/${url}`,
            method: dataItem.stageId === 0 ? 'POST' : 'PUT',
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(dataItem)
        })
        .then(json => {
            let items = gridItems.slice();
            if (dataItem.stageId === 0) {
                items = [json, ...items];
            } else {
                var index = gridItems.findIndex(v => v.stageId === json.stageId);
                items.splice(index, 1, json);
            }
            setGridItems(_.orderBy(items, ['openedDate'], ['asc']));
            cancel();
        })
        .catch(handleErrors(props))
        .finally(setLoading(false))
    }

    const edit = (dataItem, editMode = true) => {
        const item = { stageId: dataItem.stageId, stage: dataItem, editMode: editMode };
        if (dataItem.notice) { setItemInStageNotice(item); } 
        else if (dataItem.courtAction)  { setItemInStageCourtAction(item); }
        else if (dataItem.possession)   { setItemInStageMIPSale(item); }
        else if (dataItem.discharge)    { setItemInStageDischarge(item); }
        else if (dataItem.hold)         { setItemInStageHold(item); }
        else if (dataItem.enforcement)  { setItemInStageEnforcement(item); }
        else if (dataItem.customerSale) { setItemInStageCustomerSale(item); }
    };

    const remove = (dataItem) => {
        setItemInDelete(dataItem);
    };

    const deleteItem = () => {
        setLoading(true);
        api.delete(`/stage/${itemInDelete.stageId}`, {
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(json => {
            let items = gridItems.slice();
            var index = gridItems.findIndex(v => v.stageId === json.stageId);
            items.splice(index, 1);
            setGridItems(_.orderBy(items, ['openedDate'], ['asc']));
            setItemInDelete(null);
        })
        .catch(handleErrors(props))
        .finally(setLoading(false))
    };
    
    return (
        <React.Fragment>
            <Grid
            className='clickableRow'
                data={gridItems}
                total={total}
                onRowClick={({ dataItem }) => { edit(dataItem, false) }}
            >
                <GridToolbar>
                    {requiresRole(<Button
                        onClick={() => addBlockStage(props.dataItem)}
                        primary={true}
                        look='default' 
                        icon='plus'
                        className=' rounded-circle'>
                        {/* <span className='font-italic'>Add new stage</span>  */}
                    </Button>, policies.edit)}
                    
                </GridToolbar>
                <GridColumn cell={(p) => {
                    let content = null;
                    if (p.dataItem.notice) {
                        const { notice } = p.dataItem;
                        content = <span>{notice.noticeType.lookupValue}</span>;
                    } 
                    else if (p.dataItem.courtAction) {
                        const { courtAction } = p.dataItem;
                        content = <span>{courtAction.courtActionType.lookupValue}</span>;
                    }
                    else if (p.dataItem.possession) {
                        const { possession } = p.dataItem;
                        content = <span>{possession.propertyPresenter}</span>;
                    }
                    else if (p.dataItem.discharge) {
                        const { discharge } = p.dataItem;
                        content = <span>{discharge.dischargeType.lookupValue}</span>;
                    }
                    else if (p.dataItem.hold) {
                        const { hold } = p.dataItem;
                        content = <span>{hold.holdReason.lookupValue}</span>;
                    }
                    else if (p.dataItem.enforcement) {
                        const { enforcement } = p.dataItem;
                        content = <span>{enforcement.enforcementType.lookupValue}</span>;
                    }
                    else if (p.dataItem.customerSale) {
                        const { customerSale } = p.dataItem;
                        content = <span>{customerSale.customerSaleType.lookupValue}</span>;
                    }
                    return (<td onClick={() => edit(p.dataItem, false)}>{content}</td>);
                }} />
                
                <GridColumn field="openedDate" width={140} title="Opened Date" cell={KendoGridDateCell} />
                {/* <GridColumn editable={false} cell={cellWithEditing(edit, remove)} width={90} /> */}
            </Grid>
            {loading && <LoadingPanel/>}
            {itemInStageNotice && <NoticeEditor dataItem={itemInStageNotice} save={save('notice')} cancel={cancel} editMode={itemInStageNotice.editMode} />}
            {itemInStageCourtAction && <CourtActionEditor dataItem={itemInStageCourtAction} save={save('courtaction')} cancel={cancel} editMode={itemInStageCourtAction.editMode} />}
            {itemInStageEnforcement && <EnforcementEditor dataItem={itemInStageEnforcement} save={save('enforcement')} cancel={cancel} editMode={itemInStageEnforcement.editMode} />}            
            {itemInStageHold && <HoldEditor dataItem={itemInStageHold} save={save('hold')} cancel={cancel} editMode={itemInStageHold.editMode} />}
            {itemInStageDischarge && <DischargeEditor dataItem={itemInStageDischarge} save={save('discharge')} cancel={cancel} editMode={itemInStageDischarge.editMode} />}
            {itemInStageMIPSale && <MIPSaleEditor dataItem={itemInStageMIPSale} save={save('posession')} cancel={cancel} editMode={itemInStageMIPSale.editMode} />}
            {itemInStageCustomerSale && <CustomerSaleEditor dataItem={itemInStageCustomerSale} save={save('customer')} cancel={cancel} editMode={itemInStageCustomerSale.editMode} />}
            {itemInDelete && <ConfirmDelete title={`Delete Stage?`} cancel={cancel} deleteItem={deleteItem}  />}
            
        </React.Fragment>
    );
})

export default withSnackbar(BlockDetail);