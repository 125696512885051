import React, { forwardRef } from 'react';
import { Dialog as KendoDialog } from '@progress/kendo-react-dialogs';
import { Grid, GridColumn } from '@progress/kendo-react-grid';


const SearchResults = forwardRef((props, ref) => {
   
    return (
        <KendoDialog ref={ref} onClose={() => props.onClose()} width={1200}  title={`Search results '${props.searchText}'`} contentStyle={{overflow: 'hidden', height: 'calc(100vh - 200px)'}}>
            <Grid
                data={props.data}

                onRowClick={({ dataItem }) => {
                    props.onClose(dataItem);
                }}
                className='clickableRow'
                scrollable={true}
                //groupable={true}
                style={{height: '100%'}}

            >
                <GridColumn field="matterNumber" title="Matter Number" width={180} />
                <GridColumn field="matterName" title="Matter Name" width={180} />
                <GridColumn field="reference" title="Reference" width={180} />
                <GridColumn field="tableName" title="Entity Name" width={180} />
                <GridColumn field="results" title="Matches" cell={(p) => {
                    return (
                        <td>
                            {p.dataItem.results.map(item => { 
                                let highlighed = item.result.replace(new RegExp(props.searchText, 'gmi'), (match) => `<span class='highlight-text'>${match}</span>`);
                                return <div onClick={() => props.onClose(p.dataItem)} dangerouslySetInnerHTML={{__html: `${item.searched}: ${highlighed}`}}></div>

                            })}
                        </td>
                    )
                }} />
            </Grid>
        </KendoDialog>
    );
});

export default SearchResults;