import React, { useState, useEffect, useRef, useContext }  from 'react';
import { withRouter } from "react-router-dom";
import { withSnackbar } from 'notistack';

import LoadingPanel from '../common/Grid/LoadingPanel';
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import CardIcon from "../common/MuiKit/Card/CardIcon";
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";
import GenericHeader from "../common/Header/GenericHeader"; 

import GroupIcon from "@material-ui/icons/Group";
import llamaLogo from "../assets/img/JacMac Llama Logo.png";
import jacmacLogoFull from "../assets/img/Jacmac with text.png";

import api from "../../util/api";
import handleErrors from '../../util/handleErrors';

import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn } from '@progress/kendo-react-grid';

const Users = (props) => {
    const [ loading, setLoading ] = useState(false);
    const [ users, setUsers ] = useState([]);
    const [ total, setTotal ] = useState(0);
    const initPage = { take: 10, skip: 0 };
    const [ page, setPage] = useState(initPage)
    const defaultSort = {object: [], query: ''};
    const [sort, setSort] = useState(defaultSort); 
    const [roles, setRoles] = useState([]);
    const [role, setRole] = useState({});
    const [nameOrEmail, setNameOrEmail] = useState('');

    useEffect(() => {
        Promise.all([api.get(`/user/roles`, { accept: 'application/json' })])
            .then(response => {
                setRoles(response[0]);
                setRole(response[0][0])
            })
            .catch(handleErrors(props));
    }, []);

    useEffect(() => {
        setLoading(true);
        let roleFilter = role ? `filter[0].Property=role&filter[0].Operator=Equals&filter[0].Value=${role.name}&` : '';
        let nameOrEmailFilter = nameOrEmail ? `filter[1].Property=role&filter[1].Operator=Contains&filter[1].Value=${nameOrEmail}` : '';
        api.get(`/user/list/${(page.skip / page.take) + 1}/${page.take}/?${sort.query ? `${sort.query}&` : `&`}${roleFilter}${nameOrEmailFilter}`, {
            accept: 'application/json',
        })
        .then(json => {
            setUsers(json.data || []);
            setTotal(json.totalRecords);
        })
       .catch(handleErrors(props));

    }, [page, sort, role, nameOrEmail]);

    return (
        <>
            <GenericHeader/>
            <GridContainer>
                <GridItem xs={12}>
                    <Card style={{height: props.cardHeight, marginTop: '35px'}}>
                        <CardHeader>
                            <CardIcon color='warning' style={{marginTop: '-30px'}}>
                                <GroupIcon style={{color:'white'}} />
                            </CardIcon>
                            <h4 className='jm-card-title' style={{ color: '#777' }}>Contacts</h4>
                            <GridContainer className='my-3'>
                                <GridItem xs={2} className="mt-3">
                                    <DropDownList
                                        label='Role'
                                        data={roles}
                                        value={role}
                                        textField="description"
                                        dataItemKey="name"
                                        style={{ width: '100%' }}
                                        onChange={({ value }) => {
                                            setRole(value);
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={3} className="mt-3">
                                    <Input
                                        width='100%'
                                        label='Name or Email'
                                        value={nameOrEmail}
                                        onChange={({ value }) => {
                                            setNameOrEmail(value);
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardHeader>
                        <CardBody>
                        <Grid 
                            className='clickableRow'
                            filterable={false}
                            sortable={{
                                allowUnsort: true,
                                mode: 'single'
                            }}
                            sort={sort ? sort.object: []}
                            onSortChange={({ sort }) => {        
                                if (sort && sort.length > 0) {
                                    var arr = sort.map((o, i) => {
                                        return `sort[${i}].Property=${o.field}&sort[${i}].Direction=${o.dir == 'asc' ? 'Ascending' : 'descending'}`;
                                    });
                                    var str = arr.join('&');
                                    setSort({object: sort, query: str});
                                } else {
                                    setSort(defaultSort);
                                }
        
                            }}
                            pageable={props.pagerSettings}
                            data={users}
                            total={total}
                            take={page.take}
                            skip={page.skip}
                            onPageChange={(ev) => {                                
                                setPage(ev.page);
                            }}
                            onRowClick={({ dataItem }) => {
                                props.history.push(`/profile/${dataItem.userId}`)
                            }}
                            style={{height: 'calc(100vh - 215px)'}}
                        >
                            <GridColumn field="firstName" title="First Name" />
                            <GridColumn field="lastName" title="Last Name" />
                            <GridColumn field="title" title="Title" />
                            <GridColumn field="email" title="Email" />
                            <GridColumn field="phoneNumber" title="Phone Number" />
                            <GridColumn field="address" title="Address" />
                            {/* <GridColumn field="role" title="Role" /> */}

                        </Grid>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    )
}

export default withSnackbar(withRouter(Users));