import React, { useState, useContext } from 'react';
import { withRouter, Link } from "react-router-dom";
import api from "../../util/api";

import CardIcon from "../common/MuiKit/Card/CardIcon";
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";
import GenericHeader from "../common/Header/GenericHeader"; 
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";

import PersonIcon from "@material-ui/icons/Person";
import { Input } from '@progress/kendo-react-inputs';
//import { Button } from '@progress/kendo-react-buttons';

import { ProfileContext } from '../store';
import emptyProfilePicture from "../profile/empty-profile-picture.png";
import _ from 'lodash';

import llamaLogo from "../assets/img/JacMac Llama Logo.png";
import jacmacLogoFull from "../assets/img/Jacmac with text.png";

const Login = (props) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState(''); 
    const [errors, setErrors] = useState([]);     
    const { dispatch } = useContext(ProfileContext);

    const handleSubmit = (event) => {
        setErrors([]);
        event.preventDefault();

        var body = { username, password, redirect: (props.location.state && props.location.state.from) ? props.location.state.from.pathname : null };

        api
        .post('/auth/login', body)
        .then(response => {
            localStorage.setItem('Llama.expires', response.expires);
            localStorage.setItem('Llama.username', response.username);
            localStorage.setItem('Llama.displayName', response.displayName);
            localStorage.setItem('Llama.role', response.role);
            let avatar = response.avatar ? `data:${response.avatarContentType};base64,${response.avatar}` : emptyProfilePicture;
            localStorage.setItem('Llama.avatar', avatar);
            dispatch({type: 'setProfile', payload: { 
                name: response.displayName, 
                avatar  
            }});
            //props.history.push(response.redirect == null ? '/' : response.redirect)
            window.location.replace(response.redirect == null ? '/' : response.redirect);
        }).catch(({ response: { data } }) => {
            var messages = _.flatten(Object.keys(data).map(key => data[key]));
            setErrors(messages);
        })
    }
    return (
        <div style={{ height: 'calc(100vh - 50px)', display: 'flex', alignItems: 'center' }}>

            <Card style={{ maxWidth: '400px', margin: '0 auto' }}>
                <CardHeader>
                    <img alt='' src={llamaLogo} className='img-fluid' style={{ height: '35px', paddingRight: '15px' }} />
                    <img alt='' src={jacmacLogoFull} className='img-fluid ml-auto float-right' style={{ height: '55px' }} />
                    <h3>Login</h3>
                </CardHeader>
                <CardBody>
                    <form onSubmit={handleSubmit}>
                        <GridContainer>
                            {errors.length > 0 &&
                                <GridItem sm={12}>
                                    {errors.map(error => (<p className='text-danger'>{error}</p>))}
                                </GridItem>
                            }
                            <GridItem sm={12}
                                className='mt-2'>
                                <Input
                                    width='100%'
                                    label='Username'
                                    value={username}
                                    onChange={e => setUsername(e.target.value)}
                                    name="username"
                                    id="username"
                                />
                            </GridItem>
                            <GridItem sm={12}
                                className='mt-2'>
                                <Input
                                    width='100%'
                                    label='Password'
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    name="password"
                                    id="password"
                                    type='password'
                                />
                            </GridItem>
                            <GridItem sm={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '12px' }}>
                                <Link to='/forgot' style={{ fontSize: '12px' }}>
                                    Forgot password
                                </Link>
                                <button
                                    className="k-button k-title"
                                    type="submit" >
                                    Login
                                </button>
                            </GridItem>
                        </GridContainer>
                    </form>
                </CardBody>
            </Card>
        </div>       
    )
};

export default withRouter(Login);