import React, { useState, useEffect } from 'react';
import { findDOMNode } from "react-dom";

import { Button } from '@progress/kendo-react-buttons';
import Dialog from "../common/Dialog";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";

import { ComboBox } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';

import api from "../../util/api"
import handleErrors from '../../util/handleErrors';
import { requiresRole, policies } from '../../util/accessControl';

import moment from "moment";
import { withSnackbar } from 'notistack';

//import RelatedAccountsEditor from "./RelatedAccountsEditor";
import RelatedSecuritiesEditor from "./RelatedSecuritiesEditor";
import RelatedInstructionEditor from "./RelatedInstructionEditor";
import AddressFormatter from "../../util/formatAddress";

const EnforcementEditor = (props) => {
    const originalItem = {
        ...props.dataItem,
        formIssuedDate: (props.dataItem.formIssuedDate == null || props.dataItem.formIssuedDate === "") ? null : moment(props.dataItem.formIssuedDate).toDate(),
        expiryDate: (props.dataItem.expiryDate == null || props.dataItem.expiryDate === "") ? null : moment(props.dataItem.expiryDate).toDate(),
        psdoFiledDate: (props.dataItem.psdoFiledDate == null || props.dataItem.psdoFiledDate === "") ? null : moment(props.dataItem.psdoFiledDate).toDate(),
        psdoMadeDate: (props.dataItem.psdoMadeDate == null || props.dataItem.psdoMadeDate === "") ? null : moment(props.dataItem.psdoMadeDate).toDate(),
        agentInstructedDate: (props.dataItem.agentInstructedDate == null || props.dataItem.agentInstructedDate === "") ? null : moment(props.dataItem.agentInstructedDate).toDate(),
        sheriffInstructedDate: (props.dataItem.sheriffInstructedDate == null || props.dataItem.sheriffInstructedDate === "") ? null : moment(props.dataItem.sheriffInstructedDate).toDate(),
        evictionScheduledDate: (props.dataItem.evictionScheduledDate == null || props.dataItem.evictionScheduledDate === "") ? null : moment(props.dataItem.evictionScheduledDate).toDate(),
        evictionEffectedDate: (props.dataItem.evictionEffectedDate == null || props.dataItem.evictionEffectedDate === "") ? null : moment(props.dataItem.evictionEffectedDate).toDate(),
        sentToSheriff: (props.dataItem.sentToSheriff == null || props.dataItem.sentToSheriff === "") ? null : moment(props.dataItem.sentToSheriff).toDate(),
        sheriffFileClosed: (props.dataItem.sheriffFileClosed == null || props.dataItem.sheriffFileClosed === "") ? null : moment(props.dataItem.sheriffFileClosed).toDate(),
    };

    const [itemData, setItemData] = useState(originalItem);
    const [originalItemData, setOriginalItemData] = useState(originalItem);
    const initLookupObject = { loading: false, list: [] };
    const [enforcementTypes, setEnforcementTypes] = useState(initLookupObject);
    const [editMode, setEditMode] = useState(!!props.editMode);
    const [errors, setErrors] = useState({});

    const editStageQuery = `/stage/enforcement/${itemData.stageId}`;
    const newStageQuery = `/stage/block/${itemData.stage.blockId}`;

    const handleSelectChange = ({ value, target: { name } }) => {
        const idField = `${name}Id`;
        setItemData({ ...itemData, [name]: value, [idField]: value ? value.lookupId : null });
    }

    const handleDateChange = ({ value, target: { name } }) => {
        setItemData({ ...itemData, [name]: value == null ? null : moment(value).local().toDate() });
    }

    const handleTextAreaChange = ({ target: { value, name } }) => {
        setItemData({ ...itemData, [name]: value });
    }

    const updateRelatedEditorsData = (updatedData) => {
        setItemData(updatedData);
    }

    const validateForm = (ev) => {
        var validForm = true;
        var formErrors = {};

        let security = itemData.stage.matterSecurities.filter(v => v.noticeSelected === true);
        if (security.length === 0) {
            props.enqueueSnackbar('Related Security Properties required.', { variant: 'error' });
            return false;
        } else if (security.length > 1) {
            props.enqueueSnackbar('Please select 1 Related Security Properties.', { variant: 'error' });
            return false;
        }

        let matterParties = itemData.stage.matterParties.filter(v => v.noticeSelected === true);
        if (matterParties.length === 0) {
            props.enqueueSnackbar('Service Instructions required.', { variant: 'error' });
            return false;
        } else if (matterParties.length > 1) {
            props.enqueueSnackbar('Please select 1 Service Instructions.', { variant: 'error' });
            return false;
        }
        
        formErrors.formIssuedDate = (!itemData.formIssuedDate || itemData.formIssuedDate === '');
        formErrors.expiryDate = (!itemData.expiryDate || itemData.expiryDate === '');
        formErrors.psdoFiledDate = (!itemData.psdoFiledDate || itemData.psdoFiledDate === '');
        formErrors.sentToSheriff = (!itemData.sentToSheriff || itemData.sentToSheriff === '');
        formErrors.sheriffFileClosed = (!itemData.sheriffFileClosed || itemData.sheriffFileClosed === '');
        formErrors.psdoMadeDate = (!itemData.psdoMadeDate || itemData.psdoMadeDate === '');
        formErrors.agentAttending = (!itemData.agentAttending || itemData.agentAttending === '');
        formErrors.agentInstructedDate = (!itemData.agentInstructedDate || itemData.agentInstructedDate === '');
        formErrors.sheriffInstructedDate = (!itemData.sheriffInstructedDate || itemData.sheriffInstructedDate === '');
        formErrors.evictionScheduledDate = (!itemData.evictionScheduledDate || itemData.evictionScheduledDate === '');
        formErrors.evictionEffectedDate = (!itemData.evictionEffectedDate || itemData.evictionEffectedDate === '');

        setErrors(formErrors);

        const item = Object.assign({}, formErrors)
        validForm = !Object.keys(item).some(x => item[x]);

        return validForm;
    }

    const setLookup = (setFunc) => (json) => {
        setFunc({ loading: false, list: json });
        return json;
    }

    useEffect(() => {
        const axiosConfig = { accept: 'application/json' };
        const loading = { loading: true, list: [] };
        setEnforcementTypes(loading);

        let onLoadTasks = [
            api.get((itemData.stageId > 0) ? editStageQuery : newStageQuery, axiosConfig),
            api.get(`/lookup/enforcementtype`, axiosConfig).then(setLookup(setEnforcementTypes)),
        ];

        Promise.all(onLoadTasks)
            .then(responses => {
                let tempData = { ...itemData };
                let stage = responses[0];
                let enforcementTypes = responses[1]

                if (tempData.stageId === 0) {
                    tempData = {
                        ...itemData,
                        enforcementType: enforcementTypes[0],
                        enforcementTypeId: enforcementTypes[0].lookupId,
                        stage: stage,
                    };
                } else {
                    tempData = {
                        ...stage,
                        formIssuedDate: (stage.formIssuedDate == null || stage.formIssuedDate === "") ? null : moment(stage.formIssuedDate).toDate(),
                        expiryDate: (stage.expiryDate == null || stage.expiryDate === "") ? null : moment(stage.expiryDate).toDate(),
                        psdoFiledDate: (stage.psdoFiledDate == null || stage.psdoFiledDate === "") ? null : moment(stage.psdoFiledDate).toDate(),
                        psdoMadeDate: (stage.psdoMadeDate == null || stage.psdoMadeDate === "") ? null : moment(stage.psdoMadeDate).toDate(),
                        agentInstructedDate: (stage.agentInstructedDate == null || stage.agentInstructedDate === "") ? null : moment(stage.agentInstructedDate).toDate(),
                        sheriffInstructedDate: (stage.sheriffInstructedDate == null || stage.sheriffInstructedDate === "") ? null : moment(stage.sheriffInstructedDate).toDate(),
                        evictionScheduledDate: (stage.evictionScheduledDate == null || stage.evictionScheduledDate === "") ? null : moment(stage.evictionScheduledDate).toDate(),
                        evictionEffectedDate: (stage.evictionEffectedDate == null || stage.evictionEffectedDate === "") ? null : moment(stage.evictionEffectedDate).toDate(),
                        sentToSheriff: (stage.sentToSheriff == null || stage.sentToSheriff === "") ? null : moment(stage.sentToSheriff).toDate(),
                        sheriffFileClosed: (stage.sheriffFileClosed == null || stage.sheriffFileClosed === "") ? null : moment(stage.sheriffFileClosed).toDate(),
                    };
                }
                setItemData(tempData);
                setOriginalItemData(tempData);
            })
            .catch(handleErrors(props))
    }, []);

    return (
        <Dialog

            validate={validateForm}
            original={originalItemData}
            current={itemData}
            saveChanges={props.save}
            cancelChanges={props.cancel}
            width={1200}
            createMode={itemData.stageId === 0}
            title={
                <>
                    {`${itemData.accountId === 0 ? 'Add' : editMode ? 'Edit' : 'View'} Enforcement`}
                    {requiresRole(<Button className='rounded-circle ml-2' primary={true} look="bare" icon={editMode ? 'lock' : 'edit'} onClick={(ev) => setEditMode(!editMode)} />, policies.edit)}
                </>}
        >
            <form onSubmit={(ev) => ev.preventDefault()}>
                <GridContainer>

                    {editMode ? (
                        <>
                            <GridItem sm={4}>
                                <ComboBox
                                    data={enforcementTypes.list}
                                    loading={enforcementTypes.loading}
                                    onFocus={(ev) => {if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click()}}
                                    textField='lookupValue'
                                    dataItemKey='lookupId'
                                    value={itemData.enforcementType}
                                    onChange={handleSelectChange}
                                    label="Enforcement Type"
                                    name='enforcementType'
                                    id='enforcementType'
                                />
                            </GridItem>
                            {itemData.stage.matterSecurities && <RelatedSecuritiesEditor stageData={itemData} updateRelatedSecurities={updateRelatedEditorsData} editMode={editMode} />}
                            <GridItem sm={4}>
                                <span className='k-textbox-container'>
                                    <label className='k-label'>Date form 14 issued</label>
                                    <DatePicker
                                        defaultValue={new Date()}
                                        format="dd/MM/yyyy"
                                        name='formIssuedDate'
                                        id='formIssuedDate'
                                        onChange={handleDateChange}
                                        value={itemData.formIssuedDate}
                                        label=""
                                        required={true}
                                    />
                                </span>
                                {errors.formIssuedDate && <span className='text-danger'>Date form 14 issued is Required.</span>}
                            </GridItem>
                            <GridItem sm={4}>
                                <span className='k-textbox-container'>
                                    <label className='k-label'>Expiry date</label>
                                    <DatePicker
                                        defaultValue={new Date()}
                                        format="dd/MM/yyyy"
                                        name='expiryDate'
                                        id='expiryDate'
                                        onChange={handleDateChange}
                                        value={itemData.expiryDate}
                                        label=""
                                        required={true}
                                    />
                                </span>
                                {errors.expiryDate && <span className='text-danger'>Expiry date is Required.</span>}
                            </GridItem>
                            {(itemData.stage.matterParties) && <RelatedInstructionEditor stageData={itemData} updateRelatedInstructions={updateRelatedEditorsData} instructionsList={['regularlastknown']} editMode={editMode} />}
                            <GridItem sm={4}>
                                <span className='k-textbox-container'>
                                    <label className='k-label'>PSDO Application filed</label>
                                    <DatePicker
                                        defaultValue={new Date()}
                                        format="dd/MM/yyyy"
                                        name='psdoFiledDate'
                                        id='psdoFiledDate'
                                        onChange={handleDateChange}
                                        value={itemData.psdoFiledDate}
                                        label=""
                                        required={true}
                                    />
                                </span>
                                {errors.psdoFiledDate && <span className='text-danger'>PSDO Application filed is Required.</span>}
                            </GridItem>
                            <GridItem sm={4}>
                                <span className='k-textbox-container'>
                                    <label className='k-label'>Sent to Sheriff</label>
                                    <DatePicker
                                        defaultValue={new Date()}
                                        format="dd/MM/yyyy"
                                        name='sentToSheriff'
                                        id='sentToSheriff'
                                        onChange={handleDateChange}
                                        value={itemData.sentToSheriff}
                                        label=""
                                        required={true}
                                    />
                                </span>
                                {errors.sentToSheriff && <span className='text-danger'>Sent to Sheriff is Required.</span>}
                            </GridItem>
                            <GridItem sm={4}>
                                <span className='k-textbox-container'>
                                    <label className='k-label'>Sheriff file closed</label>
                                    <DatePicker
                                        defaultValue={new Date()}
                                        format="dd/MM/yyyy"
                                        name='sheriffFileClosed'
                                        id='sheriffFileClosed'
                                        onChange={handleDateChange}
                                        value={itemData.sheriffFileClosed}
                                        label=""
                                        required={true}
                                    />
                                </span> 
                                {errors.sheriffFileClosed && <span className='text-danger'>Sheriff file closed is Required.</span>}
                            </GridItem>
                            <GridItem sm={4} className="mt-3">
                                <span className='k-textbox-container'>
                                    <label className='k-label'>Date PSDO made</label>
                                    <DatePicker
                                        defaultValue={new Date()}
                                        format="dd/MM/yyyy"
                                        name='psdoMadeDate'
                                        id='psdoMadeDate'
                                        onChange={handleDateChange}
                                        value={itemData.psdoMadeDate}
                                        label=""
                                        required={true}
                                    />
                                </span>
                                {errors.psdoMadeDate && <span className='text-danger'>Date PSDO made is Required.</span>}
                            </GridItem>
                            <GridItem sm={4} className="mt-3">
                                <span className='k-textbox-container'>
                                    <label className='k-label'>Agent instructed</label>
                                    <DatePicker
                                        defaultValue={new Date()}
                                        format="dd/MM/yyyy"
                                        name='agentInstructedDate'
                                        id='agentInstructedDate'
                                        onChange={handleDateChange}
                                        value={itemData.agentInstructedDate}
                                        label=""
                                        required={true}
                                    />
                                </span>
                                {errors.agentInstructedDate && <span className='text-danger'>Agent instructed is Required.</span>}
                            </GridItem>
                            
                            <GridItem sm={4} className="mt-3">
                                <span className='k-textbox-container'>
                                    <label className='k-label'>Sheriff instructed</label>
                                    <DatePicker
                                        defaultValue={new Date()}
                                        format="dd/MM/yyyy"
                                        name='sheriffInstructedDate'
                                        id='sheriffInstructedDate'
                                        onChange={handleDateChange}
                                        value={itemData.sheriffInstructedDate}
                                        label=""
                                        required={true}
                                    />
                                </span>
                                {errors.sheriffInstructedDate && <span className='text-danger'>Sheriff instructed is Required.</span>}
                            </GridItem>
                            <GridItem sm={4} className="mt-3">
                                <span className='k-textbox-container'>
                                    <label className='k-label'>Eviction scheduled</label>
                                    <DatePicker
                                        defaultValue={new Date()}
                                        format="dd/MM/yyyy"
                                        name='evictionScheduledDate'
                                        id='evictionScheduledDate'
                                        onChange={handleDateChange}
                                        value={itemData.evictionScheduledDate}
                                        label=""
                                        required={true}
                                    />
                                </span>
                                {errors.evictionScheduledDate && <span className='text-danger'>Eviction scheduled is Required.</span>}
                            </GridItem>
                            <GridItem sm={4} className="mt-3">
                                <span className='k-textbox-container'>
                                    <label className='k-label'>Eviction effected</label>
                                    <DatePicker
                                        defaultValue={new Date()}
                                        format="dd/MM/yyyy"
                                        name='evictionEffectedDate'
                                        id='evictionEffectedDate'
                                        onChange={handleDateChange}
                                        value={itemData.evictionEffectedDate}
                                        label=""
                                        required={true}
                                    />
                                </span>
                                {errors.evictionEffectedDate && <span className='text-danger'>Eviction effected is Required.</span>}
                            </GridItem>
                            <GridItem sm={12} className="mt-3">
                                <span className='k-textbox-container'>
                                    <label className='k-label'>Details of agent attending</label>
                                    <textarea
                                        className='k-textarea w-100'
                                        value={itemData.agentAttending}
                                        onChange={handleTextAreaChange}
                                        name="agentAttending"
                                        id="agentAttending"
                                        rows={3}
                                        required={true}
                                    />
                                </span>
                                {errors.agentAttending && <span className='text-danger'>Details of agent attending is Required.</span>}
                            </GridItem>
                    
                        </>
                    ) : (
                        <>
                            <GridItem sm={12} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Enforcement Type:</label>
                                    <div className="font-size-14">{itemData.enforcementType ? itemData.enforcementType.lookupValue : ''}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={12} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Related Security Properties:</label>
                                    <div className="font-size-14">{itemData.stage.matterSecurities && itemData.stage.matterSecurities.length > 0 ? itemData.stage.matterSecurities.filter(val => !!val.noticeSelected).map((val, ind, arr) => AddressFormatter.format(val).address + ((ind + 1) < arr.length ? ', ' : '')) : ''}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Date form 14 issued:</label>
                                    <div className="font-size-14">{moment(itemData.formIssuedDate).format('D/MM/YYYY')}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Expiry date:</label>
                                    <div className="font-size-14">{moment(itemData.expiryDate).format('D/MM/YYYY')}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={12} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Service Instructions:</label>
                                    {(itemData.stage.matterParties && itemData.stage.matterParties.length > 0) 
                                    ? itemData.stage.matterParties.filter(val => !!val.noticeSelected).map((val, ind, arr) => <div className="font-size-14">{val.party.firstName} {val.party.lastName}: {!!val.registeredLastKnown && 'Regular post to last known address'}</div>)
                                    : null}                                
                                </span>
                            </GridItem>
                            <GridItem sm={3} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>PSDO Application filed:</label>
                                    <div className="font-size-14">{moment(itemData.psdoFiledDate).format('D/MM/YYYY')}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={3} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Sent to Sheriff:</label>
                                    <div className="font-size-14">{moment(itemData.sentToSheriff).format('D/MM/YYYY')}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={3} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Sheriff file closed:</label>
                                    <div className="font-size-14">{moment(itemData.sheriffFileClosed).format('D/MM/YYYY')}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={3} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Date PSDO made:</label>
                                    <div className="font-size-14">{moment(itemData.psdoMadeDate).format('D/MM/YYYY')}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={3} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Agent instructed:</label>
                                    <div className="font-size-14">{moment(itemData.agentInstructedDate).format('D/MM/YYYY')}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={3} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Sheriff instructed:</label>
                                    <div className="font-size-14">{moment(itemData.sheriffInstructedDate).format('D/MM/YYYY')}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={3} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Eviction scheduled:</label>
                                    <div className="font-size-14">{moment(itemData.evictionScheduledDate).format('D/MM/YYYY')}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={3} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Eviction effected:</label>
                                    <div className="font-size-14">{moment(itemData.evictionEffectedDate).format('D/MM/YYYY')}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={12} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Details of agent attending:</label>
                                    <div className="font-size-14">{itemData.agentAttending}</div>
                                </span>
                            </GridItem>
                        </>
                    )}
                </GridContainer>
            </form>
        </Dialog>
    )
}

export default withSnackbar(EnforcementEditor);