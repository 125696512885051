import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import { withSnackbar } from 'notistack';
import moment from "moment";

import GenericHeader from "../common/Header/GenericHeader"; 
import LoadingPanel from '../common/Grid/LoadingPanel';

import cellWithDownload from "../common/Grid/CellWithDocument";
import CardIcon from "../common/MuiKit/Card/CardIcon";
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
//import { ConfirmDelete } from "../common/Dialog";
//import Dialog, { ConfirmDelete } from "../common/Dialog";
//import cellWithEditing from "../common/Grid/CellWithEditing";

import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Input } from '@progress/kendo-react-inputs';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { formatDate } from '@telerik/kendo-intl';
//import { DropDownList } from '@progress/kendo-react-dropdowns';

import api from "../../util/api"
import KendoGridDateCell from "../../util/KendoGridDateCell";
import withRouterAndRef from '../../util/withRouterAndRef';
import handleErrors from '../../util/handleErrors';

import AttachIcon from "@material-ui/icons/AttachFile";

import DocumentSummary from './DocumentSummary';
import DocumentEditor from "./DocumentEditor";

const saveDocument = (dataItem, props, afterSave, setLoading) => {
    setLoading(true);           
    const formData = new FormData();
    Object.keys(dataItem).forEach(key => {
        if ((dataItem[key] instanceof Date)) {
            formData.append(key, dataItem[key] === null ? null : formatDate(new Date(dataItem[key]), "yyyy/MM/dd"));
        }
        else {
            if (dataItem[key] != null) {
                formData.append(key, dataItem[key]);
            }
        }
    });
    api.request({
        url: `/document`,
        method: dataItem.documentId === 0 ? 'POST' : 'PUT', 
        accept: 'application/json',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
    }) 
    .then(json => {
        afterSave(json);
    })
    .catch(handleErrors(props))
    .finally(() => setLoading(false))
}
const Document = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        insert,
    }));

    const [ documents, setDocuments ] = useState([]);
    const [ total, setTotal ] = useState(0);
    const [ page, setPage ] = useState({ take: 10, skip: 0 });
    
    const defaultSort = {object: [{field: "uploadedDate", dir: "desc"}], query: 'sort[0].Property=createdOn&sort[0].Direction=descending'};
    
    const [ sort, setSort ] = useState(defaultSort);  

    const [ loading, setLoading ] = useState(false);
    const [ itemInEdit, setItemInEdit] = useState(null);
    const [ itemInDelete, setItemInDelete] = useState(null);
    const [matterId] = useState(props.match.params.id);

    const documentsSummaryRef = useRef();
    const defaultDocumentCategory = { lookupId: 0, lookupValue: 'All' };
    const [documentCategories, setDocumentCategories] = useState([]);
    const [documentCategory, setDocumentCategory] = useState(defaultDocumentCategory);
    const [documentName, setDocumentName] = useState('');
    const [filter, setFilter] = useState({ category: documentCategory, name: documentName });
    const axiosConfig = { accept: 'application/json' };
    const _export = useRef(null);
    const _grid = useRef(null);

    useEffect(() => {   
        if (!props.summary) {
            setLoading(true);   

            let onLoadItems = [
                api.get(`/document/${matterId ?? 0}/paged/${(page.skip / page.take) + 1}/${page.take}/${filter.category.lookupId}/${filter.name}${sort ? `?${sort.query}` : ``}`, axiosConfig),
                api.get(`/lookup/documentcategory`, axiosConfig)
            ];

            Promise.all(onLoadItems)
                .then(responses => {
                    let json = responses[0];
                    setDocuments(json.data);
                    setTotal(json.totalRecords);

                    let cats = responses[1];
                    setDocumentCategories([defaultDocumentCategory, ...cats])
                    setLoading(false);
                })
                .catch(handleErrors(props));

        } 
    }, [page, sort, filter]);

    const edit = (dataItem) => {
        const document = Object.assign({}, dataItem)
        Object.keys(document).forEach(key => { if (document[key] == null) { document[key] = '' }})
        setItemInEdit(document);
    };

    const remove = (dataItem) => {
        setItemInDelete(dataItem);
    };

    const deleteItem = (dataItem) => {
        setLoading(true);
        api.delete(`/document/${dataItem.documentId}`, {
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
        .then(json => {
            setLoading(false);
            setItemInEdit(null);
            if (documentsSummaryRef && documentsSummaryRef.current) {
                documentsSummaryRef.current.load();
            } else {
                setPage({ take: 10, skip: 0 });       
            }
        })
        .catch(handleErrors(props))
    };

    const save = (dataItem) => {
        saveDocument(dataItem, props, (json) => {
            if (documentsSummaryRef && documentsSummaryRef.current) {
                documentsSummaryRef.current.load();
                setItemInEdit(null);
            } else {
                let items = documents.slice();
                if (dataItem.documentId === 0) {
                    dataItem.documentId = json.documentId;
                    items = [dataItem, ...items];
                } else {
                    var index = documents.findIndex(v => v.documentId === json.documentId);            
                    items.splice(index, 1, dataItem);
                }                
                setDocuments(items);
                setTotal(items.length);
                setItemInEdit(null);
            } 
        }, setLoading);
    }

    const cancel = () => {
        setItemInEdit(null);
        setItemInDelete(null);
        setLoading(false);
    }

    const insert = () => {
        setItemInEdit({ documentId: 0, matterId: props.match.params.id });
    }

    const renderEditors = (forceEdit = false) => {
        return (
            <React.Fragment>
                {itemInEdit && <DocumentEditor dataItem={itemInEdit} save={save} cancel={cancel} editMode={itemInEdit.documentId == 0 || forceEdit} delete={deleteItem} />}
                {/* {itemInDelete && <ConfirmDelete title={`Delete Document ${itemInDelete.documentName}?`} cancel={cancel} deleteItem={deleteItem} />} */}
                {/* {itemInDelete && <Dialog title={"Delete confirmation"} onClose={cancel}>
                    <p style={{ margin: "25px", textAlign: "center" }}>Delete document "{itemInDelete.documentName}" ?</p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={cancel}>No</button>
                        <button className="k-button" onClick={deleteItem}>Yes</button>
                    </DialogActionsBar>
                </Dialog>} */}
            </React.Fragment>
        )
    }

    const renderCard = () => {
        return (<>{!props.cards && <GenericHeader />}
            <Card style={{height: props.cardHeight, marginTop: !props.cards ? '35px' : '5px'}}>
                <CardHeader>
                    <CardIcon color='success' style={{marginTop: '-30px'}}>
                        <AttachIcon style={{color:'white'}} />
                    </CardIcon>
                    <h4 className='jm-card-title' style={{ color: '#777' }}>Documents</h4>
                    <GridContainer className='mt-3 mb-1'>
                        <GridItem xs={1}>
                            <Button
                                primary={true}
                                className='jm-action-btn jm-header-btn rounded-circle mt-3'
                                icon='plus'
                                title='Add New'
                                onClick={insert}
                            />
                        </GridItem>
                        {/*
                        <GridItem xs={3}>
                            <DropDownList
                                label='Category'
                                data={documentCategories}
                                value={documentCategory}
                                textField="lookupValue"
                                dataItemKey="lookupId"
                                style={{ width: '100%' }}
                                onChange={({ value }) => {
                                    setDocumentCategory(value);
                                }}
                            />
                        </GridItem>
                        */}
                        <GridItem xs={5}>
                            <Input
                                width='100%'
                                label='Name'
                                value={documentName}
                                style={{ width: '100%' }}
                                onChange={({ value }) => {
                                    setDocumentName(value);
                                }}
                                name="documentName"
                                id="documentName"
                            />
                        </GridItem>
                        <GridItem xs={1}>
                            <Button
                                primary={false}
                                className='jm-action-btn k-title mt-3'
                                onClick={() => {
                                    console.log('setFilter');
                                    setFilter({ category: documentCategory, name: documentName });
                                }}
                                style={{ borderRadius: '8px' }}
                            >
                                SEARCH
                            </Button>
                        </GridItem>
                        <GridItem xs={3}>
                            <ExcelExport ref={_export} />
                            <Button
                                primary={false}
                                className='jm-action-btn k-title mt-3'
                                onClick={() => {
                                    api.get(`/document/${matterId ?? 0}/paged/1/10000/${filter.category.lookupId}/${filter.name}${sort ? `?${sort.query}` : ``}`, axiosConfig)
                                        .then(json => {
                                        json.data.forEach(item => {
                                            if (item.documentDate) {
                                                item.documentDate = moment(item.documentDate).toDate();
                                            }

                                            if (item.uploadedDate) {
                                                item.uploadedDate = moment(item.uploadedDate).toDate();
                                            }
                                        });
                                        _export.current.save(json, _grid.current.columns)
                                    })
                                }}
                                style={{ borderRadius: '8px' }}
                            >
                                EXPORT TO EXCEL
                                </Button>
                            <Grid ref={_grid} style={{ display: 'none' }}>
                                <GridColumn field="documentDate" title="Document Date" width={200} format="{0:dd/MM/yyyy}" />
                                <GridColumn field="documentName" title="Name" width={300} />
                                <GridColumn field="comments" title="Comments" />
                                <GridColumn field="documentCategory.lookupValue" title="Category" width={200} />
                                <GridColumn field="user.lookupValue" title="Uploaded By" width={200} />
                                <GridColumn field="uploadedDate" title="Uploaded Date" width={200} format="{0:dd/MM/yyyy}" />
                            </Grid>
                        </GridItem>
                    </GridContainer>
                    
                </CardHeader>
                <CardBody>
                    {renderGrid()}
                </CardBody>
            </Card></>
        )
    }

    const renderGrid = () => {
        return (
            <React.Fragment>
                <Grid
                    filterable={false}
                    sortable={{
                        allowUnsort: true,
                        mode: 'single'
                    }}
                    sort={sort ? sort.object: []}
                    pageable={props.pagerSettings}
                    data={documents}
                    total={total}
                    take={page.take}
                    skip={page.skip}
                    onSortChange={({ sort }) => {
                        console.log('sort: ', sort);

                        if (sort && sort.length > 0) {
                            var arr = sort.map((o, i) => {
                                return `sort[${i}].Property=${o.field === 'uploadedDate' ? 'createdOn' : o.field}&sort[${i}].Direction=${o.dir == 'asc' ? 'Ascending' : 'descending'}`;
                            });
                            var str = arr.join('&');
                            setSort({object: sort, query: str});
                        } else {
                            setSort({object: [], query: ''});
                        }

                    }}
                    onPageChange={(ev) => setPage(ev.page)}
                    style={{ height: 'calc(100vh - 286px)' }}
                    onRowClick={({ dataItem }) => { edit(dataItem, false) }}
                    className='clickableRow'
                >
                    <GridColumn field="documentDate" title="Document Date" cell={KendoGridDateCell} width={200} />
                    <GridColumn field="documentName" title="Name" cell={cellWithDownload()} width={300} />
                    <GridColumn field="comments" title="Comments" />
                    <GridColumn field="documentCategory.lookupValue" title="Category" width={200} />
                    <GridColumn field="user.lookupValue" title="Uploaded By" width={200} />
                    <GridColumn field="uploadedDate" title="Uploaded Date" cell={KendoGridDateCell} width={200} />
                </Grid>
                {loading && <LoadingPanel/>}
                {renderEditors(true)}
            </React.Fragment>
        )
    }

    return props.summary 
    ? <DocumentSummary {...props} edit={edit} deleteItem={deleteItem} renderEditors={renderEditors} ref={documentsSummaryRef} /> 
        : props.matterStageId 
            ? renderGrid() 
            : renderCard();
});

export default withSnackbar(withRouterAndRef(Document));

export {
    saveDocument
};
