import React, { useState, useEffect } from 'react';
import { findDOMNode } from "react-dom";

import { Button } from '@progress/kendo-react-buttons';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { formatNumber } from '@telerik/kendo-intl';

import Dialog from "../common/Dialog";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";

import { withSnackbar } from 'notistack';
import moment from "moment";

import api from "../../util/api"
import handleErrors from '../../util/handleErrors';
import { editableField } from "../../util/editableField";
import { requiresRole, policies } from '../../util/accessControl';

import RelatedAccountsEditor from "./RelatedAccountsEditor";
import RelatedSecuritiesEditor from "./RelatedSecuritiesEditor";
import AddressFormatter from "../../util/formatAddress";

const CustomerSaleEditor = (props) => {
    const originalItem = {
        ...props.dataItem,
        dischargeFormRecived: (props.dataItem.dischargeFormRecived == null || props.dataItem.dischargeFormRecived === "") ? null : moment(props.dataItem.dischargeFormRecived).toDate(),
        financeDue: (props.dataItem.financeDue == null || props.dataItem.financeDue === "") ? null : moment(props.dataItem.financeDue).toDate(),
        anticipatedSettlement: (props.dataItem.anticipatedSettlement == null || props.dataItem.anticipatedSettlement === "") ? null : moment(props.dataItem.anticipatedSettlement).toDate(),
        settledDate: (props.dataItem.settledDate == null || props.dataItem.settledDate === "") ? null : moment(props.dataItem.settledDate).toDate(),
    };
    const [itemData, setItemData] = useState(originalItem);
    const [originalItemData, setOriginalItemData] = useState(originalItem);
    const [editMode, setEditMode] = useState(!!props.editMode);
    const [errors, setErrors] = useState({});
    const initLookupObject = { loading: false, list: [] };
    const [customerSaleType, setCustomerSaleType] = useState(initLookupObject);

    const editStageQuery = `/stage/customer/${itemData.stageId}`;
    const newStageQuery = `/stage/block/${itemData.stage.blockId}`;

    const handleSelectChange = ({ value, target: { name } }) => {
        const idField = `${name}Id`;
        setItemData({ ...itemData, [name]: value, [idField]: value ? value.lookupId : null });
    }

    const handleDateChange = ({ value, target: { name } }) => {
        setItemData({ ...itemData, [name]: value == null ? null : moment(value).local().toDate() });
    }

    const handleInputChange = ({ value, target: { name } }) => {
        setItemData({ ...itemData, [name]: value });
    }

    const handleCheckSelected = (element) => {
        let isChecked = !itemData[element];
        setItemData({ ...itemData, [element]: isChecked });
    }

    const updateRelatedEditorsData = (updatedData) => {
        setItemData(updatedData);
    }
    const validateForm = (ev) => {
        var validForm = true;
        var formErrors = {};

        let account = itemData.stage.matterAccounts.find(v => v.noticeSelected === true);
        if (!account) {
            props.enqueueSnackbar('Related Account/s required.', { variant: 'error' });
            return false;
        }

        let security = itemData.stage.matterSecurities.find(v => v.noticeSelected === true);
        if (!security) {
            props.enqueueSnackbar('Related Security Properties required.', { variant: 'error' });
            return false;
        }

        formErrors.dischargeFormRecived = (!itemData.dischargeFormRecived || itemData.dischargeFormRecived === '');
        formErrors.purchasePrice = (!itemData.purchasePrice || itemData.purchasePrice === '');
        formErrors.financeDue = (!itemData.financeDue || itemData.financeDue === '');
        formErrors.anticipatedSettlement = (!itemData.anticipatedSettlement || itemData.anticipatedSettlement === '');
        formErrors.settledDate = (!itemData.settledDate || itemData.settledDate === '');
        formErrors.payoutFigure = (!itemData.payoutFigure || itemData.payoutFigure === '');
       
        setErrors(formErrors);

        const item = Object.assign({}, formErrors)
        validForm = !Object.keys(item).some(x => item[x]);

        return validForm;
    }

    const setLookup = (setFunc) => (json) => {
        setFunc({ loading: false, list: json });
        return json;
    }

    useEffect(() => {
        const axiosConfig = { accept: 'application/json' };
        const loading = { loading: true, list: [] };
        setCustomerSaleType(loading);

        let onLoadTasks = [
            api.get((itemData.stageId > 0) ? editStageQuery : newStageQuery, axiosConfig),
            api.get(`/lookup/customersaletype`, axiosConfig).then(setLookup(setCustomerSaleType)),
        ];

        Promise.all(onLoadTasks)
            .then(responses => {
                let tempData = { ...itemData };
                let stage = responses[0];
                let customerSaleTypes = responses[1]

                if (tempData.stageId == 0) {
                    tempData = {
                        ...itemData,
                        customerSaleType: customerSaleTypes[0],
                        customerSaleTypeId: customerSaleTypes[0].lookupId,
                        stage: stage,
                    };
                } else {
                    tempData = {
                        ...stage,
                        dischargeFormRecived: (stage.dischargeFormRecived == null || stage.dischargeFormRecived === "") ? null : moment(stage.dischargeFormRecived).toDate(),
                        financeDue: (stage.financeDue == null || stage.financeDue === "") ? null : moment(stage.financeDue).toDate(),
                        anticipatedSettlement: (stage.anticipatedSettlement == null || stage.anticipatedSettlement === "") ? null : moment(stage.anticipatedSettlement).toDate(),
                        settledDate: (stage.settledDate == null || stage.settledDate === "") ? null : moment(stage.settledDate).toDate(),
                    };
                }
                setItemData(tempData);
                setOriginalItemData(tempData);
            })
            .catch(handleErrors(props))
    }, []);

    return (
        <Dialog
            validate={validateForm}
            original={originalItemData}
            current={itemData}
            saveChanges={props.save}
            cancelChanges={props.cancel}
            width={1200}
            createMode={itemData.stageId === 0}
            title={
                <>
                    {`${itemData.accountId === 0 ? 'Add' : editMode ? 'Edit' : 'View'} Customer Sale`}
                    {requiresRole(<Button className='rounded-circle ml-2' primary={true} look="bare" icon={editMode ? 'lock' : 'edit'} onClick={(ev) => setEditMode(!editMode)} />, policies.edit)}
                </>}
        >
            <form onSubmit={(ev) => ev.preventDefault()}>
                <GridContainer>
                    {editMode ? (
                        <>
                            <GridItem sm={4}>
                                <ComboBox
                                    data={customerSaleType.list}
                                    loading={customerSaleType.loading}
                                    onFocus={(ev) => { if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click() }}
                                    textField='lookupValue'
                                    dataItemKey='lookupId'
                                    value={itemData.customerSaleType}
                                    onChange={handleSelectChange}
                                    label="Customer Sale Type"
                                    name='customerSaleType'
                                    id='customerSaleType'
                                />
                            </GridItem>
                            {itemData.stage.matterAccounts && <RelatedAccountsEditor stageData={itemData} updateRelatedAccounts={updateRelatedEditorsData} editMode={editMode} />}

                            {itemData.stage.matterSecurities && <RelatedSecuritiesEditor stageData={itemData} updateRelatedSecurities={updateRelatedEditorsData} editMode={editMode} />}
                            <GridItem sm={4} className='mt-2'>
                                <span className='k-textbox-container'>
                                    <label className='k-label' htmlFor='dischargeFormRecived'>Discharge Authority Form received</label>
                                    <DatePicker
                                        defaultValue={new Date()}
                                        format="dd/MM/yyyy"
                                        name='dischargeFormRecived'
                                        id='dischargeFormRecived'
                                        onChange={handleDateChange}
                                        value={itemData.dischargeFormRecived}
                                        label=""
                                        required={true}
                                    />
                                </span>
                                {errors.dischargeFormRecived && <span className='text-danger'>Discharge Authority Form received is Required.</span>}
                            </GridItem>
                            <GridItem sm={4} className='mt-2'>
                                <NumericTextBox
                                    width='100%'
                                    label='Purchase price'
                                    value={itemData.purchasePrice}
                                    onChange={handleInputChange}
                                    format="c2"
                                    spinners={false}
                                    name="purchasePrice"
                                    id="purchasePrice"
                                    required={true}
                                />
                                {errors.purchasePrice && <span className='text-danger'>Purchase price is Required.</span>}
                            </GridItem>
                            <GridItem sm={4} className='mt-2'>
                                <span className='k-textbox-container'>
                                    <label className='k-label' htmlFor='financeDue'>Finance due</label>
                                    <DatePicker
                                        defaultValue={new Date()}
                                        format="dd/MM/yyyy"
                                        name='financeDue'
                                        id='financeDue'
                                        onChange={handleDateChange}
                                        value={itemData.financeDue}
                                        label=""
                                        required={true}
                                    />
                                </span>
                                {errors.financeDue && <span className='text-danger'>Finance due is Required.</span>}
                            </GridItem>
                            <GridItem sm={4} className='mt-2'>
                                <input type="checkbox" className='k-checkbox' checked={!!itemData.contractUnconditional} onChange={() => handleCheckSelected('contractUnconditional')} name="contractUnconditional" id="contractUnconditional" />
                                <label className="k-checkbox-label" htmlFor="contractUnconditional">Contract unconditional</label>
                            </GridItem>
                            <GridItem sm={4} className='mt-2'>
                                <span className='k-textbox-container'>
                                    <label className='k-label' htmlFor='anticipatedSettlement'>Anticipated settlement date</label>
                                    <DatePicker
                                        defaultValue={new Date()}
                                        format="dd/MM/yyyy"
                                        name='anticipatedSettlement'
                                        id='anticipatedSettlement'
                                        onChange={handleDateChange}
                                        value={itemData.anticipatedSettlement}
                                        label=""
                                        required={true}
                                    />
                                </span>
                                {errors.anticipatedSettlement && <span className='text-danger'>Anticipated settlement date is Required.</span>}
                            </GridItem>
                            <GridItem sm={4} className='mt-2'>
                                <span className='k-textbox-container'>
                                    <label className='k-label' htmlFor='settledDate'>Date settled</label>
                                    <DatePicker
                                        defaultValue={new Date()}
                                        format="dd/MM/yyyy"
                                        name='settledDate'
                                        id='settledDate'
                                        onChange={handleDateChange}
                                        value={itemData.settledDate}
                                        label=""
                                        required={true}
                                    />
                                </span>
                                {errors.settledDate && <span className='text-danger'>Date settled is Required.</span>}
                            </GridItem>
                            <GridItem sm={4} className='mt-2'>
                                <NumericTextBox
                                    width='100%'
                                    label='Payout figure'
                                    value={itemData.payoutFigure}
                                    onChange={handleInputChange}
                                    format="c2"
                                    spinners={false}
                                    name="payoutFigure"
                                    id="payoutFigure"
                                    required={true}
                                />
                                {errors.payoutFigure && <span className='text-danger'>Payout figure is Required.</span>}
                            </GridItem>
                        </>
                    ) : (
                        <>
                            <GridItem sm={12} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Customer Sale Type:</label>
                                    <div className="font-size-14">{itemData.customerSaleType ? itemData.customerSaleType.lookupValue : ''}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={12} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Related Accounts:</label>
                                    <div className="font-size-14">{itemData.stage.matterAccounts && itemData.stage.matterAccounts.length > 0 ? itemData.stage.matterAccounts.filter(val => !!val.noticeSelected).map((val, ind, arr) => val.accountNumber + ((ind + 1) < arr.length ? ', ' : '')) : ''}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={12} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Related Security Properties:</label>
                                    <div className="font-size-14">{itemData.stage.matterSecurities && itemData.stage.matterSecurities.length > 0 ? itemData.stage.matterSecurities.filter(val => !!val.noticeSelected).map((val, ind, arr) => AddressFormatter.format(val).address + ((ind + 1) < arr.length ? ', ' : '')) : ''}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Discharge Authority Form received:</label>
                                    <div className="font-size-14">{moment(itemData.dischargeFormRecived).format('D/MM/YYYY')}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Purchase price:</label>
                                    <div className="font-size-14">{new Intl.NumberFormat('en-AU', {style: 'currency', currency: 'AUD'}).format(itemData.purchasePrice)}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Finance due:</label>
                                    <div className="font-size-14">{moment(itemData.financeDue).format('D/MM/YYYY')}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Contract unconditional:</label>
                                    <div className="font-size-14">{!!itemData.contractUnconditional ? 'Yes' : 'No'}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Anticipated settlement date:</label>
                                    <div className="font-size-14">{moment(itemData.anticipatedSettlement).format('D/MM/YYYY')}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Date settled:</label>
                                    <div className="font-size-14">{moment(itemData.settledDate).format('D/MM/YYYY')}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Payout figure:</label>
                                    <div className="font-size-14">{new Intl.NumberFormat('en-AU', {style: 'currency', currency: 'AUD'}).format(itemData.payoutFigure)}</div>
                                </span>
                            </GridItem>
                        </>
                    )}

                </GridContainer>
            </form>
        </Dialog>
    )
}
export default withSnackbar(CustomerSaleEditor);