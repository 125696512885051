import React, { useState, useEffect } from 'react';
import LoadingPanel from '../common/Grid/LoadingPanel';
import { Redirect } from "react-router-dom";
import api from "../../util/api";

const Logout = (props) => {
    const [ loggedOut, setLoggedOut ] = useState(false);

    useEffect(() => {
        api.post(`/auth/logout`, {
            accept: 'application/json',
        }) 
        .then(() => {
            localStorage.clear();
            setLoggedOut(true);
        });
    }, []);

    return loggedOut ? <Redirect to='/login' /> : <LoadingPanel />
}

export default Logout;