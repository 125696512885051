import React from 'react';
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import DefaultLayout from "./layouts/Default";
import { Login, ConfirmEmail, Logout, ForgotPassword, ResetPassword } from "./auth";
import "./assets/scss/material-dashboard-pro-react.scss?v=1.5.0";
import '@progress/kendo-theme-material/dist/all.css';
import "./assets/css/custom.css";
import moment from "moment";
import { ProtectedRoute } from "./common/ProtectedRoute";
import api from "../util/api"
import { ProfileProvider } from './store';
// import AddressFormatter from "../util/formatAddress";
const history = createBrowserHistory();
api.setup(history);
//AddressFormatter.configure();
/* eslint-disable no-extend-native */
Date.prototype.toJSON = function () {
    return moment(this).format();
}
Array.prototype.joinNonEmpty = function (separator = ",") { return this.filter((i) => i && i.trim()).join(separator); }
/* eslint-enable no-extend-native */
console.log('React Environment App.js: ' + process.env.NODE_ENV);

const App = () => (
    <ProfileProvider>
      <Router history={history}>
        <Switch>
          <Route path='/reset' component={ResetPassword}  />
          <Route path='/forgot' component={ForgotPassword}  />
          <Route path='/login' component={Login}  />
          <Route path='/logout' component={Logout}  />
          <Route path='/confirmemail' component={ConfirmEmail}  />
          <ProtectedRoute path='/' component={DefaultLayout} />
        </Switch>
      </Router>   
    </ProfileProvider> 
);

export default App;


