import React, { useState } from 'react';
import { withRouter, Link,useHistory } from "react-router-dom";
import api from "../../util/api";
import CardIcon from "../common/MuiKit/Card/CardIcon";
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";
import PersonIcon from "@material-ui/icons/Person";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import { Input } from '@progress/kendo-react-inputs';
import _ from 'lodash';
import { withSnackbar } from 'notistack';

const ForgotPassword = (props) => {

    const [username, setUsername] = useState('');
    const [errors, setErrors] = useState([]);     
    var history = useHistory();
    
    const handleSubmit = (event) => {

        event.preventDefault();
        setErrors([]);

        var body = { email: username };

        api
        .post('/auth/forgotpassword', body)
        .then(response => {
            props.enqueueSnackbar(`An email with reset password link has been sent to ${response.email}.`, { variant: 'info' });
            
            history.push('/login');
        }).catch(({ response: { data } }) => {
            var messages = _.flatten(Object.keys(data).map(key => data[key]));
            setErrors(messages);
        })
    }

    return (
        <div style={{height: 'calc(100vh - 50px)',display:'flex',alignItems:'center'}}>
            <Card style={{maxWidth: '400px', margin: '0 auto'}}>
                <CardHeader>
                    <CardIcon color='info' style={{marginTop: '-30px'}}>
                        <PersonIcon style={{color:'white'}} />
                    </CardIcon>
                    <h4 style={{color: '#777'}}>Reset password</h4>
                </CardHeader>
                <CardBody>
                    <form onSubmit={handleSubmit}>
                        <GridContainer>
                            {errors.length > 0 && 
                            <GridItem sm={12}>
                                {errors.map(error => (<p className='text-danger'>{error}</p>))}
                            </GridItem>
                            } 
                            <GridItem sm={12}>
                                <p style={{
                                    color: '#555',
                                    textAlign: 'center',
                                    fontSize: '12px',
                                    margin: 'auto'
                                }}>Please provide your username and if the username/email exists we'll send you a link to reset your password.</p>
                            </GridItem>
                            <GridItem sm={12}>
                                <Input
                                    width='100%'
                                    label='Username/Email'
                                    value={username}
                                    onChange={e => setUsername(e.target.value)} 
                                    name="username"
                                    id="username"
                                />
                            </GridItem>
                            <GridItem sm={12} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '12px'}}>
                                <Link to='/login' style={{fontSize: '12px'}}>
                                Login
                                </Link>
                                <button 
                                    className="k-button k-title"
                                    type="submit" >
                                    Send
                                </button>
                            </GridItem>
                        </GridContainer>                       
                    </form>
                </CardBody>
            </Card>
        </div>       
    )
}

export default withSnackbar(withRouter(ForgotPassword));
