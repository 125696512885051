import React, { useState, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";

import { requiresRole, policies } from '../../util/accessControl';
import cellWithEditing from "../common/Grid/CellWithEditing";
import LoadingPanel from '../common/Grid/LoadingPanel';
import { ConfirmDelete } from "../common/Dialog";
import CardIcon from "../common/MuiKit/Card/CardIcon";
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";

import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import GenericHeader from "../common/Header/GenericHeader"; 
import handleErrors from '../../util/handleErrors';
import api from "../../util/api"
import SecurityEditor from "./SecurityEditor";

import SecurityIcon from "@material-ui/icons/Security";
import { withSnackbar } from 'notistack';
import { Col } from 'reactstrap';

const Security = (props) => {

    const [matterId] = useState(props.match.params.id || 0);
    const [gridItems, setGridItems] = useState([]);
    const [total, setTotal] = useState(0);
    const initPage = { take: matterId > 0 ? 9999 : 10, skip: 0 };
    const [ page, setPage] = useState(initPage)
    const defaultSort = {object: [], query: ''};
    const [ sort, setSort ] = useState(defaultSort); 
    const [loading, setLoading] = useState(false);
    const [itemInEdit, setItemInEdit] = useState(null);
    const [itemInDelete, setItemInDelete] = useState(null);

    useEffect(() => {
        setLoading(true);

        api.get(`/security/${matterId}/paged/${(page.skip / page.take) + 1}/${page.take}${sort.query ? `?${sort.query}` : ``}`, {
            accept: 'application/json',
        })
        .then(json => {
            setGridItems(json.data);
            setTotal(json.totalRecords);
            setLoading(false);
        })
        .catch(handleErrors(props))
    }, [page, sort]);

    const edit = (dataItem, editMode = true) => {
        console.log('dataItem', dataItem);
        const item = Object.assign({}, dataItem)
        Object.keys(item).forEach(key => {
            if (item[key] == null) {
                if (key === 'valuationAmount' || key === 'estimatedNetProceeds') {
                    item[key] = null;
                } else {
                    item[key] = '';
                }
            }
        });
        item.selectMatter = (matterId === 0);
        item.editMode = editMode;
        setItemInEdit(item);
    };

    const remove = (dataItem) => {
        setItemInDelete(dataItem);
    };

    const deleteItem = (dataItem) => {
        setLoading(true);
    
        api.delete(`/security/${dataItem.securityId}`, {
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
        .then(json => {

            setLoading(false);
            setItemInEdit(null);
            setPage({ take: 10, skip: 0 });
        })
        .catch(handleErrors(props))
    };

    const save = (dataItem) => {
        setLoading(true);
        let matter = dataItem.matter;
        dataItem.matter = null;
        dataItem.fullAddress = `${dataItem.addressUnitNumber} ${dataItem.addressStreetNumber} ${dataItem.addressStreetName} ${dataItem.addressSuburb} ${dataItem.addressState} ${dataItem.addressPostCode}`;

        api.request({
            url: `/security`,
            method: dataItem.securityId === 0 ? 'POST' : 'PUT',
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(dataItem)
        }) 
        .then(json => {
            
            let items = gridItems.slice();
            if (dataItem.securityId === 0) {
                dataItem.securityId = json.securityId;
                dataItem.matter = matter;
                items = [dataItem, ...items];
            } else {
                var index = gridItems.findIndex(v => v.securityId === json.securityId);
                items.splice(index, 1, dataItem);
            }

            setGridItems(items);
            setTotal(items.length);
            setItemInEdit(null);
            setLoading(false);
        })
        .catch(handleErrors(props))
    }

    const cancel = () => {
        setItemInEdit(null);
        setItemInDelete(null);
        setLoading(false);
    }

    const insert = () => {
        setItemInEdit({
            securityId: 0,
            matterId: matterId,
            selectMatter: matterId === 0,
            editMode: true,
            client: {}
        });
    }

    const concatLookups = (data) => {
        let result = '';
        if (data) {
            data.forEach(function (item) {
                result += ', ' + item.lookupValue;
            });
            return result.substring(2, result.length);
        }
        return result;
    }

    const renderCard = () => {
        return (<>{!props.cards && <GenericHeader />}
            <Card style={{ height: props.cardHeight, marginTop: !props.cards ? '35px' : '5px' }}>
                <CardHeader>
                    <CardIcon color='primary' style={{ marginTop: '-30px' }}>
                        <SecurityIcon style={{ color: 'white' }} />
                    </CardIcon>
                    <h4 className='jm-card-title' style={{ color: '#777' }}>Securities</h4>

                    {requiresRole(<Button
                        primary={true}
                        className='rounded-circle ml-3 mb-1'
                        icon='plus'
                        title='Add New Security'
                        onClick={insert}
                    />, policies.edit)}

                    {/* {matterId > 0 && <span style={{ float: 'right', marginTop:'5px' }}>
                        <Link to={`/securities`}>View All</Link>
                    </span>} */}
                    
                    {/* <span className='font-italic' style={{ fontSize: '12px', marginLeft: '5px' }}>Add New Security</span> */}

                </CardHeader>
                <CardBody>
                    {renderGrid()}
                </CardBody>
                
                
            </Card></>
        );
    }

    const renderGrid = () => {
        return (
            <>
                <Grid
                    filterable={false}
                    sortable={{
                        allowUnsort: true,
                        mode: 'single'
                    }}
                    sort={sort ? sort.object: []}
                    onSortChange={({ sort }) => {        
                        console.log('sort: ', sort);
                        if (sort && sort.length > 0) {
                            var arr = sort.map((o, i) => {
                                if (o.field === 'lotNumber') {
                                    return `sort[0].Property=lotNumber&sort[0].Direction=${o.dir == 'asc' ? 'Ascending' : 'descending'}` +
                                           `&sort[1].Property=lotType.lookupValue&sort[1].Direction=${o.dir == 'asc' ? 'Ascending' : 'descending'}` +
                                           `&sort[2].Property=lotTypeNumber&sort[1].Direction=${o.dir == 'asc' ? 'Ascending' : 'descending'}`;
                                }
                                
                                return `sort[${i}].Property=${o.field}&sort[${i}].Direction=${o.dir == 'asc' ? 'Ascending' : 'descending'}`;
                            });
                            var str = arr.join('&');
                            setSort({object: sort, query: str});
                        } else {
                            setSort(defaultSort);
                        }

                    }}
                    pageable={matterId > 0 ? false : props.pagerSettings}
                    className={`clickableRow ${matterId > 0 ? '' : ''}`}
                    data={gridItems}
                    total={total}
                    take={page.take}
                    skip={page.skip}
                    onPageChange={(ev) => setPage(ev.page)}
                    onRowClick={({ dataItem }) => { edit(dataItem, false) }}
                    style={{ height: props.gridHeight }}
                >
                    {matterId === 0 && <GridColumn field="matterNumber" title="Matter Number" />}
                    <GridColumn field="fullAddress" title="Street Address" />
                    <GridColumn field="registeredProprietor" title="Registered Proprietor" width={180} cell={(colProps) => <td>{colProps.dataItem ? (colProps.dataItem.securityProprietorLookups ? concatLookups(colProps.dataItem.securityProprietorLookups) : '') : '' }</td>} />

                    {/* <GridColumn field="lotNumber" title="Lot Number" />
                    <GridColumn field="lotType.lookupValue" title="Lot Type" />
                    <GridColumn field="lotTypeNumber" title="Lot Type Number" /> */}
                    
                    <GridColumn title="Lot Details" field="lotNumber" width={260} cell={(colProps) => <td>{colProps.dataItem.lotType && colProps.dataItem.lotNumber && colProps.dataItem.lotTypeNumber && `Lot ${colProps.dataItem.lotNumber} on ${colProps.dataItem.lotType.lookupValue} ${colProps.dataItem.lotTypeNumber}`}</td>} />
                    <GridColumn field="extent.lookupValue" title="Extent" width={140} />
                    <GridColumn field="volume" title="Volume" width={140} />
                    <GridColumn field="folio" title="Folio" width={140} />
                    <GridColumn field="mortgageNumber" title="Mortgage Number" width={140} />
                    <GridColumn field="mpNumber" title="MP Number" width={140} />
                </Grid>
                
                {loading && <LoadingPanel />}
                {itemInEdit && <SecurityEditor dataItem={itemInEdit} save={save} cancel={cancel} editMode={itemInEdit.editMode ?? false} delete={deleteItem} />}
            </>
        );
    }

    return props.view == "grid" ? renderGrid() : renderCard();

    // return (
    //     <Card style={{ height: props.cardHeight }}>
    //         <CardHeader>
    //             <CardIcon color='primary' style={{ marginTop: '-30px' }}>
    //                 <SecurityIcon style={{ color: 'white' }} />
    //             </CardIcon>
    //             <h4 className='jm-card-title' style={{ color: '#777' }}>Securities</h4>

    //             {requiresRole(<Button
    //                 primary={true}
    //                 className='rounded-circle float-right'
    //                 icon='plus'
    //                 title='Add New Security'
    //                 onClick={insert}
    //             />, policies.edit)}
                
    //             {/* <span className='font-italic' style={{ fontSize: '12px', marginLeft: '5px' }}>Add New Security</span> */}

    //         </CardHeader>
    //         <CardBody>
    //             <Grid
    //                 filterable={false}
    //                 sortable={true}
    //                 pageable={matterId > 0 ? false : props.pagerSettings}
    //                 className={matterId > 0 ? 'no-pager' : ''}
    //                 data={gridItems}
    //                 total={total}
    //                 take={page.take}
    //                 skip={page.skip}
    //                 onPageChange={(ev) => setPage(ev.page)}
    //                 onRowClick={({ dataItem }) => { edit(dataItem, false) }}
    //                 style={{ height: props.gridHeight }}
    //             >
    //                 {matterId === 0 && <GridColumn field="matterNumber" title="Matter Number" />}
    //                 <GridColumn field="registeredProprietor" title="Registered Proprietor" />
    //                 <GridColumn field="addressStreetNumber" title="Street Number" />
    //                 <GridColumn field="addressStreetName" title="Street Name" />
    //                 <GridColumn field="addressSuburb" title="Suburb" />
    //                 <GridColumn cell={cellWithEditing(edit, remove)} width={90} />
    //             </Grid>
    //             {matterId > 0 &&<div className='grid-footer'>
    //                 <span style={{ float: 'right', marginTop:'5px' }}>
    //                     <Link to={`/securities`}>View All</Link>
    //                 </span>
    //             </div>}
    //         </CardBody>
    //         {loading && <LoadingPanel />}
    //         {itemInEdit && <SecurityEditor dataItem={itemInEdit} save={save} cancel={cancel} editMode={itemInEdit.editMode ?? false} delete={deleteItem} />}
    //         {/* {itemInDelete && <ConfirmDelete title={`Delete Security ${itemInDelete.addressName}?`} cancel={cancel} deleteItem={deleteItem} />} */}
            
    //     </Card>
    // )
}

export default withSnackbar(withRouter(Security));