
const jacmacStyles = {
    textEllipsis: {
        overflow: 'hidden', 
        whiteSpace: 'nowrap', 
        textOverflow: 'ellipsis'
    },
    listViewItem: {
        margin: '10px 0 10px 10px',
        //flex: '0 0 auto',
        width: '300px',
        display: 'inline-block',
        '&:last-child': {
            margin: '10px'
        }

    },
    scrollArea: {
        height: '300px',
        width: '100%',
        margin: '0 auto',
        whiteSpace: 'nowrap !important'
    },
    // listViewItemHorizontalScrolling: {
    //     display: 'flex',
    //     flexWrap: 'nowrap',
    //     padding: '20px 0',
    //     overflowX: 'auto',
    //     '-webkit-overflow-scrolling': 'touch',
    //     width: '100%'
    // },
    listViewItemBody: {
        paddingBottom: '0px'
    },
    listViewItemFooter: {
        paddingTop: '0px',
        justifyContent: 'flex-end'
    },
    listViewItemButton: {
        marginLeft: '5px'
    },
    listViewItemCategory: {
        color: '#999999',
        fontSize: "14px",
        paddingTop: "0",
        marginBottom: "0",
        marginTop: "0",
        margin: "0",
        textTransform: 'uppercase',
        lineHeight: '18px'
    },
    listViewItemDescription: {
        color: '#555555',
        fontSize: "14px",
        paddingTop: "0",
        marginBottom: "5px",
        marginTop: "0",
        margin: "0",
        lineHeight: '18px'
    }
}

export default jacmacStyles;