import React, { useState, useEffect, forwardRef, useCallback } from 'react';
import { withRouter, Link } from "react-router-dom";
import 'react-perfect-scrollbar/dist/css/styles.css';
import api from "../../util/api";
import handleErrors from '../../util/handleErrors';
import { useEventListener } from "../../util/useEventListener";
import contentDisposition from "content-disposition";
import fileDownload from "js-file-download";
import LoadingPanel from '../common/Grid/LoadingPanel';
import CardIcon from "../common/MuiKit/Card/CardIcon";
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import { DialogActionsBar, Dialog } from '@progress/kendo-react-dialogs';
import { Menu, MenuItem } from '@progress/kendo-react-layout';
import { Popup } from '@progress/kendo-react-popup';
import { Button } from '@progress/kendo-react-buttons';
import AttachIcon from "@material-ui/icons/AttachFile";
import withStyles from "@material-ui/core/styles/withStyles";
import jacmacStyles from "../assets/jss/core/jacmacStyles";
import { withSnackbar } from 'notistack';
import { saveDocument } from "./index";
import DocumentEditor from "./DocumentEditor";

const LibraryListView = forwardRef((props, ref) => {
    const { classes } = props;
    const [gridItems, setGridItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ itemInEdit, setItemInEdit] = useState(null);
    const [ itemInDelete, setItemInDelete] = useState(null);
    const [menu, setMenu] = useState({ open: false, offset: { left: 0, top: 0 } });
    const handler = useCallback(
        () => {
            if (menu.show) {
                setMenu({ show: false });
            }
        },
        [menu, setMenu],
    );
    useEventListener('click', handler, document);
    const loadLibrary = () => {
        setLoading(true);

        api.get(`/document/${props.isDocument == 1 ? "recent" : "library"}`, {
            accept: 'application/json',
        })
        .then(json => {
            setGridItems(json);
            setLoading(false);
            setItemInEdit(null);
        })
        .catch(handleErrors(props))
    }
    useEffect(() => {
        loadLibrary();
    }, []);
    const save = (dataItem) => {
        saveDocument(dataItem, props, () => {
            loadLibrary();
        }, setLoading);
    };
    const cancel = () => {
        setItemInEdit(null);
        setItemInDelete(null);
    };
    const deleteItem = (dataItem) => {
        setLoading(true);
      
        api.delete(`/document/${dataItem.documentId}`, {
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
        .then(id => {

            loadLibrary()
        })
        .catch(handleErrors(props))
    };
    const download = (doc) => {
        api.get(`/document/${doc.documentId}/download`, { accept: '*/*', responseType: 'blob'})
        .then(response => {
            const disposition = contentDisposition.parse(response.headers['content-disposition']);
            fileDownload(response.data, disposition.parameters.filename, response.headers['content-type'])
        })
        .catch(handleErrors(props))
    };
    return (
        <Card style={{ height: props.cardHeight, marginTop: '35px' }}>
            <CardHeader>
                <CardIcon color={props.isDocument == 1 ? "success" : "primary" } style={{ marginTop: '-30px' }}>
                    <AttachIcon style={{ color: 'white' }} />
                </CardIcon>
                <h4 className='jm-card-title' style={{ color: '#777' }}> {props.isDocument == 1 ? "New Uploads" : "Library" } </h4>
                <Button
                    primary={true}
                    className='rounded-circle ml-3'
                    icon='plus'
                    title='Add New'
                    onClick={() => setItemInEdit({ documentId: 0, documentStatusId: props.isDocument })}
                />
                <span  className='float-right'>
                        <Link to={`/documents`}>View All</Link>
                    </span>
            </CardHeader>
            <CardBody style={{height: 'calc(100% - 5px)', overflow:'hidden'}}>
                {gridItems && gridItems.length > 0 && <div className={`list-view-item-container`} style={{height: '100%'}} >
                    <GridContainer className='px-2 py-1'>
                        {gridItems.map(dataItem => {
                            return (
                                <GridItem key={`griditemcategory-${dataItem.lookupId}`} xs={12} style={{ 'marginTop': '6px' }} >
                                    {/* <h4
                                        className={`${classes.listViewItemDescription} ${classes.textEllipsis}`}
                                        title={dataItem.lookupValue}>{dataItem.lookupValue || '\u00A0'}
                                    </h4> */}
                                    {dataItem.documents.length > 0 && <GridContainer key={`gridcontainerdocument-${dataItem.lookupId}`}>
                                        {dataItem.documents.map(doc => {
                                            return (
                                                <GridItem key={`griditemdocument-${doc.documentId}`} xs={12} className='py-1' >
                                                    {/* <a
                                                        title={doc.filename}
                                                        target="_blank"
                                                        href={`api/document/${doc.documentId}/download`}>
                                                        {doc.documentName}
                                                    </a> */}
                                                    <span className='k-link'
                                                        title={doc.filename}
                                                        onClick={(ev) => {
                                                            //download(doc);
                                                            setItemInEdit(doc);
                                                        }}
                                                        style={{lineHeight: '1', display: 'block'}}
                                                        // onContextMenu={(ev) => {
                                                        //     ev.preventDefault();
                                                        //     setMenu({show: true, offset: { left: ev.clientX, top: ev.clientY }, item: doc})
                                                        // }}
                                                        // href='#'
                                                        >
                                                        {doc.documentName}
                                                    </span>
                                                </GridItem>
                                            );
                                        })}
                                    </GridContainer>
                                    }
                                </GridItem>);
                        })}
                    </GridContainer>
                </div>}  
                {/* <div className='list-view-footer'>
                    <span>
                        <span style={{height: '26px', width:'1px', display: 'inline-block'}}/>
                    </span>
                    <span  className='list-view-footer-link'>
                        <Link to={`/documents`}>View All</Link>
                    </span> 
                </div>*/}
                
                {itemInEdit && <DocumentEditor dataItem={itemInEdit}
                    save={save}
                    cancel={cancel}
                    editMode={itemInEdit.documentId == 0}
                    delete={deleteItem}
                    />}    
            </CardBody>
            <Popup show={menu.show} offset={menu.offset}>
                <Menu vertical={true} style={{ display: 'inline-block' }} onSelect={(ev) => {
                    if (ev.item.text === 'View') {
                        setItemInEdit(menu.item);
                    } else if (ev.item.text === 'Delete') {
                        setItemInDelete(menu.item);
                    } else if (ev.item.text === 'Download') {
                        download(menu.item);
                    }
                }}>
                    <MenuItem text="Download" icon='download' />
                    <MenuItem text="View" icon='search' />
                    <MenuItem text="Delete" icon='close' />
                </Menu>
            </Popup>
            {loading && <LoadingPanel />}
        </Card>
    );
});

export default withSnackbar(withRouter(withStyles(jacmacStyles)(LibraryListView)));