import React from 'react';
import api from "../../../util/api"
import contentDisposition from "content-disposition";
import fileDownload from "js-file-download";
import handleErrors from '../../../util/handleErrors';
import { withSnackbar } from 'notistack';

const UploadList = (props) => {
    const { files } = props;
    return files.map(file =>         
        <div className="k-file-single" key={file.uid}>
            <span className="k-progress" style={{ width: "0%", opacity: "0", transition: "opacity 0.5s ease-in -out 0s"}}></span>
            <span className="k-file-extension-wrapper">
                <span className="k-file-extension">{file.extension}</span>
                <span className="k-file-state"></span>
            </span>
            <span className="k-file-name-size-wrapper">
                {file.data.documentId > 0 ?
                    <span className="k-file-name" title={file.name}>
                        {/* <a title={file.name} target="_blank" href={`api/document/${file.data.documentId}/download`}> 
                            {file.name} 
                        </a> */}
                        <span className='k-link'
                            title={file.name}
                            onClick={(ev) => {
                                api.get(`/document/${file.data.documentId}/download`, { accept: '*/*', responseType: 'blob'})
                                .then(response => {
                                    const disposition = contentDisposition.parse(response.headers['content-disposition']);
                                    fileDownload(response.data, disposition.parameters.filename, response.headers['content-type'])
                                })
                                .catch(handleErrors(props))
                            }}
                            >
                            {file.name} 
                        </span>
                    </span> :
                    <span className="k-file-name" title={file.name}>{file.name}</span>}
                <span className="k-file-size">{(file.size / 1024).toFixed(2)} KB</span>
            </span>
            <strong className="k-upload-status">
                <button type="button" className="k-button k-upload-action">
                    <span aria-label="Remove" title="Remove" className="k-icon k-delete k-i-x"></span>
                </button>
            </strong>
        </div>
    );
}

export default withSnackbar(UploadList);