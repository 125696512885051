import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import withRouterAndRef from '../../util/withRouterAndRef';
import { withRouter } from "react-router-dom";
import api from "../../util/api";
import handleErrors from '../../util/handleErrors';
import { withSnackbar } from 'notistack';
import LoadingPanel from '../common/Grid/LoadingPanel';
import Dialog from "../common/Dialog";
import PartyEditor from "../parties/PartyEditor";
import withStyles from "@material-ui/core/styles/withStyles";
import { Button } from '@progress/kendo-react-buttons';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import jacmacStyles from "../assets/jss/core/jacmacStyles";
import 'react-perfect-scrollbar/dist/css/styles.css';
import KendoGridDateCell from "../../util/KendoGridDateCell";

import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import AddressFormatter from "../../util/formatAddress";

const PartyListView = forwardRef((props, ref) =>  {
    const [parties, setParties] = useState([]);
    const [loading, setLoading] = useState(false);
    const [itemInEdit, setItemInEdit] = useState(null);
    const [itemInDelete, setItemInDelete] = useState(null);
    const [matterId] = useState(props.match.params.id);
    
    useImperativeHandle(ref, () => ({
        load,
    }));
    const load = () => {
        if (matterId) {
            setLoading(true);

            api.get(`/party/${matterId}`, {
                accept: 'application/json',
            }) 
            .then(json => {
                console.log('load', json);
                setParties(json);
                setLoading(false);
            })
            .catch(handleErrors(props))
        }
    }
    useEffect(() => {
        
        load();
        
    }, []);

    const insert = () => {
        setItemInEdit({ partyId: 0, fromMatter: true, matterId: matterId, matter: matterId ? null : {}, client: {} });
    }

    const edit = (dataItem) => {
        if (props.readonly){
            return;
        }
        const item = Object.assign({}, dataItem)
        Object.keys(item).forEach(key => { if (item[key] == null) { item[key] = '' } })
        setItemInEdit(item);
    };

    const deleteItem = () => {
        setLoading(true);

        api.delete(`/party/${itemInDelete.partyId}`, {
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
        .then(() => {
            setLoading(false);
            setItemInDelete(null);

            if (matterId) {
                setLoading(true);

                api.get(`/party/${matterId}`, {
                    accept: 'application/json',
                })
                .then(json => {
                    setParties(json);
                    setLoading(false);
                })
                .catch(handleErrors(props))
            }
        })
        .catch(handleErrors(props))

    };

    const save = (dataItem) => {
        setLoading(true);

        api.request({
            url: '/party',
            method: dataItem.partyId === 0 ? 'POST' : 'PUT',
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(dataItem)
        }) 
        .then(json => {

            let items = parties.slice();
            if (dataItem.partyId === 0) {
                dataItem.partyId = json.partyId;
                items = [dataItem, ...items];
            } else {
                var index = parties.findIndex(v => v.partyId === json.partyId);
                items.splice(index, 1, dataItem);
            }

            setParties(items);
            setItemInEdit(null);
            setLoading(false);
        })
        .catch(handleErrors(props))
    }

    const cancel = () => {
        setItemInEdit(null);
        setItemInDelete(null);
        setLoading(false);
    }  

    return (
        <>
            {props.itemData && !props.readonly && <h5 className='font-size-14 mt-3'><b>{props.itemData.matterName}</b> | <i>JM Ref:</i> <b>{props.itemData.matterNumber}</b> | <i>Client Ref:</i> <b>{props.itemData.clientReference}</b></h5>}
            <h5 className='font-size-14 font-weight-bolder mt-3 mb-4'>Parties</h5>
            <Grid
                filterable={false}
                sortable={true}
                pageable={false}
                className='clickableRow'
                data={parties}
                onRowClick={({ dataItem }) => { edit(dataItem) }}
                style={props.readonly ? {} : { height: 'calc(100vh - 350px)' }} >
                {!props.readonly && <GridToolbar>
                    <Button
                        primary={true}
                        icon='plus'
                        title='Add New'
                        onClick={insert}
                        className='rounded-circle float-right'
                    />
                </GridToolbar>}
                <GridColumn field="firstName" title="First Name" />
                <GridColumn field="lastName" title="Last Name" />
                <GridColumn title="Addresses" cell={(columnProps, context) => {
                    return (
                        <td onClick={() => edit(columnProps.dataItem)}>
                            {columnProps.dataItem.partyAddresses && columnProps.dataItem.partyAddresses.map((val, ind, arr) => {
                                const address = AddressFormatter.format(val);
                                return <><b>{address.type}:</b><div style={{ whiteSpace: 'pre' }}>{address.address}</div></>
                            })}
                        </td>
                    )
                }} />
                <GridColumn title="Phone Numbers" cell={(columnProps, context) => {
                    return (
                        <td onClick={() => edit(columnProps.dataItem)}>
                            {columnProps.dataItem.businessPhone && <div><b>Work:</b> {columnProps.dataItem.businessPhone}</div>}
                            {columnProps.dataItem.homePhone && <div><b>Home:</b> {columnProps.dataItem.homePhone}</div>}
                            {columnProps.dataItem.mobilePhone && <div><b>Mobile:</b> {columnProps.dataItem.mobilePhone}</div>}
                        </td>
                    )
                }} />
                <GridColumn field="email" title="Email" />
                <GridColumn field="dateOfBirth" title="Date of Birth" format="dd/MM/yyyy" cell={KendoGridDateCell} />
 
            </Grid>

            {loading && <LoadingPanel />}
            {itemInEdit && <PartyEditor dataItem={itemInEdit} save={save} cancel={cancel} editMode={true} />}
            {itemInDelete && <Dialog title={"Delete confirmation"} onClose={cancel}>
                <p style={{ margin: "25px", textAlign: "center" }}>Delete party "{[itemInDelete.customerTitle, itemInDelete.firstName, itemInDelete.lastName].joinNonEmpty(" ")}" ?</p>
                <DialogActionsBar>
                    <button className="k-button" onClick={cancel}>No</button>
                    <button className="k-button" onClick={deleteItem}>Yes</button>
                </DialogActionsBar>
            </Dialog>}
        </>
    );
});

export default withSnackbar(withRouterAndRef(withStyles(jacmacStyles)(PartyListView)));

