import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { findDOMNode } from "react-dom";

import { withRouter } from "react-router-dom";
import api from "../../util/api"
import handleErrors from '../../util/handleErrors';
import { requiresRole, policies } from '../../util/accessControl'
import { editableField } from "../../util/editableField";

import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { ComboBox, AutoComplete, MultiSelect } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Button } from '@progress/kendo-react-buttons';
import { formatNumber } from '@telerik/kendo-intl';

import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import Dialog from "../common/Dialog";

import moment from "moment";
import { withSnackbar } from 'notistack';

const AccountEditor = (props) => {
    const [errors, setErrors] = useState({});
    const initLookupObject = { loading: false, list: [] };
    const [payments, setPayments] = useState(initLookupObject);
    const [varianceTypes, setVarianceTypes] = useState(initLookupObject);
    const [relationshipTypes, setRelationshipTypes] = useState(initLookupObject);
    const [securities, setSecurities] = useState(initLookupObject);
    const [matters, setMatters] = useState(initLookupObject);


    const originalItem = {
        ...props.dataItem,
        dateOfAgreement: (props.dataItem.dateOfAgreement == null || props.dataItem.dateOfAgreement === "") ? null : moment(props.dataItem.dateOfAgreement).toDate(),
        nextPaymentDue: (props.dataItem.nextPaymentDue == null || props.dataItem.nextPaymentDue === "") ? null : moment(props.dataItem.nextPaymentDue).toDate(),
    }
    const [itemData, setItemData] = useState(originalItem);
    const [originalItemData, setOriginalItemData] = useState(originalItem);
    const [editMode, setEditMode] = useState(!!props.editMode);
    const [matterId] = useState(props.match.params.id || (itemData.matterId || 0));

    useEffect(() => {
        setPayments({ loading: true, list: [] });
        setVarianceTypes({ loading: true, list: [] });
        const axiosConfig = { accept: 'application/json' };
        const tasks = [
            api.get(`/lookup/paymentFrequency`, axiosConfig),
            api.get(`/lookup/varianceType`, axiosConfig),
            api.get(`/party/${matterId}/lookup`, axiosConfig),
            api.get(`/security/${matterId}/lookup`, axiosConfig)
        ];

        Promise.all(tasks)
        .then(json => {
            let item = {};
            if (itemData.paymentFrequencyId && itemData.paymentFrequencyId > 0) {
                const pItem = json[0].find(v => v.lookupId === itemData.paymentFrequencyId);
                if (pItem) {
                    item.paymentFrequency = pItem;
                }
            }
            if (itemData.varianceTypeId && itemData.varianceTypeId > 0) {
                const vItem = json[1].find(v => v.lookupId === itemData.varianceTypeId);
                if (vItem) {
                    item.varianceType = vItem;
                }
            }
            setPayments({ loading: false, list: json[0] });
            setVarianceTypes({ loading: false, list: json[1] });
            setRelationshipTypes({ loading: false, list: json[2] });
            setSecurities({ loading: false, list: json[3] })
            if (item.paymentFrequency || item.varianceType) {
                setItemData({ ...itemData, ...item });
                setOriginalItemData({ ...itemData, ...item });
            }
            
        })
        .catch(handleErrors(props))

    }, []);

    const matterAutoCompleteChanged = ({ value, ...rest }) => {
        setItemData({ ...itemData, [rest.target.name]: { ...itemData[rest.target.name], lookupValue: value }, matterNumber: value });
        if (value.length < 3) { return };

        if (!(rest.nativeEvent instanceof InputEvent)) {
            const matter = _.find(matters.list, o => o.lookupValue == value);
            if (matter) {
                setItemData({ ...itemData, [rest.target.name]: matter, [rest.target.name + 'Id']: matter.lookupId, matterNumber: matter.lookupValue });
                setMatters([]);
                return;
            }
        }
        loadMatters(value);
    }

    const loadMatters = (value) => {
        setMatters({ list: [], loading: true });
        api.get(`/matter/lookup/contains/${value}`, {
            accept: 'application/json',
        })
        .then(json => {
            setMatters({ list: json || [], loading: false });
        })
        .catch(handleErrors(props))
    }

    const handleTextAreaChange = ({ target: { value, name } }) => {
        setItemData({ ...itemData, [name]: value });
    }

    const handleInputChange = ({ value, target: { name } }) => {
        setItemData({ ...itemData, [name]: value });
    }

    const handleSelectChange = ({ value, target: { name } }) => {
        const idField = `${name}Id`;
        setItemData({ ...itemData, [name]: value, [idField]: value ? value.lookupId : null });
    }

    // const handleAccountsChange = (event) => {
    //     setItemData({ ...itemData, 'securityAccountsLookups': event.target.value});
    // }

    const handleDateChange = ({ value, target: { name } }) => {
        setItemData({ ...itemData, [name]: value == null ? null : moment(value).local().toDate() });
    }

    const validateForm = () => {
        var validForm = true;
        var formErrors = {};
        formErrors.accountNumber = (!itemData.accountNumber || itemData.accountNumber === '');
        formErrors.originalAmountAdvanced = (!itemData.originalAmountAdvanced || itemData.originalAmountAdvanced === '');
        formErrors.dateOfAgreement = (!itemData.dateOfAgreement || itemData.dateOfAgreement === '');
        //formErrors.agreementTerms = (!itemData.agreementTerms || itemData.agreementTerms === '');
        //formErrors.paymentFrequency = (!itemData.paymentFrequency || itemData.paymentFrequency === '');
        //formErrors.nextPaymentDue = (!itemData.nextPaymentDue || itemData.nextPaymentDue === '');
        //formErrors.currentBalance = (!itemData.currentBalance || itemData.currentBalance === '');
        //formErrors.currentArrearsType = (!itemData.currentArrearsType || itemData.currentArrearsType === '');
        //formErrors.daysDelinquent = (!itemData.daysDelinquent || itemData.daysDelinquent === '');
        //formErrors.delinquencyReason = (!itemData.delinquencyReason || itemData.delinquencyReason === '');
        //formErrors.currentInterestRate = (!itemData.currentInterestRate || itemData.currentInterestRate === '')

        if (itemData.selectMatter) {
            formErrors.matter = (!itemData.matter || itemData.matter === '');
        }

        setErrors(formErrors);

        const item = Object.assign({}, formErrors)
        validForm = !Object.keys(item).some(x => item[x]);

        return validForm;
    }

    const concatLookups = (data) => {
        let result = '';
        if (data) {
            data.forEach(function (item) {
                result += ', ' + item.lookupValue;
            });
            return result.substring(2, result.length);
        }
        return result;
    }

    return (
        <Dialog 
            validate={validateForm}
            original={originalItemData}
            current={itemData}
            saveChanges={props.save}
            cancelChanges={props.cancel}
            width={1200}
            createMode={itemData.accountId === 0}
            inEditMode={editMode}
            deleteItem={props.delete}
            deleteItemMessage="Are you sure you want to delete this Account?"
            title={
                <>
                    {`${itemData.accountId === 0 ? 'Add' : editMode ? 'Edit' : 'View'} Account`}
                    {requiresRole(<Button className='rounded-circle ml-2' primary={true} look="bare" icon={editMode ? 'lock' : 'edit'} onClick={(ev) => setEditMode(!editMode)} />, policies.edit)}
                </>}
        >
            <form onSubmit={(ev) => ev.preventDefault()}>
                <GridContainer>
                    <GridItem sm={12}>
                        <h5>Account Information</h5>
                    </GridItem>

                    {editMode ? (<>

                        {itemData.selectMatter &&
                            <GridItem sm={12} className='mt-3'>
                                <GridContainer>
                                    <GridItem sm={6} className='mt-3'>
                                        <AutoComplete
                                            loading={matters.loading}
                                            data={matters.list}
                                            value={itemData.matterNumber}
                                            onChange={matterAutoCompleteChanged}
                                            textField='lookupValue'
                                            style={{ width: '100%', borderWidth: '1px' }}
                                            name="matter"
                                            id="matter"
                                            label="Matter Number"
                                            required={true}
                                            disabled={itemData.accountId > 0}
                                        />
                                        {errors.matter && <span className='text-danger'>Matter Number is Required.</span>}
                                    </GridItem>
                                </GridContainer>
                            </GridItem>            
                        }

                        <GridItem sm={12}>
                            <GridContainer>

                                <GridItem sm={6} className='mt-3'>
                                    <Input
                                        width='100%'
                                        label='Account Number'
                                        value={itemData.accountNumber}
                                        onChange={handleInputChange}
                                        name="accountNumber"
                                        id="accountNumber"
                                        maxLength="100"
                                        required={true}
                                    />
                                    {errors.accountNumber && <span className='text-danger'>Account Number is Required.</span>}
                                </GridItem>

                                <GridItem sm={6} className='mt-3'>
                                    <Input
                                        width='100%'
                                        label='Description'
                                        value={itemData.accountName}
                                        onChange={handleInputChange}
                                        name="accountName"
                                        id="accountName"
                                        maxLength="100"
                                    />
                                </GridItem>

                                <GridItem sm={6} className='mt-3'>
                                    <MultiSelect
                                        data={relationshipTypes.list}
                                        loading={relationshipTypes.loading}
                                        //onFocus={(ev) => { if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click() }}
                                        textField='lookupValue'
                                        dataItemKey='lookupId'
                                        value={itemData.borrowers}
                                        onChange={handleSelectChange}
                                        label="Borrowers"
                                        name='borrowers'
                                        id='borrowers'
                                    //required={true}
                                    />
                                </GridItem>

                                <GridItem sm={6} className='mt-3'>
                                    <MultiSelect
                                        data={securities.list}
                                        loading={securities.loading}
                                        textField='lookupValue'
                                        dataItemKey='lookupId'
                                        value={itemData.securities}
                                        onChange={handleSelectChange}
                                        label="Securities"
                                        name='securities'
                                        id='securities'
                                    />
                                </GridItem>

                                <GridItem sm={6} className='mt-3'>
                                    <NumericTextBox
                                        width='100%'
                                        label='Original Amount Advanced'
                                        value={itemData.originalAmountAdvanced}
                                        onChange={handleInputChange}
                                        format="c2"
                                        spinners={false}
                                        name="originalAmountAdvanced"
                                        id="originalAmountAdvanced"
                                        required={true}
                                    />
                                    {errors.originalAmountAdvanced && <span className='text-danger'>Original Amount Advanced is Required.</span>}
                                </GridItem>

                                <GridItem sm={6} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='k-label'>Date of Agreement</label>
                                        <DatePicker
                                            defaultValue={new Date()}
                                            format="dd/MM/yyyy"
                                            name='dateOfAgreement'
                                            id='dateOfAgreement'
                                            onChange={handleDateChange}
                                            value={itemData.dateOfAgreement}
                                            label=""
                                            required={true}
                                        />
                                    </span>
                                    {errors.dateOfAgreement && <span className='text-danger'>Date of Agreement is Required.</span>}
                                </GridItem>

                                <GridItem sm={6} className='mt-3'>
                                    <ComboBox
                                        data={varianceTypes.list}
                                        loading={varianceTypes.loading}
                                        onFocus={(ev) => { if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click() }}
                                        textField='lookupValue'
                                        dataItemKey='lookupId'
                                        value={itemData.varianceType}
                                        onChange={handleSelectChange}
                                        label="Shortfall/Surplus"
                                        name='varianceType'
                                        id='varianceType'
                                        required={true}
                                    />
                                    {errors.varianceType && <span className='text-danger'>Shortfall/Surplus is Required.</span>}
                                </GridItem>

                                {/* 
                                 
                                <GridItem sm={12} className='mt-3'>
                                    <Input
                                        width='100%'
                                        label='Type of Product'
                                        value={itemData.productType}
                                        onChange={handleInputChange}
                                        name="productType"
                                        id="productType"
                                    />
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <Input
                                        width='100%'
                                        label='Type of Agreement'
                                        value={itemData.agreementType}
                                        onChange={handleInputChange}
                                        name="agreementType"
                                        id="agreementType"
                                    />
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <Input
                                        width='100%'
                                        label='Term of Agreement'
                                        value={itemData.agreementTerms}
                                        onChange={handleInputChange}
                                        name="agreementTerms"
                                        id="agreementTerms"
                                        required={true}
                                    />
                                    {errors.agreementTerms && <span className='text-danger'>Term of Agreement is Required.</span>}
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <NumericTextBox
                                        width='100%'
                                        label='LVR (at Instruction Date)'
                                        value={itemData.loanValueRatio}
                                        onChange={handleInputChange}
                                        format="n4"
                                        spinners={false}
                                        name="loanValueRatio"
                                        id="loanValueRatio"
                                    />
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <Input
                                        width='100%'
                                        label='Mortgage Insurer'
                                        value={itemData.mortgageInsurer}
                                        onChange={handleInputChange}
                                        name="mortgageInsurer"
                                        id="mortgageInsurer"
                                    />
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <Input
                                        width='100%'
                                        label='Mortgage Insurance Policy No.'
                                        value={itemData.mortgageInsurerPolicyNo}
                                        onChange={handleInputChange}
                                        name="mortgageInsurerPolicyNo"
                                        id="mortgageInsurerPolicyNo"
                                    />
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <Input
                                        width='100%'
                                        label='Invoice Reference'
                                        value={itemData.invoiceReference}
                                        onChange={handleInputChange}
                                        name="invoiceReference"
                                        id="invoiceReference"
                                    />
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <NumericTextBox
                                        width='100%'
                                        label='Current Interest Rate '
                                        value={itemData.currentInterestRate}
                                        onChange={handleInputChange}
                                        format="p2"
                                        spinners={false}
                                        name="currentInterestRate"
                                        id="currentInterestRate"
                                        required={true}
                                    />
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <NumericTextBox
                                        width='100%'
                                        label='Current Daily Interest Rate Amount '
                                        value={itemData.dailyInterestAmount}
                                        onChange={handleInputChange}
                                        format="c2"
                                        spinners={false}
                                        name="dailyInterestAmount"
                                        id="dailyInterestAmount"
                                    />
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <NumericTextBox
                                        width='100%'
                                        label='Current Payment Amount '
                                        value={itemData.currentPaymentAmount}
                                        onChange={handleInputChange}
                                        format="c2"
                                        spinners={false}
                                        name="currentPaymentAmount"
                                        id="currentPaymentAmount"
                                    />
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <ComboBox
                                        data={payments.list}
                                        loading={payments.loading}
                                        onFocus={(ev) => { if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click() }}
                                        textField='lookupValue'
                                        dataItemKey='lookupId'
                                        value={itemData.paymentFrequency}
                                        onChange={handleSelectChange}
                                        label="Payment Frequency"
                                        name='paymentFrequency'
                                        id='paymentFrequency'
                                        required={true}
                                    />
                                    {errors.paymentFrequency && <span className='text-danger'>Payment Frequency is Required.</span>}
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='k-label'>Date Next Payment Due</label>
                                        <DatePicker
                                            defaultValue={new Date()}
                                            format="dd/MM/yyyy"
                                            name='nextPaymentDue'
                                            id='nextPaymentDue'
                                            onChange={handleDateChange}
                                            value={itemData.nextPaymentDue}
                                            label=""
                                            required={true}
                                        />
                                    </span>
                                    {errors.nextPaymentDue && <span className='text-danger'>Date Next Payment Due is Required.</span>}
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <NumericTextBox
                                        width='100%'
                                        label='Current Loan Balance'
                                        value={itemData.currentBalance}
                                        onChange={handleInputChange}
                                        format="c2"
                                        spinners={false}
                                        name="currentBalance"
                                        id="currentBalance"
                                        required={true}
                                    />
                                    {errors.currentBalance && <span className='text-danger'>Current Loan Balance is Required.</span>}
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <Input
                                        width='100%'
                                        label='Current Arrears'
                                        value={itemData.currentArrearsType}
                                        onChange={handleInputChange}
                                        name="currentArrearsType"
                                        id="currentArrearsType"
                                        required={true}
                                    />
                                    {errors.currentArrearsType && <span className='text-danger'>Current Arrears is Required.</span>}
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <NumericTextBox
                                        width='100%'
                                        label='No of Days Delinquent at instruction date'
                                        value={itemData.daysDelinquent}
                                        onChange={handleInputChange}
                                        format="n0"
                                        spinners={false}
                                        name="daysDelinquent"
                                        id="daysDelinquent"
                                        required={true}
                                    />
                                    {errors.daysDelinquent && <span className='text-danger'>No of Days Delinquent (at instruction date) is Required.</span>}
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <Input
                                        width='100%'
                                        label='Delinquency Reason at instruction date'
                                        value={itemData.delinquencyReason}
                                        onChange={handleInputChange}
                                        name="delinquencyReason"
                                        id="delinquencyReason"
                                        required={true}
                                    />
                                    {errors.delinquencyReason && <span className='text-danger'>Delinquency Reason (at instruction date) is Required.</span>}
                                </GridItem>

                                 */}

                            </GridContainer>
                        </GridItem>

                        <GridItem sm={12}>
                            <h5>Additional Information</h5>
                            <GridContainer>
                                <GridItem sm={12}>
                                    <textarea
                                        className='k-textarea w-100'
                                        label='Additional Information'
                                        value={itemData.additionalInformation}
                                        onChange={handleTextAreaChange}
                                        name="additionalInformation"
                                        id="additionalInformation"
                                        rows={5}
                                    />
                                </GridItem>
                            </GridContainer>
                        </GridItem>

                    </>) : (<>

                        <GridItem sm={12}>
                            <GridContainer>

                                <GridItem sm={6} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>Account Number:</label>
                                        <div className="font-size-14">{itemData.accountNumber}</div>
                                    </span>
                                </GridItem>

                                <GridItem sm={6} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>Description:</label>
                                        <div className="font-size-14">{itemData.accountName}</div>
                                    </span>
                                </GridItem>

                                <GridItem sm={6} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>Borrowers:</label>
                                            <div className="font-size-14"> {concatLookups(itemData.borrowers)}</div>
                                    </span>
                                </GridItem>

                                <GridItem sm={6} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>Securities:</label>
                                        <div className="font-size-14"> {concatLookups(itemData.securities)}</div>
                                    </span>
                                </GridItem>

                                <GridItem sm={6} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>Original Amount Advanced:</label>
                                        <div className="font-size-14">{itemData.originalAmountAdvanced ? formatNumber(itemData.originalAmountAdvanced, "c2") : ''}</div>
                                    </span>
                                </GridItem>

                                <GridItem sm={6} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>Date of Agreement:</label>
                                        <div className="font-size-14">{itemData.dateOfAgreement ? moment(itemData.dateOfAgreement).format('D/MM/YYYY') : ''}</div>
                                    </span>
                                </GridItem>

                                <GridItem sm={6} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>Shortfall/Surplus:</label>
                                        <div className="font-size-14">{itemData.varianceType ? itemData.varianceType.lookupValue : ''}</div>
                                    </span>
                                </GridItem>

                                    {/*
                                     
                                <GridItem sm={12} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>Type of Product:</label>
                                        <div className="font-size-14">{itemData.productType}</div>
                                    </span>
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>Type of Agreement:</label>
                                        <div className="font-size-14">{itemData.agreementType}</div>
                                    </span>
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>Term of Agreement:</label>
                                        <div className="font-size-14">{itemData.agreementTerms}</div>
                                    </span>
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>LVR (at Instruction Date):</label>
                                        <div className="font-size-14">{itemData.loanValueRatio ? formatNumber(itemData.loanValueRatio, "n4") : ''}</div>
                                    </span>
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>Mortgage Insurer:</label>
                                        <div className="font-size-14">{itemData.mortgageInsurer}</div>
                                    </span>
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>Mortgage Insurance Policy No.:</label>
                                        <div className="font-size-14">{itemData.mortgageInsurerPolicyNo}</div>
                                    </span>
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>Invoice Reference:</label>
                                        <div className="font-size-14">{itemData.invoiceReference}</div>
                                    </span>
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>Current Interest Rate:</label>
                                        <div className="font-size-14">{itemData.currentInterestRate ? formatNumber(itemData.currentInterestRate, "p2") : ''}</div>
                                    </span>
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>Current Daily Interest Rate Amount:</label>
                                        <div className="font-size-14">{itemData.dailyInterestAmount ? formatNumber(itemData.dailyInterestAmount, "c2") : ''}</div>
                                    </span>
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>Current Payment Amount:</label>
                                        <div className="font-size-14">{itemData.currentPaymentAmount ? formatNumber(itemData.currentPaymentAmount, "c2") : ''}</div>
                                    </span>
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>Payment Frequency:</label>
                                        <div className="font-size-14">{itemData.paymentFrequency ? itemData.paymentFrequency.lookupValue : ''}</div>
                                    </span>
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>Date Next Payment Due:</label>
                                        <div className="font-size-14">{itemData.nextPaymentDue ? moment(itemData.nextPaymentDue).format('D/MM/YYYY') : ''}</div>
                                    </span>
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>Current Loan Balance:</label>
                                        <div className="font-size-14">{itemData.currentBalance ? formatNumber(itemData.currentBalance, "c2") : ''}</div>
                                    </span>
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>Current Arrears:</label>
                                        <div className="font-size-14">{itemData.currentArrearsType}</div>
                                    </span>
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>No of Days Delinquent at instruction date:</label>
                                        <div className="font-size-14">{itemData.daysDelinquent ? formatNumber(itemData.daysDelinquent, "n0") : ''}</div>
                                    </span>
                                </GridItem>

                                <GridItem sm={12} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>Delinquency Reason at instruction date:</label>
                                        <div className="font-size-14">{itemData.delinquencyReason}</div>
                                    </span>
                                </GridItem>

                                    */}
                            </GridContainer>
                        </GridItem>

                        <GridItem sm={12} className='mt-3'>
                            <span className='k-textbox-container'>
                                <label className='ro-label'>Additional Information:</label>
                                <div className="font-size-14">{itemData.additionalInformation}</div>
                            </span>
                        </GridItem>
                    </>)}

                </GridContainer>
            </form>
        </Dialog>
    )
};

export default withSnackbar(withRouter(AccountEditor));