import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Link } from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from 'moment';

import { ConfirmDelete } from "../common/Dialog";
import LoadingPanel from '../common/Grid/LoadingPanel';
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import GenericHeader from "../common/Header/GenericHeader"; 

import api from "../../util/api"
import withRouterAndRef from '../../util/withRouterAndRef';
//import KendoGridBooleanCell from "../../util/KendoGridBooleanCell";
//import KendoGridDateTimeCell from "../../util/KendoGridDateTimeCell";
import handleErrors from '../../util/handleErrors';

import EventEditor from "./EventEditor";
import EventSummary from "./EventSummary";

import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { Switch } from '@progress/kendo-react-inputs';
import { ExcelExport } from '@progress/kendo-react-excel-export';

import CardIcon from "../common/MuiKit/Card/CardIcon";
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";

import EventIcon from "@material-ui/icons/Event";

const saveEvent = (dataItem, props, afterSave, setLoading) => {
    setLoading(true);
    api.request({
        url: `/event`,
        method: dataItem.eventId === 0 ? 'POST' : 'PUT', 
        accept: 'application/json',
        headers: {
            'Content-Type': 'application/json'
        },
        data: JSON.stringify(dataItem)
    }) 
    .then(json => {
        afterSave(json);
    })
    .catch(handleErrors(props))
}

const Event = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        insert,
    }));
    const [ events, setEvents ] = useState([]);
    const [ total, setTotal ] = useState(0);
    const initPage = { take: props.critical ? 5 : 10, skip: 0 };
    const [ page, setPage] = useState(initPage)
    const defaultSort = {object: [{field: "startDate", dir: "asc"}], query: 'sort[0].Property=startDate&sort[0].Direction=ascending'};
    const [ sort, setSort ] = useState(defaultSort);
    const [togglePastFilter, setTogglePastFilter] = useState(false);

    const [ loading, setLoading ] = useState(false);
    const [ itemInEdit, setItemInEdit ] = useState(null);
    const [ itemInDelete, setItemInDelete ] = useState(null);
    const [matterId] = useState(props.match.params.id);
    const eventsSummaryRef = useRef();
    const idRoute = matterId ? `/${matterId}` : '';
    const _export = useRef(null);
    const _grid = useRef(null);

    useEffect(() => {    
        if (!props.summary){
            //console.log('props.critical : ', props.critical, sort.query);
            setLoading(true);
            api.get(
                props.critical 
                    ? `/event${idRoute}/paged/${(page.skip / page.take) + 1}/${page.take}/${!togglePastFilter}?sort[0].property=startdate&sort[0].direction=ascending&filter[0].property=enddate&filter[0].operator=GreaterThanOrEqual&filter[0].value=${moment().format("DD/MM/YYYY")}`
                    : `/event${idRoute}/paged/${(page.skip / page.take) + 1}/${page.take}/${!togglePastFilter}${sort.query ? `?${sort.query}` : ``}`, 
            {
                accept: 'application/json',
            }) 
            .then(json => {
                json.data.forEach(item => {
                    if (item.startDate) {
                        item.startDate = moment(item.startDate).toDate();
                    }
                });
                setEvents(json.data);
                setTotal(json.totalRecords);
                setLoading(false);
            })
            .catch(handleErrors(props))
        }
    }, [page, sort, togglePastFilter]);

    const edit = (dataItem) => {
        const event = Object.assign({}, dataItem)
        Object.keys(event).forEach(key => { if (event[key] == null) { event[key] = '' } })
        setItemInEdit(event);
    };

    const remove = (dataItem) => {
        setItemInDelete(dataItem);
    };

    const deleteItem = () => {
        setLoading(true);
     
        api.delete(`/event/${itemInDelete.eventId}`, {
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
        .then(json => {
          
            setLoading(false);
            setItemInDelete(null);
            setPage({ take: 5, skip: 0 });
        })
        .catch(handleErrors(props))
    };

    const save = (dataItem) => {
        saveEvent(dataItem, props, (json) => {
            if (eventsSummaryRef && eventsSummaryRef.current) {
                eventsSummaryRef.current.load();
                setItemInEdit(null);
            } else {
                let items = events.slice();
                if (dataItem.eventId === 0) {
                    dataItem.eventId = json.eventId;
                    items = [dataItem, ...items];
                } else {
                    var index = events.findIndex(v => v.eventId === json.eventId);            
                    items.splice(index, 1, dataItem);
                }

                if (props.getEvents) {
                    props.getEvents();
                }            
                
                setEvents(items);
                setTotal(items.length);
                setItemInEdit(null);
                setLoading(false);
            }
        }, setLoading);
        
    }

    const cancel = () => {
        setItemInEdit(null);
        setItemInDelete(null);
    }

    const insert = () => {
        setItemInEdit({ eventId: 0, matter: matterId ? null : {}, matterId });
    }

    const renderEditors = (forceEdit = false) => {
        return (
            <>                
                {itemInEdit && <EventEditor dataItem={itemInEdit} save={save} cancel={cancel} editMode={itemInEdit.eventId === 0 || forceEdit}/>}
                {itemInDelete && <ConfirmDelete title={`Delete Event ${itemInDelete.subject}?`} cancel={cancel} deleteItem={deleteItem} />}
            </>
        )
    }

    const renderCard = () => {
        return (
            <GridContainer>
                {!props.cards && <GenericHeader />}
                <Card style={!props.cards ? { height: props.cardHeight, marginTop: '35px' } : { height: '350px', marginTop: '0px' }}>
                    <CardHeader>
                        <CardIcon color='primary' style={{marginTop: '-30px'}}>
                            <EventIcon style={{color:'white'}} />
                        </CardIcon>
                        <h4 className='jm-card-title' style={{ color: '#777' }}>Events ({total})</h4>

                        {!props.cards && <GridContainer className='my-3'>
                            <GridItem xs={1}>
                                <Button
                                    primary={true}
                                    className='rounded-circle mb-1'
                                    icon='plus'
                                    title='Add New'
                                    onClick={insert}
                                />
                            </GridItem>
                            <GridItem xs={1}>
                                <Switch onLabel='Future Only' offLabel='All' className='mb-1 w-100'
                                    checked={togglePastFilter}
                                    onChange={() => {
                                        setTogglePastFilter(!togglePastFilter);
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={3}>
                                <ExcelExport ref={_export} />
                                <Button
                                    primary={false}
                                    className='jm-action-btn k-title'
                                    onClick={() => {
                                        api.get(props.critical
                                            ? `/event${idRoute}/paged/1/10000/${!togglePastFilter}?sort[0].property=startdate&sort[0].direction=ascending&filter[0].property=enddate&filter[0].operator=GreaterThanOrEqual&filter[0].value=${moment().format("DD/MM/YYYY")}`
                                            : `/event${idRoute}/paged/1/10000/${!togglePastFilter}${sort.query ? `?${sort.query}` : ``}`, {
                                            accept: 'application/json',
                                        }).then(json => {
                                            json.data.forEach(item => {
                                                if (item.startDate) {
                                                    item.startDate = moment(item.startDate).format('d/MM/YYYY h:mm:ss a');
                                                }
                                            });
                                            _export.current.save(json, _grid.current.columns)
                                        })
                                    }}
                                    style={{ borderRadius: '8px' }}
                                >
                                    EXPORT TO EXCEL
                                </Button> 
                                <Grid ref={_grid} style={{ display: 'none' }}>
                                    <GridColumn field="startDate" title="Start Date" format="{0:dd/MM/yyyy hh:mm a}" />
                                    {!matterId && <GridColumn field="matter.matterName" title="Matter Name" />}
                                    <GridColumn field="subject" title="Event" />
                                </Grid>
                            </GridItem>
                        </GridContainer>}

                        {!!props.cards &&
                            <>
                                <Button
                                    primary={true}
                                    className='rounded-circle ml-3 mb-1'
                                    icon='plus'
                                    title='Add New'
                                    onClick={insert}
                                />

                                <span style={{ float: 'right', marginTop: '5px' }}>
                                    <Link to={`/events/${matterId}`}>View All</Link>
                                </span>
                            </>
                        }

                    </CardHeader>
                    <CardBody>
                        {renderGrid()}
                    </CardBody>
                </Card>
            </GridContainer>
        )
    }

    const renderGrid = () => {
        return (
            <>
                {!props.cards ? (
                    <Grid
                        filterable={false}
                        sortable={{
                            allowUnsort: true,
                            mode: 'single'
                        }}
                        sort={sort ? sort.object: []}
                        onSortChange={({ sort }) => {        
                            //console.log('sort: ', sort);
                            if (sort && sort.length > 0) {
                                var arr = sort.map((o, i) => {
                                    return `sort[${i}].Property=${o.field}&sort[${i}].Direction=${o.dir === 'asc' ? 'ascending' : 'descending'}`;
                                });
                                var str = arr.join('&');
                                setSort({object: sort, query: str});
                            } else {
                                setSort({object: [], query: ''});
                            }
    
                        }}
                        pageable={props.pagerSettings}
                        data={events}
                        total={total}
                        take={page.take}
                        skip={page.skip}
                        onPageChange={(ev) => setPage(ev.page)}
                        onRowClick={({ dataItem }) => { edit(dataItem, false) }}
                        style={{ height: 'calc(100vh - 275px)' }}
                    >
                        <GridToolbar>
                            <Button
                                primary={true}
                                icon='plus'
                                title='Add New'
                                onClick={insert}
                            />
                        </GridToolbar>
                        <GridColumn field="startDate" title="Start Date" format="{0:dd/MM/yyyy hh:mm a}" />
                        {!matterId && <GridColumn field="matter.matterName" title="Matter Name" />}
                        <GridColumn field="subject" title="Event" />
                        {/* {!matterId && <GridColumn field="matter.matterNumber" title="Matter Number" />} */}
                        {/* <GridColumn field="endDate" title="End Date" cell={KendoGridDateTimeCell} /> */}
                        {/* <GridColumn field="isAllDay" title="All Day Event" cell={KendoGridBooleanCell} /> */}
                        {/* <GridColumn cell={cellWithEditing(edit, remove)} width={125} /> */}
                    </Grid>
                ) : (
                    <div className={`${events && events.length > 0 ? 'list-view-item-container' : 'list-view-item-container-empty'}`} style={{height: '100%'}} >
                        {(events && events.length > 0) ? events.map(i => {
                            const date = moment(i.startDate);
                            const end = i.endDate ? moment(i.endDate) : null;
                            return (
                                <div key={`keyeventitem${i.eventId}`} className='event-item' style={{width: '100%'}} onClick={() => setItemInEdit(i)}>
                                    <div className='event-item-title'>
                                        <h6>{date.format('MMM')}</h6>
                                        <h5>{date.date()}</h5>
                                    </div>
                                    <div className='event-item-content'>
                                        <h5>{i.subject}</h5>
                                        <h6>{`${date.format('dddd hh:mm A')}${end ? ` - ${end.format('hh:mm A')}` : ''}`}</h6>
                                    </div>
                                </div>
                            );
                        }) : <p style={{width: '100%', textAlign: 'center', color: 'rgba(0, 0, 0, 0.62)'}}>No data</p>}      
                        </div>   
                )}
                {loading && <LoadingPanel/>}
                {renderEditors(!props.cards)}
            </>
        )
    }

    return props.summary ? <EventSummary {...props} edit={edit} renderEditors={renderEditors} ref={eventsSummaryRef} /> : props.matterStageId ? renderGrid() : renderCard();
});

export default withSnackbar(withRouterAndRef(Event));
export {
    saveEvent
}