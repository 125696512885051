import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';

export default function cellWithDownload() {
    return class extends GridCell {
        render() {
            return (
                <td>
                    {this.props.dataItem.documentId > 0 ?
                        <u className="text-primary" >
                            <a                             
                                title={this.props.dataItem.filename} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                href={`api/document/${this.props.dataItem.documentId}/download`}> 
                                {this.props.dataItem.documentName}
                            </a>
                        </u> :
                        this.props.dataItem.documentName
                    }
                </td>
            );
        }
    };
}