import React, { useState, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { withSnackbar } from 'notistack';
import llamaLogo from "../../assets/img/JacMac Llama Logo.png";
import jacmacLogo from "../../assets/img/Jacmac with text.png";
import SearchResults from "../../home/SearchResults";
import { Dialog as KendoDialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { findDOMNode } from 'react-dom';
import api from "../../../util/api";
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import handleErrors from '../../../util/handleErrors';
import { Card } from '@progress/kendo-react-layout';
import { requiresRole, policies } from '../../../util/accessControl';
import moment from 'moment';

const Header = (props) => {
    const { loading, setLoading, title, ...rest } = props;

    const [ keyword, setKeyword ] = useState('');
    const [ searchResults, setSearchResults ] = useState([]);
    const [ noResults, setNoResults ] = useState(false);

    const resultsDialogRef = useRef();

    const handleClick = (ev) => {
        search();
    }

    const handleKeyPress = (ev) => {
        if (ev.key === 'Enter') {
            search();
        }
    }

    const handleClose = (dataItem) => {
        setSearchResults([]);
        if (dataItem) {
            props.history.push(`/files/${dataItem.matterId}`);
        }
    }

    const search = () => {
        setSearchResults([])
        if (keyword) {
            setLoading(true);
            api.get(`search/${keyword}`)
            .then((response) => {
                if (!response || response.length === 0) {
                    setNoResults(true);
                } else {
                    setSearchResults(response);
                }                
            }).catch((error) => {
                console.error('error: ', error);
            }).finally(() => {
                setLoading(false);
                findDOMNode(resultsDialogRef.current).focus();
            })
        }
    }

    const newInstruction = () => {
        let dataItem = {
            matterId: 0, matterNumber: 'New Matter Number', clientContact: {}, manager: {}, isSensitiveMatter: false, instructionDate: moment().toDate()
        };
        setLoading(true);

        api.request({
            url: '/matter',
            method: dataItem.matterId === 0 ? 'POST' : 'PUT',
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(dataItem)
        })
        .then(json => {
            dataItem.matterId = json.matterId;
            props.history.push(`/instruction/${dataItem.matterId}`);
        })
        .catch(handleErrors(props))
        .finally(setLoading(false));
    }

    return (
        <>
            <div className='d-flex flex-row align-items-center' style={{position: 'absolute', top: '0', left: '0', right: '0', padding: '15px 30px', backgroundColor: '#fff'}}>
                <img alt='' src={llamaLogo} className='img-fluid' style={{height: '35px',paddingRight: '15px'}} />
                <h4 className='mr-auto'>{title}</h4>
                {/* <Card className='searchbox'>
                    <Input placeholder='Search' onKeyPress={handleKeyPress} value={keyword} onChange={({ value }) => setKeyword(value)} />
                    <Button icon='search' onClick={handleClick} />
                </Card> */}

                {requiresRole(
                <Button
                    primary={false}
                    className='jm-action-btn k-title'
                    icon='plus'
                    title='Create new Matter'
                    onClick={newInstruction}
                    style={{marginTop: '1px', height: '42px', borderRadius: '8px', marginRight: 'auto', marginLeft: 'auto'}}
                >
                    New Matter
                </Button>, policies.edit)}
                <img alt='' src={jacmacLogo} className='img-fluid ml-auto' style={{height: '55px'}} />
            </div>
            {
                !noResults && searchResults.length > 0 && 
                <SearchResults ref={resultsDialogRef} onClose={handleClose} data={searchResults} searchText={keyword} />
            }
            {
                noResults && 
                <KendoDialog onClose={() => setNoResults(false)} width={400} title='No results found'>
                    <p>The search '{keyword}' did not return any matches.</p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => setNoResults(false)}>Ok</button>
                    </DialogActionsBar>
                </KendoDialog>
            }
        </>
    )
}

export default withSnackbar(withRouter(Header));