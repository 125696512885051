import React, { useState, useEffect } from 'react';
import { findDOMNode } from "react-dom";

import LoadingPanel from '../common/Grid/LoadingPanel';
import Dialog from "../common/Dialog";
import cellWithEditing from "../common/Grid/CellWithEditing";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";

import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import { ComboBox, AutoComplete, DropDownList } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';

import uuidv4 from "uuid/v4";
import Mustache from "mustache";
import _ from "lodash";

import api from "../../util/api"
import AddressFormatter from "../../util/formatAddress";
import handleErrors from '../../util/handleErrors';
import { requiresRole, policies } from '../../util/accessControl';
import { editableField } from "../../util/editableField";

import moment from "moment";
import { withSnackbar } from 'notistack';

const PartyEditor = (props) => {
    const [errors, setErrors] = useState({});
    const initLookupObject = { loading: false, list: [] };
    const [matters, setMatters] = useState(initLookupObject);
    const originalItem = {
        ...props.dataItem,
        dateOfBirth: (props.dataItem.dateOfBirth == null || props.dataItem.dateOfBirth === "") ? null : moment(props.dataItem.dateOfBirth).toDate()
    };
    const [itemData, setItemData] = useState(originalItem);
    const [originalItemData, setOriginalItemData] = useState(originalItem);
    const [editMode, setEditMode] = useState(!!props.editMode);

    const initAddress = {
        addressName: "",
        addressUnitNumber: "",
        addressStreetNumber: "",
        addressStreetName: "",
        addressSuburb: "",
        addressState: "",
        addressPostCode: ""
    }
   
    const [addressSettings, setAddressSettings] = useState({
        format: [],
        available: {},
        required: []
    });

    const isAvailable = (name) => {
        //console.log('isAvailable: ', addressSettings, addressSettings.available)
        return addressSettings.available.hasOwnProperty(name);
    }

    const isRequired = (name) => {
        return addressSettings.required.includes(name);
    }

    const getLabel = (name, fallback) => {
        if (addressSettings.available[name]) {
            return addressSettings.available[name];
        }
        return fallback;
    }
    const [addressList, setAddressList] = useState(initLookupObject);
    const [relationships, setRelationships] = useState(initLookupObject);
    const [addressTypes, setAddressTypes] = useState(initLookupObject);
    const [allPartyItems, setAllPartyItems] = useState(initLookupObject);
    const [partyItems, setPartyItems] = useState(initLookupObject);
    const [defaultAddressType, setDefaultAddressType] = useState({});

    const setLookup = (setFunc) => (json) => {
        setFunc({ loading: false, list: json });
        return json;
    }

    useEffect(() => {
        //console.log("itemData", itemData);
        const axiosConfig = { accept: 'application/json' };
        const loading = { loading: true, list: [] };
        setAddressTypes(loading);
        setRelationships(loading);
        setAddressList(loading);
        setPartyItems(loading);

        let onLoadTasks = [
            api.get(`/lookup/relationshipInterest`, axiosConfig).then(setLookup(setRelationships)),
            api.get(`/party/addresstypes`, axiosConfig).then(setLookup(setAddressTypes)),
            api.get(`/partyaddress/${props.dataItem.partyId}`, axiosConfig).then(setLookup(setAddressList)),
            api.get(`/party/${itemData.matterId}/partyaddresslookup`, axiosConfig).then(setLookup(setPartyItems)),
        ];

        Promise.all(onLoadTasks)
            .then(responses => {
                if (responses[0]) {
                    let tempData = { ...itemData };
                    let relationshipInterest = tempData.relationshipInterest;
                    if (tempData.relationshipInterestId && tempData.relationshipInterestId > 0) {
                        relationshipInterest = responses[0].find(v => v.lookupId === tempData.relationshipInterestId);
                    }
                    tempData = { ...tempData, relationshipInterest: relationshipInterest };
                    
                    //console.log('tempData', tempData);
                    setItemData(tempData);
                    setOriginalItemData(tempData);
                }

                if (responses[1]) {
                    const addrType = { ..._.find(responses[1], (o) => o.addressType === 'Street') };
                    //console.log('load: ', addrType, responses[1]);
                    setDefaultAddressType(addrType);
                    setAddressSettings({
                        format: addrType.formatString,
                        available: addrType.availableFields,
                        required: addrType.requiredFields
                    });
                }

                if (responses[2]) {
                    let addressResponse = responses[2].map(address => ({ ...address, uid: uuidv4() }));
                    setAddressList({ loading: false, list: addressResponse });
                }

                if (responses[3]) {
                    let partyResponse = responses[3];
                    setAllPartyItems({ loading: false, list: partyResponse });
                    setPartyItems({ loading: false, list: partyResponse });
                    
                    //console.log('partyItems', partyResponse);
                }
                
            })
            .catch(handleErrors(props))
    }, []);

    const [editingAddress, setEditingAddress] = useState(null);
    const [deletingAddress, setDeletingAddress] = useState(null);
    const [isEditingAddress, setIsEditingAddress] = useState(false);
    const [isAddingAddress, setIsAddingAddress] = useState(false);

    const handleInputChange = ({ value, target: { name } }) => {
        setItemData({ ...itemData, [name]: value });
    }

    const handleAddressInputChange = ({ value, target: { name } }) => {
        setEditingAddress({ ...editingAddress, [name]: value });
    }

    const handleAddressTypeChange = ({ value, target: { name } }) => {
        
        if (name === 'copyAddress') {
            //console.log('handleAddressTypeChange', editingAddress);
            if (value && value.partyAddress) {
                let thisAddressType = addressTypes.list.find(v => v.lookupId === value.partyAddress.addressTypeId);

                setEditingAddress({
                    ...editingAddress,
                    ['copyAddress']: value,
                    ['copyAddressFrom']: value.lookupId,
                    ['addressName']: value.partyAddress.addressName,
                    ['addressUnitNumber']: value.partyAddress.addressUnitNumber,
                    ['addressStreetNumber']: value.partyAddress.addressStreetNumber,
                    ['addressStreetName']: value.partyAddress.addressStreetName,
                    ['addressSuburb']: value.partyAddress.addressSuburb,
                    ['addressState']: value.partyAddress.addressState,
                    ['addressPostCode']: value.partyAddress.addressPostCode,
                    ['addressTypeId']: value.partyAddress.addressTypeId,
                    ['addressType']: thisAddressType,
                });

                setAddressSettings({
                    format: thisAddressType.formatString,
                    available: thisAddressType.availableFields,
                    required: thisAddressType.requiredFields
                });

            } else {
                setEditingAddress({
                    ...editingAddress,
                    ['copyAddress']: value,
                    ['copyAddressFrom']: value ? value.lookupId : null,
                    ['addressName']: '',
                    ['addressUnitNumber']: '',
                    ['addressStreetNumber']: '',
                    ['addressStreetName']: '',
                    ['addressSuburb']: '',
                    ['addressState']: '',
                    ['addressPostCode']: '',
                });
            }
        }
        else {

            const idField = `${name}Id`;
            setEditingAddress({ ...editingAddress, [name]: value, [idField]: value ? value.lookupId : null });
            setAddressSettings({
                format: value.formatString,
                available: value.availableFields,
                required: value.requiredFields
            });
        }
    }

    const matterAutoCompleteChanged = ({ value, ...rest }) => {
        setItemData({ ...itemData, [rest.target.name]: { ...itemData[rest.target.name], lookupValue: value }, matterNumber: value });
        if (value.length < 3) { return };

        if (!(rest.nativeEvent instanceof InputEvent)) {
            const matter = _.find(matters.list, o => o.lookupValue === value);
            if (matter) {
                setItemData({ ...itemData, [rest.target.name]: matter, [rest.target.name + 'Id']: matter.lookupId, matterNumber: matter.lookupValue });
                setMatters([]);
                return;
            }
        }
        loadMatters(value);
    }

    const loadMatters = (value) => {
        setMatters({ list: [], loading: true });
        api.get(`/matter/lookup/contains/${value}`, {
            accept: 'application/json',
        })
            .then(json => {
                setMatters({ list: json || [], loading: false });
            })
            .catch(handleErrors(props))
    }

    const handleSelectChange = ({ value, target: { name } }) => {
        const idField = `${name}Id`;
        setItemData({ ...itemData, [name]: value, [idField]: value ? value.lookupId : null });
    }

    const handleDateChange = ({ value, target: { name } }) => {
        setItemData({ ...itemData, [name]: value == null ? null : moment(value).local().toDate() });
    }

    const handleIsLastKnown = ({ target: { value, name, checked } }) => {
        setEditingAddress({ ...editingAddress, 'isLastKnownAddress': checked });
    }

    const edit = (dataItem) => {
        const item = Object.assign({}, dataItem)
        let filteredPartyList = _.filter(allPartyItems.list, function (currentPartyItem) {
            console.log('filteredPartyList', currentPartyItem, dataItem);
            return currentPartyItem.lookupId != dataItem.partyAddressId;
        });
        
        setPartyItems({ loading: false, list: filteredPartyList });
        if (item.addressTypeId && !item.addressType) {
            const addrType = {..._.find(addressTypes.list, (o) => o.lookupId === item.addressTypeId)};
            
            item.addressType = addrType;
            setAddressSettings({
                format: addrType.formatString,
                available: addrType.availableFields,
                required: addrType.requiredFields
            })
        }

        const copyFrom = { ..._.find(partyItems.list, (o) => o.lookupId === item.copyAddressFrom) };
        item.copyAddress = copyFrom;

        Object.keys(item).forEach(key => { if (item[key] == null) { item[key] = '' } })
        setEditingAddress(item);
        setIsAddingAddress(false);
        setIsEditingAddress(true); 
    };

    const remove = (dataItem) => {
        setDeletingAddress(dataItem);
    };

    const [addressValidationFailed, setAddressValidationFailed] = useState(false);

    const validateAddress = (address) => {
        //if (address.copyAddressFrom > 0) {
        //    console.log('editingAddress', address);
        //    return true;
        //}
        const valid = _.some(addressSettings.required, (o) => address[o]);
        setAddressValidationFailed(!valid);
        return valid;
    }

    const saveAndReturnToList = () => {
        if (validateAddress(editingAddress)){
            let items = addressList.list.slice();
            var index = items.findIndex(v => v.uid === editingAddress.uid);            
            items.splice(index, 1, editingAddress);
            
            if (editingAddress.isLastKnownAddress === true) {
                items.forEach((value, i) => {
                    if (value.uid !== editingAddress.uid) {
                        items.splice(i, 1, { ...value, isLastKnownAddress: false});
                    }
                });
            }
            
            setAddressList({ loading: false, list: items });
            setItemData({ ...itemData, partyAddresses: items });
            returnToList();
        }
    }

    const addAddressAndReturn = () => {
        if (validateAddress(editingAddress)){
            addAddress();
            returnToList();
        }
    }

    const addAddress = () => {
        let items = addressList.list.slice();
        items.push(editingAddress);

        if (editingAddress.isLastKnownAddress === true) {
            items.forEach((value, i) => {
                if (value.uid !== editingAddress.uid) {
                    items.splice(i, 1, { ...value, isLastKnownAddress: false });
                }
            });
        }

        setAddressList({ loading: false, list: items });
        setItemData({ ...itemData, partyAddresses: items });
    }

    const returnToList = () => { 
        setIsAddingAddress(false);
        setIsEditingAddress(false); 
        setEditingAddress(null);
        setDeletingAddress(null);

        setPartyItems(allPartyItems);
    }

    const addNewAddress = () => {
        setPartyItems(allPartyItems);

        setIsAddingAddress(true);
        setIsEditingAddress(false);       
        setEditingAddress({ ...initAddress, partyId: props.dataItem.partyId, uid: uuidv4(), addressTypeId: defaultAddressType.lookupId, addressType: { ...defaultAddressType } });
    }

    const cancelDeleteAddress = () => {
        setDeletingAddress(null);
        setAddressList({ list: addressList.list, loading: false });
    }

    const confirmDeleteAddress = () => {
        let items = addressList.slice();
        var index = items.findIndex(v => v.uid === deletingAddress.uid);            
        items.splice(index, 1);
        setAddressList(items);
        returnToList();
    }

    const formatAddress = (address) => AddressFormatter.format(address);
    const validateForm = () => {
        var validForm = true;
        var formErrors = {};
        
        formErrors.isEditingAddress = isEditingAddress;
        formErrors.isAddingAddress = isAddingAddress;
        formErrors.firstName = (!itemData.firstName || itemData.firstName === '');
        formErrors.lastName = (!itemData.lastName || itemData.lastName === '');
        formErrors.email = (!itemData.email || itemData.email === '');
        //formErrors.mobilePhone = (!itemData.mobilePhone || itemData.mobilePhone === '');

        if (itemData.selectMatter) {
            formErrors.matter = (!itemData.matter || itemData.matter === '');
        }

        setErrors(formErrors);
        const item = Object.assign({}, formErrors)
        validForm = !Object.keys(item).some(x => item[x]);
        return validForm;
    } 
   
    const renderAddressList = () => {
        return (
            <React.Fragment>
                <GridItem sm={12} className='jm-form-section-title mb-3'>
                    <h5>Addresses</h5>
                    {itemData.partyId === 0 && <Button primary={true} icon='plus' title='Add New' onClick={addNewAddress} />}
                    {itemData.partyId > 0 && !!editMode && requiresRole(<Button primary={true} icon='plus' title='Add New' onClick={addNewAddress} />, policies.edit)}
                </GridItem>
                <Grid
                    filterable={false}
                    sortable={false}
                    pageable={false}
                    data={addressList.list}
                    style={{ height: "100%", maxHeight: '504px' }}
                    className='jm-grid-noheader'
                >
                    <GridColumn cell={(p) => {
                        const { type, address } = formatAddress(p.dataItem);
                        return (
                        <td>
                            <GridContainer>
                                <GridItem xs={12}>
                                        Type: {type} <span className="font-weight-bold">{p.dataItem.isLastKnownAddress ? "(last known address)" : "" }</span>
                                </GridItem>
                                <GridItem xs={12} style={{whiteSpace: 'pre'}}>
                                        {address}
                                </GridItem>
                            </GridContainer>
                        </td>
                        );
                    }} />
                    <GridColumn cell={cellWithEditing(edit, remove, null, null, true)} width={54} />
                </Grid>
                {addressList.loading && <LoadingPanel />}
                {deletingAddress && <Dialog title={"Delete confirmation"} onClose={cancelDeleteAddress}>
                    <p style={{ margin: "25px", textAlign: "center" }}>Delete address?</p>
                    <p style={{ margin: "0", textAlign: "center" }}>{formatAddress(deletingAddress).address}</p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={cancelDeleteAddress}>No</button>
                        <button className="k-button" onClick={confirmDeleteAddress}>Yes</button>
                    </DialogActionsBar>
                </Dialog>}
                
            </React.Fragment>
        )
    }
    const renderAddressEditor = () => {
        return (
            <React.Fragment>
                <GridItem sm={6}>  
                    {
                        isAddingAddress && 
                        <Button
                            style={{fontSize: '8px', marginBottom: '5px'}}
                            primary={true}
                            icon='check'
                            title='Add and Return'
                            onClick={addAddressAndReturn}
                        />
                    }
                    {errors.isAddingAddress && <span className='text-danger'>Please save the address.</span>}
                    {
                        isEditingAddress &&
                        <Button
                            style={{fontSize: '8px', marginBottom: '5px'}}
                            primary={true}
                            icon='check'
                            title='Save and Return'
                            onClick={saveAndReturnToList}
                        />
                    } 
                    {errors.isEditingAddress && <span className='text-danger'>Please save the address.</span>}
                    <Button
                        style={{fontSize: '8px', marginBottom: '5px'}}
                        className='ml-1'
                        primary={true}
                        icon='close'
                        title='Return'
                        onClick={returnToList}
                    />
  
                </GridItem>

                <GridItem sm={12} className='mt-3'>
                    <input type="checkbox" className='k-checkbox' defaultChecked={editingAddress.isLastKnownAddress} onClick={handleIsLastKnown} name="isLastKnownAddress" id="isLastKnownAddress" />
                    <label className="k-checkbox-label" htmlFor="isLastKnownAddress">Last Known Address</label>
                </GridItem>

                <GridItem sm={12} className='mt-3'>
                    <DropDownList
                        data={partyItems.list}
                        loading={partyItems.loading}
                        onFocus={(ev) => { if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click() }}

                        textField='lookupValue'
                        dataItemKey='lookupId'
                        value={editingAddress.copyAddress}
                        onChange={handleAddressTypeChange}
                        label="Copy Address from"
                        name='copyAddress'
                        id='copyAddress'
                    />
                </GridItem>

                <GridItem sm={12} className='mt-3'>
                    <DropDownList
                        data={addressTypes.list}
                        loading={addressTypes.loading}
                        onFocus={(ev) => {if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click()}}

                        textField='addressType'
                        dataItemKey='lookupId'
                        value={editingAddress.addressType}
                        onChange={handleAddressTypeChange}
                        label="Address Type"
                        name='addressType'
                        id='addressType'
                    />
                </GridItem>

                {isAvailable('addressName') && <GridItem sm={12} className='mt-3'>
                    <Input
                        width='100%'
                        label={getLabel('addressName', 'Property/Building Name')}
                        value={editingAddress.addressName}
                        onChange={handleAddressInputChange}
                        name="addressName"
                        id="addressName"
                        required={isRequired('addressName')}
                    />
                </GridItem>}
                {isAvailable('addressUnitNumber') && <GridItem sm={12} className='mt-3'>
                    <Input
                        width='100%'
                        label={getLabel('addressUnitNumber', 'Unit/Level/Shop No.')}
                        value={editingAddress.addressUnitNumber}
                        onChange={handleAddressInputChange}
                        name="addressUnitNumber"
                        id="addressUnitNumber"
                        required={isRequired('addressUnitNumber')}
                    />
                </GridItem>}
                {isAvailable('addressStreetNumber') && <GridItem sm={12} className='mt-3'>
                    <Input
                        width='100%'
                        label={getLabel('addressStreetNumber', 'Street Number')}
                        value={editingAddress.addressStreetNumber}
                        onChange={handleAddressInputChange}
                        name="addressStreetNumber"
                        id="addressStreetNumber"
                        required={isRequired('addressStreetNumber')}
                    />
                </GridItem>}
                {isAvailable('addressStreetName') && <GridItem sm={12} className='mt-3'>
                    <Input
                        width='100%'
                        label={getLabel('addressStreetName', 'Street Name')}
                        value={editingAddress.addressStreetName}
                        onChange={handleAddressInputChange}
                        name="addressStreetName"
                        id="addressStreetName"
                        required={isRequired('addressStreetName')}
                    />
                </GridItem>}
                {isAvailable('addressSuburb') && <GridItem sm={12} className='mt-3'>
                    <Input
                        width='100%'
                        label={getLabel('addressSuburb', 'Suburb')}
                        value={editingAddress.addressSuburb}
                        onChange={handleAddressInputChange}
                        name="addressSuburb"
                        id="addressSuburb"
                        required={isRequired('addressSuburb')}
                    />
                </GridItem>}
                {isAvailable('addressState') && <GridItem sm={12} className='mt-3'>
                    <Input
                        width='100%'
                        label={getLabel('addressState', 'State')}
                        value={editingAddress.addressState}
                        onChange={handleAddressInputChange}
                        name="addressState"
                        id="addressState"
                        required={isRequired('addressState')}
                    />
                </GridItem>}
                {isAvailable('addressPostCode') && <GridItem sm={12} className='mt-3'>
                    <Input
                        width='100%'
                        label={getLabel('addressPostCode', 'Postcode')}
                        value={editingAddress.addressPostCode}
                        onChange={handleAddressInputChange}
                        name="addressPostCode"
                        id="addressPostCode"
                        required={isRequired('addressPostCode')}
                    />
                </GridItem>}
                
                <GridItem sm={12} className='mt-3'>
                    {/* {editingAddress.uid} */}
                    <p style={{whiteSpace: 'pre'}}>
                        {Mustache.render(addressSettings.format.join('\r\n'), editingAddress)}
                    </p>
                </GridItem>
                {addressValidationFailed && <Dialog title={"Invalid Address"} onClose={() => setAddressValidationFailed(false)}>
                    <p style={{ margin: "25px", textAlign: "center" }}>Please complete all required address fields.</p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => setAddressValidationFailed(false)}>Ok</button>
                    </DialogActionsBar>
                </Dialog>}
            </React.Fragment>
        )        
    }

    return (
        <Dialog
            validate={validateForm}
            original={originalItemData}
            current={itemData}
            saveChanges={props.save}
            cancelChanges={props.cancel}
            width={1200}
            createMode={itemData.partyId === 0}
            inEditMode={editMode}
            deleteItem={props.delete}
            deleteItemMessage="Are you sure you want to delete this Party?"
            title={
                <>
                    {`${itemData.partyId === 0 ? 'Add' : editMode ? 'Edit' : 'View'} Party`}
                    {requiresRole(<Button className='rounded-circle ml-2' primary={true} look="bare" icon={editMode ? 'lock' : 'edit'} onClick={(ev) => setEditMode(!editMode)} />, policies.edit)}
                </>}
        >
            <form onSubmit={(ev) => ev.preventDefault()}>
                <GridContainer>
                    <GridItem sm={6}>
                        <GridContainer>
                            <GridItem sm={12}>
                                <h5>General Information</h5>
                            </GridItem>

                            {itemData.selectMatter &&
                                <GridItem sm={12} className='mt-3'>
                                    <AutoComplete
                                        loading={matters.loading}
                                        data={matters.list}
                                        value={itemData.matterNumber}
                                        onChange={matterAutoCompleteChanged}
                                        textField='lookupValue'
                                        style={{ width: '100%', borderWidth: '1px' }}
                                        name="matter"
                                        id="matter"
                                        label="Matter Number"
                                        required={true}
                                        disabled={itemData.partyId > 0}
                                    />
                                    {errors.matter && <span className='text-danger'>Matter Number is Required.</span>}
                                </GridItem>
                            }

                            {editMode ?
                                (<>
                                    {/*
                                    <GridItem sm={12} className='mt-3'>
                                        <Input
                                            width='100%'
                                            label='Title'
                                            value={itemData.customerTitle}
                                            onChange={handleInputChange}
                                            name="customerTitle"
                                            id="customerTitle"
                                        />
                                    </GridItem>
                                    */}
                                    <GridItem sm={12} className='mt-3'>
                                        <Input
                                            width='100%'
                                            label='First Name'
                                            value={itemData.firstName}
                                            onChange={handleInputChange}
                                            name="firstName"
                                            id="firstName"
                                            maxLength="300"
                                            required
                                        />
                                        {errors.firstName && <span className='text-danger'>First Name is Required.</span>}
                                    </GridItem>

                                    <GridItem sm={12} className='mt-3'>
                                        <Input
                                            width='100%'
                                            label='Last Name'
                                            value={itemData.lastName}
                                            onChange={handleInputChange}
                                            name="lastName"
                                            id="lastName"
                                            maxLength="300"
                                            required
                                        />
                                        {errors.lastName && <span className='text-danger'>Last Name is Required.</span>}
                                    </GridItem>

                                    <GridItem sm={12} className='mt-3'>
                                        <Input
                                            width='100%'
                                            label='Email'
                                            value={itemData.email}
                                            onChange={handleInputChange}
                                            name="email"
                                            id="email"
                                            maxLength="300"
                                            required
                                        />
                                        {errors.email && <span className='text-danger'>Email is Required.</span>}
                                    </GridItem>

                                    <GridItem sm={12} className='mt-3'>
                                        <Input
                                            width='100%'
                                            label='Home Telephone'
                                            value={itemData.homePhone}
                                            onChange={handleInputChange}
                                            name="homePhone"
                                            id="homePhone"
                                            maxLength="50"
                                        />
                                        {errors.homePhone && <span className='text-danger'>Home Telephone is Required.</span>}
                                    </GridItem>

                                    <GridItem sm={12} className='mt-3'>
                                        <Input
                                            width='100%'
                                            label='Mobile Telephone'
                                            value={itemData.mobilePhone}
                                            onChange={handleInputChange}
                                            name="mobilePhone"
                                            id="mobilePhone"
                                            maxLength="50"
                                        />
                                    </GridItem>

                                    <GridItem sm={12} className='mt-3'>
                                        <Input
                                            width='100%'
                                            label='Business Telephone'
                                            value={itemData.businessPhone}
                                            onChange={handleInputChange}
                                            name="businessPhone"
                                            id="businessPhone"
                                            maxLength="50"
                                        />
                                    </GridItem>

                                    <GridItem sm={12} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='k-label'>Date of Birth</label>
                                            <DatePicker
                                                defaultValue={new Date()}
                                                format={"dd/MM/yyyy"}
                                                name="dateOfBirth"
                                                id="dateOfBirth"
                                                value={itemData.dateOfBirth}
                                                onChange={handleDateChange}
                                            />
                                        </span>
                                    </GridItem>

                                    <GridItem sm={12} className='mt-3'>
                                        <Input
                                            width='100%'
                                            label='Comments'
                                            value={itemData.interest}
                                            onChange={handleInputChange}
                                            name="interest"
                                            id="interest"
                                            maxLength="500"
                                        />
                                    </GridItem>

                                    {/*
                                    <GridItem sm={12} className='mt-3'>
                                        <ComboBox
                                            data={relationships.list}
                                            loading={relationships.loading}
                                            onFocus={(ev) => { if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click() }}
                                            textField='lookupValue'
                                            dataItemKey='lookupId'
                                            value={itemData.relationshipInterest}
                                            onChange={handleSelectChange}
                                            label="Relationship/Interest"
                                            name='relationshipInterest'
                                            id='relationshipInterest'
                                        />
                                    </GridItem>
                                    */}
                                </>) :

                                (<>
                                    {/*
                                    <GridItem sm={12} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Title:</label>
                                            <div className="font-size-14">{itemData.customerTitle}</div>
                                        </span>
                                    </GridItem>
                                    */}
                                    <GridItem sm={12} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>First Name:</label>
                                            <div className="font-size-14">{itemData.firstName}</div>
                                        </span>
                                    </GridItem>

                                    <GridItem sm={12} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Last Name:</label>
                                            <div className="font-size-14">{itemData.lastName}</div>
                                        </span>
                                    </GridItem>
                                    
                                    <GridItem sm={12} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Email:</label>
                                            <div className="font-size-14">{itemData.email}</div>
                                        </span>
                                    </GridItem>

                                    <GridItem sm={12} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Home Telephone:</label>
                                            <div className="font-size-14">{itemData.homePhone}</div>
                                        </span>
                                    </GridItem>

                                    <GridItem sm={12} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Mobile Telephone:</label>
                                            <div className="font-size-14">{itemData.mobilePhone}</div>
                                        </span>
                                    </GridItem>

                                    <GridItem sm={12} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Business Telephone:</label>
                                            <div className="font-size-14">{itemData.businessPhone}</div>
                                        </span>
                                    </GridItem>

                                    <GridItem sm={12} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Date of Birth:</label>
                                            <div className="font-size-14">{itemData.dateOfBirth ? moment(itemData.dateOfBirth).format('D/MM/YYYY') : ''}</div>
                                        </span>
                                    </GridItem>

                                    <GridItem sm={12} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Comments:</label>
                                            <div className="font-size-14">{itemData.interest ? itemData.interest : ''}</div>
                                        </span>
                                    </GridItem>

                                    {/*
                                    <GridItem sm={12} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Relationship/Interest:</label>
                                            <div className="font-size-14">{itemData.relationshipInterest ? itemData.relationshipInterest.lookupValue : ''}</div>
                                        </span>
                                    </GridItem>
                                    */}
                                </>)
                            }

                        </GridContainer>
                    </GridItem>
                    <GridItem sm={6}>
                        <GridContainer>
                            {!editingAddress ? renderAddressList() : renderAddressEditor()}
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            </form>
        </Dialog>
    )
};
export default withSnackbar(PartyEditor);