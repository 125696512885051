import React, { useState, useEffect } from 'react';
import Dialog from "../common/Dialog";
import { Input } from '@progress/kendo-react-inputs';
import { DateTimePicker, DatePicker, TimePicker } from '@progress/kendo-react-dateinputs';
import { Button } from '@progress/kendo-react-buttons';
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import api from "../../util/api"
import { requiresRole, policies } from '../../util/accessControl'
import moment from "moment";
import handleErrors from '../../util/handleErrors';
import { withSnackbar } from 'notistack';

const EventEditor = (props) => {
    const [errors, setErrors] = useState({});
    const originalItem = {
        ...props.dataItem,
        startDate: (props.dataItem.startDate == null || props.dataItem.startDate === "") ? moment().toDate() : moment(props.dataItem.startDate).toDate(),
        endDate: (props.dataItem.endDate == null || props.dataItem.endDate === "") ? moment().add(30, 'm').toDate() : moment(props.dataItem.endDate).toDate(),
        createdOn: (props.dataItem.createdOn == null || props.dataItem.createdOn === "") ? moment().toDate() : moment(props.dataItem.createdOn).toDate(),
    };
    const [itemEvent, setItemEvent] = useState(originalItem);    
    const [originalItemEvent, setOriginalItemEvent] = useState(originalItem);
    const [editMode, setEditMode] = useState(!!props.editMode);
    const timeStep = { hour: 1, minute: 15 };

    useEffect(() => {
        const axiosConfig = { accept: 'application/json' };

        if (itemEvent.matterId) {
            let onLoadItems = [
                api.get(`/matter/${itemEvent.matterId}`, axiosConfig)
            ];
    
            Promise.all(onLoadItems)
                .then(responses => {
                    let tempData = { ...originalItem };
                    if (tempData.matterId > 0) {
                        tempData = { ...tempData, matter: responses[0] };
                        setItemEvent(tempData);
                        setOriginalItemEvent(tempData);
                    }
                })
                .catch(handleErrors(props))
        }
        
    }, [editMode]);

    const handleInputChange = ({ value, target: { name } }) => {
        setItemEvent({ ...itemEvent, [name]: value });
    }
    
    const handleIsAllDayChange = ({ target: { value, name, checked } }) => {
        let mstartDate = moment(itemEvent.startDate).startOf('day').toDate();
        let mendDate = moment(itemEvent.endDate).startOf('day').toDate();

        setItemEvent({
            ...itemEvent,
            'isAllDay': checked,
            startDate: checked ? mstartDate : itemEvent.startDate,
            endDate: checked ? mendDate : itemEvent.endDate,
        });
    }

    const handleDateChange = ({ value, target: { name } }) => {
        console.log(value, name);
        if (name === "startDate" && !itemEvent.isAllDay && value > itemEvent.endDate) {
            setItemEvent({
                ...itemEvent,
                startDate: value == null ? null : moment(value).local().toDate(),
                endDate: value == null ? null : moment(value).add(30, 'm').toDate()
            });
        }
        else {
            setItemEvent({ ...itemEvent, [name]: value == null ? null : moment(value).local().toDate() });
        }
        
    }

    const validateForm = () => {
        var validForm = true;
        var formErrors = {};
        formErrors.subject = (!itemEvent.subject || itemEvent.subject === '');
        formErrors.startDate = (!itemEvent.startDate || itemEvent.startDate === '');
        formErrors.endDate = (!itemEvent.endDate || itemEvent.endDate === '');
        formErrors.startEnd = (!formErrors.startDate && !formErrors.endDate && (itemEvent.startDate > itemEvent.endDate));

        setErrors(formErrors);

        const item = Object.assign({}, formErrors)
        validForm = !Object.keys(item).some(x => item[x]);
        return validForm;
    }
    
    return (
        <Dialog 
            validate={validateForm}
            original={originalItemEvent}
            current={itemEvent}
            saveChanges={props.save}
            cancelChanges={props.cancel}
            width={600}
            createMode={itemEvent.eventId === 0}
            inEditMode={editMode}
            title={
                <>
                    {`${itemEvent.eventId === 0 ? 'Add' : editMode ? 'Edit' : 'View'} Event`}
                    {requiresRole(<Button className='rounded-circle ml-2' primary={true} look="bare" icon={editMode ? 'lock' : 'edit'} onClick={(ev) => setEditMode(!editMode)} />, policies.edit)}
                </>}
        >
            <form onSubmit={(ev) => ev.preventDefault()}>
                <GridContainer>

                    <GridItem sm={12}>
                        <div className="font-size-14">{itemEvent.matter ? `${itemEvent.matter.matterName} | ${itemEvent.matter.matterNumber} | ${itemEvent.matter.clientReference}` : 'No Matter'}</div>
                    </GridItem>

                    {editMode ? (
                        <>
                            <GridItem sm={12} className='mt-4'>
                                <Input
                                    width='100%'
                                    label='Event name'
                                    value={itemEvent.subject}
                                    onChange={handleInputChange}
                                    name="subject"
                                    id="subject"
                                    required={true}
                                />
                                {errors.subject && <span className='text-danger'>Event name is Required.</span>}
                            </GridItem>
                            <GridItem sm={12} className='mt-3'>
                                <input type="checkbox" className='k-checkbox' defaultChecked={itemEvent.isAllDay} onClick={handleIsAllDayChange} name="isAllDay" id="isAllDay"  />
                                <label className="k-checkbox-label" htmlFor="isAllDay">All Day Event</label>
                            </GridItem>

                            {!itemEvent.isAllDay && <>
                                <GridItem sm={3} className='mt-4'>
                                    <span className='k-textbox-container'>
                                        <label className='k-label'>Start Date</label>
                                        <DatePicker
                                            format="dd/MM/yyyy"
                                            name='startDate'
                                            id='startDate'
                                            onChange={handleDateChange}
                                            value={itemEvent.startDate}
                                            required={true}
                                        />
                                    </span>
                                    {errors.startDate && <span className='text-danger'>Start Date is Required.</span>}
                                </GridItem>
                                <GridItem sm={3} className='mt-4'>
                                    <span className='k-textbox-container'>
                                        <label className='k-label'>Start Time</label>
                                        <TimePicker
                                            format="hh:mm a"
                                            name='startDate'
                                            id='startDate'
                                            onChange={handleDateChange}
                                            value={itemEvent.startDate}
                                            steps={timeStep}
                                            required={true}
                                        />
                                    </span>
                                    {errors.startDate && <span className='text-danger'>Start Time is Required.</span>}
                                </GridItem>
                            </>}

                            {itemEvent.isAllDay && <GridItem sm={6} className='mt-4'>
                                <span className='k-textbox-container'>
                                    <label className='k-label'>Start Date</label>
                                    <DatePicker
                                        value={itemEvent.startDate}
                                        onChange={handleDateChange}
                                        format='dd/MM/yyyy'
                                        width='100%'
                                        name="startDate"
                                        id="startDate"
                                        required
                                    />
                                </span>
                                {errors.startDate && <span className='text-danger'>Start Date is Required.</span>}
                            </GridItem>}

                            {!itemEvent.isAllDay && <>
                                <GridItem sm={3} className='mt-4'>
                                    <span className='k-textbox-container'>
                                        <label className='k-label'>End Date</label>
                                        <DatePicker
                                            format="dd/MM/yyyy"
                                            name='endDate'
                                            id='endDate'
                                            onChange={handleDateChange}
                                            value={itemEvent.endDate}
                                            steps={timeStep}
                                            required
                                        />
                                    </span>
                                    {errors.endDate && <span className='text-danger'>End Date is Required.</span>}
                                    {errors.startEnd && <span className='text-danger'>The end date you entered occurs before the start date</span>}
                                </GridItem>

                                <GridItem sm={3} className='mt-4'>
                                    <span className='k-textbox-container'>
                                        <label className='k-label'>End Time</label>
                                        <TimePicker
                                            format="hh:mm a"
                                            name='endDate'
                                            id='endDate'
                                            onChange={handleDateChange}
                                            value={itemEvent.endDate}
                                            steps={timeStep}
                                            required
                                        />
                                    </span>
                                    {errors.endDate && <span className='text-danger'>End Time is Required.</span>}
                                    {errors.startEnd && <span className='text-danger'>The end time you entered occurs before the start time</span>}
                                </GridItem>
                            </>}

                            {itemEvent.isAllDay && <GridItem sm={6} className='mt-4'>
                                <span className='k-textbox-container'>
                                    <label className='k-label'>End Date</label>
                                    <DatePicker
                                        value={itemEvent.endDate}
                                        onChange={handleDateChange}
                                        format='dd/MM/yyyy'
                                        width='100%'
                                        name="endDate"
                                        id="endDate"
                                        required
                                    />
                                </span>
                                {errors.endDate && <span className='text-danger'>End Date is Required.</span>}
                                {errors.startEnd && <span className='text-danger'>The end date you entered occurs before the start date</span>}
                            </GridItem>}
                        </>
                    ) : (
                        <>
                            <GridItem sm={3} className='mt-3'>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Event name:</label>
                                </span>                                
                            </GridItem> 
                            <GridItem sm={9} className='mt-3'>
                                <span className='k-textbox-container'>
                                    <div className="font-size-14">{itemEvent.subject}</div>
                                </span>                                
                            </GridItem>
                            <GridItem sm={3} className='mt-3'>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Date:</label>
                                </span>                                
                            </GridItem> 
                            <GridItem sm={9} className='mt-3'>
                                <span className='k-textbox-container'>
                                    <div className="font-size-14">
                                        {itemEvent.isAllDay 
                                        ? (<>ALL DAY EVENT<br/>{(itemEvent.startDate && itemEvent.endDate && itemEvent.startDate.getTime() === itemEvent.endDate.getTime()) ? moment(itemEvent.startDate).format('D/MM/YYYY') : `${moment(itemEvent.startDate).format('D/MM/YYYY')} until ${moment(itemEvent.endDate).format('D/MM/YYYY')}`}</>) 
                                        : `${moment(itemEvent.startDate).format('D/MM/YYYY hh:mm A')} until ${moment(itemEvent.endDate).format('D/MM/YYYY hh:mm A')}`}
                                    </div>
                                </span>                                
                            </GridItem>
                        </>
                    )}

                    {/* {editableField(
                        <GridItem sm={12} style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                            <Input
                                width='100%'
                                label='Subject'
                                value={itemEvent.subject}
                                onChange={handleInputChange}
                                name="subject"
                                id="subject"
                                required={true}
                            />
                            {errors.subject && <span className='text-danger'>Subject is Required.</span>}
                        </GridItem>, editMode, 'Subject', itemEvent.subject ?? '')}

                    {editableField(<GridItem sm={12} style={{ paddingBottom: '15px', paddingTop: '5px' }}>
                        <input type="checkbox" className='k-checkbox' defaultChecked={itemEvent.isAllDay} onClick={handleIsAllDayChange} name="isAllDay" id="isAllDay"  />
                        <label className="k-checkbox-label" htmlFor="isAllDay">All Day Event</label>
                    </GridItem>, editMode, 'All Day Event', itemEvent.isAllDay ? 'Yes' : 'No')}

                    {editableField(
                        <GridItem sm={6}>
                            <span className='k-textbox-container'>
                                <label className='k-label'>Start Date</label>

                                {!itemEvent.isAllDay && <DateTimePicker
                                    format="dd/MM/yyyy hh:mm a"
                                    name='startDate'
                                    id='startDate'
                                    onChange={handleDateChange}
                                    value={itemEvent.startDate}
                                    label="Start Date"
                                    required={true}
                                />}

                                {itemEvent.isAllDay && <DatePicker
                                    value={itemEvent.startDate}
                                    onChange={handleDateChange}
                                    format='dd/MM/yyyy'
                                    width='100%'
                                    label="Start Date"
                                    name="startDate"
                                    id="startDate"
                                    required
                                />}

                            </span>
                            {errors.startDate && <span className='text-danger'>Start Date is Required.</span>}
                            {errors.startEnd && <span className='text-danger'>The start date you entered occurs after the end date</span>}
                        </GridItem>, editMode, 'Start Date', moment(itemEvent.startDate).format(itemEvent.isAllDay ? 'D/MM/YYYY' : 'D/MM/YYYY hh:mm A' ))}

                    {editableField(<GridItem sm={6}>
                        <span className='k-textbox-container'>
                            <label className='k-label'>End Date</label>
                            {!itemEvent.isAllDay && <DateTimePicker
                                format="dd/MM/yyyy hh:mm a"
                                name='endDate'
                                id='endDate'
                                onChange={handleDateChange}
                                value={itemEvent.endDate}
                                label="End Date"
                                required
                            />}

                            {itemEvent.isAllDay && <DatePicker
                                value={itemEvent.endDate}
                                onChange={handleDateChange}
                                format='dd/MM/yyyy'
                                width='100%'
                                label="End Date"
                                name="endDate"
                                id="endDate"
                                required
                            />}
                        </span>
                        {errors.endDate && <span className='text-danger'>End Date is Required.</span>}
                        {errors.startEnd && <span className='text-danger'>The end date you entered occurs before the start date</span>}
                    </GridItem>, editMode, 'End Date', moment(itemEvent.endDate).format(itemEvent.isAllDay ? 'D/MM/YYYY' : 'D/MM/YYYY hh:mm A'))} */}

                </GridContainer>
            </form>

        </Dialog>
    )
};

export default withSnackbar(EventEditor);