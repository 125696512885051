import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, roles, ...rest }) => (
    <Route {...rest} render={props => {
        var auth = localStorage.getItem('Llama.expires');

        if(!auth || auth <= Date.now())
        {
            return <Redirect to={{ pathname: `/login`, state: { from: props.location } }} /> 
        }

        return <Component {...props} />}}
    />
)

export {
    ProtectedRoute
};