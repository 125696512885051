import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import classNames from 'classnames';
import { requiresRole, policies } from '../../../util/accessControl'

const cellWithEditing = (edit, remove = null, vertical = false, matterInstruction = null) => {

    return class extends GridCell {
        render() {
            return (
                <td>
                    {matterInstruction && requiresRole(<Button
                        primary={true}
                        icon='cogs'
                        title='Edit Instruction'
                        onClick={() => { matterInstruction(this.props.dataItem) }}
                    />, policies.edit) 
                    }
                    
                    {requiresRole(<Button
                        primary={true}
                        icon='pencil'
                        onClick={() => { edit(this.props.dataItem); }}
                        title='Edit'
                        className={classNames({'ml-1': !vertical, 'mt-1': vertical})}
                    >
                    </Button>, policies.edit)
                    }

                    {remove && 
                        requiresRole(<Button
                            primary={true}
                            icon='close'
                            onClick={() => { remove(this.props.dataItem); }}
                            title='Delete'
                            className={classNames({'ml-1': (remove && !vertical), 'mt-1': (remove && vertical)})}
                        >
                        </Button>, policies.edit)
                    }

                    {/* {partyAddress &&
                        <Button
                            primary={true}
                            icon='list-bulleted'
                            onClick={() => { partyAddress(this.props.dataItem); }}
                            title='Party Addresses'
                            className={classNames({'ml-1': (partyAddress && !vertical), 'mt-1': (partyAddress && vertical)})}
                        >
                        </Button>
                    } */}
                </td>
            );
        }
    };
}

export default cellWithEditing