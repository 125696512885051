import React from 'react';
import { Redirect } from "react-router-dom";
const handleErrors = props => error => {
    console.log(error)
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        if ([401, 403].indexOf(error.response.status) !== -1) {
            props.enqueueSnackbar("You dont have the required permission, please contact IT Service Desk.", { variant: 'error' });
            props.history.push('/login')
            return;
        }
    }

    if (error && error.text) {
        console.error(error);
        error.text().then(errorMessage => {
            try {
                var errorObject = JSON.parse(errorMessage);
                
                Object.keys(errorObject.errors).forEach(key => {
                    props.enqueueSnackbar(errorObject.errors[key][0], { variant: 'error' });
                })
                props.enqueueSnackbar(errorObject.title, { variant: 'error' });
            }
            catch (err) {
                props.enqueueSnackbar('An error has occured, please contact IT Service Desk.', { variant: 'error' });
            }
        })
    } else if (error && error.response && error.response.data) {
        props.enqueueSnackbar(error.response.data, { variant: 'error' });
    } else {
        props.enqueueSnackbar('An error has occured, please contact IT Service Desk.', { variant: 'error' });
    }
}

export default handleErrors;