import React, { useState, useEffect } from 'react';
import { findDOMNode } from "react-dom";
import Dialog from "../common/Dialog";
import { Input } from '@progress/kendo-react-inputs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';

import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import api from "../../util/api"
import moment from "moment";

import { withSnackbar } from 'notistack';
import handleErrors from '../../util/handleErrors';

const MatterStageEditor = (props) => {

    const [errors, setErrors] = useState({});
    const initLookupObject = { loading: false, list: [] };
    const originalItem = {
        ...props.dataItem,
        instructionDate: (props.dataItem.instructionDate == null || props.dataItem.instructionDate === "") ? moment().toDate() : moment(props.dataItem.instructionDate).toDate(),
        //matter: props.dataItem.matter,
        //createdOn: props.dataItem.createdOn
    };
    const [itemData, setItemData] = useState(originalItem);
    const [originalItemData, setOriginalItemData] = useState(originalItem);
    const [blockTypes, setBlockTypes] = useState(initLookupObject);
    const [blockStatuses, setBlockStatuses] = useState(initLookupObject);
 
    const setLookup = (setFunc) => (json) => {
        setFunc({ loading: false, list: json });
        return json;
    }
    useEffect(() => {
        const axiosConfig = { accept: 'application/json' };
        const loading = { loading: true, list: [] };
        setBlockTypes(loading);
        setBlockStatuses(loading);

        let onLoadTasks = [
            api.get(`/lookup/blocktype`, axiosConfig).then(setLookup(setBlockTypes)),
            api.get(`/lookup/blockstatus`, axiosConfig).then(setLookup(setBlockStatuses)),
        ];

        Promise.all(onLoadTasks)
            .then(responses => {
                let tempData = { ...itemData };
                let blockType = tempData.blockType;
                if (tempData.blockTypeId && tempData.blockTypeId > 0) {
                    blockType = responses[0].find(v => v.lookupId === tempData.blockTypeId);
                }
                let blockStatus = tempData.blockStatus;
                if (tempData.blockStatusId && tempData.blockStatusId > 0) {
                    blockStatus = responses[1].find(v => v.lookupId === tempData.blockStatusId);
                } else {
                    blockStatus = responses[1].find(v => v.lookupValue === "Active");
                }

                tempData = { ...tempData, blockType: blockType, blockStatus: blockStatus, blockStatusId: blockStatus ? blockStatus.lookupId : null };
                setItemData(tempData);
                setOriginalItemData(tempData);

                const cell = document.getElementsByClassName('k-detail-cell')[0];
                if (cell) {
                    cell.setAttribute('colspan', '5');
                }
            })
            .catch(handleErrors(props))
    }, []);

    const handleInputChange = ({ value, target: { name } }) => {
        setItemData({ ...itemData, [name]: value });
    }
    const handleSelectChange = ({ value, target: { name } }) => {
        const idField = `${name}Id`;
        setItemData({ ...itemData, [name]: value, [idField]: value ? value.lookupId : null });
    }
    const handleDateChange = ({ value, target: { name } }) => {
        setItemData({ ...itemData, [name]: value == null ? null : moment(value).local().toDate() });
    }

    const validateForm = () => {
        var validForm = true;
        var formErrors = {};
        formErrors.blockType = (!itemData.blockType || itemData.blockType === '');
        formErrors.blockStatus = (!itemData.blockStatus || itemData.blockStatus === '');
        formErrors.instructionDate = (!itemData.instructionDate || itemData.instructionDate === '');
        
        setErrors(formErrors);

        const item = Object.assign({}, formErrors)
        validForm = !Object.keys(item).some(x => item[x]);

        return validForm;
    }

    //const handleTextAreaChange = ({ target: { value, name } }) => {
    //    setItemData({ ...itemData, [name]: value });
    //}
    //const handleSaveForm = () => {
    //    var validForm = true;
    //    var formErrors = {};
    //    formErrors.blockType = (!itemData.blockType || itemData.blockType === '');
    //    formErrors.blockStatus = (!itemData.blockStatus || itemData.blockStatus === '');
    //    formErrors.instructionDate = (!itemData.instructionDate || itemData.instructionDate === '');
    //    setErrors(formErrors);
    //    const item = Object.assign({}, formErrors)
    //    validForm = !Object.keys(item).some(x => item[x]);
    //    if (validForm) { props.save(itemData); }
    //}

    return (
        <Dialog 
        
        //onClose={props.cancel} 
            createMode={itemData.blockId === 0}
            validate={validateForm}
            original={originalItemData}
            current={itemData}
            saveChanges={props.save}
            cancelChanges={props.cancel}
            width={600}
            title={`${itemData.blockId === 0 ? 'Add' : 'Edit'} Block`}>
            <form onSubmit={(ev) => ev.preventDefault()}>
                <GridContainer>
                    <GridItem sm={9}>
                        <div className="font-size-14">{itemData.matter ? `${itemData.matter.matterName} | ${itemData.matter.matterNumber} | ${itemData.matter.clientReference}` : 'No Matter'}</div>
                    </GridItem>
                    <GridItem sm={3} className='d-flex justify-content-end'>
                        <div className="font-size-14">{itemData.createdOn ? moment(itemData.createdOn).format('DD/MM/YYYY') : ''}</div>
                    </GridItem>
                    <GridItem sm={12} className='mt-3'>
                        <Input
                            width='100%'
                            label='Block Number'
                            value={itemData.blockName}
                            onChange={handleInputChange}
                            name="blockName"
                            id="blockName"
                            disabled
                        />
                    </GridItem>
                    
                    <GridItem sm={12} className='mt-3'>
                        <ComboBox
                            data={blockTypes.list}
                            loading={blockTypes.loading}
                            onFocus={(ev) => {if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click()}}
                            textField='lookupValue'
                            dataItemKey='lookupId'
                            value={itemData.blockType}
                            onChange={handleSelectChange}
                            label="Block Type"
                            name='blockType'
                            id='blockType'
                            required
                        />
                        {errors.blockType && <span className='text-danger'>Block Type is Required.</span>}
                    </GridItem>

                    <GridItem sm={12} className='mt-3'>
                        <ComboBox
                            data={blockStatuses.list}
                            loading={blockStatuses.loading}
                            onFocus={(ev) => {if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click()}}
                            textField='lookupValue'
                            dataItemKey='lookupId'
                            value={itemData.blockStatus}
                            onChange={handleSelectChange}
                            label="Block Status"
                            name='blockStatus'
                            id='blockStatus'
                            required
                        />
                        {errors.blockStatus && <span className='text-danger'>Block Status is Required.</span>}
                    </GridItem>

                    <GridItem sm={12} className='mt-3'>
                        <span className='k-textbox-container'>
                            <label className='k-label'>Instruction Date</label>
                            <DatePicker
                                defaultValue={new Date()}
                                format={"dd/MM/yyyy"}
                                name="instructionDate"
                                id="instructionDate"
                                value={itemData.instructionDate}
                                onChange={handleDateChange}
                                label="Instruction Date"
                                required
                            />
                        </span>
                        {errors.instructionDate && <span className='text-danger'>Instruction Date is Required.</span>}
                    </GridItem>

                    <GridItem sm={12} className='mt-3'>
                        {/*
                        <span className='k-textbox-container'>
                            <label className='k-label'>Comments</label>
                            <textarea
                                className='k-textarea w-100'
                                value={itemData.comments}
                                onChange={handleTextAreaChange}
                                name="comments"
                                id="comments"
                                rows={5}
                            />
                        </span>
                        */}
                    </GridItem>
                   
                </GridContainer>
            </form>
        </Dialog>
    )
}

export default withSnackbar(MatterStageEditor);