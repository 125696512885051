import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { useEventListener } from "../../util/useEventListener";
import { withRouter } from "react-router-dom";
import _ from 'lodash';
import Task from "../task";
import Document from "../document";
import Security from "../securities";
import Account from "../account";
import Party from "../parties";
import ActivityBlock from "../activityBlock";
import Conversation from "../conversation";
import Event from "../event";
import jacmacLogo from "../assets/img/Jacmac without text 74x35.png";
import llamaLogo from "../assets/img/JacMac Llama Logo.png";
import { ProfileContext } from '../store';
import { Button } from '@progress/kendo-react-buttons';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import api from "../../util/api"
import { Badge } from "@material-ui/core";
import MatterEditor from "./MatterEditor";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import LoadingPanel from '../common/Grid/LoadingPanel';
import CardIcon from "../common/MuiKit/Card/CardIcon";
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";
import handleErrors from '../../util/handleErrors';
import { withSnackbar } from 'notistack';
import AttachIcon from "@material-ui/icons/AttachFile";
import ListIcon from "@material-ui/icons/List";
import SecurityIcon from "@material-ui/icons/Security";
import PartyIcon from "@material-ui/icons/Group";
import AccountIcon from "@material-ui/icons/AccountBalance";
import ChatIcon from "@material-ui/icons/Chat";
import TabTitle from "../common/TabStrip/TabTitle";
import { Link } from "react-router-dom";
import { Popup } from "@progress/kendo-react-popup";
import GenericHeader from "../common/Header/GenericHeader"; 

const MatterDetail = (props) => { 
    
    const [ selected, setSelected ] = useState(0);
    const id = props.match.params.id;

    const { dispatch } = useContext(ProfileContext);
    const [ itemInEdit, setItemInEdit ] = useState(null);
    const [ usersLoading, setUsersLoading ] = useState(false);
    const [ users, setUsers ] = useState([]);
    const [ matterInfo, setMatterInfo ] = useState({});
    const loadUsers = (value) => {
        setUsersLoading(true)
        api.get(`/lookup/user/${value}`, {
            accept: 'application/json',
        })
        .then(json => {
            setUsers(json || []);
        })
       .catch(handleErrors(props))
       .finally(() => setUsersLoading(false));
    }

    const [ fileManager, setFileManager ] = useState({});
    const [ showFileManager, setShowFileManager ] = useState(false);
    const [ fileManagerInfo, setFileManagerInfo ] = useState(null);
    const fileManagerRef = useRef(null);
    const updateFileManager = () => {
        updateProperty([{propertyName: 'ManagerId', propertyValue: fileManager.lookupId}]);
        setShowFileManager(false);
    }
    const fileManagerChanged = ({value, ...rest}) => {
        setFileManager({ lookupValue: value });
        setFileManagerInfo(null);
        if (value.length < 3) {
            return
        };
        
        if (!(rest.nativeEvent instanceof InputEvent)){
            const user = _.find(users, o => o.lookupValue === value);
            if (user) {
                setFileManager(user);
                setUsers([]);
                return;
            }
        }

        loadUsers(value);
    }
    const viewFileManagerRef = useRef(null);
    const [ viewFileManager, setViewFileManager ] = useState(false);
    const onViewFileManager = (ev) => {
        stopEvents(ev);
        if (showFileManager) {
            setShowFileManager(false);
        }
        if (!fileManagerInfo) {
            api.get(`/user/contact/${fileManager.lookupId}`)
            .then(json => {
                setFileManagerInfo(json);
            });
        }
        setViewFileManager(!viewFileManager)
    }

    const [ clientContact, setClientContact ] = useState({});
    const [ showClientContact, setShowClientContact ] = useState(false);
    const [ clientContactInfo, setClientContactInfo ] = useState(null);
    const clientContactRef = useRef(null);
    const updateClientContact = () => {
        updateProperty([{propertyName: 'ClientContactId', propertyValue: clientContact.lookupId}]);
        setShowClientContact(false);
    }
    const clientContactChanged = ({value, ...rest}) => {        
        setClientContact({ lookupValue: value });
        setClientContactInfo(null);
        if (value.length < 3) {
            return
        };
        
        if (!(rest.nativeEvent instanceof InputEvent)){
            const user = _.find(users, o => o.lookupValue === value);
            if (user) {
                setClientContact(user);
                setUsers([]);
                return;
            }
        }

        loadUsers(value);
    }
    const viewClientContactRef = useRef(null);
    const [ viewClientContact, setViewClientContact ] = useState(false);
    const onViewClientContact = (ev) => {
        stopEvents(ev);
        if (showClientContact) {
            setShowClientContact(false);
        }
        if (!clientContactInfo) {
            api.get(`/user/contact/${clientContact.lookupId}`)
            .then(json => {
                setClientContactInfo(json);
            });
        }
        setViewClientContact(!viewClientContact)
    }

    const [ sensitivities, setSensitivities ] = useState("");
    const [ showSensitivities, setShowSensitivities ] = useState(false);
    const sensitivitiesRef = useRef(null);
    const updateSensitivities = () => {
        updateProperty([{propertyName: 'Sensitivities', propertyValue: sensitivities}]);
        setShowSensitivities(false)
    }
    const viewSensitivitiesRef = useRef(null);
    const [ viewSensitivities, setViewSensitivities ] = useState(false);
    const onViewSensitivities = (ev) => {
        stopEvents(ev);
        if (showSensitivities) {
            setShowSensitivities(false);
        }
        setViewSensitivities(!viewSensitivities)
    }

    const [ overview, setOverview ] = useState("");
    const [ showOverview, setShowOverview ] = useState(false);
    const overviewRef = useRef(null);
    const updateOverview = () => {
        updateProperty([{propertyName: 'Overview', propertyValue: overview}]);
        setShowOverview(false)
    }
    const viewOverviewRef = useRef(null);
    const [ viewOverview, setViewOverview ] = useState(false);
    const onViewOverview = (ev) => {
        stopEvents(ev);
        if (showOverview) {
            setShowOverview(false);
        }
        setViewOverview(!viewOverview);
    }
    

    const handler = useCallback(
        () => {
            if (showFileManager) setShowFileManager(false);
            if (showClientContact) setShowClientContact(false);
            if (showSensitivities) setShowSensitivities(false);
            if (showOverview) setShowOverview(false);
            if (viewSensitivities) setViewSensitivities(false);
            if (viewOverview) setViewOverview(false);
            if (viewFileManager) setViewFileManager(false);
            if (viewClientContact) setViewClientContact(false);
        },
        [
            showFileManager, 
            setShowFileManager, 
            showClientContact, 
            setShowClientContact, 
            showSensitivities, 
            setShowSensitivities, 
            showOverview, 
            setShowOverview,
            viewSensitivities,
            setViewSensitivities,
            viewOverview,
            setViewOverview,
            viewFileManager,
            setViewFileManager,
            viewClientContact,
            setViewClientContact
        ],
    )
    useEventListener('click', handler, document);
    const stopEvents = (ev) => {
        ev.nativeEvent.stopImmediatePropagation();
        ev.stopPropagation();
    }

    //const [ eventsLoading, setEventsLoading ] = useState(false);
    //const [ events, setEvents ] = useState([]);
    
    //const getEvents = () => {    
    //    setLoading(true)  
    //    api.get(`/event/critical/${id}`, {
    //        accept: 'application/json',
    //    }) 
    //    .then(json => {
    //        setEvents(json || []);
    //    })
    //   .catch(handleErrors(props))
    //    .finally(() => setLoading(false));
    //}

    const axiosConfig = { accept: 'application/json' };

    const [ loading, setLoading ] = useState(false);
    const [ matterNumber, setMatterNumber ] = useState([]);
    const [ myTasks, setMyTasks ] = useState([]);
    const [ theirTasks, setTheirTasks ] = useState([]);
    const [ unattendedTasks, setUnattendedTasks] = useState(0);
    const [ theirUnattendedTasks, setTheirUnattendedTasks] = useState(0);
    const [ notes, setNotes ] = useState([]);
    const [ unattendedNotes, setUnattendedNotes ] = useState(0);

    const loadMyTasks = (property = 'DueDate') => {
        api.get(`/task/mine/${id}/${property}`, axiosConfig).then(d => {
            handleMyTasks(d.data)
        });
    }
    const loadTheirTasks = (property = 'DueDate') => {
        //console.log('loadTheirTasks');
        api.get(`/task/their/${id}/${property}`, axiosConfig).then(d => {
            handleTheirTasks(d.data)
        });
    }

    const handleMyTasks = (data) => {
        data.reverse();
        setMyTasks(data);
        const count = _.filter(data, p => {
            return (p.taskStatus && p.taskStatus.lookupValue !== "Completed") ?? true;
        }).length;
        setUnattendedTasks(count);
    }

    const handleTheirTasks = (data) => {
        data.reverse();
        setTheirTasks(data);
        const count = _.filter(data, p => {
            return (p.taskStatus && p.taskStatus.lookupValue !== "Completed") ?? true;
        }).length;
        setTheirUnattendedTasks(count);
    }

    const loadNotes = (direction = 'descending') => {
        api.get(`/conversation/${id}/paged/1/10?sort[0].direction=${direction}&sort[0].property=createdon`, axiosConfig).then(d => {
            handleNotes(d.data)
        });
    }
    const handleNotes = (data) => {
        setNotes(data);
        const count = _.filter(data, p => {
            //return ((p.responseRequired && !p.responseRecieved) || (!p.responseRequired && !p.acknowledged) && localStorage.getItem('Llama.username') === (p.user ? p.user.identifier : '')) ?? true;
            return (p.responseRequired && !p.responseRecieved) || (!p.responseRequired && !p.acknowledged) ? false : true;
        }).length;
        setUnattendedNotes(count);
    }
    useEffect(() => {
        setLoading(true);    
        //getEvents(); 
        let onLoadTasks = [
            api.get(`/matter/${id}`, axiosConfig),
            api.post(`/matter/isviewed/${id}`, axiosConfig),
            api.get(`/task/mine/${id}`, axiosConfig),
            api.get(`/task/their/${id}`, axiosConfig),
            api.get(`/conversation/${id}/paged/1/10?sort[0].direction=descending&sort[0].property=createdon`, axiosConfig)
        ];

        Promise.all(onLoadTasks)
            .then(responses => {
                let json = responses[0];
                setSensitivities(json.sensitivities || "");
                setOverview(json.overview || "");
                setClientContact(json.clientContact ?? {});
                setFileManager(json.manager ?? {});
                setMatterNumber(json.matterNumber);

                handleMyTasks(responses[2].data);
                handleTheirTasks(responses[3].data);
                handleNotes(responses[4].data);
                setMatterInfo(json);
                dispatch({
                    type: 'setTitle',
                    payload: `<b>${json.matterName}</b> | <i>JM Ref:</i> <b>${json.matterNumber}</b> | <i>Client Ref:</i> <b>${json.clientReference}</b> | <i>Status:</i> <b>${json.matterStatus ? json.matterStatus.lookupValue : ''}</b>`
                });

                setLoading(false);
            })
            .catch(handleErrors(props))
    }, []);

    const partPageProps = {
        //cardHeight: 'calc(100vh - 475px)',
        //gridHeight: 'calc(100vh - 580px)',
        pagerSettings: props.pagerSettings
    };
    const tabWrapperStyle = {
        marginTop: '24px',
        position: 'relative'
    }
    const handleSelect = (e) => {
        setSelected(e.selected);
    }

    const updateProperty = (json) => {
      
        api.request({
            url: `/matter/${id}`,
            method: 'PATCH',
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(json)
        }) 
        .then(json => {
            
        })
        .catch(handleErrors(props));
    }

    const cancel = () => {
        setItemInEdit(null);
        setLoading(false);
    }

    const save = (dataItem) => {
        console.log('save: ', dataItem);
        setLoading(true);   
        
        api.request({
            url: '/matter',
            method: dataItem.matterId === 0 ? 'POST' : 'PUT', 
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(dataItem)
        }) 
        .then(json => {
            setItemInEdit(null);
            setSensitivities(json.sensitivities || "");
            setOverview(json.overview || "");
            setClientContact(json.clientContact ?? {});
            setFileManager(json.manager ?? {});
            setMatterNumber(json.matterNumber);
            setMatterInfo(json);
            dispatch({
                type: 'setTitle',
                payload: `<b>${json.matterName}</b> | <i>JM Ref:</i> <b>${json.matterNumber}</b> | <i>Client Ref:</i> <b>${json.clientReference}</b> | <i>Status:</i> <b>${json.matterStatus ? json.matterStatus.lookupValue : ''}</b>`
            });
            setLoading(false);
        })
        .catch(handleErrors(props))
    }

    const myTasksRef = useRef();
    const theirTasksRef = useRef();
    const notesRef = useRef();
    const documentsRef = useRef();
    const eventRef = useRef();

    //console.log('Llama.role: ', localStorage.getItem('Llama.role'))

    return (
        <React.Fragment>
            <GenericHeader onClick={(ev) => { 
                        stopEvents(ev);
                        setItemInEdit(matterInfo);
                    }} />
            <GridContainer style={{marginTop: '15px'}}>
                <GridItem md={12} style={{position:'relative'}}>
                    <GridContainer justify='flex-start'>
                        <GridItem className='d-flex' xs={12} md={3}>
                            <div className='d-none d-lg-inline-flex' style={{
                                display: 'inline-flex',
                                borderRight: '1px solid #ccc',
                                padding: '12px 10px 5px',
                                
                            }}>
                                <img alt='' src={llamaLogo} className='img-fluid' style={{height: '35px'}} />
                            </div>
                            <div style={{
                                paddingLeft: '10px'
                            }}>                                
                                <div>
                                    <label>Instructing Officer</label>
                                    {/* {requiresRole(<button
                                        title='Edit'
                                        className='k-button k-bare k-primary k-button-icon ml-2 mb-1'
                                        onClick={(ev) => {
                                            stopEvents(ev);
                                            if (viewClientContact) {
                                                setViewClientContact(false);
                                            }
                                            setShowClientContact(!showClientContact);  
                                        }}
                                        ref={clientContactRef}
                                    >
                                        <span className="k-icon k-i-pencil"></span>
                                    </button>, policies.edit)} */}
                                </div>
                                <p style={{whiteSpace:'pre'}} ref={viewClientContactRef} onClick={onViewClientContact}>{clientContact.lookupValue}</p>
                                <Popup
                                    anchor={viewClientContactRef.current}
                                    show={viewClientContact}
                                    popupClass={'popup-content'}
                                >
                                    {clientContactInfo ?
                                    <dl className='row' style={{ width: '450px', lineHeight: '18px', padding: '8px 10px 0px 10px' }} onClick={stopEvents}>
                                        
                                        {clientContactInfo.firstName && <><dt className='col-sm-3'>First Name</dt>
                                        <dd className='col-sm-9'>{clientContactInfo.firstName}</dd></>}

                                        {clientContactInfo.lastName && <><dt className='col-sm-3'>Last Name</dt>
                                        <dd className='col-sm-9'>{clientContactInfo.lastName}</dd></>}

                                        {clientContactInfo.title && <><dt className='col-sm-3'>Title</dt>
                                        <dd className='col-sm-9'>{clientContactInfo.title}</dd></>}

                                        {clientContactInfo.email && <><dt className='col-sm-3'>Email</dt>
                                        <dd className='col-sm-9'>{clientContactInfo.email}</dd></>}

                                        {clientContactInfo.phoneNumber && <><dt className='col-sm-3'>Phone</dt>
                                        <dd className='col-sm-9'>{clientContactInfo.phoneNumber}</dd></>}

                                        {clientContactInfo.address && <><dt className='col-sm-3'>Address</dt>
                                        <dd className='col-sm-9'>{clientContactInfo.address}</dd></>}
                                    </dl> : <div style={{minHeight: '100px'}}><LoadingPanel /></div> }
                                </Popup>
                                <Popup
                                    anchor={clientContactRef.current}
                                    show={showClientContact}
                                    popupClass={'popup-content p-0'}
                                >
                                    <div className='p-1 d-flex flex-column' onClick={stopEvents}>                                        
                                        <AutoComplete
                                            loading={usersLoading}
                                            data={users}
                                            value={clientContact.lookupValue}
                                            onChange={clientContactChanged}
                                            textField='lookupValue'
                                            style={{width:'100%', borderWidth: '1px'}}
                                        />
                                        
                                        <button
                                            title='OK'
                                            className='k-button k-primary k-button-icon mt-1 d-flex align-self-end'
                                            onClick={() => { updateClientContact() }}
                                        >
                                            <span className="k-icon k-i-check"></span>
                                        </button>
                                    </div>                                    
                                </Popup>
                            </div>
                        </GridItem>
                        <GridItem className='d-flex' xs={12} md={3}>
                            <div style={{
                                display: 'inline-flex',
                                borderRight: '1px solid #ccc',
                                padding: '12px 10px 5px'
                            }}>
                                <img alt='' src={jacmacLogo} className='img-fluid' style={{height: '35px'}} />
                            </div>
                            <div style={{
                                paddingLeft: '10px'
                            }}>                                
                                <div>
                                    <label>File Manager</label>
                                    {/* {requiresRole(<button
                                        title='Edit'
                                        className='k-button k-bare k-primary k-button-icon ml-2 mb-1'
                                        onClick={(ev) => { 
                                            stopEvents(ev);
                                            if (viewFileManager) {
                                                setViewFileManager(false);
                                            }
                                            setShowFileManager(!showFileManager);
                                        }}
                                        ref={fileManagerRef}
                                    >
                                        <span className="k-icon k-i-pencil"></span>
                                    </button>, policies.edit)} */}
                                </div>
                                <p style={{whiteSpace:'pre'}} ref={viewFileManagerRef} onClick={onViewFileManager}>{fileManager.lookupValue}</p>
                                <Popup
                                    anchor={viewFileManagerRef.current}
                                    show={viewFileManager}
                                    popupClass={'popup-content'}
                                >
                                    {fileManagerInfo ?
                                    <dl className='row' style={{ width: '450px', lineHeight: '18px', padding: '8px 10px 0px 10px' }} onClick={stopEvents}>
                                        
                                        {fileManagerInfo.firstName && <><dt className='col-sm-3'>First Name</dt>
                                        <dd className='col-sm-9'>{fileManagerInfo.firstName}</dd></>}

                                        {fileManagerInfo.lastName && <><dt className='col-sm-3'>Last Name</dt>
                                        <dd className='col-sm-9'>{fileManagerInfo.lastName}</dd></>}

                                        {fileManagerInfo.title && <><dt className='col-sm-3'>Title</dt>
                                        <dd className='col-sm-9'>{fileManagerInfo.title}</dd></>}

                                        {fileManagerInfo.email && <><dt className='col-sm-3'>Email</dt>
                                        <dd className='col-sm-9'>{fileManagerInfo.email}</dd></>}

                                        {fileManagerInfo.phoneNumber && <><dt className='col-sm-3'>Phone</dt>
                                        <dd className='col-sm-9'>{fileManagerInfo.phoneNumber}</dd></>}

                                        {fileManagerInfo.address && <><dt className='col-sm-3'>Address</dt>
                                        <dd className='col-sm-9'>{fileManagerInfo.address}</dd></>}
                                    </dl> : <div style={{minHeight: '100px'}}><LoadingPanel /></div> }
                                </Popup>
                                <Popup
                                    anchor={fileManagerRef.current}
                                    show={showFileManager}
                                    popupClass={'popup-content'}
                                >
                                    <div className='p-1 d-flex flex-column' onClick={stopEvents}>
                                        
                                        <AutoComplete
                                            loading={usersLoading}
                                            data={users}
                                            value={fileManager.lookupValue}
                                            onChange={fileManagerChanged}
                                            textField='lookupValue'
                                            style={{width:'100%', borderWidth: '1px'}}
                                        />
                                        
                                        <button
                                            title='OK'
                                            className='k-button k-primary k-button-icon mt-1 d-flex align-self-end'
                                            onClick={() => { updateFileManager() }}
                                        >
                                            <span className="k-icon k-i-check"></span>
                                        </button>
                                    </div>
                                    
                                </Popup>
                            </div>                    
                        </GridItem>
                        <GridItem className='d-flex flex-column' xs={12} md={3}>
                            <div>
                                <label>Sensitivities</label>
                                {/* {requiresRole(<button
                                    title='Edit'
                                    className='k-button k-bare k-primary k-button-icon ml-2 mb-1'
                                    onClick={(ev) => { 
                                        stopEvents(ev);
                                        if (viewSensitivities) {
                                            setViewSensitivities(false);
                                        }
                                        setShowSensitivities(!showSensitivities)  
                                    }}
                                    ref={sensitivitiesRef}
                                >
                                    <span className="k-icon k-i-pencil"></span>
                                </button>, policies.edit)} */}
                            </div>
                            <p style={{ whiteSpace: 'break-spaces', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '95%', color: 'red' }} ref={viewSensitivitiesRef} onClick={onViewSensitivities}>{sensitivities}</p>
                            <Popup
                                anchor={viewSensitivitiesRef.current}
                                show={viewSensitivities}
                                popupClass={'popup-content'}
                            >
                                <p style={{ maxWidth: '300px', lineHeight: '18px', padding: '8px 10px 0px 10px', color:'red' }} onClick={stopEvents}>{sensitivities}</p>
                            </Popup>
                            <Popup
                                anchor={sensitivitiesRef.current}
                                show={showSensitivities}
                                popupClass={'popup-content'}
                            >
                                <div className='p-1 d-flex flex-column' onClick={stopEvents}>
                                    <textarea
                                        className='k-textarea w-100'
                                        value={sensitivities}
                                        onChange={({ target: { value } }) => setSensitivities(value)}
                                        name="sensitivities"
                                        id="sensitivities"
                                        rows={5}
                                    />
                                    <button
                                        title='OK'
                                        className='k-button k-primary k-button-icon mt-1 d-flex align-self-end'
                                        onClick={() => { updateSensitivities() }}
                                    >
                                        <span className="k-icon k-i-check"></span>
                                    </button>
                                </div>
                                
                            </Popup>
                        </GridItem>
                        <GridItem className='d-flex flex-column' xs={12} md={3}>
                            <div>
                                <label>Matter Overview</label>
                                {/* {requiresRole(<button
                                    title='Edit'
                                    className='k-button k-bare k-primary k-button-icon ml-2 mb-1'
                                    onClick={(ev) => { 
                                        stopEvents(ev);
                                        if (viewOverview) {
                                            setViewOverview(false);
                                        }
                                        setShowOverview(!showOverview)  
                                    }}
                                    ref={overviewRef}
                                >
                                    <span className="k-icon k-i-pencil"></span>
                                </button>, policies.edit)}  */}
                            </div>
                            <p style={{ whiteSpace: 'break-spaces', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '95%' }} ref={viewOverviewRef} onClick={onViewOverview}>{overview}</p>
                            <Popup
                                anchor={viewOverviewRef.current}
                                show={viewOverview}
                                popupClass={'popup-content'}
                            >
                                <p style={{ maxWidth: '300px', lineHeight: '18px', padding: '8px 10px 0px 10px' }} onClick={stopEvents}>{overview}</p>
                            </Popup>
                            <Popup
                                anchor={overviewRef.current}
                                show={showOverview}
                                popupClass={'popup-content'}
                            >
                                <div className='p-1 d-flex flex-column' onClick={stopEvents}>
                                    <textarea
                                        className='k-textarea w-100'
                                        value={overview}
                                        onChange={({ target: { value } }) => setOverview(value)}
                                        name="overview"
                                        id="overview"
                                        rows={5}
                                    />
                                    <button
                                        title='OK'
                                        className='k-button k-primary k-button-icon mt-1 d-flex align-self-end'
                                        onClick={() => { updateOverview() }}
                                    >
                                        <span className="k-icon k-i-check"></span>
                                    </button>
                                </div>
                                
                            </Popup>
                        </GridItem>
                    </GridContainer>
                    {loading && <LoadingPanel/>}
                </GridItem>
                
                {/*Accounts/Parties/Securities*/}
                <GridItem md={12} style={{marginTop: '10px'}}>
                    <GridContainer>
                        <GridItem md={12}>
                            {/*   Accounts / Parties / Securities   */}
                                    <TabStrip selected={selected} onSelect={handleSelect}>
                                        <TabStripTab title={<TabTitle content={`Accounts (${matterInfo && matterInfo.accountsCount ? matterInfo.accountsCount : 0})`} icon={<AccountIcon />} />}>
                                            <div style={tabWrapperStyle}>
                                                <Account {...partPageProps} gridHeight='14rem' />
                                            </div>
                                        </TabStripTab>
                                        <TabStripTab title={<TabTitle content={`Parties (${matterInfo && matterInfo.partiesCount ? matterInfo.partiesCount : 0})`} icon={<PartyIcon />} />}>
                                            <div style={tabWrapperStyle}>
                                                <Party {...partPageProps} gridHeight='14rem' />
                                            </div>
                                        </TabStripTab>
                                        <TabStripTab title={<TabTitle content={`Securities (${matterInfo && matterInfo.securitiesCount ? matterInfo.securitiesCount : 0})`} icon={<SecurityIcon />} />}>
                                            <div style={tabWrapperStyle}>
                                                <Security {...partPageProps} gridHeight='14rem' />
                                            </div>
                                        </TabStripTab>
                                    </TabStrip>
                        </GridItem>

                        {/*Task / Notes / Documents*/}
                        <GridItem md={12}>
                            <span style={{display: 'block', marginTop: '18px'}}></span>
                            <Card>
                                <GridContainer>
                                    <GridItem lg={12} xl={3}>
                                        {/*Task*/}
                                        <CardHeader className='pr-xl-0'>
                                            <CardIcon color='rose' style={{ marginTop: '-30px', position: 'relative' }}>
                                                {/* (unattendedTasks + theirUnattendedTasks)> 0 && <Badge badgeContent={(unattendedTasks + theirUnattendedTasks)} color="primary" style={{position: 'absolute', right: '2px', top: '2px'}}></Badge> */}  
                                                <ListIcon style={{color:'white'}} />
                                            </CardIcon>
                                            <h4 className='jm-card-title' style={{color: '#777'}}>Tasks</h4>

                                            <Button
                                                primary={true}
                                                className='rounded-circle ml-3 mb-1'
                                                icon='plus'
                                                title='Add New'
                                                onClick={() => myTasksRef.current.insert()}
                                            />

                                            <span style={{ float: 'right', marginTop:'5px' }}>
                                                <Link to={`/tasks/${id}`}>View All</Link>
                                            </span>
                                        </CardHeader>
                                        <CardBody className='pr-xl-0' style={{ height: '400px'}}>
                                            <div style={{ marginTop: '9px', height: '100%' }}>
                                                <div style={{height: 'calc(50%)'}} >     
                                                    {localStorage.getItem('Llama.role') === 'client' 
                                                    ? <Task summary type='their' ref={theirTasksRef} tasks={theirTasks} tasksLoading={loading} load={loadTheirTasks} />
                                                    : <Task summary type='mine' ref={myTasksRef} tasks={myTasks} tasksLoading={loading} load={loadMyTasks} />}                                            
                                                </div>
                                                <div style={{height: 'calc(50%)', marginTop: '0px'}}>
                                                    {localStorage.getItem('Llama.role') === 'client' 
                                                    ? <Task summary type='mine' ref={myTasksRef} tasks={myTasks} tasksLoading={loading} load={loadMyTasks} />
                                                    : <Task summary type='their' ref={theirTasksRef} tasks={theirTasks} tasksLoading={loading} load={loadTheirTasks} />}
                                                    
                                                </div> 
                                            </div>                                                                                       
                                        </CardBody>
                                    </GridItem>

                                    <GridItem lg={12} xl={6}>
                                        {/*Notes*/}
                                        <CardHeader className='px-xl-0 mt-4 mt-xl-0'>
                                            <CardIcon color='info' style={{ marginTop: '-30px', position: 'relative' }}>
                                                {/* unattendedNotes > 0 && <Badge badgeContent={unattendedNotes} color="primary" style={{position: 'absolute', right: '2px', top: '2px'}}></Badge> */} 
                                                <ChatIcon style={{color:'white'}} />
                                            </CardIcon>
                                            <h4 className='jm-card-title' style={{color: '#777'}}>Notes</h4>

                                            <Button
                                                primary={true}
                                                className='rounded-circle ml-3 mb-1'
                                                icon='plus'
                                                title='Add New'
                                                onClick={() => notesRef.current.insert()}
                                            />

                                            <span style={{ float: 'right', marginTop: '5px' }}>
                                                <Link to={`/notes/${id}`}>View All</Link>
                                            </span>         
                                        </CardHeader>
                                        <CardBody className='px-xl-0' style={{ height: '400px'}}>
                                            <div style={{marginTop: '9px', height: 'calc(100%)'}}>
                                                <Conversation summary ref={notesRef} notes={notes} notesLoading={loading} load={loadNotes} />
                                            </div>
                                        </CardBody>
                                    </GridItem>
                                    <GridItem lg={12} xl={3}>
                                        {/*Documents*/}
                                        <CardHeader className='pl-xl-0 mt-4 mt-xl-0'>
                                            <CardIcon color='success' style={{marginTop: '-30px'}}>
                                                <AttachIcon style={{color:'white'}} />
                                            </CardIcon>
                                            <h4 className='jm-card-title' style={{color: '#777'}}>Documents</h4>

                                            <Button
                                                primary={true}
                                                className='rounded-circle ml-3 mb-1'
                                                icon='plus'
                                                title='Add New'
                                                onClick={() => documentsRef.current.insert()}
                                            />

                                            <span style={{ float: 'right', marginTop:'5px' }}>
                                                <Link to={`/documents/${id}`}>View All</Link>
                                            </span>

                                        </CardHeader>
                                        <CardBody className='pl-xl-0' style={{ height: '400px'}}>
                                            <div style={{marginTop: '9px', height: '100%'}}>
                                                <Document summary ref={documentsRef} />
                                            </div>
                                        </CardBody>
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>                        
                    </GridContainer>                    
                </GridItem> 

                {/*Block Section*/}
                <GridItem md={12} style={{marginTop: '20px'}}>
                    <GridContainer>
                        <GridItem lg={12} xl={9}>
                            <span style={{display: 'block', marginTop: '18px'}}></span>
                            {/* <Block {...partPageProps} ref={blockRef} matterNumber={matterNumber} matter={matterInfo} /> */}
                            {/* <BlockPanel {...partPageProps} ref={blockRef} matterNumber={matterNumber} matter={matterInfo} /> */}
                            
                            <ActivityBlock {...partPageProps} fromMatter={true} />
                        </GridItem>
                        <GridItem lg={12} xl={3}>
                            <span style={{display: 'block', marginTop: '18px'}}></span>
                            <Event cards critical ref={eventRef} />
                        </GridItem>
                    </GridContainer>
                    
                </GridItem>

            </GridContainer>        
            {itemInEdit && <MatterEditor dataItem={itemInEdit} save={save} cancel={cancel} />}
        </React.Fragment>
    );
}

export default withSnackbar(withRouter(MatterDetail));