import React, { useState, useEffect } from 'react';
import { findDOMNode } from "react-dom";

import { Button } from '@progress/kendo-react-buttons';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { formatNumber } from '@telerik/kendo-intl';

import Dialog from "../common/Dialog";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";

import { withSnackbar } from 'notistack';
import moment from "moment";

import api from "../../util/api"
import handleErrors from '../../util/handleErrors';
import { editableField } from "../../util/editableField";
import { requiresRole, policies } from '../../util/accessControl';

import RelatedAccountsEditor from "./RelatedAccountsEditor";
import RelatedSecuritiesEditor from "./RelatedSecuritiesEditor";

import AddressFormatter from "../../util/formatAddress";
const MIPSaleEditor = (props) => {
    const originalItem = {
        ...props.dataItem,
        contractDate: (props.dataItem.contractDate == null || props.dataItem.contractDate === "") ? null : moment(props.dataItem.contractDate).toDate(),
        financeDue: (props.dataItem.financeDue == null || props.dataItem.financeDue === "") ? null : moment(props.dataItem.financeDue).toDate(),
        depositReceivedDate: (props.dataItem.depositReceivedDate == null || props.dataItem.depositReceivedDate === "") ? null : moment(props.dataItem.depositReceivedDate).toDate(),
        anticipatedSettlement: (props.dataItem.anticipatedSettlement == null || props.dataItem.anticipatedSettlement === "") ? null : moment(props.dataItem.anticipatedSettlement).toDate(),
        settledDate: (props.dataItem.settledDate == null || props.dataItem.settledDate === "") ? null : moment(props.dataItem.settledDate).toDate(),
        settlementBooked: (props.dataItem.settlementBooked == null || props.dataItem.settlementBooked === "") ? null : moment(props.dataItem.settlementBooked).toDate(),
    };
    const [itemData, setItemData] = useState(originalItem);
    const [originalItemData, setOriginalItemData] = useState(originalItem);
    const [editMode, setEditMode] = useState(!!props.editMode);
    const [errors, setErrors] = useState({});
    const initLookupObject = { loading: false, list: [] };
    const [possessionEffecteds, setPossessionEffecteds] = useState(initLookupObject);
    const [varianceTypes, setVarianceTypes] = useState(initLookupObject);

    const editStageQuery = `/stage/posession/${itemData.stageId}`;
    const newStageQuery = `/stage/block/${itemData.stage.blockId}`;

    const handleSelectChange = ({ value, target: { name } }) => {
        const idField = `${name}Id`;
        setItemData({ ...itemData, [name]: value, [idField]: value ? value.lookupId : null });
    }

    const handleDateChange = ({ value, target: { name } }) => {
        setItemData({ ...itemData, [name]: value == null ? null : moment(value).local().toDate() });
    }

    const handleInputChange = ({ value, target: { name } }) => {
        setItemData({ ...itemData, [name]: value });
    }

    const handleCheckSelected = (element) => {
        let isChecked = !itemData[element];
        setItemData({ ...itemData, [element]: isChecked });
    }

    const updateRelatedEditorsData = (updatedData) => {
        setItemData(updatedData);
    }
    const validateForm = (ev) => {
        var validForm = true;
        var formErrors = {};

        let security = itemData.stage.matterSecurities.find(v => v.noticeSelected === true);
        if (!security) {
            props.enqueueSnackbar('Related Security Properties required.', { variant: 'error' });
            return false;
        }

        formErrors.possessionEffected = (!itemData.possessionEffected || itemData.possessionEffected === '');
        formErrors.propertyPresenter = (!itemData.propertyPresenter || itemData.propertyPresenter === '');
        formErrors.agent = (!itemData.agent || itemData.agent === '');
        formErrors.contractDate = (!itemData.contractDate || itemData.contractDate === '');
        formErrors.purchasePrice = (!itemData.purchasePrice || itemData.purchasePrice === '');
        formErrors.depositAmount = (!itemData.depositAmount || itemData.depositAmount === '');
        formErrors.financeDue = (!itemData.financeDue || itemData.financeDue === '');
        formErrors.depositReceivedDate = (!itemData.depositReceivedDate || itemData.depositReceivedDate === '');
        formErrors.anticipatedSettlement = (!itemData.anticipatedSettlement || itemData.anticipatedSettlement === '');
        formErrors.settlementBooked = (!itemData.settlementBooked || itemData.settlementBooked === '');
        formErrors.settlementBooked = (!itemData.settlementBooked || itemData.settlementBooked === '');
        formErrors.settledDate = (!itemData.settledDate || itemData.settledDate === '');
        formErrors.payoutFigure = (!itemData.payoutFigure || itemData.payoutFigure === '');

        setErrors(formErrors);

        const item = Object.assign({}, formErrors)
        validForm = !Object.keys(item).some(x => item[x]);

        return validForm;
    }

    const setLookup = (setFunc) => (json) => {
        setFunc({ loading: false, list: json });
        return json;
    }

    useEffect(() => {
        const axiosConfig = { accept: 'application/json' };
        const loading = { loading: true, list: [] };
        setPossessionEffecteds(loading);
        setVarianceTypes(loading);

        let onLoadTasks = [
            api.get((itemData.stageId > 0) ? editStageQuery : newStageQuery, axiosConfig),
            api.get(`/lookup/possessioneffected`, axiosConfig).then(setLookup(setPossessionEffecteds)),
            api.get(`/lookup/variancetype`, axiosConfig).then(setLookup(setVarianceTypes)),
        ];

        Promise.all(onLoadTasks)
            .then(responses => {
                let tempData = { ...itemData };
                let stage = responses[0];
                let possessionEffecteds = responses[1]
                let varianceTypes = responses[2]

                if (tempData.stageId == 0) {
                    tempData = {
                        ...itemData,
                        possessionEffected: possessionEffecteds[0],
                        possessionEffectedId: possessionEffecteds[0].lookupId,
                        varianceType: varianceTypes[0],
                        varianceTypeId: varianceTypes[0].lookupId,
                        stage: stage,
                    };
                } else {
                    tempData = {
                        ...stage,
                        contractDate: (stage.contractDate == null || stage.contractDate === "") ? null : moment(stage.contractDate).toDate(),
                        financeDue: (stage.financeDue == null || stage.financeDue === "") ? null : moment(stage.financeDue).toDate(),
                        depositReceivedDate: (stage.depositReceivedDate == null || stage.depositReceivedDate === "") ? null : moment(stage.depositReceivedDate).toDate(),
                        anticipatedSettlement: (stage.anticipatedSettlement == null || stage.anticipatedSettlement === "") ? null : moment(stage.anticipatedSettlement).toDate(),
                        settledDate: (stage.settledDate == null || stage.settledDate === "") ? null : moment(stage.settledDate).toDate(),
                        settlementBooked: (stage.settlementBooked == null || stage.settlementBooked === "") ? null : moment(stage.settlementBooked).toDate(),
                    };
                }
                setItemData(tempData);
                setOriginalItemData(tempData);
            })
            .catch(handleErrors(props))
    }, []);

    return (
        <Dialog
            validate={validateForm}
            original={originalItemData}
            current={itemData}
            saveChanges={props.save}
            cancelChanges={props.cancel}
            width={1200}
            createMode={itemData.stageId === 0}
            title={
                <>
                    {`${itemData.accountId === 0 ? 'Add' : editMode ? 'Edit' : 'View'} MIP Sale`}
                    {requiresRole(<Button className='rounded-circle ml-2' primary={true} look="bare" icon={editMode ? 'lock' : 'edit'} onClick={(ev) => setEditMode(!editMode)} />, policies.edit)}
                </>}
        >
            <form onSubmit={(ev) => ev.preventDefault()}>
                <GridContainer>

                    {editMode ? (
                        <>
                            {itemData.stage.matterSecurities && <RelatedSecuritiesEditor stageData={itemData} updateRelatedSecurities={updateRelatedEditorsData} editMode={editMode} />}
                            <GridItem sm={4}>
                                <ComboBox
                                    data={possessionEffecteds.list}
                                    loading={possessionEffecteds.loading}
                                    onFocus={(ev) => {if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click()}}
                                    textField='lookupValue'
                                    dataItemKey='lookupId'
                                    value={itemData.possessionEffected}
                                    onChange={handleSelectChange}
                                    label="Possession effected"
                                    name='possessionEffected'
                                    id='possessionEffected'
                                    required={true}
                                />
                                {errors.possessionEffected && <span className='text-danger'>Possession effected is Required.</span>}
                            </GridItem>
                            <GridItem sm={4}>
                                <Input
                                    width='100%'
                                    label='Property presenter'
                                    value={itemData.propertyPresenter}
                                    onChange={handleInputChange}
                                    name="propertyPresenter"
                                    id="propertyPresenter"
                                    required={true}
                                />
                                {errors.propertyPresenter && <span className='text-danger'>Property presenter is Required.</span>}
                            </GridItem>
                            <GridItem sm={4} >
                                <Input
                                    width='100%'
                                    label='Agent'
                                    value={itemData.agent}
                                    onChange={handleInputChange}
                                    name="agent"
                                    id="agent"
                                    required={true}
                                />
                                {errors.agent && <span className='text-danger'>Agent is Required.</span>}
                            </GridItem>
                            <GridItem sm={4} className="mt-3 pt-3" >
                                <input type="checkbox" className='k-checkbox' checked={!!itemData.privateTreaty} onChange={() => handleCheckSelected('privateTreaty')} name="privateTreaty" id="privateTreaty" />
                                <label className="k-checkbox-label" htmlFor="privateTreaty">Private treaty?</label>
                            </GridItem>
                            <GridItem sm={4} className="mt-3" >
                                <span className='k-textbox-container'>
                                    <label className='k-label' htmlFor='contractDate'>Contract date</label>
                                    <DatePicker
                                        defaultValue={new Date()}
                                        format="dd/MM/yyyy"
                                        name='contractDate'
                                        id='contractDate'
                                        onChange={handleDateChange}
                                        value={itemData.contractDate}
                                        label=""
                                        required={true}
                                    />
                                </span>
                                {errors.agent && <span className='text-danger'>Agent is Required.</span>}
                            </GridItem>
                            <GridItem sm={4} className="mt-3" >
                                <NumericTextBox
                                    width='100%'
                                    label='Purchase price'
                                    value={itemData.purchasePrice}
                                    onChange={handleInputChange}
                                    format="c2"
                                    spinners={false}
                                    name="purchasePrice"
                                    id="purchasePrice"
                                    required={true}
                                />
                                {errors.purchasePrice && <span className='text-danger'>Purchase price is Required.</span>}
                            </GridItem>
                            <GridItem sm={4} className="mt-3">
                                <NumericTextBox
                                    width='100%'
                                    label='Deposit amount'
                                    value={itemData.depositAmount}
                                    onChange={handleInputChange}
                                    format="c2"
                                    spinners={false}
                                    name="depositAmount"
                                    id="depositAmount"
                                    required={true}
                                />
                                {errors.depositAmount && <span className='text-danger'>Deposit amount is Required.</span>}
                            </GridItem>
                            <GridItem sm={4} className="mt-3">
                                <span className='k-textbox-container'>
                                    <label className='k-label' htmlFor='financeDue'>Finance due</label>
                                    <DatePicker
                                        defaultValue={new Date()}
                                        format="dd/MM/yyyy"
                                        name='financeDue'
                                        id='financeDue'
                                        onChange={handleDateChange}
                                        value={itemData.financeDue}
                                        label=""
                                        required={true}
                                    />
                                </span>
                                {errors.financeDue && <span className='text-danger'>Finance due is Required.</span>}
                            </GridItem>
                            <GridItem sm={4} className="mt-3 pt-3">
                                <input type="checkbox" className='k-checkbox' checked={!!itemData.contractUnconditional} onChange={() => handleCheckSelected('contractUnconditional')} name="contractUnconditional" id="contractUnconditional" />
                                <label className="k-checkbox-label" htmlFor="contractUnconditional">Contract unconditional</label>
                            </GridItem>
                            <GridItem sm={4} className="mt-3">
                                <span className='k-textbox-container'>
                                    <label className='k-label' htmlFor='depositReceivedDate'>Deposit received</label>
                                    <DatePicker
                                        defaultValue={new Date()}
                                        format="dd/MM/yyyy"
                                        name='depositReceivedDate'
                                        id='depositReceivedDate'
                                        onChange={handleDateChange}
                                        value={itemData.depositReceivedDate}
                                        label=""
                                        required={true}
                                    />
                                </span>
                                {errors.depositReceivedDate && <span className='text-danger'>Deposit received is Required.</span>}
                            </GridItem>
                            <GridItem sm={4} className="mt-3">
                                <span className='k-textbox-container'>
                                    <label className='k-label' htmlFor='anticipatedSettlement'>Anticipated settlement date</label>
                                    <DatePicker
                                        defaultValue={new Date()}
                                        format="dd/MM/yyyy"
                                        name='anticipatedSettlement'
                                        id='anticipatedSettlement'
                                        onChange={handleDateChange}
                                        value={itemData.anticipatedSettlement}
                                        label=""
                                        required={true}
                                    />
                                </span>
                                {errors.anticipatedSettlement && <span className='text-danger'>Anticipated settlement date is Required.</span>}
                            </GridItem>
                            <GridItem sm={4} className="mt-3">
                                <span className='k-textbox-container'>
                                    <label className='k-label' htmlFor='settlementBooked'>Settlement booked</label>
                                    <DatePicker
                                        defaultValue={new Date()}
                                        format="dd/MM/yyyy"
                                        name='settlementBooked'
                                        id='settlementBooked'
                                        onChange={handleDateChange}
                                        value={itemData.settlementBooked}
                                        label=""
                                        required={true}
                                    />
                                </span>
                                {errors.settlementBooked && <span className='text-danger'>Settlement booked is Required.</span>}
                            </GridItem>
                            <GridItem sm={4} className="mt-3">
                                <span className='k-textbox-container'>
                                    <label className='k-label' htmlFor='settledDate'>Date settled</label>
                                    <DatePicker
                                        defaultValue={new Date()}
                                        format="dd/MM/yyyy"
                                        name='settledDate'
                                        id='settledDate'
                                        onChange={handleDateChange}
                                        value={itemData.settledDate}
                                        label=""
                                        required={true}
                                    />
                                </span>
                                {errors.settledDate && <span className='text-danger'>Date settled is Required.</span>}
                            </GridItem>
                            <GridItem sm={4} className="mt-3">
                                <NumericTextBox
                                    width='100%'
                                    label='Payout figure'
                                    value={itemData.payoutFigure}
                                    onChange={handleInputChange}
                                    format="c2"
                                    spinners={false}
                                    name="payoutFigure"
                                    id="payoutFigure"
                                    required={true}
                                />
                                {errors.payoutFigure && <span className='text-danger'>Payout figure is Required.</span>}
                            </GridItem>
                            <GridItem sm={4} className="mt-3 pt-3">
                                <input type="checkbox" className='k-checkbox' checked={!!itemData.nODRequired} onChange={() => handleCheckSelected('nODRequired')} name="nODRequired" id="nODRequired" />
                                <label className="k-checkbox-label" htmlFor="nODRequired">NOD required</label>
                            </GridItem>

                        </>
                    ) : (
                        <>
                            <GridItem sm={12} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Related Security Properties:</label>
                                    <div className="font-size-14">{itemData.stage.matterSecurities && itemData.stage.matterSecurities.length > 0 ? itemData.stage.matterSecurities.filter(val => !!val.noticeSelected).map((val, ind, arr) => AddressFormatter.format(val).address + ((ind + 1) < arr.length ? ', ' : '')) : ''}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Possession effected:</label>
                                    <div className="font-size-14">{itemData.possessionEffected ? itemData.possessionEffected.lookupValue : ''}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Property presenter:</label>
                                    <div className="font-size-14">{itemData.propertyPresenter}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Agent:</label>
                                    <div className="font-size-14">{itemData.agent}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Private treaty:</label>
                                    <div className="font-size-14">{!!itemData.privateTreaty ? 'Yes' : 'No'}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Contract date:</label>
                                    <div className="font-size-14">{moment(itemData.contractDate).format('D/MM/YYYY')}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Purchase price:</label>
                                    <div className="font-size-14">{new Intl.NumberFormat('en-AU', {style: 'currency', currency: 'AUD'}).format(itemData.purchasePrice)}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Deposit amount:</label>
                                    <div className="font-size-14">{new Intl.NumberFormat('en-AU', {style: 'currency', currency: 'AUD'}).format(itemData.depositAmount)}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Finance due:</label>
                                    <div className="font-size-14">{moment(itemData.financeDue).format('D/MM/YYYY')}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Contract unconditional:</label>
                                    <div className="font-size-14">{!!itemData.contractUnconditional ? 'Yes' : 'No'}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Deposit received:</label>
                                    <div className="font-size-14">{moment(itemData.depositReceivedDate).format('D/MM/YYYY')}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Anticipated settlement date:</label>
                                    <div className="font-size-14">{moment(itemData.anticipatedSettlement).format('D/MM/YYYY')}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Settlement booked:</label>
                                    <div className="font-size-14">{moment(itemData.settlementBooked).format('D/MM/YYYY')}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Date settled:</label>
                                    <div className="font-size-14">{moment(itemData.settledDate).format('D/MM/YYYY')}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Payout figure:</label>
                                    <div className="font-size-14">{new Intl.NumberFormat('en-AU', {style: 'currency', currency: 'AUD'}).format(itemData.payoutFigure)}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>NOD required:</label>
                                    <div className="font-size-14">{!!itemData.nODRequired ? 'Yes' : 'No'}</div>
                                </span>
                            </GridItem>
                        </>
                    )}

                </GridContainer>
            </form>
        </Dialog>
    )
}
export default withSnackbar(MIPSaleEditor);