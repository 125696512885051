import React, { useState, useEffect, useRef, useContext } from 'react';
import { withRouter } from "react-router-dom";
import moment from "moment";
import { withSnackbar } from 'notistack';
import { ProfileContext } from '../store';
import api from "../../util/api"
import GavelIcon from "@material-ui/icons/Gavel";

import LoadingPanel from '../common/Grid/LoadingPanel';
import CardIcon from "../common/MuiKit/Card/CardIcon";
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";
import GridContainer from '../common/MuiKit/Grid/GridContainer';
import GridItem from '../common/MuiKit/Grid/GridItem';

import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';

const MatterSummary = (props) => {

    const [loading, setLoading] = useState(false);

    const [ matterStages, setMatterStages ] = useState([]);
    const defaultMatterStage = { lookupId: 0, lookupValue: 'All matters' };
    const [ matterStage, setMatterStage ] = useState(defaultMatterStage);
    const [ fileManagers, setFileManagers ] = useState([]);
    const defaultFileManager = { id: 0, userName: 'all', fullName: 'All staff' };
    const [ fileManager, setFileManager ] = useState(defaultFileManager);

    const _export = useRef(null);
    const _grid = useRef(null);    
    const { dispatch } = useContext(ProfileContext);

    useEffect(() => {
        setLoading(true);
        api.get('user/colleagues', { accept: 'application/json' })
        .then(json => {
            setFileManagers([ defaultFileManager, ...json ]);
            setLoading(false);
        })
        api.get('lookup/BlockType', { accept: 'application/json' })
        .then(json => {
            setMatterStages([ defaultMatterStage, ...(json.map(m => ({ ...m, lookupValue: `${m.lookupValue} Stage` }))) ]);
            setLoading(false);
        })
    }, []);

    return (
        <Card style={{ margin: props.margin }}>
            <CardHeader>
                <CardIcon color='warning' style={{ marginTop: '-30px' }}>
                    <GavelIcon style={{ color: 'white' }} />
                </CardIcon>
                <h4 className='jm-card-title'>Matters</h4>

            </CardHeader>
            <CardBody>
                <GridContainer>
                    <GridItem xs={3}>
                        <DropDownList 
                            data={matterStages} 
                            value={matterStage}
                            textField="lookupValue"
                            dataItemKey="lookupId"
                            style={{width: '100%'}}
                            onChange={({ value }) => { 
                                setMatterStage(value);
                                dispatch({ type: 'setStage', payload: value });
                             }}
                        />
                    </GridItem>
                    <GridItem xs={3}>
                        <DropDownList 
                            data={fileManagers} 
                            value={fileManager}
                            textField="fullName"
                            dataItemKey="id"
                            style={{width: '100%'}}
                            onChange={({ value }) => { 
                                setFileManager(value);
                                dispatch({ type: 'setManager', payload: value });
                            }}
                        />
                    </GridItem>
                    <GridItem xs={1}>
                        <Button
                            primary={false}
                            className='jm-action-btn k-title'
                            onClick={() => { 
                                props.history.push(`/files`);
                            }}
                            style={{ borderRadius: '8px' }}
                        >
                            SEARCH
                        </Button>
                    </GridItem>
                    <GridItem xs={5}>
                        <ExcelExport
                            ref={_export}
                        />
                        <Button
                            primary={false}
                            className='jm-action-btn k-title'
                            onClick={() => {
                                api.get(`/matter/paged/1/1000/${fileManager.id}/0/0/${matterStage.lookupId}`, {
                                    accept: 'application/json',
                                }).then(json => {
                                    json.data.forEach(item => {
                                        if (item.instructionDate) {
                                            item.instructionDate = moment(item.instructionDate).toDate();
                                        }

                                        item.securityPostCode = '';
                                        if (item.securities) {
                                            item.securities.forEach(sec => { item.securityPostCode += ', ' + sec.addressPostCode; });
                                        }
                                        if (item.securityPostCode.length > 0) {
                                            item.securityPostCode = item.securityPostCode.substring(2, item.securityPostCode.length);
                                        }
                                    });
                                    _export.current.save(json, _grid.current.columns)
                                })
                            }}
                            style={{ borderRadius: '8px' }}
                        >
                            EXPORT TO EXCEL
                        </Button>
                        <Grid ref={_grid} style={{ display: 'none' }}>
                            <GridColumn field="matterNumber" title="File Number" />
                            <GridColumn field="loanNumber" title="Loan Number" />
                            <GridColumn field="clientContact.lookupValue" title="Client Contact" />
                            <GridColumn field="manager.lookupValue" title="Jacmac Contact" />
                            <GridColumn field="securityPostCode" title="Security PostCode" />
                            <GridColumn field="sensitivities" title="Sensitivities" />
                            <GridColumn field="overview" title="Overview" />
                        </Grid>
                    </GridItem>
                </GridContainer>
            </CardBody>
            {loading && <LoadingPanel />}
        </Card>
    );
}
export default withSnackbar(withRouter(MatterSummary));