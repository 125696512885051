import React, { useState, useEffect } from 'react';
import LoadingPanel from '../common/Grid/LoadingPanel';
import api from "../../util/api";
import handleErrors from '../../util/handleErrors';
import { withSnackbar } from 'notistack';
import CardIcon from "../common/MuiKit/Card/CardIcon";
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import PersonIcon from "@material-ui/icons/Person";

const Register = (props) => {

    const initProfile = {
        email: '',
        firstName: '',
        lastName: '',
        role: null,
        phoneNumber: '',
        title: '',
        address: ''
    };

    const [ userProfile, setUserProfile ] = useState(initProfile);
    const [ loading, setLoading ] = useState(false);
    
    const [ roles, setRoles ] = useState([]);

    useEffect(() => {        
        api.get(`/user/roles`, {
            accept: 'application/json', 
        }) 
        .then(json => {            
            setRoles(json);
        })
        .catch(handleErrors(props));
    }, [])

    const handleInputChange = ({ value, target: { name } }) => {
        setUserProfile({...userProfile, [name]: value });
    }
    const handleDropdownChange = ({ value, target: { name } }) => {
        setUserProfile({...userProfile, [name]: value });
    }
    const handleSave = () => {
        setLoading(true);
        api.post(`/auth/register`, userProfile) 
        .then(json => {            
            setLoading(false);
            setUserProfile(initProfile);
            props.enqueueSnackbar(json, { variant: 'info' });
        })
        .catch(handleErrors(props));
    }

    return (
        <div style={{ height: 'calc(100vh - 100px)', display:'flex', alignItems:'center' }}>
            <Card style={{ maxWidth: '500px', margin: '0 auto' }}>
                <CardHeader>
                    <CardIcon color='info' style={{marginTop: '-30px'}}>
                        <PersonIcon style={{color:'white'}} />
                    </CardIcon>
                    <h4 style={{color: '#777'}}>Register User</h4>
                </CardHeader>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Input
                                width='100%'
                                label='Email'
                                value={userProfile.email}
                                onChange={handleInputChange}
                                name="email"
                                id="email"
                                required
                            />
                        </GridItem>
                        <GridItem xs={12}>
                            <Input
                                width='100%'
                                label='First Name'
                                value={userProfile.firstName}
                                onChange={handleInputChange}
                                name="firstName"
                                id="firstName"
                                required
                            />
                        </GridItem>
                        <GridItem xs={12}>
                            <Input
                                width='100%'
                                label='Last Name'
                                value={userProfile.lastName}
                                onChange={handleInputChange}
                                name="lastName"
                                id="lastName"
                                required
                            />
                        </GridItem>
                        <GridItem xs={12}>
                            <DropDownList
                                data={roles}
                                textField="name"
                                dataItemKey="name"
                                width='100%'
                                label='Role'
                                value={userProfile.role}
                                onChange={handleDropdownChange}
                                name="role"
                                id="role"
                                required
                            />
                        </GridItem>
                        <GridItem xs={12}>
                            <Input
                                width='100%'
                                label='Title'
                                value={userProfile.title}
                                onChange={handleInputChange}
                                name="title"
                                id="title"
                            />
                        </GridItem>                    
                        <GridItem xs={12}>
                            <Input
                                width='100%'
                                label='Phone Number'
                                value={userProfile.phoneNumber}
                                onChange={handleInputChange}
                                name="phoneNumber"
                                id="phoneNumber"
                            />
                        </GridItem>                    
                        <GridItem xs={12}>
                            <Input
                                width='100%'
                                label='Address'
                                value={userProfile.address}
                                onChange={handleInputChange}
                                name="address"
                                id="address"
                            />
                        </GridItem>
                    </GridContainer>
                    <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: '10px'}}>
                        <Button onClick={handleSave}>Register</Button>
                    </div>
                </CardBody>
            </Card>
            {loading && <LoadingPanel/>}
        </div>
    );
}

export default withSnackbar(Register);