import React, { useState, useContext } from 'react';
import cn from "classnames";
import { withRouter, Switch, Route } from "react-router-dom";
import { ProfileContext } from '../store'
import { withStyles } from "@material-ui/core/styles";
import appStyle from "../assets/jss/material-dashboard-pro-react/layouts/adminStyle";
import routes from "../routes";
import logo from "../assets/img/Jacmac 2020 sm.png";
import Sidebar from "../common/Sidebar/Sidebar";

const getRoutes = routes => {        
    return routes.map((prop, key) => {

        if (prop.collapse) {
            return this.getRoutes(prop.views);
        }
        if (prop.layout !== "") {
            return (
                <Route 
                    path={prop.layout + prop.path}
                    component={prop.component}
                    key={key}
                />         
            );
        } else {
            return (
                <Route exact
                    path={prop.path}
                    component={prop.component}
                    key={key}
                />         
            );
        }
    });
};
const getActiveRoute = routes => {
    let activeRoute = "Llama";
    for (let i = 0; i < routes.length; i++) {

        
        if (routes[i].collapse) {
            let collapseActiveRoute = getActiveRoute(routes[i].views);
            if (collapseActiveRoute !== activeRoute) {
                return collapseActiveRoute;
            }
        } else {

            const path = window.location.href.replace(process.env.REACT_APP_REDIRECT_URL.slice(0, -1), '');
            if (
                path === routes[i].path
            ) { 
                return routes[i].name;                
                //activeRoute += '---' + routes[i].name
            }
        }
    }
    return activeRoute;    
};

const Default = (props) => {
    const { classes, ...rest } = props;

    const [ miniActive, setMiniActive ] = useState(true);
    const [ mobileOpen, setMobileOpen ] = useState(false);
    const [ headerVisible, setHeaderVisible ] = useState(true);  
    const { state } = useContext(ProfileContext);
   
    const mainPanel = classes.mainPanel +
    " " +
    cn({
      [classes.mainPanelSidebarMini]: miniActive
    });
 
    const content = cn({
        [classes.content]: headerVisible,
        [classes.contentNoHeader]: !headerVisible,
    })

    return (
        <div className={classes.wrapper}>
            <Sidebar
                sidebarMinimize={() => setMiniActive(!miniActive)}
                routes={routes}
                logoText='Home'
                logo={logo}
                handleDrawerToggle={() => setMobileOpen(!mobileOpen)}
                open={mobileOpen}
                miniActive={miniActive}
                avatar={state.avatar}
                //fullname={localStorage.getItem("Llama.displayName")}
                fullname={state.name}
                {...rest}
            />
            <div className={mainPanel} style={{height: '100vh', backgroundColor: '#efefef'}}> 
                {/* <HomeNavbar
                    sidebarMinimize={() => setMiniActive(!miniActive)}
                    miniActive={miniActive}
                    brandText={state.title}
                    handleDrawerToggle={() => setMobileOpen(!mobileOpen)}
                    {...rest}
                /> */}
                <div className={content}>
                    <div className={classes.container}>
                        <Switch>
                            {getRoutes(routes)}                                
                        </Switch>
                    </div>
                </div>
               
            </div> 
            <div id="dialog-root"></div>
        </div>
    )
}

export default withRouter(withStyles(appStyle)(Default)) ;