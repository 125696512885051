import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import StepWizard from 'react-step-wizard';
import styles from './wizard.module.scss';
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import CardIcon from "../common/MuiKit/Card/CardIcon";
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";
import GavelIcon from "@material-ui/icons/Gavel";
import moment from "moment";

const InstructionWizard = (props) => {
    const [state, updateState] = useState({
        form: {},
        transitions: {
            enterRight: `${styles.animated} ${styles.enterRight}`,
            enterLeft: `${styles.animated} ${styles.enterLeft}`,
            exitRight: `${styles.animated} ${styles.exitRight}`,
            exitLeft: `${styles.animated} ${styles.exitLeft}`,
            intro: `${styles.animated} ${styles.intro}`,
        }
    });

    const [stats, setStats] = useState({});

    const updateForm = (key, value) => {
        const { form } = state;

        form[key] = value;
        updateState({
            ...state,
            form,
        });
    };

    const onStepChange = (data) => {
        setStats(data);
    };

    const setInstance = wizard => updateState({
        ...state,
        wizard,
    });

    const { wizard } = state;

    return (
        <Card style={{ minHeight: 'calc(100vh - 150px)', marginTop: '35px' }}>
            <CardHeader >
                 <CardIcon color='warning' style={{marginTop: '-30px'}}>
                     <GavelIcon style={{color:'white'}} />
                 </CardIcon>
                 <h4 style={{color: '#777'}}>New Matter</h4>
            </CardHeader>
            <CardBody className='d-flex flex-column justify-content-between position-static'>
                    <GridContainer className='flex-grow-1'>
                        <div className={`${styles.rswWrapper} flex-grow-1 px-3`}>
                            <StepWizard
                                onStepChange={onStepChange}
                                transitions={state.transitions}
                                nav={<Nav savePanel={props.savePanel} date={props.itemData && props.itemData.instructionDate ? props.itemData.instructionDate : moment().toDate()} />}                        
                                instance={setInstance}
                                style={{display:'flex'}}
                            >
                                {props.children}
                            </StepWizard>
                        </div>
                    </GridContainer>
                    <GridContainer className='mt-auto justify-content-between px-3'>
                        {wizard && <InstanceDemo wizard={wizard} savePanel={props.savePanel} stats={stats} /> }
                    </GridContainer>                    
            </CardBody>            
        </Card>
    )
}

export default InstructionWizard;

const Nav = (props) => {
    
    const dots = [];
    for (let i = 1; i <= props.totalSteps; i += 1) {
        const isActive = props.currentStep === i;
        dots.push((
            <span
                key={`step-${i}`}
                className={`${styles.dot} ${isActive ? styles.active : ''}`}
                onClick={() => {
                    if (props.savePanel(i, props.currentStep)){
                        props.goToStep(i);
                    }                    
                }}
            >&bull;</span>
        ));
    }

    return (<>
        <div className={styles.nav}>
            {dots}            
        </div>
        <div>
            <span className='k-textbox-container d-inline'>
                <label className='ro-label'>Date: </label>
                <div className="font-size-14 d-inline">{moment(props.date).format('DD/MM/YYYY')}</div>
            </span>
        </div>
    </>);
};

const InstanceDemo = withRouter((props) => {
    const { wizard } = props;
    return (
        <>

            {wizard.currentStep != 1 && <button className={'k-button k-title'} onClick={() => {
                if (props.savePanel(0, wizard.currentStep)){
                    wizard.previousStep();
                }
            }}>Previous</button>}
            <div/>
            {wizard.currentStep != wizard.totalSteps && <button className={'k-button k-title k-primary'} onClick={() => {
                console.log(wizard);
                if (props.savePanel(0, wizard.currentStep)){
                    wizard.nextStep();
                }                
                }}>Next</button>}
            {wizard.currentStep == wizard.totalSteps && <button className={'k-button k-title k-primary'} onClick={() => {
                props.history.push(`/files/${props.match.params.id}`);
            }}>Submit</button>}
        </>
    );
})


