import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import EventEditor from "../event/EventEditor";

import api from "../../util/api"
import handleErrors from '../../util/handleErrors';

import { Button } from '@progress/kendo-react-buttons';

import CardIcon from "../common/MuiKit/Card/CardIcon";
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import LoadingPanel from '../common/Grid/LoadingPanel';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';

import EventIcon from "@material-ui/icons/Event";
import moment from 'moment';
import { withSnackbar } from 'notistack';
import KendoGridBooleanCell from "../../util/KendoGridBooleanCell";
import KendoGridDateTimeCell from "../../util/KendoGridDateTimeCell";

const EventListView = (props) => {
    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);
    useEffect(() => {
        setLoading(true);
        api.get(`/event/critical/0/10`, {
            accept: 'application/json',
        })
        .then(json => {
            console.log('json: ', json);
            setEvents(json || []);
        })
        .catch(handleErrors(props))
        .finally(cancelEvent());
    }, []);

    const [eventInEdit, setEventInEdit] = useState(null);
    const saveEvent = (itemEvent) => {
        setLoading(true);
        api.request({
            url: '/event',
            method: itemEvent.eventId === 0 ? 'POST' : 'PUT', 
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(itemEvent)
        })
        .then(json => {

            if (props.getEvents) {
                props.getEvents();
            }

            api.get(`/event/critical/0/10`, {
                accept: 'application/json',
            })
            .then(json => {
                setEvents(json || []);
            })
            .catch(handleErrors(props))
            .finally(cancelEvent());
        })
        .catch(handleErrors(props))
        .finally(cancelEvent());
    }

    const cancelEvent = () => {
        setLoading(false);
        setEventInEdit(null);
    }

    return (
        <Card style={{ margin: props.margin, height: props.cardHeight}}>
            <CardHeader>
                <CardIcon color='rose' style={{ marginTop: '-30px' }}>
                    <EventIcon style={{ color: 'white' }} />
                </CardIcon>
                <h4 className='jm-card-title'>{`Upcoming Events`}</h4>
                <span style={{float: 'right'}}>
                    <Link to={`/event`}>View All</Link>
                </span>
            </CardHeader>
            <CardBody>
                <Grid
                    className='clickableRow'
                    filterable={false}
                    sortable={false}
                    pageable={false}
                    data={events}
                    style={{ height: props.gridHeight ? props.gridHeight : '439px', lineHeight: 1}}
                    onRowClick={(ev) => {
                        if (ev.dataItem.matterId) {
                            props.history.push(`/files/${ev.dataItem.matterId}`);
                        }
                        //setEventInEdit(ev.dataItem)
                    }}
                >
                    <GridColumn field="startDate" title="Start Date" cell={KendoGridDateTimeCell} />
                    <GridColumn field="subject" title="Event" />
                    <GridColumn field="matter.matterName" title="Matter Name" />
                    <GridColumn title="File Manager" cell={(columnProps, context) => {
                        return (
                            <td>
                                <div style={{ whiteSpace: 'pre' }}>{columnProps.dataItem.matter ? columnProps.dataItem.matter.manager ? columnProps.dataItem.matter.manager.lookupValue : '' : '' }</div>
                            </td>
                        )
                    }} />
                    <GridColumn title="Instructing Officer" cell={(columnProps, context) => {
                        return (
                            <td>
                                <div style={{ whiteSpace: 'pre' }}>{columnProps.dataItem.matter ? columnProps.dataItem.matter.clientContact ? columnProps.dataItem.matter.clientContact.lookupValue : '' : ''}</div>
                            </td>
                        )
                    }} />
                    {/* 
                    <GridColumn field="endDate" title="End Date" cell={KendoGridDateTimeCell} />
                    <GridColumn field="matter.matterNumber" title="Matter Number" />
                    <GridColumn field="message" title="Message" />
                    <GridColumn field="isAllDay" title="All Day Event" cell={KendoGridBooleanCell} />
                    */}
                </Grid>
                {/* <GridContainer>
                    {(events && events.length > 0) ? events.map(i => {
                        const date = moment(i.startDate);
                        const end = i.endDate ? moment(i.endDate) : null;
                        return (
                            <div key={`keyeventitem${i.eventId}`} className='event-item' onClick={() => setEventInEdit(i)}>
                                <div className='event-item-title'>
                                    <h6>{date.format('MMM')}</h6>
                                    <h5>{date.date()}</h5>
                                </div>
                                <div className='event-item-content'>
                                    <h5>{i.subject}</h5>
                                    <h6>{`${date.format('dddd hh:mm A')}${end ? ` - ${end.format('hh:mm A')}` : ''}`}</h6>
                                </div>
                            </div>
                        );
                    }) : <p style={{width: '100%', textAlign: 'center', color: 'rgba(0, 0, 0, 0.62)'}}>No data</p>}         
                </GridContainer> */}
            </CardBody>
            {eventInEdit && <EventEditor dataItem={eventInEdit} save={saveEvent} cancel={cancelEvent} editMode={false} />}
            {loading && <LoadingPanel />}
        </Card>
        );
}

export default withRouter(withSnackbar(EventListView));