import React, { useState } from 'react';
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import Dialog from "../common/Dialog";

import { editableField } from "../../util/editableField";
import { requiresRole, policies } from '../../util/accessControl'

import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';

import { withSnackbar } from 'notistack';

const LookupEditor = (props) => {
    const [errors, setErrors] = useState({});
    const originalItem = { ...props.dataItem };
    const [dataItem, setDataItem] = useState(originalItem);
    const [originalDataItem, setOriginalDataItem] = useState(originalItem);
    const [editMode, setEditMode] = useState(!!props.editMode);

    const handleInputChange = ({ value, target: { name }, ...rest }) => {
        setDataItem({ ...dataItem, [name]: value });
    }

    const validateForm = () => {
        var validForm = true;
        var formErrors = {};
        formErrors.lookupValue = (!dataItem.lookupValue || dataItem.lookupValue === '');
        setErrors(formErrors);

        const item = Object.assign({}, formErrors)
        validForm = !Object.keys(item).some(x => item[x]);
        return validForm;
    }

    return (
        <Dialog
            createMode={dataItem.lookupId === 0}
            inEditMode={editMode}
            validate={validateForm}
            original={originalDataItem}
            current={dataItem}
            saveChanges={props.save}
            cancelChanges={props.cancel}
            //deleteItem={props.delete}
            //deleteItemMessage="Are you sure you want to delete this Lookup Item?"
            width={600}
            title={
                <>
                    {`${dataItem.lookupId === 0 ? 'Add' : editMode ? 'Edit' : 'View'} Lookup`}
                    {requiresRole(<Button className='rounded-circle ml-2' primary={true} look="bare" icon={editMode ? 'lock' : 'edit'} onClick={(ev) => setEditMode(!editMode)} />, policies.edit)}
                </>
            }
        >
            <form onSubmit={(ev) => ev.preventDefault()}>
                <GridContainer>

                    {editableField(
                        <GridItem sm={12} className='mt-3'>
                            <Input
                                width='100%'
                                label='Lookup Value'
                                value={dataItem.lookupValue}
                                onChange={handleInputChange}
                                name="lookupValue"
                                id="lookupValue"
                                required={true}
                            />
                            {errors.lookupValue && <span className='text-danger'>Lookup Value is Required.</span>}
                        </GridItem>, editMode, 'Lookup Value', dataItem.lookupValue ?? '')}
                   
                </GridContainer>
            </form>
        </Dialog>
    )
}

export default withSnackbar(LookupEditor);