import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';

import { Link } from "react-router-dom";
import withRouterAndRef from '../../util/withRouterAndRef';
import api from "../../util/api";
import handleErrors from '../../util/handleErrors';
import { withSnackbar } from 'notistack';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import LoadingPanel from '../common/Grid/LoadingPanel';
import moment from 'moment';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import KendoGridDateCell from "../../util/KendoGridDateCell";
import KendoGridHtmlCell from "../../util/KendoGridHtmlCell";

const ConversationSummary = forwardRef((props, ref) => {
    const id = props.match.params.id;

    useImperativeHandle(ref, () => ({
        load,
    }));

    const [ notesLoading, setNotesLoading ] = useState(false);
    const [ notes, setNotes ] = useState([]);

    useEffect(() => {
        //load();
    }, []);

    const load = (dataItem) => {
        //console.log('load dataItem: ', dataItem);        
    }
    const handleSort = ({ target: { value } }) => {
        props.load(value === "Newest" ? 'descending' : 'ascending');
    }
    const msgRe = new RegExp(process.env.REACT_APP_NOTE_MSG_RE, "mg");
    ///(?<=(<[^<>]>)+).+?(?=(<\/[^<>]>)+)|^\b.+(\b|\r|\n|\W)$/mg
    

    const handleRowClick = (dataItem) => {
        
        //dataItem.viewed = true;
        //console.log('dataItem: ', dataItem);
        if (props.onRowClick) {
            props.onRowClick(dataItem.matterId);
        } else {
            props.edit(dataItem)
        }
    }

    return (
        <React.Fragment>
            <Grid
                className='clickableRow grid-with-footer align-cells-top cell-line-height-1'                
                data={props.notes}
                onRowClick={(p) => {
                    handleRowClick(p.dataItem)
                }}
                rowRender={(tr, { dataItem }) => {
                    //console.log('dataItem: ', dataItem);
                    var bold = (dataItem.responseRequired && !dataItem.responseRecieved) || (!dataItem.responseRequired && !dataItem.acknowledged) ? false : true;
                    return React.cloneElement(tr, { className: bold ? 'color-based-conversation' : 'color-based-conversation-bold' }, tr.props.children);
                }}
            >        
                <GridColumn field='createdOn' title='Added' cell={KendoGridDateCell} width={90} />  

                {id && id > 0 ? <GridColumn field='to' title='To' width={135} /> : <GridColumn field='matter.matterName' title='Matter Name' width={135} /> }
                
                <GridColumn field='from' title='From' width={135} /> 
                <GridColumn title='Message' cell={(p) => {                    
                    const { message } = p.dataItem;

                    return (
                        <td onClick={() => handleRowClick(p.dataItem)} dangerouslySetInnerHTML={{ __html: message }}></td>
                    )
                }} />
                {/* <GridColumn field="message" title="Message" cell={(colProps) => <KendoGridHtmlCell {...colProps} onClick={(ev) => handleRowClick(colProps.dataItem) } />} /> */}
                {/* <GridColumn title='Message' cell={(p) => {                    
                    const { message } = p.dataItem;
                    const result = [...message.matchAll(msgRe)].map(value => {
                        return value[0].replace('&nbsp;', ' ');
                    }).join(' ').trim();
                    return (
                        <td onClick={() => handleRowClick(p.dataItem)} style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{result}</td>
                    )
                }} /> */}
                {/* <GridColumn title='Acknowlege/Respond' width={125} cell={(p) => {
                    const { responseRequired, responseRecieved, acknowledged } = p.dataItem;
                    return (
                        <td onClick={() => handleRowClick(p.dataItem)}>                                
                            {
                                (responseRequired && responseRecieved) ?
                                'Response Recieved' :
                                (responseRequired  && !responseRecieved) ?
                                'Response Required' :
                                (!responseRequired  && acknowledged) ?
                                'Acknowledged' :
                                (!responseRequired  && !acknowledged) ?
                                'Unacknowledged' : ''
                            }
                        </td>
                    );
                }} /> */}
            </Grid>
            <div className='grid-footer'>
                <span>
                    <DropDownList 
                        data={["Newest", "Oldest"]} 
                        defaultValue='Oldest' 
                        style={{border: 'none', width: '130px'}}
                        onChange={handleSort}
                    />
                </span>
            </div>
            {notesLoading && <LoadingPanel/>}
            {props.renderEditors && props.renderEditors()}
        </React.Fragment>
    );
});

export default withSnackbar(withRouterAndRef(ConversationSummary));