import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import withRouterAndRef from '../../util/withRouterAndRef';
import api from "../../util/api";
import handleErrors from '../../util/handleErrors';
import { withSnackbar } from 'notistack';
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import moment from 'moment';
import LoadingPanel from '../common/Grid/LoadingPanel';

const EventSummary = forwardRef((props, ref) => {
    const id = props.match.params.id;

    useImperativeHandle(ref, () => ({
        load,
    }));

    const [ eventsLoading, setEventsLoading ] = useState(false);
    const [ events, setEvents ] = useState([]);

    useEffect(() => {
        load();
    }, []);

    const load = () => {
        setEventsLoading(true)  
        api.get(`/event/critical/${id}`, {
            accept: 'application/json',
        }) 
        .then(json => {
            setEvents(json || []);
        })
       .catch(handleErrors(props))
       .finally(() => setEventsLoading(false));
    }

    return (
        <>
            <GridContainer>
                {(events && events.length > 0) ? events.map(i => {
                    const date = moment(i.startDate);
                    const end = i.endDate ? moment(i.endDate) : null;
                    return (
                        <div key={`keyeventitem${i.eventId}`} className='event-item' onClick={() => props.edit(i)}>
                            <div className='event-item-title'>
                                <h6>{date.format('MMM')}</h6>
                                <h5>{date.date()}</h5>
                            </div>
                            <div className='event-item-content'>
                                <h5>{i.subject}</h5>
                                <h6>{`${date.format('dddd hh:mm A')}${end ? ` - ${end.format('hh:mm A')}` : ''}`}</h6>
                            </div>
                        </div>
                    );
                }) : <p style={{width: '100%', textAlign: 'center', color: 'rgba(0, 0, 0, 0.62)'}}>No data</p>}         
            </GridContainer>
            {eventsLoading && <LoadingPanel/>}
            {props.renderEditors(false)}
        </>
    );
});

export default withSnackbar(withRouterAndRef(EventSummary));