import React, { useState } from 'react';
import { withRouter, useLocation, useHistory } from "react-router-dom";
import api from "../../util/api";
import CardIcon from "../common/MuiKit/Card/CardIcon";
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";
import PersonIcon from "@material-ui/icons/Person";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import { Input } from '@progress/kendo-react-inputs';
import _ from 'lodash';
import { withSnackbar } from 'notistack';

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

const ResetPassword = (props) => {

    const [password, setPassword] = useState(''); 
    const [confirmPassword, setConfirmPassword] = useState(''); 
    const [errors, setErrors] = useState([]);     

    var query = useQuery();
    var history = useHistory();

    const handleSubmit = (event) => {
        setErrors([]);
        event.preventDefault();


        var body = { password, confirmPassword, userId: query.get('userId'), code: query.get('code') };

        api
        .post('/auth/resetpassword', body)
        .then(response => {
            props.enqueueSnackbar(`Password reset, please login.`, { variant: 'info' });
            history.push('/login');
            
        }).catch(({ response: { data } }) => {
            console.log(data);
            var messages = data.split(";;");
            console.log(messages);
            setErrors(messages);
        })
    }
    return (
        <div style={{height: 'calc(100vh - 50px)',display:'flex',alignItems:'center'}}>
            <Card style={{maxWidth: '400px', margin: '0 auto'}}>
                <CardHeader>
                    <CardIcon color='info' style={{marginTop: '-30px'}}>
                        <PersonIcon style={{color:'white'}} />
                    </CardIcon>
                    <h4 style={{color: '#777'}}>Reset password</h4>
                </CardHeader>
                <CardBody>
                    <form onSubmit={handleSubmit}>
                        <GridContainer>
                            {errors.length > 0 && 
                            <GridItem sm={12}>
                                <p className='text-danger'>
                                    <ul>
                                        {errors.map(function (msg, i) {
                                            return <li>{msg}</li>;
                                        })}
                                    </ul>
                                </p>
                            </GridItem>} 
                            <GridItem sm={12}>
                                <Input
                                    width='100%'
                                    label='Password'
                                    value={password}
                                    onChange={e => setPassword(e.target.value)} 
                                    name="password"
                                    id="password"
                                    type='password'
                                />
                            </GridItem>
                            <GridItem sm={12}>
                                <Input
                                    width='100%'
                                    label='Confirm Password'
                                    value={confirmPassword}
                                    onChange={e => setConfirmPassword(e.target.value)} 
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    type='password'
                                />
                            </GridItem>
                            <GridItem sm={12}>
                                <ul>Password requirements:
                                    <li>Must be at least 8 characters</li>
                                    <li>Must have at least one lowercase character ('a'-'z')</li>
                                    <li>Must have at least one uppercase character ('A'-'Z')</li>
                                    <li>Must have at least one digit ('0'-'9')</li>
                                    <li>Must have at least one non alphanumeric character</li>
                                </ul>
                            </GridItem>
                            <GridItem sm={12} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingTop: '12px'}}>

                                <button 
                                    className="k-button k-title"
                                    type="submit" >
                                    Reset
                                </button>
                            </GridItem>

                        </GridContainer>                       
                    </form>
                </CardBody>
            </Card>
        </div>       
    )
};

export default withSnackbar(withRouter(ResetPassword));