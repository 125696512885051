import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useEventListener } from "../../util/useEventListener";
import { findDOMNode } from "react-dom";
import { Link } from "react-router-dom";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import UploadList from "../common/Upload/UploadList";
import Dialog from "../common/Dialog";

import api from "../../util/api";
import { editableField } from "../../util/editableField";
import { requiresRole, policies } from '../../util/accessControl'
import handleErrors from '../../util/handleErrors';

import fileDownload from "js-file-download";
import contentDisposition from "content-disposition";
import { Popup } from "@progress/kendo-react-popup";
//import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Upload } from '@progress/kendo-react-upload';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Input } from '@progress/kendo-react-inputs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';

import uuidv4 from 'uuid/v4';
import moment from "moment";
import { withSnackbar } from 'notistack';

const language = 'en';
const maxFileSize = 20971520;

const messagesObj = {
    upload: {
        select: 'Select file...'
    },
};

loadMessages(messagesObj, language);

const DocumentEditor = (props) => {
    const initLookupObject = { loading: false, list: [] };
    const [errors, setErrors] = useState({});
    const originalItem = {
        ...props.dataItem,
        documentDate: (props.dataItem.documentDate == null || props.dataItem.documentDate === "") ? moment().toDate() : moment(props.dataItem.documentDate).toDate(),
    };
    const [ document, setDocument] = useState(originalItem);
    const [ originalDocument, setOriginalDocument] = useState(originalItem);
    const [ editMode, setEditMode] = useState(!!props.editMode);
    const [ documentCategories, setDocumentCategories] = useState(initLookupObject);

    const addNewCategoryRef = useRef(null);
    const [ addNewCategory, setAddNewCategory ] = useState(false);
    const [ newCategory, setNewCategory ] = useState('');
    const onAddNewCategory = (ev) => {
        if (!newCategory) {
            setAddNewCategory(false);
            return;
        }
        api.request({
            url: `/lookup`,
            method: 'POST',
            accept: 'application/json',
            headers: { 'Content-Type': 'application/json' },
            data: JSON.stringify({
                LookupType: "DocumentCategory",
                LookupValue: newCategory,
                LookupId: 0
            })
        })
        .then(json => {
            setDocumentCategories({ list: [ ...documentCategories.list, json ], loading: false});
            setAddNewCategory(false);  
            setNewCategory("");          
        })
        .catch(handleErrors(props))
    }

    const handler = useCallback(
        () => {
            if (addNewCategory) setAddNewCategory(false);
        },
        [
            addNewCategory,
            setAddNewCategory
        ],
    )
    useEventListener('click', handler, document);
    const handleInputChange = ({ value, target: { name }, ...rest }) => {
        setDocument({...document, [name]: value });
    }
    const handleSelectChange = ({ value, target: { name } }) => {
        const idField = `${name}Id`;
        setDocument({...document, [name]: value, [idField]: value ? value.lookupId : null });
    }
    const handleDateChange = ({ value, target: { name } }) => {
        setDocument({ ...document, [name]: value == null ? null : moment(value).local().toDate() });
    }
    const handleCheckChange = ({ target: { name, value, checked } }) => {
        setDocument({...document, [name]: checked });
    }
    const handleTextAreaChange = ({ target: { value, name } }) => {
        //console.log('value, name: ', value, name);
        setDocument({ ...document, [name]: value });
    }
    const validateForm = () => {
        var validForm = true;
        var formErrors = {};
        formErrors.documentName = (!document.documentName || document.documentName === '');
        formErrors.documentDate = (!document.documentDate || document.documentDate === '');
        formErrors.documentCategory = (!document.documentCategory || document.documentCategory === '');
        formErrors.filename = (!document.filename || document.filename === '');
        formErrors.filesize = (document.contentLength > maxFileSize);

        setErrors(formErrors);

        const item = Object.assign({}, formErrors)
        validForm = !Object.keys(item).some(x => item[x]);
        return validForm;
    }

    const download = (doc) => {
        if (doc.documentId) {
            api.get(`/document/${doc.documentId}/download`, { accept: '*/*', responseType: 'blob' })
            .then(response => {
                const disposition = contentDisposition.parse(response.headers['content-disposition']);
                fileDownload(response.data, disposition.parameters.filename, response.headers['content-type'])
            })
            .catch(err => {
                props.enqueueSnackbar('File not found.', { variant: 'error' });
            })
            //.catch(handleErrors(props));
        }
    }

    const setLookup = (setFunc) => (json) => {
        setFunc({ loading: false, list: json });
        return json;
    }

    useEffect(() => {
        const axiosConfig = { accept: 'application/json' };
        const loading = { loading: true, list: [] };
        
        let onLoadItems = [
            api.get(`/lookup/documentcategory`, axiosConfig).then(setLookup(setDocumentCategories)), // Load Users
        ];

        if (document && document.matterId && document.matterId > 0) {
            onLoadItems.push(api.get(`/matter/${document.matterId}`, axiosConfig));
        }

        Promise.all(onLoadItems)
            .then(responses => {
                let tempData = { ...document };
                tempData = { ...tempData, matter: responses[1] };

                setDocument(tempData);
                setOriginalDocument(tempData);
            })
            .catch(handleErrors(props))
    }, [editMode]);

    return (
        <Dialog 
            createMode={document.documentId === 0}
            inEditMode={editMode}
            validate={validateForm}
            original={originalDocument}
            current={document}
            saveChanges={props.save}
            cancelChanges={props.cancel}
            width={600}
            deleteItem={props.delete}
            deleteItemMessage="Are you sure you want to delete this Document?"
            title={
                <>
                    {`${document.documentId === 0 ? 'Add' : editMode ? 'Edit' : 'View'} Document`}
                    {requiresRole(<Button className='rounded-circle ml-2' primary={true} look="bare" icon={editMode ? 'lock' : 'edit'} onClick={(ev) => setEditMode(!editMode)} />, policies.edit)}
                </>
            }
        >
            <form onSubmit={(ev) => ev.preventDefault()}>
                <GridContainer>
                    <GridItem sm={12}>
                        <div className="font-size-14">{document.matter ? `${document.matter.matterName} | ${document.matter.matterNumber} | ${document.matter.clientReference}` : 'No Matter'}</div>
                    </GridItem>
                    
                    {editMode ? (
                        <>
                            <GridItem sm={12} className='mt-4'>
                                <Input
                                    width='100%'
                                    label='Name'
                                    value={document.documentName}
                                    onChange={handleInputChange}
                                    name="documentName"
                                    id="documentName"
                                    required={true}
                                />
                                {errors.documentName && <span className='text-danger'>Document Name is Required.</span>}
                            </GridItem>
                            <GridItem sm={6} className='mt-3'>
                                <span className='k-textbox-container'>
                                    <label className='k-label'>Document Date</label>
                                    <DatePicker
                                        defaultValue={new Date()}
                                        format="dd/MM/yyyy"
                                        name='documentDate'
                                        id='documentDate'
                                        onChange={handleDateChange}
                                        value={document.documentDate}
                                        label=""
                                        required={true}
                                    />
                                </span>
                                {errors.documentDate && <span className='text-danger'>Document Date is Required.</span>}
                            </GridItem>
                            <GridItem sm={6} />
                            
                            <GridItem sm={6} className='mt-3'>
                                <ComboBox onFocus={(ev) => { if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click() }}
                                    data={documentCategories.list}
                                    loading={documentCategories.loading}
                                    textField='lookupValue'
                                    dataItemKey='lookupId'
                                    value={document.documentCategory}
                                    onChange={handleSelectChange}
                                    label="Category"
                                    name='documentCategory'
                                    id='documentCategory'
                                    required={true}
                                />
                                {errors.documentCategory && <span className='text-danger'>Category is Required.</span>}
                            </GridItem>
                            
                            {/*{requiresRole(
                            <GridItem sm={6} className='mt-3' style={{paddingTop:'8.5px'}}>
                                    {!addNewCategory && <div className='p-1 d-flex flex-row'>
                                        <Button style={{borderRadius: '8px', width:'100%'}} ref={addNewCategoryRef} onClick={() => setAddNewCategory(true)}>Add New Category</Button>
                                    </div>}

                                    {addNewCategory && <div className='p-1 d-flex flex-row'>                                        
                                        <Input
                                            value={newCategory}
                                            onChange={({ value }) => setNewCategory(value)}
                                            name='newCategory'
                                            id='newCategory'
                                            style={{width:'100%'}}
                                            className='w-100 border-right-0'
                                        />                                        
                                        <button
                                            title='OK'
                                            className='k-button k-primary k-button-icon rounded-0'
                                            onClick={onAddNewCategory}
                                            style={{height:'33.75px'}}
                                        >
                                            <span className="k-icon k-i-check"></span>
                                        </button>
                                    </div>} 
                            </GridItem>, ['admin','user'])}
                            */}
                            <GridItem sm={12}>
                                <LocalizationProvider language={language} >
                                    <IntlProvider locale={language} >
                                        <Upload
                                            //listItemUI={UploadList}
                                            batch={false}
                                            multiple={false}                                    
                                            required
                                            files={document && document.filename ? [
                                                {
                                                    name: document.filename,
                                                    extension: document.extension,
                                                    size: document.contentLength,
                                                    uid: uuidv4(),
                                                    data: document
                                                }
                                            ] : []}
                                            autoUpload={false}
                                            showActionButtons={false}
                                            onAdd={(ev) => {
                                                const file = ev.newState[0];
                                                //console.log('file:', file);
                                                setDocument({
                                                    ...document,
                                                    documentContent: null,
                                                    filename: file.name,
                                                    extension: file.extension,
                                                    contentType: '',
                                                    contentLength: file.size,
                                                    file: file.getRawFile()
                                                });
                                            }}
                                            onRemove={(ev) => {
                                                //console.log('onRemove:', ev);

                                                setDocument({
                                                    ...document,
                                                    documentContent: null,
                                                    filename: null,
                                                    extension: null,
                                                    contentType: null,
                                                    contentLength: null
                                                });
                                            }}
                                        />
                                    </IntlProvider>
                                </LocalizationProvider>
                                {errors.filename && <span className='text-danger'>File is Required.</span>}
                                {errors.filesize && <span className='text-danger'>Maximum file size is 20 MB.</span>}
                            </GridItem>
                            <GridItem sm={6} />
                            <GridItem sm={12} className='mt-3'>
                                <span className='k-textbox-container'>
                                    <label className='k-label'>Comments</label>
                                    <textarea
                                        className='k-textarea w-100'
                                        label='Comments'
                                        value={document.comments}
                                        onChange={handleTextAreaChange}
                                        name="comments"
                                        id="comments"
                                        rows={5}
                                    />     
                                </span>                     
                            </GridItem>
                            <GridItem sm={12} className='mt-3'>
                                <input type="checkbox" className='k-checkbox mt-2' defaultChecked={document.copyToLibrary} onClick={handleCheckChange} name="copyToLibrary" id="copyToLibrary" />
                                <label className="k-checkbox-label" htmlFor="copyToLibrary" style={{marginTop: '8px'}}>Copy to Library</label>
                            </GridItem>
                        </>
                    ) : (
                        <>
                            <GridItem sm={3} className='mt-3'>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Date of document:</label>
                                    <div className="font-size-14">{document.documentDate ? moment(document.documentDate).format('D/MM/YYYY') : ''}</div>
                                </span>
                            </GridItem> 
                            <GridItem sm={3} className='mt-3'>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Document name:</label>
                                    <div className="font-size-14">{document.documentName}</div>
                                </span>
                            </GridItem>    
                            {/*
                            <GridItem sm={3} className='mt-3'>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Category:</label>
                                    <div className="font-size-14">{document.documentCategory ? document.documentCategory.lookupValue : ''}</div>
                                </span>                                
                                </GridItem>
                            */}
                            <GridItem sm={3} className='mt-3'>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Uploaded by:</label>
                                    <div className="font-size-14">{document.user ? document.user.lookupValue : ''}</div>
                                </span>                                
                            </GridItem> 
                            
                            <GridItem sm={9} className='mt-3'>
                                    <span className='k-textbox-container'>
                                    <label className='ro-label'>Comments:</label>
                                    <div className="font-size-14">
                                        {document.comments}
                                    </div>
                                </span>                                
                            </GridItem>
                            <GridItem sm={3} className='mt-3'>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Document file:</label>
                                </span>                                
                            </GridItem> 
                            <GridItem sm={9} className='mt-3'>
                                <span className='k-textbox-container'>
                                    <div className="font-size-14">
                                        <span className='k-link'
                                            title={document.filename}
                                            onClick={(ev) => {
                                                download(document);
                                            }}>
                                                {document.filename}
                                        </span>
                                    </div>
                                </span>                                
                            </GridItem>
                        </>
                    )}
                </GridContainer>
            </form>
        </Dialog>
    )
}

export default withSnackbar(DocumentEditor);