import React from 'react';
import {Popup} from "@progress/kendo-react-popup";

export default class KendoGridHtmlCell extends React.Component {
    anchor = null;
    constructor(props) {
        super(props);
        this.state = { show: false };
    }
    render() {
        const value = "<div style='height: 34px;padding-top: 5px;'>"+this.props.dataItem[this.props.field]+"</div>";

        return (
            <>
                <td 
                    dangerouslySetInnerHTML={{ __html: value }} 
                    onClick={this.props.onClick} 
                    ref={(cell) => { this.anchor = cell; }} 
                    onMouseOver={() => this.setState({ show: true })}
                    onMouseOut={() => this.setState({ show: false })}
                >
                    
                </td>
                <Popup
                    anchor={this.anchor}
                    show={this.state.show}
                    popupClass={'popup-content'}
                    style={{maxWidth: '60%'}}
                >
                    <span dangerouslySetInnerHTML={{ __html: this.props.dataItem[this.props.field] }} />
                </Popup>
            </>
        );
    }
}