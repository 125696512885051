import React, { useState } from 'react';
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";

import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";

const RelatedAccountsEditor = (props) => {
    const itemData = props.stageData;
    let disabled = !props.editMode;
    
    const handleAccountSelected = (accountItem) => {

        let matterAccounts = itemData.stage.matterAccounts.slice();
        var index = matterAccounts.findIndex(v => v.accountId === accountItem.accountId);

        matterAccounts.splice(index, 1, {
            ...accountItem,
            noticeSelected: !accountItem.noticeSelected
        });

        let updatedData = {
            ...itemData,
            stage: {
                ...itemData.stage,
                matterAccounts: matterAccounts,
            },
        }

        props.updateRelatedAccounts(updatedData);
    }

    return (
        <GridItem sm={12}>
            <h5 className='related-title'>Related Account(s)</h5>
            <GridContainer>
                {(itemData.stage && itemData.stage.matterAccounts && itemData.stage.matterAccounts.length > 0) ? itemData.stage.matterAccounts.map(a => {

                    return (
                        <GridItem key={`griditem-${a.accountId}`} sm={4}>
                            <Card key={`card-${a.accountId}`} className='related-card-wrapper'>
                                <CardHeader className='related-card-header'>
                                    <h6>Account</h6>
                                    <input type="checkbox" className='k-checkbox' checked={!!a.noticeSelected} onChange={() => handleAccountSelected(a)} name={`accountchecked-${a.accountId}`} id={`accountchecked-${a.accountId}`} disabled={disabled} />
                                </CardHeader>
                                <CardBody className='related-card-body'>                                    
                                    <p>{a.accountNumber}</p>
                                </CardBody>
                            </Card>
                        </GridItem>
                    );
                }) : <p>No data</p>}
            </GridContainer>
            <p style={{ marginBottom: '5px'}}>&nbsp;</p>
        </GridItem>
        );
}
export default RelatedAccountsEditor;