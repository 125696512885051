import React, { useState, useEffect } from 'react';
import { findDOMNode } from "react-dom";
import { NavLink } from "react-router-dom";
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { DatePicker, DateTimePicker } from '@progress/kendo-react-dateinputs';
import { Button } from '@progress/kendo-react-buttons';
import { filterBy } from '@progress/kendo-data-query';
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import Dialog from "../common/Dialog";
import { Autorenew } from "@material-ui/icons";
import api from "../../util/api";
import { requiresRole, policies } from '../../util/accessControl';
import handleErrors from '../../util/handleErrors';
import _ from "lodash";
import { withSnackbar } from 'notistack';
import moment from "moment";

const TaskEditor = (props) => {
    const [errors, setErrors] = useState({});
    const originalItem = {
        ...props.dataItem,
        dueDate: (props.dataItem.dueDate == null || props.dataItem.dueDate === "") ? null : moment(props.dataItem.dueDate).toDate(),
        taskDate: (props.dataItem.taskDate == null || props.dataItem.taskDate === "") ? moment().toDate() : moment(props.dataItem.taskDate).toDate(),
        creator: (props.dataItem.creator == null || props.dataItem.creator === "") ? localStorage.getItem('Llama.username') : props.dataItem.creator,
    };
    const [originalTask, setOriginalTask] = useState(originalItem);
    const [task, setTask] = useState(originalItem);
    
    const initLookupObject = { loading: false, list: [] };

    const [ taskStatuses, setTaskStatuses ] = useState(initLookupObject);
    const [ taskPriorities, setTaskPriorities ] = useState(initLookupObject);
    const [ users, setUsers ] = useState(initLookupObject);
    const [allUsers, setAllUsers] = useState(initLookupObject);
    const [ weekDays, setWeekDays] = useState(initLookupObject);
    const [ weekMonths, setWeekMonths] = useState(initLookupObject);
    const [ recurringTypes, setRecurringTypes] = useState(initLookupObject);
    const [ editMode, setEditMode] = useState(!!props.editMode);

    const setLookup = (setFunc) => (json) => {
        setFunc({ loading: false, list: json });
        return json;
    }

    useEffect(() => {
        const axiosConfig = { accept: 'application/json' };
        const loading = { loading: true, list: [] };
        setTaskStatuses(loading); 
        setTaskPriorities(loading);
        setUsers(loading);

        let onLoadTasks = [
            api.get(`/lookup/taskstatus`, axiosConfig).then(setLookup(setTaskStatuses)), // Load Task Statuses
            api.get(`/lookup/taskpriority`, axiosConfig).then(setLookup(setTaskPriorities)), // Load Task Priorities
            api.get(`/lookup/recurringtype`, axiosConfig).then(setLookup(setRecurringTypes)), // Load Recurring Types
            api.get(`/lookup/weekday`, axiosConfig).then(setLookup(setWeekDays)), // Load Weekdays
            api.get(`/lookup/weekofmonth`, axiosConfig).then(setLookup(setWeekMonths)), // Load Week Of Month
            api.get(`/lookup/user`, axiosConfig).then((json) => {
                //setLookup(setUsers);
                setUsers({ loading: false, list: json });
                setAllUsers({ loading: false, list: json });
                return json;
            }), // Load Users            
        ];
        if (task.matterId && task.matterId > 0) {
            onLoadTasks.push(api.get(`/matter/${task.matterId ?? 0}`, axiosConfig));
        }
        if (task && task.user && !task.viewed && localStorage.getItem('Llama.username') === task.user.identifier) {
            onLoadTasks.push(api.post(`/task/isviewed/${task.taskId}`, axiosConfig))
        }

        Promise.all(onLoadTasks)
        .then(responses => {
            
            let tempTask = { ...task };
              
            let statusItem = tempTask.taskStatus;
            if (tempTask.taskStatusId && tempTask.taskStatusId > 0) {
                statusItem = responses[0].find(v => v.lookupId === tempTask.taskStatusId);
            } else {
                statusItem = responses[0].find(v => v.lookupValue === "Submitted");                
            }
            let priorityItem = tempTask.taskPriority;
            if (tempTask.taskPriorityId && tempTask.taskPriorityId > 0) {
                priorityItem = responses[1].find(v => v.lookupId === tempTask.taskPriorityId);
            }

            let recurringItem = tempTask.recurringType;
            if (tempTask.recurringTypeId && tempTask.recurringTypeId > 0) {
                recurringItem = responses[2].find(v => v.lookupId === tempTask.recurringTypeId);
            }

            let recurringWeekDay = tempTask.weekDay;
            if (tempTask.recurringMonthlyOnDayTypeId && tempTask.recurringMonthlyOnDayTypeId > 0) {
                recurringWeekDay = responses[3].find(v => v.lookupId === tempTask.recurringMonthlyOnDayTypeId);
            }

            let recurringWeekOfMonth = tempTask.weekOfMonth;
            if (tempTask.RecurringMonthlyOnDayCountId && tempTask.RecurringMonthlyOnDayCountId > 0) {
                recurringWeekOfMonth = responses[4].find(v => v.lookupId === tempTask.RecurringMonthlyOnDayCountId);
            }

            let userItem = tempTask.user;
            if (tempTask.userId && tempTask.userId > 0) {
                userItem = responses[5].find(v => v.lookupId === tempTask.userId);
            }

            let creatorDetail = responses[5].find(v => v.identifier === tempTask.creator.toLowerCase());
            let creatorName = (creatorDetail && creatorDetail.lookupValue) ? creatorDetail.lookupValue : tempTask.creatorName;

            tempTask = {
                ...tempTask,
                user: userItem,
                taskPriority: priorityItem,
                taskStatus: statusItem,
                taskStatusId: statusItem ? statusItem.lookupId : null,
                recurringType: recurringItem,
                weekDay: recurringWeekDay,
                weekOfMonth: recurringWeekOfMonth,
                creatorName: creatorName,
                viewed: (responses[7] ? true : tempTask.viewed)
            };
            if (task.matterId && task.matterId > 0) {
                tempTask.matter = responses[6];
            }
            setTask(tempTask);
            setOriginalTask(tempTask);
        })
        .catch(handleErrors(props))
    }, [editMode]);

    const handleInputChange = ({ value, target: { name } }) => {
        setTask({...task, [name]: value });
    }
    const handleSelectChange = ({ value, target: { name } }) => {
        let idField = `${name}Id`;
        
        if (`${name}` === 'weekOfMonth') { idField = 'recurringMonthlyOnDayCountId'; }
        else if (`${name}` === 'weekDay') { idField = 'recurringMonthlyOnDayTypeId'; }
        let tempTask = { ...task, [name]: value, [idField]: value ? value.lookupId : null };

        setTask(tempTask);
    }
    const handleDateChange = ({ value, target: { name } }) => {
        setTask({...task, [name]: value == null ? null : moment(value).local().toDate() });
    }
    const handleCheckChange = ({ target: { name, value, checked } }) => {

        setTask({ ...task, [name]: checked });

    }
    const handleStatusChange = ({ target: { name, value, checked } }) => {
        const newStatus = _.find(taskStatuses.list, o => { return (checked && o.lookupValue === "Completed") || (!checked && o.lookupValue === "Submitted") });

        if (name === "taskStatusCheck") {
            props.patch([
                {propertyName: 'TaskStatusId', propertyValue: newStatus.lookupId}, 
                {propertyName: 'CompletedDate', propertyValue: newStatus.lookupValue === "Completed" ? moment().toDate() : null}
            ], task.taskId); 
            
        }

        setTask({ ...task, taskStatus: newStatus, taskStatusId: newStatus ? newStatus.lookupId : null });
    }
    const handleRecurringMonthlyTypeChange = ({ target: { name, value, checked } }) => {
        setTask({ ...task, recurringMonthlyOnMonthDate: value === "onMonthDate", recurringMonthlyOnDay: value === "onDay" });
    }
    const validateForm = () => {
        var validForm = true;
        var formErrors = {};
        formErrors.title = (!task.title || task.title === '');
        formErrors.taskDate = (!task.taskDate || task.taskDate === '');

        setErrors(formErrors);

        const item = Object.assign({}, formErrors)
        validForm = !Object.keys(item).some(x => item[x]);

        return validForm;
    }

    const user = localStorage.getItem('Llama.username');
    const isCreator = user === task.creator || task.taskId === 0;
    const isAssignee = task.user ? user === task.user.identifier : false;
    console.log('user: ', user, isCreator, isAssignee, task, props.type);

    let timeout;
    const filterChange = (event) => {

        clearTimeout(timeout);
        timeout = setTimeout(() => {
            const data = allUsers.list.slice();
            const filtered = filterBy(data, event.filter);
            console.log('filterChange: ', event, data, filtered);
            setUsers({ list: filtered, loading: false });
        }, 200);
        setUsers({ list: users.list, loading: true });

    }

    return (
        <Dialog 
            validate={validateForm}
            original={originalTask}
            current={task}
            saveChanges={props.save}
            cancelChanges={props.cancel}
            inEditMode={editMode}
            width={800} 
            createMode={task.taskId === 0}
            deleteItem={props.delete}
            deleteItemMessage="Are you sure you want to delete this Task?"
            title={
                <>
                    {`${task.taskId === 0 ? 'Add' : editMode ? 'Edit' : 'View'} Task`}
                    {requiresRole(<Button className='rounded-circle ml-2' primary={true} look="bare" icon={editMode ? 'lock' : 'edit'} onClick={(ev) => setEditMode(!editMode)} />, policies.edit)}
                </>}
        >
            <form onSubmit={(ev) => ev.preventDefault()}>
                <GridContainer>

                    <GridItem sm={9}>
                        {props.hyperlink 
                        ? <div className="font-size-14">{task.matter ? <NavLink to={`/files/${task.matter.matterId}`}>{task.matter.matterName} | {task.matter.matterNumber} | {task.matter.clientReference}</NavLink> : 'No Matter'}</div>
                        : <div className="font-size-14">{task.matter ? `${task.matter.matterName} | ${task.matter.matterNumber} | ${task.matter.clientReference}` : 'No Matter'}</div>}
                        
                    </GridItem>

                    {editMode ? (
                    <>
                        <GridItem sm={3} className='d-flex justify-content-end'>
                            <div className="font-size-14">{task.taskDate ? moment(task.taskDate).format('DD/MM/YYYY') : ''}</div>
                        </GridItem>
                        <GridItem sm={6} className='mt-3'>
                            <ComboBox
                                onFocus={(ev) => { if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click() }}
                                data={users.list}
                                loading={users.loading}
                                textField='lookupValue'
                                dataItemKey='lookupId'
                                value={task.user}
                                onChange={handleSelectChange}
                                label="To"
                                name='user'
                                id='user'
                                className='disabled'
                                disabled={!isCreator}
                                filterable={true}
                                onFilterChange={filterChange}
                            />
                        </GridItem>
                        <GridItem sm={6} className='mt-3'>
                            <span className='k-textbox-container'>
                                <div className="mt-2 font-size-14">{task.creatorName}</div>
                                <label className='k-label'>From</label>
                            </span>
                        </GridItem>
                        <GridItem sm={12} className='mt-3'>
                            <Input
                                width='100%'
                                label='Task'
                                value={task.title}
                                onChange={handleInputChange}
                                name="title"
                                id="title"
                                required={true}
                                disabled={!isCreator}
                            />
                            {errors.title && <span className='text-danger'>Title is Required.</span>}
                        </GridItem>
                        <GridItem sm={6} className='mt-3'>
                            <span className='k-textbox-container'>
                                <label className='k-label'>Due Date</label>
                                    <DatePicker
                                    value={task.dueDate}
                                    defaultValue={null}
                                    onChange={handleDateChange}
                                    format='dd/MM/yyyy'
                                    formatPlaceholder="formatPattern"
                                    width='100%'
                                    label="Due Date"
                                    name="dueDate"
                                    id="dueDate"
                                    disabled={!isCreator}
                                />
                            </span>
                        </GridItem>

                        {task && task.taskId > 0 && <><GridItem sm={6} className='mt-3'>
                            <ComboBox onFocus={(ev) => {if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click()}} 
                                data={taskStatuses.list}
                                loading={taskStatuses.loading}
                                textField='lookupValue'
                                dataItemKey='lookupId'
                                value={task.taskStatus}
                                onChange={handleSelectChange}
                                label="Status"
                                name='taskStatus'
                                id='taskStatus'
                                disabled={!isAssignee && !isCreator}
                            />
                        </GridItem>
                        <GridItem sm={6} /></>}

                            {/*
                        <GridItem sm={12} className='mt-3'>
                            <input type="checkbox" className='k-checkbox' checked={task.recurring} onClick={handleCheckChange} name="recurring" id="recurring" />
                            <label className="k-checkbox-label" htmlFor="recurring">Recurring</label>
                        </GridItem>
                            
                        {task.recurring && <>
                            <GridItem sm={6} className='mt-4'>
                                <ComboBox onFocus={(ev) => { if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click() }}
                                    data={recurringTypes.list}
                                    loading={recurringTypes.loading}
                                    textField='lookupValue'
                                    dataItemKey='lookupId'
                                    value={task.recurringType}
                                    onChange={handleSelectChange}
                                    label="Recurring Type"
                                    name='recurringType'
                                    id='recurringType'
                                    //disabled={!isCreator}
                                />
                            </GridItem>
                            <GridItem sm={6} />
                        </>}

                        {task.recurring && task.recurringType && task.recurringType.lookupValue && task.recurringType.lookupValue === "Weekly" && <>
                            <GridItem sm={12} className='mt-3'>
                                <label className='ro-label mr-2'>Recur every</label>
                                <NumericTextBox
                                    width='120px'
                                    value={task.recurringEveryWeek}
                                    onChange={handleInputChange}
                                    format="n0"
                                    min={1}
                                    max={52}
                                    spinners={true}
                                    name="recurringEveryWeek"
                                    id="recurringEveryWeek"
                                    required={false}
                                />
                                <label className='ro-label ml-2'>week(s) on:</label>
                            </GridItem>

                            <GridItem sm={2} className='mt-4'>
                                <input type="checkbox" className='k-checkbox' defaultChecked={task.recurringMonday} onClick={handleCheckChange} name="recurringMonday" id="recurringMonday" />
                                <label className="k-checkbox-label" htmlFor="recurringMonday">Monday</label>
                            </GridItem>

                            <GridItem sm={2} className='mt-4'>
                                <input type="checkbox" className='k-checkbox' defaultChecked={task.recurringTuesday} onClick={handleCheckChange} name="recurringTuesday" id="recurringTuesday" />
                                <label className="k-checkbox-label" htmlFor="recurringMonday">Tuesday</label>
                            </GridItem>

                            <GridItem sm={2} className='mt-4'>
                                <input type="checkbox" className='k-checkbox' defaultChecked={task.recurringWednesday} onClick={handleCheckChange} name="recurringWednesday" id="recurringWednesday" />
                                <label className="k-checkbox-label" htmlFor="recurringWednesday">Wednesday</label>
                            </GridItem>

                            <GridItem sm={2} className='mt-4'>
                                <input type="checkbox" className='k-checkbox' defaultChecked={task.recurringThursday} onClick={handleCheckChange} name="recurringThursday" id="recurringThursday" />
                                <label className="k-checkbox-label" htmlFor="recurringThursday">Thursday</label>
                            </GridItem>

                            <GridItem sm={2} className='mt-4'>
                                <input type="checkbox" className='k-checkbox' defaultChecked={task.recurringFriday} onClick={handleCheckChange} name="recurringFriday" id="recurringFriday" />
                                <label className="k-checkbox-label" htmlFor="recurringFriday">Friday</label>
                            </GridItem>
                            </>}

                        {task.recurring && task.recurringType && task.recurringType.lookupValue && task.recurringType.lookupValue === "Monthly" && <>
                            <GridItem sm={12} className='mt-3'>
                                <input type="radio" className='k-radio' name="recurringMonthlyType" value='onDay' checked={task.recurringMonthlyOnDay} onClick={handleRecurringMonthlyTypeChange} />
                                <label className='ro-label mx-2'>Recur on day</label>
                                <NumericTextBox
                                    width='100px'
                                    value={task.recurringMonthlyOnDayDays}
                                    onChange={handleInputChange}
                                    format="n0"
                                    min={1}
                                    max={31}
                                    spinners={true}
                                    name="recurringMonthlyOnDayDays"
                                    id="recurringMonthlyOnDayDays"
                                    required={false}
                                />
                                <label className='ro-label mx-2'>of every</label>
                                <NumericTextBox
                                    width='100px'
                                    value={task.recurringMonthlyOnDayMonth}
                                    onChange={handleInputChange}
                                    format="n0"
                                    min={1}
                                    max={12}
                                    spinners={true}
                                    name="recurringMonthlyOnDayMonth"
                                    id="recurringMonthlyOnDayMonth"
                                    required={false}
                                />
                                <label className='ro-label ml-2'>month(s)</label>
                            </GridItem>
                            <GridItem sm={12} className='mt-3'>
                                
                                <input type="radio" className='k-radio' name="recurringMonthlyType" value='onMonthDate' checked={task.recurringMonthlyOnMonthDate} onClick={handleRecurringMonthlyTypeChange} />
                                <label className='ro-label mx-2'>The</label>
                                <ComboBox onFocus={(ev) => { if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click() }}
                                    width='120px'
                                    style={{width: '120px'}}
                                    data={weekMonths.list}
                                    loading={weekMonths.loading}
                                    textField='lookupValue'
                                    dataItemKey='lookupId'
                                    value={task.weekOfMonth}
                                    onChange={handleSelectChange}
                                    name='weekOfMonth'
                                    id='weekOfMonth'
                                    //disabled={!isCreator}
                                />
                                <ComboBox onFocus={(ev) => { if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click() }}
                                    width='120px'
                                    style={{width: '150px'}}
                                    className='ml-2'
                                    data={weekDays.list}
                                    loading={weekDays.loading}
                                    textField='lookupValue'
                                    dataItemKey='lookupId'
                                    value={task.weekDay}
                                    onChange={handleSelectChange}
                                    name='weekDay'
                                    id='weekDay'
                                    //disabled={!isCreator}
                                />
                                <label className='ro-label mx-2'>of every</label>
                                <NumericTextBox
                                    width='100px'
                                    value={task.recurringMonthlyOnMonthCount}
                                    onChange={handleInputChange}
                                    format="n0"
                                    min={1}
                                    max={12}
                                    spinners={true}
                                    name="recurringMonthlyOnMonthCount"
                                    id="recurringMonthlyOnMonthCount"
                                    required={false}
                                />
                                <label className='ro-label ml-2'>month(s)</label>
                            </GridItem>
                            </>}
                            */}
                    </>
                    ) : (
                    <>
                        <GridItem sm={3}></GridItem>
                        <GridItem sm={3} className='mt-3'>
                            <span className='k-textbox-container'>
                                <label className='ro-label'>Task:</label>
                            </span>                                
                        </GridItem> 
                        <GridItem sm={9} className='mt-3'>
                            <span className='k-textbox-container'>
                            <div className="font-size-14" dangerouslySetInnerHTML={{ __html: task.title }}></div>
                            </span>                                
                        </GridItem>
                        <GridItem sm={3} className='mt-3'>
                            <span className='k-textbox-container'>
                                <label className='ro-label'>Date sent:</label>
                                <div className="font-size-14">{task.createdOn ? moment(task.createdOn).format('DD/MM/YYYY') : ''}</div>
                            </span>
                        </GridItem>    
                        <GridItem sm={3} className='mt-3'>
                            <span className='k-textbox-container'>
                                <label className='ro-label'>Due date:</label>
                                <div className="font-size-14">{task.dueDate ? moment(task.dueDate).format('DD/MM/YYYY') : ''}</div>
                            </span>
                        </GridItem> 
                        <GridItem sm={3} className='mt-3'>
                            <span className='k-textbox-container'>
                                <label className='ro-label'>To be completed by:</label>
                                <div className="font-size-14">{task.user ? task.user.lookupValue : ''}</div>
                            </span>                                
                        </GridItem>
                        <GridItem sm={3} className='mt-3'>
                            <span className='k-textbox-container'>
                                <label className='ro-label'>Assigned by:</label>
                                <div className="font-size-14">{task.creatorName}</div>
                            </span>                                
                        </GridItem>
                        <GridItem sm={8} className='mt-3 position-relative'>
                            {task.recurring && <><div className="position-absolute" style={{ top: '50%', transform: 'translateY(-50%)' }}><Autorenew /></div><div style={{marginLeft: '30px'}}><label className='ro-label font-weight-normal'>{task.recurrencePattern}</label></div></>}
                        </GridItem>
                        {requiresRole(<GridItem sm={4} className='mt-3'>
                            <input type="checkbox" className='k-checkbox' onClick={handleStatusChange} name="taskStatusCheck" id="taskStatusCheck" checked={task.taskStatus && task.taskStatus.lookupValue === "Completed"} />
                            <label className="k-checkbox-label" htmlFor="taskStatusCheck">MARK AS COMPLETE</label>
                        </GridItem>, props.type === 'mine' ? policies.edit : props.type === 'their' ? policies.client : null)}
                    </>
                    )}

                </GridContainer>
            </form>
        </Dialog>
    )
}

export default withSnackbar(TaskEditor);