import React, { useState } from 'react';
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";

import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import AddressFormatter from "../../util/formatAddress";

const RelatedSecuritiesEditor = (props) => {
    const itemData = props.stageData;
    let disabled = !props.editMode;

    const handleSecuritySelected = (securityItem) => {
        let matterSecurities = itemData.stage.matterSecurities.slice();
        var index = matterSecurities.findIndex(v => v.securityId === securityItem.securityId);
        matterSecurities.splice(index, 1, {
            ...securityItem,
            noticeSelected: !securityItem.noticeSelected
        });
        let updatedData = {
            ...itemData,
            stage: {
                ...itemData.stage,
                matterSecurities: matterSecurities,
            },
        }
        props.updateRelatedSecurities(updatedData);
    }
    
    return (
        <GridItem sm={12}>
            <h5 className='related-title'>Related Security Properties</h5>
            <GridContainer>
                {(itemData.stage.matterSecurities && itemData.stage.matterSecurities.length > 0) ? itemData.stage.matterSecurities.map(a => {
                    const { address } = AddressFormatter.format(a);
                    return (
                        <GridItem key={`griditem-${a.securityId}`} sm={4}>
                            <Card key={`card-${a.securityId}`} className='related-card-wrapper'>
                                
                                <CardHeader className='related-card-header'>
                                    <h6>Property</h6>
                                    <input type="checkbox" className='k-checkbox float-right' checked={!!a.noticeSelected} onChange={() => handleSecuritySelected(a)} name={`securitychecked-${a.securityId}`} id={`securitychecked-${a.securityId}`} disabled={disabled} />
                                </CardHeader>
                                <CardBody className='related-card-body'>
                                    <p>{address}</p>
                                </CardBody>
                            </Card>
                        </GridItem>
                    );
                }) : <p>No data</p>}
            </GridContainer>
            <p style={{ marginBottom: '5px' }}>&nbsp;</p>
        </GridItem>
    );
}

export default RelatedSecuritiesEditor;