import React from 'react';

export default class KendoGridArrayCell extends React.Component {
    render() {
        const value = this.props.dataItem[this.props.field];
        return (
            <td style={{whiteSpace:'pre'}}>
                {value == null ? null : value.map(m => m.lookupValue).join('\r\n')}
            </td>
        );
    }
}