import React, { useState } from 'react';
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from '../common/MuiKit/Card/CardBody';

import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";

const RelatedPartyAddressesEditor = (props) => {
    const itemData = props.stageData;
    let disabled = !props.editMode;

    const handlePartyAddressSelected = (partyAddress) => {
        let isChecked = !partyAddress.noticeSelected;

        let matterParties = itemData.stage.matterParties.slice();
        let partyIndex = matterParties.findIndex(v => v.partyId === partyAddress.partyId);
        let matterParty = matterParties[partyIndex];
        let party = matterParty.party

        let partyAddresses = party.partyAddresses.slice();
        let partyAddressIndex = partyAddresses.findIndex(v => v.partyAddressId === partyAddress.partyAddressId);
        partyAddresses.splice(partyAddressIndex, 1, { ...partyAddress, noticeSelected: isChecked });
        matterParties.splice(partyIndex, 1, { ...matterParty, party: { ...party, partyAddresses: partyAddresses } });

        let courtActionAddresses = itemData.courtActionAddresses.slice();
        if (isChecked) {
            courtActionAddresses.push({ stageId: itemData.stageId, partyAddressId: partyAddress.partyAddressId });
        } else {
            let courtActionAddressIndex = courtActionAddresses.findIndex(v => v.partyAddressId === partyAddress.partyAddressId);
            courtActionAddresses.splice(courtActionAddressIndex, 1);
        }

        let updatedData = ({
            ...itemData,
            stage: {
                ...itemData.stage,
                matterParties: matterParties,
            },
            courtActionAddresses: courtActionAddresses,
        });

        props.updateRelatedPartyAddresses(updatedData);
    }

    return (
        <GridItem sm={12}>
            <h5 className='related-title'>Posted to</h5>
            <GridContainer>
                {(itemData.stage.matterParties && itemData.stage.matterParties.length > 0) ? itemData.stage.matterParties.map(a => {
                    let partyAddresses = a.party.partyAddresses ? a.party.partyAddresses.slice() : [];
                    return (
                        <GridItem key={`griditemaddress-${a.partyId}`} sm={4}>
                            <Card key={`cardaddress-${a.partyId}`} className='related-card-wrapper'>
                                <CardHeader className='related-card-header'>
                                    <h6>Party: {a.party.firstName} {a.party.lastName}</h6>
                                </CardHeader>
                                <CardBody className='related-card-body'>
                                    {partyAddresses.length > 0 && <GridContainer key={`gridcontaineraddress-${a.partyId}`}>
                                        {partyAddresses.map(b => {
                                            const address = [
                                                b.addressName,
                                                b.addressUnitNumber,
                                                b.addressStreetNumber,
                                                b.addressStreetName,
                                                b.addressSuburb,
                                                b.addressState,
                                                b.addressPostCode].joinNonEmpty(" ");

                                            return (
                                                <GridItem key={`griditemaddress-${b.partyAddressId}`} sm={12}>
                                                    <input type="checkbox" className='k-checkbox' checked={!!b.noticeSelected} onChange={() => handlePartyAddressSelected(b)} name={`partyaddress-${b.partyAddressId}`} id={`partyaddress-${b.partyAddressId}`} disabled={disabled} />
                                                    <label className="k-checkbox-label" htmlFor={`partyaddress-${b.partyAddressId}`}>{address}</label>
                                                </GridItem>
                                            );
                                        })}
                                    </GridContainer>}
                                    {partyAddresses.length === 0 && <label className="k-checkbox-label">No data</label>}
                                </CardBody>
                            </Card>
                        </GridItem>
                    );
                }) : <p>No data</p>}
            </GridContainer>
            <p style={{ marginBottom: '5px' }}>&nbsp;</p>
        </GridItem>
        );
}
export default RelatedPartyAddressesEditor;