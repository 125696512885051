import api from "./api";
import _ from "lodash";
import Mustache from "mustache";

class AddressFormatter {
    addressTypes = [];
    format = (address, separator = '\r\n') => {
        let addrType = {};

        if (!address) {
            return {
                type: 'None',
                address: ''
            }
        }

        if (address && address.securityId > 0) {
            addrType = { ..._.find(this.addressTypes, (o) => o.addressType === 'Security Address') };
        }
        else {
            addrType = { ..._.find(this.addressTypes, (o) => o.lookupId === address.addressTypeId || (!address.addressTypeId && o.addressType === 'Address')) };
        }
        if (addrType.formatString) {
            return {
                type: addrType.addressType,
                address: Mustache.render(addrType.formatString.join(separator), address)
            };            
        } 
        else {
            return {
                type: 'None',
                address: address.addressName
            }
        }
    }
    configure = () => {
        api.get(`/party/addresstypes`, {
            accept: 'application/json',
        }) 
        .then(json => {
            this.addressTypes = json.data;
            
            return
        })
        .catch((errors) => {})
    }
}

const formatter = new AddressFormatter();

formatter.configure();

export default formatter;
