import React, { useContext } from 'react';
import llamaLogo from "../../assets/img/JacMac Llama Logo.png";
import jacmacLogoFull from "../../assets/img/Jacmac with text.png";
import { ProfileContext } from '../../store';
import { withRouter } from "react-router-dom";
import { requiresRole, policies } from '../../../util/accessControl'

const GenericHeader = (props) => {
    const { state, dispatch } = useContext(ProfileContext);
    return (
        <div className='d-flex flex-row align-items-center' style={{ position: 'absolute', top: '0', left: '0', right: '0', padding: '15px 30px', backgroundColor: '#fff' }}>
            <img alt='' src={llamaLogo} className='img-fluid' style={{ height: '35px', paddingRight: '15px' }} />
            {props.match.params.id && <h4 dangerouslySetInnerHTML={{__html: state.title}}></h4>}
            {props.match && props.match.path === '/files/:id' && requiresRole(<button
                    title='Edit'
                    className='k-button k-bare k-primary k-button-icon ml-2 mb-1'
                    onClick={props.onClick}
                    //ref={overviewRef}
                >
                    <span className="k-icon k-i-pencil"></span>
                </button>, policies.edit)}
            <img alt='' src={jacmacLogoFull} className='img-fluid ml-auto' style={{ height: '55px' }} />
        </div>
    );
}

export default withRouter(GenericHeader);