import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import api from "../../util/api";
import handleErrors from '../../util/handleErrors';
import { requiresRole, policies } from '../../util/accessControl'

import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { formatDate } from '@telerik/kendo-intl';

import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import LoadingPanel from '../common/Grid/LoadingPanel';

import LookupEditor from "./LookupEditor";

import { withSnackbar } from 'notistack';

const Lookups = (props) => {
    const initLookupObject = { loading: false, list: [] };
    const defaultType = props.match.params.lookuptype;
    const [lookupTypes, setLookupTypes] = useState(initLookupObject);
    const [lookupValues, setLookupValues] = useState(initLookupObject);
    const [selectedType, setSelecteType] = useState({ name: defaultType });
    const [itemInEdit, setItemInEdit] = useState(null);

    const edit = (dataItem) => {
        const item = Object.assign({}, dataItem)
        Object.keys(item).forEach(key => { if (item[key] == null) { item[key] = '' } })
        setItemInEdit(item);
    };

    const insert = () => {
        setItemInEdit({ lookupId: 0, lookupValue: '', lookupType: selectedType.name });
    }

    const save = (dataItem) => {

        api.request({
            url: `/lookup`,
            method: dataItem.lookupId === 0 ? 'POST' : 'PUT',
            accept: 'application/json',
            headers: { 'Content-Type': 'application/json' },
            data: JSON.stringify(dataItem)
        })
        .then(json => {
            //afterSave(json);
        })
        .catch(handleErrors(props))
        .finally(() => {
            setItemInEdit(null);
            let newType = selectedType;
            setSelecteType({ name : newType.name });
        })
    }

    const cancel = () => {
        setItemInEdit(null);
    }

    const deleteItem = (dataItem) => {
        

        // api.delete(`/lookup/${dataItem.lookupId}`, {
        //     accept: 'application/json',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     }
        // }) 
        // .then(json => {
        //     setItemInEdit(null);
        //     let newType = selectedType;
        //     setSelecteType({ name : newType.name });
        // })
        // .catch(handleErrors(props))
    };

    useEffect(() => {
        const axiosConfig = { accept: 'application/json' };
        let onLoadTasks = [];

        if (lookupTypes.list.length == 0) {
            onLoadTasks.push(api.get(`/lookup/lookuptypes`, axiosConfig));
        }

        if (selectedType && selectedType.name) {
            onLoadTasks.push(api.get(`/lookup/${selectedType.name}/1000`, axiosConfig));
        };

        Promise.all(onLoadTasks)
            .then(responses => {
                let nextIndex = 0;

                if (lookupTypes.list.length == 0) {
                    let lookupType = { loading: false, list: responses[nextIndex] };
                    nextIndex += 1;
                    setLookupTypes(lookupType);
                }

                if (selectedType && selectedType.name) {
                    setLookupValues({ loading: false, list: responses[nextIndex] });
                    nextIndex += 1;
                }
            })
            .catch(handleErrors(props))

    }, [selectedType]);

    return (
        <GridContainer>
            <GridItem md={4} xs={12}>
                <Grid
                    onRowClick={(e) => {
                        setSelecteType(e.dataItem);
                    }}
                    className='clickableRow'
                    selectedField="selected"
                    style={{height: props.cardHeight}}
                    data={lookupTypes.list.map(type => ({ ...type, selected: type.name === selectedType.name }))}
                >
                    <GridToolbar>
                        
                        {/* {requiresRole(<button
                            title='Add'
                            className='k-button k-primary k-button-icon'
                            onClick={() => {
                                
                            }}
                        >
                            <span className="k-icon k-i-plus"></span>
                        </button>, policies.admin)} */}
                       
                    </GridToolbar>
                    <GridColumn field='name' title='Lookup type'></GridColumn>
                </Grid>
                {lookupTypes.loading && <LoadingPanel />}
            </GridItem>
            <GridItem md={8} xs={12}>
                <Grid
                    style={{height: props.cardHeight}}
                    data={lookupValues.list}
                    onRowClick={(e) => {
                        edit(e.dataItem);
                    }}
                >
                    <GridToolbar>
                        {selectedType && selectedType.name && requiresRole(<button
                            title='Add'
                            className='k-button k-primary k-button-icon'
                            onClick={() => {
                                if (selectedType && selectedType.name) {
                                    insert();
                                }
                            }}
                        >
                            <span className="k-icon k-i-plus"></span>
                        </button>, policies.admin)}
                    </GridToolbar>
                    <GridColumn field='lookupValue' title={selectedType.name ? `${selectedType.name} values` : 'Please select a lookup type'}></GridColumn>
                </Grid>
                {lookupValues.loading && <LoadingPanel />}
            </GridItem>
            {itemInEdit && <LookupEditor dataItem={itemInEdit} save={save} cancel={cancel} editMode={true} delete={deleteItem} />}
        </GridContainer>
    );

}
export default withRouter(withSnackbar(Lookups));