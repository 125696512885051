import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Row } from 'reactstrap';

const style = {
  grid: {
    margin: "0 -15px",
    width: "calc(100% + 30px)"
    // '&:before,&:after':{
    //   display: 'table',
    //   content: '" "',
    // },
    // '&:after':{
    //   clear: 'both',
    // }
  }
};

function GridContainer({ ...props }) {
  const { classes, children, className, noGutters, ...rest } = props;
  // return (
  //   <Grid container {...rest} className={classes.grid + " " + className}>
  //     {children}
  //   </Grid>
  // );
  return (
    // <Container fluid {...rest} className={className}>
      <Row {...rest} className={className} noGutters={noGutters}>
        {children}
      </Row>
    // </Container>
  );
}

export default withStyles(style)(GridContainer);
