import React, { useReducer } from "react";

export const profileState = {
    avatar: localStorage.getItem('Llama.avatar'),
    name: localStorage.getItem('Llama.displayName'),
    title: '&nbsp;',
    manager: null,
    stage: null
}
// export const matterState = {
//     managerId: 0,
//     stageId: 0
// }

export const profileReducer = (profile, action) => {    
    switch (action.type) {
        case 'setProfileAvatar':
            return {
                ...profile,
                avatar: action.payload
            }
        case 'setProfile':
            return {
                ...action.payload
            }            
        case 'setProfileName':
            return {
                ...profile,
                name: action.payload
            }            
        case 'setTitle':
            return {
                ...profile,
                title: action.payload
            }
        case 'setManager':
            return {
                ...profile,
                manager: action.payload
            }
        case 'setStage':
            return {
                ...profile,
                stage: action.payload
            }
        default:
            return {
                ...profile
            }
    }
}
// export const matterReducer = (filter, action) => {
//     switch (action.type) {
//         case 'setManager':
//             return {
//                 ...filter,
//                 managerId: action.payload
//             }
//         case 'setStage':
//             return {
//                 ...filter,
//                 stageId: action.payload
//             }
//         default:
//             return {
//                 ...filter
//             }
//     }
// }

export const ProfileContext = React.createContext(profileState);
//export const MatterContext = React.createContext(matterState);

export function ProfileProvider(props) {
    const [state, dispatch] = useReducer(profileReducer, profileState);
    return (
        <ProfileContext.Provider value={{ state, dispatch }}>
            {props.children}
        </ProfileContext.Provider>
    );
  }
// export function MatterProvider(props) {
//     const [state, dispatch] = useReducer(matterReducer, matterState);
//     return (
//         <MatterContext.Provider value={{ state, dispatch }}>
//             {props.children}
//         </MatterContext.Provider>
//     );
//   }