import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import EventIcon from "@material-ui/icons/Event";
// import AccountIcon from "@material-ui/icons/AccountBox";
// import PartyIcon from "@material-ui/icons/Group";
// import SecurityIcon from "@material-ui/icons/AccountBalance";
import PersonIcon from "@material-ui/icons/Person";
import AttachIcon from "@material-ui/icons/AttachFile";
import GroupIcon from "@material-ui/icons/Group";
import AssignmentIcon from '@material-ui/icons/AssignmentTurnedIn';

import Home from "./home";
//import Client from "./client";
import Matter from "./matter";
import Event from "./event";
import MatterDetail from "./matter/MatterDetail";
 import Party from "./parties";
 import Account from "./account";
 import Security from "./securities";
// import PartyListView from "./parties/PartyListView";
import Instruction from "./instruction";
import Profile from "./profile";
import Users from "./users";
import Register from "./auth/Register";
import ConfirmEmail from "./auth/ConfirmEmail";
import Lookups from "./lookup";
import Blocks from "./block";
import ActivityBlocks from "./activityBlock";
import Tasks from './task';
import Notes from './conversation';
import Documents from './document';
import Team from "./team"
import { policies, canAccess } from '../util/accessControl';


const fullPageProps = {
    cardHeight: 'calc(100vh - 150px)',
    gridHeight: 'calc(100vh - 215px)',
    pagerSettings: {
        buttonCount: 3,
        info: true,
        type: 'numeric',
        pageSizes: [5, 10, 20, 50, 100],
        previousNext: true
    }
};

const MatterComponent = (props) => <Matter {...fullPageProps} {...props} />;
const EventComponent = (props) => <Event {...fullPageProps} {...props} />;
const BlockComponent = (props) => <Blocks {...fullPageProps} {...props} />;
const ActivityBlockComponent = (props) => <ActivityBlocks {...fullPageProps} {...props} />;
const MatterDetailComponent = (props) => <MatterDetail {...fullPageProps} {...props} />;
const PartyComponent = (props) => <Party {...fullPageProps} {...props} />;
const AccountComponent = (props) => <Account {...fullPageProps} {...props} />;
const SecurityComponent = (props) => <Security {...fullPageProps} {...props} />;
// const PartyListViewComponent = (props) => <PartyListView {...fullPageProps.pagerSettings} {...props} />;
const InstructionComponent = (props) => <Instruction {...fullPageProps} {...props} />;
const ProfileComponent = (props) => <Profile {...fullPageProps} {...props} />;
const RegisterComponent = (props) => <Register {...fullPageProps} {...props} />;
const TasksComponent = (props) => <Tasks {...fullPageProps} {...props} />;
const NotesComponent = (props) => <Notes {...fullPageProps} {...props} />;
const DocumentsComponent = (props) => <Documents {...fullPageProps} {...props} />;
const LookupsComponent = (props) => <Lookups {...fullPageProps} {...props} />;
const UsersComponent = (props) => <Users {...fullPageProps} {...props} />;
const TeamComponent = (props) => <Team {...fullPageProps} {...props} />;

const ethosRoutes = [
    {
        path: '/',
        name: 'Home',
        icon: HomeIcon,
        component: Home,
        layout: '',
        redirect: true
    },
    {
        path: '/files/:id',
        name: 'Matter Details',
        icon: FolderOpenIcon,
        component: MatterDetailComponent,
        layout: '',
        redirect: true
    },
    {
        path: '/files',
        name: 'Matters',
        icon: FolderOpenIcon,
        component: MatterComponent,
        layout: ''
    },
    {
        path: '/documents',
        name: 'Library',
        icon: AttachIcon,
        component: DocumentsComponent,
        layout: ''
    },
    //{
    //    path: '/events',
    //    name: 'Calendar',
    //    icon: EventIcon,
    //    component: EventComponent,
    //    layout: ''
    //},
    {
        path: '/events/:id?',
        name: 'Calendar',
        redirect: true,
        component: EventComponent,
        layout: ''
    },
    {
        path: '/blocks/:id?',
        name: 'Blocks',
        redirect: true,
        component: BlockComponent,
        layout: ''
    },
    {
        path: '/activityBlocks/:id?',
        name: 'Activity Blocks',
        redirect: true,
        component: ActivityBlockComponent,
        layout: ''
    },
     {
         path: '/event/:id?',
         name: 'Events',
         icon: EventIcon,
         component: EventComponent,
         layout: '',
         redirect: true
     },
    {
        path: '/instruction/:id?',
        name: 'Listview',
        icon: FolderOpenIcon,
        component: InstructionComponent,
        layout: '',
        redirect: true
    },
    {
        path: '/profile/:id?',
        name: 'Profile',
        icon: PersonIcon,
        component: ProfileComponent,
        layout: '',
        redirect: true
    },
    {
        path: '/users',
        name: 'Contacts',
        icon: GroupIcon,
        component: UsersComponent,
        layout: ''
    },
    {
        path: '/register',
        name: 'Register Users',
        icon: PersonIcon,
        component: RegisterComponent,
        layout: '',
        hide: !canAccess(policies.admin)
    },
    {
        path: '/tasks/:id?',
        name: 'Tasks',
        component: TasksComponent,
        layout: '',
        redirect: true
    },
    {
        path: '/notes/:id?',
        name: 'Notes',
        component: NotesComponent,
        layout: '',
        redirect: true
    },
    {
        path: '/documents/:id',
        name: 'Documents',
        component: DocumentsComponent,
        layout: '',
        redirect: true
    },
    {
        path: '/parties/',
        name: 'Parties',
        component: PartyComponent,
        layout: '',
        redirect: true
    },
    {
        path: '/account/',
        name: 'account',
        component: AccountComponent,
        layout: '',
        redirect: true
    },
    {
        path: '/securities/',
        name: 'securities',
        component: SecurityComponent,
        layout: '',
        redirect: true
    },
    {
        path: '/lookups/:lookuptype?',
        name: 'Lookups',
        component: LookupsComponent,
        layout: '',
        redirect: true
    },
    //{
    //    path: '/team/',
    //    name: 'Team',
    //    icon: AssignmentIcon,
    //    component: TeamComponent,
    //    layout: ''
    //},
    // {
    //     path: '/confirmemail',
    //     name: 'Confirm Email',
    //     icon: PersonIcon,
    //     component: ConfirmEmailComponent,
    //     layout: '',
    //     redirect: true
    // }
];

export default ethosRoutes;