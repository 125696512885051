import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';

import { Link } from "react-router-dom";
import withRouterAndRef from '../../util/withRouterAndRef';
import api from "../../util/api";
import handleErrors from '../../util/handleErrors';
import { withSnackbar } from 'notistack';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare  } from '@fortawesome/free-regular-svg-icons';
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import LoadingPanel from '../common/Grid/LoadingPanel';
import moment from 'moment';
import _ from 'lodash';
import { DropDownList } from '@progress/kendo-react-dropdowns';

const TaskSummary = forwardRef((props, ref) => {

    const id = props.match.params.id;

    useImperativeHandle(ref, () => ({
         load
     }));
    //const [ unattendedTasks, setUnattendedTasks ] = useState(0);
    //const [ tasksLoading, setTasksLoading ] = useState(false);
    //const [ tasks, setTasks ] = useState([]);

    useEffect(() => {
        //load();
    }, []);

    const load = () => {
    //     setTasksLoading(true);
    //     api.get(`/task/${props.type}/${id}`, {
    //         accept: 'application/json',
    //     })
    //     .then(json => {
    //         setTasks(json.data);
    //         if (props.type === 'mine') {
    //             const count = _.filter(json.data, o => {
    //                 return o.pending == true;
    //             }).length;
    //             setUnattendedTasks(count);
    //         }
            
    //     })
    //     .catch(handleErrors(props))
    //     .finally(() => setTasksLoading(false));
    }
    const handleSort = ({ target: { value } }) => {
        props.load(value.replace(' ', ''));
    }
    return (
        <React.Fragment>
            <Grid
                className={`taskSummary hide-header clickableRow h-100 ${props.type === 'mine' ? 'task-top' : 'task-bottom'}`}
                data={props.tasks}
            >          
                <GridToolbar style={{padding: '4px'}}>
                
                    <span className="k-header font-weight-bold d-inline-block ml-2 mt-1" style={{}}><a className="k-link">{`${props.type === 'mine' ? 'File Manager' : 'Instructing Officer'} Tasks`}</a></span>
                    <DropDownList 
                        data={["Due Date", "Newest"]} 
                        defaultValue='Due Date' 
                        style={{border: 'none', width: '130px', float: 'right'}}
                        onChange={handleSort}
                    />
                </GridToolbar>

                <GridColumn cell={(p) => {
                    if (!p.dataItem) return null;
                    
                    const { title, dueDate, completedDate, pending = (p.dataItem.taskStatus && p.dataItem.taskStatus.lookupValue !== "Completed") ?? true } = p.dataItem;

                    const dueDateStr = moment(dueDate).isValid() ? moment(dueDate).format("DD/MM/YYYY") : "";
                    const completedDateStr = moment(completedDate).isValid() ? moment(completedDate).format("DD/MM/YYYY") : "";

                    return (
                        <td onClick={() => props.edit(p.dataItem)}>
                            <GridContainer style={{alignItems:'center'}} >
                                <GridItem md={2}>
                                    {pending ? <FontAwesomeIcon icon={faSquare} size="2x" color='#c64069' /> : <FontAwesomeIcon icon={faCheckSquare} size="2x" color='#c64069' />}
                                </GridItem>
                                <GridItem md={10}>
                                    <GridContainer style={{ lineHeight: '16px' }} className={`${pending ? 'color-based-task-bold' : 'color-based-task' }`}>
                                        <GridItem md={12} >
                                            {title}
                                        </GridItem>
                                        <GridItem md={12} className='font-italic'>
                                            {pending ? `Due: ${dueDateStr}` : `Completed: ${completedDateStr}`}                                                                               
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                        </td>
                    );                                                     
                }} />
            </Grid>
 
            {props.tasksLoading && <LoadingPanel/>}
            {props.renderEditors()}
        </React.Fragment>
    );
});

export default withSnackbar(withRouterAndRef(TaskSummary));