import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { withRouter } from "react-router-dom";
import withRouterAndRef from '../../util/withRouterAndRef';
import api from "../../util/api";
import { requiresRole, policies } from '../../util/accessControl';

import LoadingPanel from '../common/Grid/LoadingPanel';
import CardIcon from "../common/MuiKit/Card/CardIcon";
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";
import CardFooter from "../common/MuiKit/Card/CardFooter";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";

import AccountEditor from "./AccountEditor";

import { Button } from '@progress/kendo-react-buttons';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import Dialog from "../common/Dialog";

import AccountIcon from "@material-ui/icons/AccountBox";
import withStyles from "@material-ui/core/styles/withStyles";
import jacmacStyles from "../assets/jss/core/jacmacStyles";
import 'react-perfect-scrollbar/dist/css/styles.css';
import KendoGridDateCell from "../../util/KendoGridDateCell";

import ListView from "../common/ListView";

import moment from "moment";
import { withSnackbar } from 'notistack';
import handleErrors from '../../util/handleErrors';

import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import AddressFormatter from "../../util/formatAddress";
const AccountListView = forwardRef((props, ref) => {

    const { classes } = props;
    const [gridItems, setGridItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [itemInEdit, setItemInEdit] = useState(null);
    const [itemInDelete, setItemInDelete] = useState(null);
    const [matterId] = useState(props.match.params.id);
    useImperativeHandle(ref, () => ({
        load,
    }));
    const load = () => {
        setLoading(true);

        api.get(`/account/${matterId}`, {
            accept: 'application/json',
        })
        .then(json => {
            setGridItems(json);
            setLoading(false);
        })
        .catch(handleErrors(props))
    }
    useEffect(() => {
        load();
    }, []);

    const insert = () => {
        setItemInEdit({ accountId: 0, fromMatter: true, matterId: matterId, matter: matterId ? null : {}, client: {} });
    }

    const edit = (dataItem) => {
        if (props.readonly){
            return;
        }
        const item = Object.assign({}, dataItem);
        Object.keys(item).forEach(key => {
            if (item[key] == null) {
                if (key === 'originalAmountAdvanced' ||
                    key === 'loanValueRatio' ||
                    key === 'currentInterestRate' ||
                    key === 'currentPaymentAmount' ||
                    key === 'currentBalance' ||
                    key === 'accountFeesAccrued' ||
                    key === 'governmentCharges' ||
                    key === 'daysDelinquent' ||
                    key === 'dailyInterestAmount'
                ) {
                    item[key] = null;
                } else {
                    item[key] = '';
                }
            }
        });
        setItemInEdit(item);
    };

    const remove = (dataItem) => {
        setItemInDelete(dataItem);
    };

    const deleteItem = () => {
        setLoading(true);

        api.delete(`/account/${itemInDelete.accountId}`, {
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(json => {
                setLoading(false);
                setItemInDelete(null);

                api.get(`/account/${matterId}`, {
                    accept: 'application/json',
                })
                    .then(json => {
                        setGridItems(json);
                        setLoading(false);
                    })
                    .catch(handleErrors(props))
            })
            .catch(handleErrors(props))
    };

    const save = (dataItem) => {
        setLoading(true);

        if (dataItem.matter && !dataItem.matter.lookupId) { dataItem.matter = ""; }

        api.request({
            url: '/account',
            method: dataItem.accountId === 0 ? 'POST' : 'PUT',
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(dataItem)
        })
            .then(json => {
                let items = gridItems.slice();
                if (dataItem.accountId === 0) {
                    dataItem.accountId = json.accountId;
                    items = [dataItem, ...items];
                } else {
                    var index = gridItems.findIndex(v => v.accountId === json.accountId);
                    items.splice(index, 1, dataItem);
                }

                setGridItems(items);
                setItemInEdit(null);
                setLoading(false);
            })
            .catch(handleErrors(props))
    }

    const cancel = () => {
        setItemInEdit(null);
        setItemInDelete(null);
        setLoading(false);
    }

    const renderListItem = (dataItem, index) => {
        const paymentFrequency = dataItem.paymentFrequency ? dataItem.paymentFrequency.lookupValue : '';
        const dateOfAgreement = dataItem.dateOfAgreement ? moment(dataItem.dateOfAgreement).format('DD/MM/YYYY') : "";
        const account = `${dataItem.accountNumber}`;

        return (

            <Card key={dataItem.accountId} className={classes.listViewItem}>
                <CardBody className={classes.listViewItemBody}>
                    <GridContainer>
                        <GridItem md={12}>
                            <p className={`${classes.listViewItemCategory} ${classes.textEllipsis}`}>Account</p>
                            <h4 className={`${classes.listViewItemDescription} ${classes.textEllipsis}`} title={account}>{account || '\u00A0'}</h4>
                        </GridItem>
                        <GridItem md={12}>
                            <p className={`${classes.listViewItemCategory} ${classes.textEllipsis}`}>Date of Agreement</p>
                            <h4 className={`${classes.listViewItemDescription} ${classes.textEllipsis}`} title={dateOfAgreement}>{dateOfAgreement || '\u00A0'}</h4>
                        </GridItem>
                        <GridItem md={12}>
                            <p className={`${classes.listViewItemCategory} ${classes.textEllipsis}`}>Payment Frequency</p>
                            <h4 className={`${classes.listViewItemDescription} ${classes.textEllipsis}`} title={paymentFrequency}>{paymentFrequency || '\u00A0'}</h4>
                        </GridItem>
                    </GridContainer>
                </CardBody>
                <CardFooter className={classes.listViewItemFooter}>
                   
                    {requiresRole(<Button
                        className={classes.listViewItemButton}
                        primary={true}
                        icon='pencil'
                        onClick={() => { edit(dataItem); }}
                        title='Edit'
                    >
                    </Button>, policies.edit)}

                    {requiresRole(<Button
                        className={classes.listViewItemButton}
                        primary={true}
                        icon='close'
                        onClick={() => { remove(dataItem); }}
                        title='Delete'
                    >
                    </Button>, policies.edit)}

                </CardFooter>
            </Card>
        );
    }

    return (
        <>
            {props.itemData && !props.readonly && <h5 className='font-size-14 mt-3'><b>{props.itemData.matterName}</b> | <i>JM Ref:</i> <b>{props.itemData.matterNumber}</b> | <i>Client Ref:</i> <b>{props.itemData.clientReference}</b></h5>}
            <h5 className='font-size-14 font-weight-bolder mt-3 mb-4'>Accounts</h5>
            {/* <ListView insert={insert} items={gridItems} renderListItem={renderListItem} /> */}

            <Grid
                filterable={false}
                sortable={true}
                pageable={false}
                className='clickableRow'
                data={gridItems}
                onRowClick={({ dataItem }) => { edit(dataItem) }}
                style={props.readonly ? {} : { height: 'calc(100vh - 350px)' }} 
            >
                {!props.readonly && <GridToolbar>
                    <Button
                        primary={true}
                        icon='plus'
                        title='Add New'
                        onClick={insert}
                        className='rounded-circle float-right'
                    />
                </GridToolbar>}
                <GridColumn field="accountNumber" title="Account No." />
                <GridColumn title="Borrower" cell={(columnProps, context) => {
                    return (
                        <td onClick={() => edit(columnProps.dataItem)}>
                            {columnProps.dataItem.borrowers && columnProps.dataItem.borrowers.map((val) => val.lookupValue).join(', ')}
                        </td>
                    )
                }} />
                {props.readonly && <GridColumn title="Security Addresses" cell={(columnProps, context) => {
                    return (
                        <td onClick={() => edit(columnProps.dataItem)}>
                            <div style={{ whiteSpace: 'pre' }}>{columnProps.dataItem.security}</div>
                        </td>
                    )
                }} />}
                <GridColumn field="originalAmountAdvanced" title="Original Advance" />
                <GridColumn field="dateOfAgreement" title="Date of loan Agreement" format="dd/MM/yyyy"  cell={KendoGridDateCell} />
                <GridColumn field="varianceType.lookupValue" title="Shortfall/Surplus" />
 
            </Grid>

            {loading && <LoadingPanel />}
            {itemInEdit && <AccountEditor dataItem={itemInEdit} save={save} cancel={cancel} editMode={true} />}
            {itemInDelete && <Dialog title={"Delete confirmation"} onClose={cancel}>
                <p style={{ margin: "25px", textAlign: "center" }}>Delete Account "{itemInDelete.accountNumber}" ?</p>
                <DialogActionsBar>
                    <button className="k-button" onClick={cancel}>No</button>
                    <button className="k-button" onClick={deleteItem}>Yes</button>
                </DialogActionsBar>
            </Dialog>}
        </>
    );
});

export default withSnackbar(withRouterAndRef(withStyles(jacmacStyles)(AccountListView)));