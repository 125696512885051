import React from 'react';
import { formatDate } from '@telerik/kendo-intl';

export default class KendoGridDateCell extends React.Component {
    render() {
        const value = this.props.dataItem[this.props.field];
        return (
            <td>
                {value == null ? null : formatDate(new Date(value), "dd/MM/yyyy")}
            </td>
        );
    }
}