import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";

import LoadingPanel from '../common/Grid/LoadingPanel';
import CardIcon from "../common/MuiKit/Card/CardIcon";
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";

import GavelIcon from "@material-ui/icons/Gavel";
import withStyles from "@material-ui/core/styles/withStyles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMore from "@material-ui/icons/ExpandMore";

import accordionStyle from "../assets/jss/material-dashboard-pro-react/components/accordionStyle";
import PartyListView from "../parties/PartyListView";
import AccountListView from "../account/AccountListView";
import SecurityListView from "../securities/SecurityListView";
import MatterGeneralInfo from "../matter/MatterGeneralInfo";

import { Button } from '@progress/kendo-react-buttons';
import InstructionWizard from "./InstructionWizard";

import api from "../../util/api"
import moment from "moment";

import handleErrors from '../../util/handleErrors';
import { withSnackbar } from 'notistack';
import llamaLogo from "../assets/img/JacMac Llama Logo.png";
import jacmacLogo from "../assets/img/Jacmac with text.png";
const Instruction = (props) => { 
    const [errors, setErrors] = useState({
        matterNumber: false,
        instructionDate: false,
        matterType: false,
        matterStatus: false,
        clientReference: false,
        clientContact: false,
        manager: false,
    }) 

    const [matterTypes, setMatterTypes] = useState([]);
    useEffect(() => {
       
        api.get(`/lookup/mattertype`, {
            accept: 'application/json',
        })
        .then(json => {
            setMatterTypes(json);
        })
        .catch(handleErrors(props));
    }, []);

    const [matterStatuses, setMatterStatuses] = useState([]);
    useEffect(() => {

        api.get(`/lookup/matterstatus`, {
            accept: 'application/json',
        })
        .then(json => {
            setMatterStatuses(json);
        })
        .catch(handleErrors(props));
    }, []);

    const [matterItem, setMatterItem] = useState();
    const id = props.match.params.id;
    const [loading, setLoading] = useState(false);
    useEffect(() => {

        if (id) {
            setLoading(true);

            api.get(`/matter/${id}`, {
                accept: 'application/json',
            })
            .then(json => {
                json.itemLoaded = true;
                json.instructionDate = (json.instructionDate == null || json.instructionDate === "") ? moment().toDate() : moment(json.instructionDate).toDate();
                
                json.matterStatus = (json.matterStatus == null || json.matterStatus === "") ? {} : json.matterStatus;
                json.clientContact = (json.clientContact == null || json.clientContact === "") ? {} : json.clientContact;
                json.manager = (json.manager == null || json.manager === "") ? {} : json.manager;

                setMatterItem(json);
                setLoading(false);
            })
            .catch(handleErrors(props));
        } else {
            setMatterItem({ itemLoaded: true, instructionDate: moment().toDate() });
        }
        
    }, []);

    const [ selected, setSelected ] = useState(0);

    const savePanel = (nextIndex, currentIndex) => {
        console.log('savePanel: ', nextIndex, currentIndex, partiesRef);
        var validForm = true;
        if (currentIndex === 1) {
            validForm = handleSaveForm(matterItem);
        } else if (currentIndex === 4 || nextIndex === 5) {
            if (partiesRef && partiesRef.current) {
                partiesRef.current.load();
            }
            if (accountsRef && accountsRef.current) {
                accountsRef.current.load();
            }
            if (securitiesRef && securitiesRef.current) {
                securitiesRef.current.load();
            }
        }

        //if (validForm) { setSelected(nextIndex) };
        return validForm;
    }

    const handleSaveForm = () => {

        var validForm = true;
        var formErrors = {};
        formErrors.matterNumber = (!matterItem.matterNumber || matterItem.matterNumber === '');
        formErrors.instructionDate = (!matterItem.instructionDate || matterItem.instructionDate === '');
        //formErrors.matterType = (!matterItem.matterType || matterItem.matterType === '' || !matterItem.matterType.lookupId);
        formErrors.matterStatus = (!matterItem.matterStatus || matterItem.matterStatus === '' || !matterItem.matterStatus.lookupId);
        formErrors.clientReference = (!matterItem.clientReference || matterItem.clientReference === '');
        formErrors.clientContact = (!matterItem.clientContact || matterItem.clientContact === '' || !matterItem.clientContact.lookupId);
        formErrors.manager = (!matterItem.manager || matterItem.manager === '' || !matterItem.manager.lookupId);

        setErrors(formErrors);

        const item = Object.assign({}, formErrors)
        validForm = !Object.keys(item).some(x => item[x]);

        if (validForm) {
            saveMatterInstruction(matterItem);
            
        }

        return validForm;
    } 

    const saveMatterInstruction = (dataItem) => {

        setLoading(true);

        api.request({
            url: '/matter',
            method: dataItem.matterId === 0 ? 'POST' : 'PUT',
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(dataItem)
        }) 
        .then(json => {
            setLoading(false);
        })
        .catch(handleErrors(props))
    }

    const { classes } = props;
    const panelClasses = {
        root: classes.expansionPanel,
        expanded: classes.expansionPanelExpanded
    };
    const panelSummaryClasses = {
        root: classes.expansionPanelSummary,
        expanded: classes.expansionPanelSummaryExpaned,
        content: classes.expansionPanelSummaryContent,
        expandIcon: classes.expansionPanelSummaryExpandIcon
    };

    const handleChange = panel => (event, expanded) => {
        savePanel(expanded ? panel : -1, selected);
    };

    const partiesRef = useRef();
    const accountsRef = useRef();
    const securitiesRef = useRef();
    return (
        <>
            <div className='d-flex flex-row align-items-center' style={{position: 'absolute', top: '0', left: '0', right: '0', padding: '15px 30px', backgroundColor: '#fff'}}>
                <img alt='' src={llamaLogo} className='img-fluid' style={{height: '35px',paddingRight: '15px'}} />
                <h4 className='mr-auto'></h4>

                <img alt='' src={jacmacLogo} className='img-fluid ml-auto' style={{height: '55px'}} />
            </div>
            <InstructionWizard {...props} savePanel={savePanel} itemData={matterItem}>
                <MatterGeneralInfo itemData={matterItem} setItemData={setMatterItem} matterStatuses={matterStatuses} matterTypes={matterTypes} formErrors={errors} />
                <PartyListView margin="15px 0" itemData={matterItem} />
                <SecurityListView margin="15px 0" itemData={matterItem} />
                <AccountListView margin="15px 0" itemData={matterItem} />
                {matterItem && <div className='font-size-14'>
                    <h5 className='mt-3 font-size-14'><b>{matterItem.matterName}</b> | <i>JM Ref:</i> <b>{matterItem.matterNumber}</b> | <i>Client Ref:</i> <b>{matterItem.clientReference}</b></h5>
                    <div className='mt-4 font-size-14'>
                        <b>File Manager: </b>{matterItem.manager ? matterItem.manager.lookupValue : null}
                    </div>
                    <div className='mt-1 font-size-14'>
                        <b>Instructing Officer: </b>{matterItem.clientContact ? matterItem.clientContact.lookupValue : null}
                    </div>
                    <div className='mt-1 font-size-14'>
                        <b>Sensitivities: </b>{matterItem.sensitivities || 'None'}
                    </div>
                    <div className='mt-1 font-size-14'>
                        <b>Other Comments: </b>{matterItem.overview || 'None'}
                    </div>
                    <div className='mt-5' />
                    <AccountListView margin="15px 0" itemData={matterItem} readonly={true} ref={accountsRef} />
                    <div className='mt-5' />
                    <PartyListView margin="15px 0" itemData={matterItem} readonly={true} ref={partiesRef} />
                    <div className='mt-5' />                    
                    <SecurityListView margin="15px 0" itemData={matterItem} readonly={true} ref={securitiesRef} />
                </div>}
            </InstructionWizard>
        </>
        
    );

    // return (
    //     <Card style={{minHeight: props.cardHeight}}>
    //         <CardHeader>
    //             <CardIcon color='warning' style={{marginTop: '-30px'}}>
    //                 <GavelIcon style={{color:'white'}} />
    //             </CardIcon>
    //             <h4 style={{color: '#777'}}>New Instruction</h4>

    //         </CardHeader>
    //         <CardBody style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
    //             <div className={classes.root}>         
                    
    //                 <ExpansionPanel
    //                     expanded={selected === 0}
    //                     onChange={handleChange(0)}
    //                     key={0}
    //                     classes={panelClasses}
    //                 >
    //                     <ExpansionPanelSummary
    //                         expandIcon={<ExpandMore />}
    //                         classes={panelSummaryClasses}
    //                     >
    //                         <h4 className={classes.title}>General Information</h4>
    //                     </ExpansionPanelSummary>
    //                     <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                            
    //                         <div style={{ width: '100%', marginTop: '10px' }} >
    //                             <MatterGeneralInfo itemData={matterItem} setItemData={setMatterItem} matterStatuses={matterStatuses} matterTypes={matterTypes} formErrors={errors} />
    //                             <Button
    //                                 style={{ float: "right" }}
    //                                 primary={true}
    //                                 onClick={(ev) => {
    //                                     savePanel(1, 0);
    //                                 }}
    //                             >Next</Button>
    //                             <span
    //                                 style={{ float: "right" }}
    //                                 >{'\u00A0\u00A0'}
    //                             </span>
    //                             <Button
    //                                 style={{ float: "right" }}
    //                                 primary={true}
    //                                 onClick={(ev) => {
    //                                     savePanel(-1, 0);
    //                                 }}
    //                             >Previous</Button>
    //                         </div>
                            
    //                     </ExpansionPanelDetails>
    //                 </ExpansionPanel>

    //                 <ExpansionPanel
    //                     expanded={selected === 1}
    //                     onChange={handleChange(1)}
    //                     key={1}
    //                     classes={panelClasses}
    //                 >
    //                     <ExpansionPanelSummary
    //                         expandIcon={<ExpandMore />}
    //                         classes={panelSummaryClasses}
    //                     >
    //                         <h4 className={classes.title}>Party Information</h4>
    //                     </ExpansionPanelSummary>
    //                     <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                            
    //                         <div style={{ width: '100%', }} >
    //                             <PartyListView margin="15px 0" />
    //                             <Button
    //                                 style={{ float: "right" }}
    //                                 primary={true}
    //                                 onClick={(ev) => {
    //                                     savePanel(2, 1);
    //                                 }}
    //                             >Next</Button>
    //                             <span
    //                                 style={{ float: "right" }}
    //                             >{'\u00A0\u00A0'}
    //                             </span>
    //                             <Button
    //                                 style={{ float: "right" }}
    //                                 primary={true}
    //                                 onClick={(ev) => {
    //                                     savePanel(0, 1);
    //                                 }}
    //                             >Previous</Button>
    //                         </div>
                           
    //                     </ExpansionPanelDetails>
    //                 </ExpansionPanel>

    //                 <ExpansionPanel
    //                     expanded={selected === 2}
    //                     onChange={handleChange(2)}
    //                     key={2}
    //                     classes={panelClasses}
    //                 >
    //                     <ExpansionPanelSummary
    //                         expandIcon={<ExpandMore />}
    //                         classes={panelSummaryClasses}
    //                     >
    //                         <h4 className={classes.title}>Account Information</h4>
    //                     </ExpansionPanelSummary>
    //                     <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                            
    //                         <div style={{ width: '100%', }} >
    //                             <AccountListView margin="15px 0" />
    //                             <Button
    //                                 style={{ float: "right" }}
    //                                 primary={true}
    //                                 onClick={(ev) => {
    //                                     savePanel(3, 2);
    //                                 }}
    //                             >Next</Button>
    //                             <span
    //                                 style={{ float: "right" }}
    //                             >{'\u00A0\u00A0'}
    //                             </span>
    //                             <Button
    //                                 style={{ float: "right" }}
    //                                 primary={true}
    //                                 onClick={(ev) => {
    //                                     savePanel(1, 2);
    //                                 }}
    //                             >Previous</Button>
    //                         </div>
                           
    //                     </ExpansionPanelDetails>
    //                 </ExpansionPanel>

    //                 <ExpansionPanel
    //                     expanded={selected === 3}
    //                     onChange={handleChange(3)}
    //                     key={3}
    //                     classes={panelClasses}
    //                 >
    //                     <ExpansionPanelSummary
    //                         expandIcon={<ExpandMore />}
    //                         classes={panelSummaryClasses}
    //                     >
    //                         <h4 className={classes.title}>Security Information</h4>
    //                     </ExpansionPanelSummary>
    //                     <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                            
    //                         <div style={{ width: '100%', }} >
    //                             <SecurityListView margin="15px 0" />
    //                             <Button
    //                                 style={{ float: "right" }}
    //                                 primary={true}
    //                                 onClick={(ev) => {
    //                                     savePanel(4, 3);
    //                                 }}
    //                             >Next</Button>
    //                             <span
    //                                 style={{ float: "right" }}
    //                             >{'\u00A0\u00A0'}
    //                             </span>
    //                             <Button
    //                                 style={{ float: "right" }}
    //                                 primary={true}
    //                                 onClick={(ev) => {
    //                                     savePanel(2, 3);
    //                                 }}
    //                             >Previous</Button>
    //                         </div>
                            
    //                     </ExpansionPanelDetails>
    //                 </ExpansionPanel>
    //             </div>
    //             {/*
    //             {id && <Accordion
    //                 panels={panels}
    //                 selectedIndex={selected}
    //                 setSelectedIndex={savePanel}
    //                 //handleChange={handleChange}
    //             />}
    //             */}
                
    //             <Button
    //                 style={{ float: "right" }}
    //                 primary={true}
    //                 onClick={(ev) => {
    //                     //Do Nothing for now
    //                 }}
    //             >Submit</Button>
    //         </CardBody>
    //         {loading && <LoadingPanel />}
    //     </Card>
    // )
}

export default withSnackbar(withRouter(withStyles(accordionStyle)(Instruction)));
