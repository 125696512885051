import React, { useState, useEffect } from 'react';
import { findDOMNode } from "react-dom";

import { NumericTextBox } from '@progress/kendo-react-inputs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Button } from '@progress/kendo-react-buttons';
import { formatNumber } from '@telerik/kendo-intl';

import Dialog from "../common/Dialog";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";

import api from "../../util/api"
import handleErrors from '../../util/handleErrors';
import { editableField } from "../../util/editableField";
import { requiresRole, policies } from '../../util/accessControl';

import moment from "moment";
import { withSnackbar } from 'notistack';

import RelatedAccountsEditor from "./RelatedAccountsEditor";
import RelatedSecuritiesEditor from "./RelatedSecuritiesEditor";

const DischargeEditor = (props) => {
    const dischargeCustomerSale = 99;
    const dischargeRefinance = 100;
    const originalItem = {
        ...props.dataItem,
        formReceived: (props.dataItem.formReceived == null || props.dataItem.formReceived === "") ? null : moment(props.dataItem.formReceived).toDate(),
        financeDueDate: (props.dataItem.financeDueDate == null || props.dataItem.financeDueDate === "") ? null : moment(props.dataItem.financeDueDate).toDate(),
        anticipatedSettlementDate: (props.dataItem.anticipatedSettlementDate == null || props.dataItem.anticipatedSettlementDate === "") ? null : moment(props.dataItem.anticipatedSettlementDate).toDate(),
        actualSettlementDate: (props.dataItem.actualSettlementDate == null || props.dataItem.actualSettlementDate === "") ? null : moment(props.dataItem.actualSettlementDate).toDate(),
        confirmationDate: (props.dataItem.confirmationDate == null || props.dataItem.confirmationDate === "") ? null : moment(props.dataItem.confirmationDate).toDate(),
    };
    const [itemData, setItemData] = useState(originalItem);
    const [originalItemData, setOriginalItemData] = useState(originalItem);
    const initLookupObject = { loading: false, list: [] };
    const [dischargeTypes, setDischargeTypes] = useState(initLookupObject);
    const [varianceTypes, setVarianceTypes] = useState(initLookupObject);
    const [editMode, setEditMode] = useState(!!props.editMode);

    const editStageQuery = `/stage/discharge/${itemData.stageId}`;
    const newStageQuery = `/stage/block/${itemData.stage.blockId}`;

    const handleSelectChange = ({ value, target: { name } }) => {
        const idField = `${name}Id`;
        setItemData({ ...itemData, [name]: value, [idField]: value ? value.lookupId : null });
    }

    const handleDateChange = ({ value, target: { name } }) => {
        setItemData({ ...itemData, [name]: value == null ? null : moment(value).local().toDate() });
    }

    const handleInputChange = ({ value, target: { name } }) => {
        setItemData({ ...itemData, [name]: value });
    }

    const handleCheckSelected = (element) => {
        let isChecked = !itemData[element];
        setItemData({ ...itemData, [element]: isChecked });
    }

    const updateRelatedEditorsData = (updatedData) => {
        setItemData(updatedData);
    }
    const validateForm = () => {

        let account = itemData.stage.matterAccounts.find(v => v.noticeSelected === true);
        if (!account) {
            props.enqueueSnackbar('Related Account/s required.', { variant: 'error' });
            return false;
        }
        let security = itemData.stage.matterSecurities.find(v => v.noticeSelected === true);
        if (!security) {
            props.enqueueSnackbar('Related Security Properties required.', { variant: 'error' });
            return false;
        }

        return true;
    }
    
    const setLookup = (setFunc) => (json) => {
        setFunc({ loading: false, list: json });
        return json;
    }

    useEffect(() => {
        const axiosConfig = { accept: 'application/json' };
        const loading = { loading: true, list: [] };
        setDischargeTypes(loading);
        setVarianceTypes(loading);

        let onLoadTasks = [
            api.get((itemData.stageId > 0) ? editStageQuery : newStageQuery, axiosConfig),
            api.get(`/lookup/dischargetype`, axiosConfig).then(setLookup(setDischargeTypes)),
            api.get(`/lookup/variancetype`, axiosConfig).then(setLookup(setVarianceTypes)),
        ];

        Promise.all(onLoadTasks)
            .then(responses => {
                let tempData = { ...itemData };
                let stage = responses[0];
                let dischargeTypes = responses[1]
                let varianceTypes = responses[2]

                if (tempData.stageId === 0) {
                    tempData = {
                        ...itemData,
                        dischargeType: dischargeTypes[0],
                        dischargeTypeId: dischargeTypes[0].lookupId,
                        varianceType: varianceTypes[0],
                        varianceTypeId: varianceTypes[0].lookupId,
                        stage: stage,
                    };
                } else {
                    tempData = {
                        ...stage,
                        formReceived: (stage.formReceived == null || stage.formReceived === "") ? null : moment(stage.formReceived).toDate(),
                        financeDueDate: (stage.financeDueDate == null || stage.financeDueDate === "") ? null : moment(stage.financeDueDate).toDate(),
                        anticipatedSettlementDate: (stage.anticipatedSettlementDate == null || stage.anticipatedSettlementDate === "") ? null : moment(stage.anticipatedSettlementDate).toDate(),
                        actualSettlementDate: (stage.actualSettlementDate == null || stage.actualSettlementDate === "") ? null : moment(stage.actualSettlementDate).toDate(),
                        confirmationDate: (stage.confirmationDate == null || stage.confirmationDate === "") ? null : moment(stage.confirmationDate).toDate(),
                    };
                }

                setItemData(tempData);
                setOriginalItemData(tempData);
            })
            .catch(handleErrors(props))
    }, []);

    return (
        <Dialog
            validate={validateForm}
            original={originalItemData}
            current={itemData}
            saveChanges={props.save}
            cancelChanges={props.cancel}
            width={1200}
            createMode={itemData.stageId === 0}
            title={
                <>
                    {`${itemData.accountId === 0 ? 'Add' : editMode ? 'Edit' : 'View'} Discharge`}
                    {requiresRole(<Button className='rounded-circle ml-2' primary={true} look="bare" icon={editMode ? 'lock' : 'edit'} onClick={(ev) => setEditMode(!editMode)} />, policies.edit)}
                </>}
        >
            <form onSubmit={(ev) => ev.preventDefault()}>
                <GridContainer>

                    {editableField(<GridItem sm={4}>
                        <ComboBox
                            data={dischargeTypes.list}
                            loading={dischargeTypes.loading}
                            onFocus={(ev) => { if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click() }}
                            textField='lookupValue'
                            dataItemKey='lookupId'
                            value={itemData.dischargeType}
                            onChange={handleSelectChange}
                            label="Discharge Type"
                            name='dischargeType'
                            id='dischargeType'
                        />
                    </GridItem>, editMode, 'Discharge Type', (itemData.dischargeType) ? itemData.dischargeType.lookupValue : '', [2, 2])}

                    {editableField(<GridItem sm={4}>
                        <span className='k-textbox-container'>
                            <label className='k-label' htmlFor='formReceived'>Discharge Authority Form received</label>
                            <DatePicker
                                defaultValue={new Date()}
                                format="dd/MM/yyyy"
                                name='formReceived'
                                id='formReceived'
                                onChange={handleDateChange}
                                value={itemData.formReceived}
                                label=""
                            />
                        </span>
                    </GridItem>, editMode, 'Discharge Authority Form received', (itemData.formReceived) ? moment(itemData.formReceived).format('D/MM/YYYY') : '', [2, 2])}

                    {(itemData.dischargeTypeId === dischargeCustomerSale) && editableField(<GridItem sm={4}>
                        <NumericTextBox
                            width='100%'
                            label='Purchase price'
                            value={itemData.purchasePrice}
                            onChange={handleInputChange}
                            format="c2"
                            spinners={false}
                            name="purchasePrice"
                            id="purchasePrice"
                        />
                    </GridItem>, editMode, 'Purchase price', itemData.purchasePrice ? formatNumber(itemData.purchasePrice, "c2") : '', [2, 2])}

                    {(itemData.dischargeTypeId === dischargeCustomerSale) && editableField(<GridItem sm={4}>
                        <span className='k-textbox-container'>
                            <label className='k-label' htmlFor='financeDueDate'>Finance due date</label>
                            <DatePicker
                                defaultValue={new Date()}
                                format="dd/MM/yyyy"
                                name='financeDueDate'
                                id='financeDueDate'
                                onChange={handleDateChange}
                                value={itemData.financeDueDate}
                                label=""
                            />
                        </span>
                    </GridItem>, editMode, 'Finance due date', itemData.financeDueDate ? moment(itemData.financeDueDate).format('D/MM/YYYY') : '', [2, 2])}

                    {(itemData.dischargeTypeId === dischargeCustomerSale) && editableField(<GridItem sm={4} className='d-flex align-items-center'>
                        <input type="checkbox" className='k-checkbox' checked={!!itemData.contractUnconditional} onChange={() => handleCheckSelected('contractUnconditional')} name="contractUnconditional" id="contractUnconditional" />
                        <label className="k-checkbox-label" htmlFor="contractUnconditional">Contract of sale unconditional</label>
                    </GridItem>, editMode, 'Contract of sale unconditional', !!itemData.contractUnconditional ? 'Yes' : 'No', [2, 2])}

                    {(itemData.dischargeTypeId === dischargeRefinance) && editableField(<GridItem sm={4}>
                        <span className='k-textbox-container'>
                            <label className='k-label' htmlFor='confirmationDate'>Unconditional refinance confirmation</label>
                            <DatePicker
                                defaultValue={new Date()}
                                format="dd/MM/yyyy"
                                name='confirmationDate'
                                id='confirmationDate'
                                onChange={handleDateChange}
                                value={itemData.confirmationDate}
                                label=""
                            />
                        </span>
                    </GridItem>, editMode, 'Unconditional refinance confirmation', itemData.confirmationDate ? moment(itemData.confirmationDate).format('D/MM/YYYY') : '', [2, 2])}

                    {/*--*/}
                    {editableField(<GridItem sm={4}>
                        <span className='k-textbox-container'>
                            <label className='k-label' htmlFor='anticipatedSettlementDate'>Anticipated settlement date</label>
                            <DatePicker
                                defaultValue={new Date()}
                                format="dd/MM/yyyy"
                                name='anticipatedSettlementDate'
                                id='anticipatedSettlementDate'
                                onChange={handleDateChange}
                                value={itemData.anticipatedSettlementDate}
                                label=""
                            />
                        </span>
                    </GridItem>, editMode, 'Anticipated settlement date', itemData.anticipatedSettlementDate ? moment(itemData.anticipatedSettlementDate).format('D/MM/YYYY') : '', [2, 2])}

                    {/*--*/}
                    {editableField(<GridItem sm={4}>
                        <span className='k-textbox-container'>
                            <label className='k-label' htmlFor='actualSettlementDate'>Date settled</label>
                            <DatePicker
                                defaultValue={new Date()}
                                format="dd/MM/yyyy"
                                name='actualSettlementDate'
                                id='actualSettlementDate'
                                onChange={handleDateChange}
                                value={itemData.actualSettlementDate}
                                label=""
                            />
                        </span>
                    </GridItem>, editMode, 'Anticipated settlement date', itemData.actualSettlementDate ? moment(itemData.actualSettlementDate).format('D/MM/YYYY') : '', [2, 2])}

                    {/*--*/}
                    {editableField(<GridItem sm={4}>
                        <NumericTextBox
                            width='100%'
                            label='Payout figure'
                            value={itemData.payoutFigure}
                            onChange={handleInputChange}
                            format="c2"
                            spinners={false}
                            name="payoutFigure"
                            id="payoutFigure"
                        />
                    </GridItem>, editMode, 'Payout figure', itemData.payoutFigure ? formatNumber(itemData.payoutFigure, "c2") : '', [2, 2])}

                    {(itemData.dischargeTypeId === dischargeCustomerSale) && editableField(<GridItem sm={4}>
                        <ComboBox
                            data={varianceTypes.list}
                            loading={varianceTypes.loading}
                            onFocus={(ev) => { if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click() }}
                            textField='lookupValue'
                            dataItemKey='lookupId'
                            value={itemData.varianceType}
                            onChange={handleSelectChange}
                            label="Shortfall or surplus"
                            name='varianceType'
                            id='varianceType'
                        />
                    </GridItem>, editMode, 'Shortfall or surplus', itemData.varianceType ? itemData.varianceType.lookupValue : '', [2, 2])}

                    {itemData.stage.matterAccounts && <RelatedAccountsEditor stageData={itemData} updateRelatedAccounts={updateRelatedEditorsData} editMode={editMode} />}

                    {itemData.stage.matterSecurities && <RelatedSecuritiesEditor stageData={itemData} updateRelatedSecurities={updateRelatedEditorsData} editMode={editMode} />}

                </GridContainer>
            </form>
        </Dialog>
    )
}

export default withSnackbar(DischargeEditor);