import React, { useState, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";

import { requiresRole, policies } from '../../util/accessControl';
import cellWithEditing from "../common/Grid/CellWithEditing";
import LoadingPanel from '../common/Grid/LoadingPanel';
import { ConfirmDelete } from "../common/Dialog";

import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';

import api from "../../util/api"
import handleErrors from '../../util/handleErrors';

import PartyEditor from "./PartyEditor";
import GenericHeader from "../common/Header/GenericHeader"; 
import CardIcon from "../common/MuiKit/Card/CardIcon";
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";
import PartyIcon from "@material-ui/icons/Group";
import { IntlProvider, load, LocalizationProvider, loadMessages, IntlService } from '@progress/kendo-react-intl';

import AddressFormatter from "../../util/formatAddress";

import { withSnackbar } from 'notistack';

const Party = (props) => {

    const [matterId] = useState(props.match.params.id || 0);
    const [gridItems, setGridItems] = useState([]);
    const [total, setTotal] = useState(0);
    const initPage = { take: matterId > 0 ? 9999 : 10, skip: 0 };
    const [ page, setPage] = useState(initPage)
    const defaultSort = {object: [], query: ''};
    const [ sort, setSort ] = useState(defaultSort); 
    const [loading, setLoading] = useState(false);
    const [itemInEdit, setItemInEdit] = useState(null);
    const [itemInDelete, setItemInDelete] = useState(null);
    const formatAddress = (address) => AddressFormatter.format(address);

    const DATE_FORMAT = 'yyyy-mm-ddThh:mm:ss';
    const intl = new IntlService();
    const parseDates = (data) => {
        data.forEach(o => {
            o.dateOfBirth = o.dateOfBirth ? intl.parseDate(o.dateOfBirth, DATE_FORMAT) : null;
        });
    }

    useEffect(() => {
        setLoading(true);

        api.get(`/party/${matterId}/paged/${(page.skip / page.take) + 1}/${page.take}${sort.query ? `?${sort.query}` : ``}`, {
            accept: 'application/json',
        }) 
        .then(json => {
            parseDates(json.data);
            setGridItems(json.data);
            setTotal(json.totalRecords);
            setLoading(false);
        })
        .catch(handleErrors(props))
    }, [page, sort]);

    const edit = (dataItem, editMode = true) => {
        const item = Object.assign({}, dataItem)
        Object.keys(item).forEach(key => { if (item[key] == null) { item[key] = '' } })
        item.selectMatter = (matterId == 0);
        item.editMode = editMode;

        setItemInEdit(item);
    };

    const remove = (dataItem) => {
        setItemInDelete(dataItem);
    };

    const deleteItem = (dataItem) => {
        setLoading(true);

        api.delete(`/party/${dataItem.partyId}`, {
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
        .then(json => {

            setLoading(false);
            setItemInEdit(null);
            setPage({ take: 10, skip: 0 });
        })
        .catch(handleErrors(props))
    };

    const save = (dataItem) => {
        setLoading(true);
        let matter = dataItem.matter;
        dataItem.matter = null;

        api.request({
            url: `/party`,
            method: dataItem.partyId === 0 ? 'POST' : 'PUT',
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(dataItem)
        }) 
        .then(json => {
            let items = gridItems.slice();
            if (dataItem.partyId === 0) {
                dataItem.partyId = json.partyId;
                dataItem.matter = matter;
                items = [dataItem, ...items];
            } else {
                var index = gridItems.findIndex(v => v.partyId === json.partyId);
                items.splice(index, 1, dataItem);
            }

            parseDates(items);
            setGridItems(items);
            setTotal(items.length);
            setItemInEdit(null);
            setLoading(false);
        })
        .catch(handleErrors(props))
    }

    const cancel = () => {
        setItemInEdit(null);
        setItemInDelete(null);
        setLoading(false);
    }

    const insert = () => {
        setItemInEdit({
            partyId: 0,
            matterId: parseInt(matterId),
            selectMatter: matterId == 0,
            editMode: true,
            client: {}
        });
    }

    const renderCard = () => {
        return (<>{!props.cards && <GenericHeader />}
            <Card style={{ height: props.cardHeight, marginTop: !props.cards ? '35px' : '5px' }}>
                <CardHeader>
                    <CardIcon color='primary' style={{ marginTop: '-30px' }}>
                        <PartyIcon style={{ color: 'white' }} />
                    </CardIcon>
                    <h4 className='jm-card-title' style={{ color: '#777' }}>Parties</h4>
                    {requiresRole(<Button
                        primary={true}
                        className='rounded-circle ml-3 mb-1'
                        icon='plus'
                        title='Add New Party'
                        onClick={insert}
                    />, policies.edit)}                    
                    {/* {matterId > 0 && <span style={{ float: 'right', marginTop:'5px' }}>
                        <Link to={`/parties`}>View All</Link>
                    </span>}                     */}
                    {/* <span className='font-italic' style={{ fontSize: '12px', marginLeft: '5px' }}>Add New Party</span>  */}
                </CardHeader>
                <CardBody>
                    {renderGrid()}
                </CardBody>                
            </Card></>
        );
    }

    const renderGrid = () => {
        return (
            <>
                <Grid
                    filterable={false}
                    sortable={{
                        allowUnsort: true,
                        mode: 'single'
                    }}
                    sort={sort ? sort.object: []}
                    onSortChange={({ sort }) => {        
                        if (sort && sort.length > 0) {
                            var arr = sort.map((o, i) => {
                                return `sort[${i}].Property=${o.field}&sort[${i}].Direction=${o.dir == 'asc' ? 'Ascending' : 'descending'}`;
                            });
                            var str = arr.join('&');
                            setSort({object: sort, query: str});
                        } else {
                            setSort(defaultSort);
                        }

                    }}
                    pageable={matterId > 0 ? false : props.pagerSettings}
                    className={`clickableRow ${matterId > 0 ? '' : ''}`}
                    data={gridItems}
                    total={total}
                    take={page.take}
                    skip={page.skip}
                    onPageChange={(ev) => setPage(ev.page)}
                    onRowClick={({ dataItem }) => { edit(dataItem, false) }}
                    style={{ height: props.gridHeight }}
                >
                    {matterId == 0 && <GridColumn field="matterNumber" title="Matter Number" />}
                    <GridColumn title="Full Name" cell={(col) => <td>{col.dataItem.firstName} {col.dataItem.lastName}</td>} />

                    <GridColumn field="mobilePhone" title="Mobile Phone" />
                    <GridColumn field="dateOfBirth" title="DOB" format="{0:dd/MM/yyyy}" />
                    <GridColumn title="Address" cell={(col) => {
                        //const addr = col.dataItem.partyAddresses.find((v, i, o) => v.isLastKnownAddress === true);
                        //console.log('col.dataItem.partyAddresses', col, col.dataItem);
                        if (col.dataItem && col.dataItem.partyAddresses) {
                            const addr = col.dataItem.partyAddresses[0];
                            const { type, address } = formatAddress(addr);
                            return <td>
                                {addr && `${address}`}
                            </td>
                        } else {
                            return <td></td>
                        }
                    }} />
                    <GridColumn field="email" title="Email" />
                    <GridColumn field="interest" title="Comments" />
                    {/* <GridColumn cell={cellWithEditing(edit, remove)} width={90} /> */}

                    
                </Grid>
                
                
                
                {loading && <LoadingPanel />}
                {itemInEdit && <PartyEditor dataItem={itemInEdit} save={save} cancel={cancel} editMode={itemInEdit.editMode ?? false} delete={deleteItem}  />}
                {/* {itemInDelete && <ConfirmDelete title={`Delete Party "${itemInDelete.firstName} ${itemInDelete.lastName}"?`} cancel={cancel} deleteItem={deleteItem} />} */}
            </>
        );
    }

    return props.view == "grid" ? renderGrid() : renderCard();

    // return (
    //     <Card style={{ height: props.cardHeight }}>
    //         <CardHeader>
    //             <CardIcon color='primary' style={{ marginTop: '-30px' }}>
    //                 <PartyIcon style={{ color: 'white' }} />
    //             </CardIcon>
    //             <h4 className='jm-card-title' style={{ color: '#777' }}>Parties</h4>
    //             {requiresRole(<Button
    //                 primary={true}
    //                 className='rounded-circle float-right'
    //                 icon='plus'
    //                 title='Add New Party'
    //                 onClick={insert}
    //             />, policies.edit)}
                
    //             {/* <span className='font-italic' style={{ fontSize: '12px', marginLeft: '5px' }}>Add New Party</span>  */}


    //         </CardHeader>
    //         <CardBody>
    //             <Grid
    //                 filterable={false}
    //                 sortable={true}
    //                 pageable={matterId > 0 ? false : props.pagerSettings}
    //                 className={`clickableRow ${matterId > 0 ? 'no-pager' : ''}`}
    //                 data={gridItems}
    //                 total={total}
    //                 take={page.take}
    //                 skip={page.skip}
    //                 onPageChange={(ev) => setPage(ev.page)}
    //                 onRowClick={({ dataItem }) => { edit(dataItem, false) }}
    //                 style={{ height: props.gridHeight }}
    //             >
    //                 {matterId == 0 && <GridColumn field="matterNumber" title="Matter Number" />}
    //                 <GridColumn field="firstName" title="First Name" />
    //                 <GridColumn field="lastName" title="Last Name" />
    //                 <GridColumn field="email" title="Email" />
    //                 <GridColumn field="homePhone" title="Home Phone" />
    //                 {/* <GridColumn cell={cellWithEditing(edit, remove)} width={90} /> */}

                    
    //             </Grid>
                
    //             {matterId > 0 &&<div className='grid-footer'>
    //                 <span style={{ float: 'right', marginTop:'5px' }}>
    //                     <Link to={`/parties`}>View All</Link>
    //                 </span>
    //             </div>}
    //         </CardBody>

    //         {loading && <LoadingPanel />}
    //         {itemInEdit && <PartyEditor dataItem={itemInEdit} save={save} cancel={cancel} editMode={itemInEdit.editMode ?? false} delete={deleteItem}  />}
    //         {/* {itemInDelete && <ConfirmDelete title={`Delete Party "${itemInDelete.firstName} ${itemInDelete.lastName}"?`} cancel={cancel} deleteItem={deleteItem} />} */}
            
    //     </Card>
    //)
}

export default withSnackbar(withRouter(Party));