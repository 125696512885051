import React, { useState, useEffect, useRef, useContext } from 'react';
import { withRouter } from "react-router-dom";
import _ from 'lodash';

import MatterEditor from "./MatterEditor";

import LoadingPanel from '../common/Grid/LoadingPanel';
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import CardIcon from "../common/MuiKit/Card/CardIcon";
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";
import cellWithEditing from "../common/Grid/CellWithEditing";
import { ConfirmDelete } from "../common/Dialog";

import GavelIcon from "@material-ui/icons/Gavel";
import { withSnackbar } from 'notistack';

import KendoGridDateCell from "../../util/KendoGridDateCell";
import handleErrors from '../../util/handleErrors';
import api from "../../util/api"
import moment from "moment";

// import { SplitButton } from '@progress/kendo-react-buttons';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
// import { ComboBox, AutoComplete } from '@progress/kendo-react-dropdowns';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { ProfileContext } from '../store';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { Switch } from '@progress/kendo-react-inputs';
// import llamaLogo from "../assets/img/Llama logo.png";
// import jacmacLogo from "../assets/img/Jacmac with text.png";
// import { Input } from '@progress/kendo-react-inputs';
import Header from "../common/Header";

const Matter = (props) => {
    // const initLookupObject = { loading: false, list: [] };
    const [matters, setMatters] = useState([]);
    const [total, setTotal] = useState(0);
    const initPage = { take: 10, skip: 0 };
    const [page, setPage] = useState(initPage)
    const defaultSort = { object: [], query: '' };
    const [sort, setSort] = useState(defaultSort);
    const [toggleLoad, setToggleLoad] = useState(false);
    const [toggleActiveOnly, setToggleActiveOnly] = useState(false);
    const [loading, setLoading] = useState(false);
    const [itemInEdit, setItemInEdit] = useState(null);
    const [itemInDelete, setItemInDelete] = useState(null);
    // const [ user, setUser] = useState({ lookupId: 0, lookupValue: 'All' });
    // const [ due, setDue] = useState(false);
    // const [ recent, setRecent] = useState(false);
    // const [ users, setUsers] = useState(initLookupObject);
    // const [ exportData, setExportData] = useState([]);
    // const _exportReportRef = useRef();

    const { state: { stage, manager }, dispatch } = useContext(ProfileContext);
    //const [ filterData, setFilterData] = useState({ url: `/matter/paged/${(page.skip / page.take) + 1}/${page.take}/${manager ? manager.id: 0}/0/0/${stage ? stage.lookupId : 0}${sort ? `?${sort.query}` : ``}` });

    const [matterStages, setMatterStages] = useState([]);
    const defaultMatterStage = { lookupId: 0, lookupValue: 'All' };
    const [matterStage, setMatterStage] = useState(stage || defaultMatterStage);

    const [fileManagers, setFileManagers] = useState([]);
    const defaultFileManager = { id: 0, userName: 'all', fullName: 'All Staff' };
    const [fileManager, setFileManager] = useState(manager || defaultFileManager);

    const _export = useRef(null);
    const _grid = useRef(null);

    useEffect(() => {
        api.get('user/colleagues', { accept: 'application/json' })
            .then(json => {
                // var user = json.find(v => v.userName === localStorage.getItem('Llama.username'));
                // setFileManager(user);
                // dispatch({ type: 'setManager', payload: user });
                setFileManagers([defaultFileManager, ...json]);
            })
        api.get('lookup/BlockType', { accept: 'application/json' })
            .then(json => {
                setMatterStages([defaultMatterStage, ...json]);
            })
    }, []);

    // const exportReport = () => {
    //     setLoading(true); 
    //     let exportPage = { take: 10000, skip: 0 };
    //     let currentDue = due;
    //     let currentUser = user;
    //     let currentRecent = recent;
    //     let url = `/matter/paged/${(exportPage.skip / exportPage.take) + 1}/${exportPage.take}/${currentUser.lookupId}/${(currentDue ? 1 : 0)}/${(currentRecent ? 1 : 0)}`;

    //     api.get(url, {
    //         accept: 'application/json',
    //     })
    //     .then(json => {
    //         setExportData(json.data);
    //         setLoading(false);
    //         _exportReportRef.current.save();
    //     })
    //     .catch(handleErrors(props))
    // }

    useEffect(() => {
        setLoading(true);
        const filter = toggleActiveOnly ? 'filter[0].Property=matterStatusId&filter[0].Operator=Equals&filter[0].Value=51' : '';
        const url = `/matter/paged/${(page.skip / page.take) + 1}/${page.take}/${fileManager.id}/0/0/${matterStage.lookupId}?${filter ? filter + '&' : '' }${sort ? `${sort.query}` : ``}`

        api.get(url, {
            accept: 'application/json',
        })
            .then(json => {
                setMatters(json.data);
                setTotal(json.totalRecords);
                setLoading(false);
            })
            .catch(handleErrors(props))
    }, [page, sort, toggleLoad, toggleActiveOnly]);

    const edit = (dataItem) => {
        const matter = Object.assign({}, dataItem)
        Object.keys(matter).forEach(key => { if (matter[key] == null) { matter[key] = '' } })
        setItemInEdit(matter);
    };

     const remove = (dataItem) => {
         setItemInDelete(dataItem);
     };

    const deleteItem = (itemInDelete) => {
        setLoading(true);
        api.delete(`/matter/${itemInDelete.matterId}`, {
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(json => {
            setLoading(false);
            setItemInDelete(null);
            setItemInEdit(null);
            setPage({ take: 10, skip: 0 });
            props.enqueueSnackbar(`Matter ${itemInDelete.matterNumber} has been deleted successfully.`, { variant: 'info' });
        })
        .catch(handleErrors(props))
    };

    const save = (dataItem) => {
        setLoading(true);

        api.request({
            url: '/matter',
            method: dataItem.matterId === 0 ? 'POST' : 'PUT',
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(dataItem)
        })
            .then(json => {

                let items = matters.slice();
                if (dataItem.matterId === 0) {
                    dataItem.matterId = json.matterId;
                    items = [dataItem, ...items];
                } else {
                    var index = matters.findIndex(v => v.matterId === json.matterId);
                    items.splice(index, 1, dataItem);
                }

                setMatters(items);
                setTotal(items.length);
                setItemInEdit(null);
                setLoading(false);
            })
            .catch(handleErrors(props))
    }

    const cancel = () => {
        setItemInEdit(null);
        setItemInDelete(null);
        setLoading(false);
    }

    // const insert = () => {
    //     setItemInEdit({ matterId: 0, matterNumber: 'New Matter Number', clientContact: {}, manager: {}, isSensitiveMatter: false });
    // }

    // const newInstruction = () => {
    //     let dataItem = { matterId: 0, matterNumber: 'New Matter Number', clientContact: {}, manager: {}, isSensitiveMatter: false };
    //     setLoading(true);

    //     api.request({
    //         url: '/matter',
    //         method: dataItem.matterId === 0 ? 'POST' : 'PUT',
    //         accept: 'application/json',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         data: JSON.stringify(dataItem)
    //     }) 
    //     .then(json => {

    //         let items = matters.slice();
    //         dataItem.matterId = json.matterId;
    //         items = [dataItem, ...items];
    //         setMatters(items);
    //         setTotal(items.length);
    //         setItemInEdit(null);
    //         setLoading(false);

    //         props.history.push(`/instruction/${dataItem.matterId}`);
    //     })
    //     .catch(handleErrors(props))
    // }

    // const userAutoCompleteChanged = ({ value, ...rest }) => {
    //     setUser({ lookupId: user.lookupId, lookupValue: value });

    //     if (value.length < 3) { return };

    //     if (!(rest.nativeEvent instanceof InputEvent)) {
    //         const userItem = _.find(users.list, o => o.lookupValue === value);
    //         if (userItem) {
    //             let currentDue = due;
    //             let currentRecent = recent;
    //             setFilterData({ url: `/matter/paged/${(initPage.skip / initPage.take) + 1}/${initPage.take}/${userItem.lookupId}/${(currentDue ? 1 : 0)}/${(currentRecent ? 1 : 0)}` })
    //             setUsers({ list: [], loading: false });
    //             setPage(initPage);
    //             setUser(userItem);
    //             return;
    //         }
    //     }

    //     loadUsers(value);
    // }

    // const loadUsers = (value) => {
    //     setUsers({ list: [], loading: true });
    //     api.get(`/lookup/user/${value}`, {
    //         accept: 'application/json',
    //     })
    //     .then(json => {
    //         let data = json.slice();
    //         data = [{lookupId:0, lookupValue: 'All'}, ...data];
    //         setUsers({ list: data || [], loading: false });
    //     })
    //     .catch(handleErrors(props))
    // }

    const matterInstruction = (dataItem) => {
        props.history.push(`/instruction/${dataItem.matterId}`);
    }

    return (
        <GridContainer>
            <Header setLoading={setLoading} loading={loading} title="Matters" />
            <GridItem xs={12}>
                <Card style={{ height: props.cardHeight, marginTop: '35px' }}>
                    <CardHeader>
                        <CardIcon color='warning' style={{ marginTop: '-30px' }}>
                            <GavelIcon style={{ color: 'white' }} />
                        </CardIcon>
                        <h4 className='jm-card-title' style={{ color: '#777' }}>Files</h4>
                        <GridContainer className='my-3'>
                            <GridItem xs={2}>
                                <DropDownList
                                    data={matterStages}
                                    value={matterStage}
                                    textField="lookupValue"
                                    dataItemKey="lookupId"
                                    style={{ width: '100%' }}
                                    onChange={({ value }) => {
                                        setMatterStage(value);
                                        dispatch({ type: 'setStage', payload: value.lookupId });
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={2}>
                                <DropDownList
                                    data={fileManagers}
                                    value={fileManager}
                                    textField="fullName"
                                    dataItemKey="id"
                                    style={{ width: '100%' }}
                                    onChange={({ value }) => {
                                        setFileManager(value);
                                        dispatch({ type: 'setManager', payload: value.id });
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={1}>
                                <Switch onLabel='Active Only' offLabel='All' className='mb-1 w-100'
                                    checked={toggleActiveOnly}
                                    onChange={(e) => {
                                        setToggleActiveOnly(!toggleActiveOnly);
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={1}>
                                <Button
                                    primary={false}
                                    className='jm-action-btn k-title'
                                    onClick={() => {
                                        //setFilterData({ url: `/matter/paged/1/${page.take}/${fileManager.id}/${(due ? 1 : 0)}/0/${matterStage.lookupId}` });
                                        setToggleLoad(!toggleLoad);
                                    }}
                                    style={{ borderRadius: '8px' }}
                                >
                                    SEARCH 
                                </Button>
                            </GridItem>
                            <GridItem xs={3}>
                                <ExcelExport
                                    ref={_export}
                                />
                                <Button
                                    primary={false}
                                    className='jm-action-btn k-title'
                                    onClick={() => {
                                        api.get(`/matter/paged/1/1000/${fileManager.id}/0/0/${matterStage.lookupId}`, {
                                            accept: 'application/json',
                                        }).then(json => {
                                            //console.log('json', json);
                                            json.data.forEach(item => {
                                                if (item.instructionDate) {
                                                    item.instructionDate = moment(item.instructionDate).toDate();
                                                }
                                                item.securityAccountDetails = '';
                                                item.securityPostCode = '';
                                                item.accNumbers = '';

                                                if (item.securities) {
                                                    item.securities.forEach(sec => {
                                                        item.securityPostCode += ', ' + sec.addressPostCode;
                                                        item.securityAccountDetails += ', ' + `${!sec.addressUnitNumber ? '' : sec.addressUnitNumber + ' '}${!sec.addressStreetNumber ? '' : sec.addressStreetNumber + ' '}${!sec.addressStreetName ? '' : sec.addressStreetName + ' '}${!sec.addressSuburb ? '' : sec.addressSuburb + ' '}${!sec.addressState ? '' : sec.addressState + ' '}${!sec.addressPostCode ? '' : sec.addressPostCode + ' '}`;
                                                    });
                                                }

                                                if (item.accounts) {
                                                    item.accounts.forEach(acc => {
                                                        item.accNumbers += ', ' + acc.accountNumber;
                                                    });
                                                }

                                                if (item.securityPostCode.length > 0) {
                                                    item.securityPostCode = item.securityPostCode.substring(2, item.securityPostCode.length);
                                                }

                                                if (item.securityAccountDetails.length > 0) {
                                                    item.securityAccountDetails = item.securityAccountDetails.substring(2, item.securityAccountDetails.length);
                                                }

                                                if (item.accNumbers.length > 0) {
                                                    item.accNumbers = item.accNumbers.substring(2, item.accNumbers.length);
                                                }
                                            });
                                            _export.current.save(json, _grid.current.columns)
                                        })
                                    }}
                                    style={{ borderRadius: '8px' }}
                                > 
                                    EXPORT TO EXCEL
                                </Button> 
                                <Grid ref={_grid} style={{ display: 'none' }}>
                                    <GridColumn field="matterNumber" title="File Number" />
                                    <GridColumn field="accNumbers" title="Account Number" />
                                    <GridColumn field="clientContact.lookupValue" title="Client Contact" />
                                    <GridColumn field="manager.lookupValue" title="Jacmac Contact" />
                                    <GridColumn field="securityAccountDetails" title="Securities" />
                                    <GridColumn field="securityPostCode" title="Security PostCode" />
                                    <GridColumn field="sensitivities" title="Sensitivities" />
                                    <GridColumn field="overview" title="Overview" />
                                </Grid>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        <Grid
                            className='clickableRow'
                            filterable={false}
                            sortable={{
                                allowUnsort: true,
                                mode: 'single'
                            }}
                            sort={sort ? sort.object : []}
                            onSortChange={({ sort }) => {

                                if (sort && sort.length > 0) {
                                    var arr = sort.map((o, i) => {
                                        //console.log('o: ', o);
                                        return `sort[${i}].Property=${o.field == 'manager.lookupValue' ? 'Manager.FullName' : o.field == 'clientContact.lookupValue' ? 'ClientContact.FullName' : o.field}&sort[${i}].Direction=${o.dir == 'asc' ? 'Ascending' : 'descending'}`;
                                    });
                                    var str = arr.join('&');
                                    setSort({ object: sort, query: str });
                                } else {
                                    setSort(defaultSort);
                                }

                            }}
                            pageable={props.pagerSettings}
                            data={matters}
                            total={total}
                            take={page.take}
                            skip={page.skip}
                            onPageChange={(ev) => {
                                //setFilterData({ url: `/matter/paged/${(ev.page.skip / ev.page.take) + 1}/${ev.page.take}/${fileManager.id}/${(due ? 1 : 0)}/0/${matterStage.lookupId}` });
                                setPage(ev.page);
                            }}
                            onRowClick={({ dataItem }) => {
                                props.history.push(`/files/${dataItem.matterId}`)
                            }}
                            style={{ height: 'calc(100vh - 275px)' }}
                        >
                            <GridColumn field="matterNumber" title="File Number" />
                            <GridColumn field="clientReference" title="Client Matter No." />
                            <GridColumn field="matterName" title="Matter Name" />
                            <GridColumn field="instructionDate" title="Date" cell={KendoGridDateCell} />
                            <GridColumn field="manager.lookupValue" title="File Manager" />
                            <GridColumn field="clientContact.lookupValue" title="Client Contact" />
                            <GridColumn field="matterStatus.lookupValue" title="Matter Status" />
                            <GridColumn cell={cellWithEditing(edit, null, false, matterInstruction)} width={100} />
                        </Grid>
                    </CardBody>

                    {loading && <LoadingPanel />}
                    {itemInEdit && <MatterEditor dataItem={itemInEdit} save={save} cancel={cancel} delete={deleteItem} />}
                    {itemInDelete && <ConfirmDelete title={`Delete File ${itemInDelete.matterNumber}?`} cancel={cancel} deleteItem={deleteItem} />}

                </Card>
            </GridItem>

        </GridContainer>
    )
}

export default withSnackbar(withRouter(Matter));