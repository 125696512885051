import React, { useState } from 'react';
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from '../common/MuiKit/Card/CardBody';

import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";

import { DatePicker } from '@progress/kendo-react-dateinputs';
import moment from "moment";

const RelatedInstructionEditor = (props) => {
    const itemData = props.stageData;
    const itemList = props.instructionsList;
    let disabled = !props.editMode;

    const handlePartySelected = (partyItem) => {
        let isNoticeSelected = !partyItem.noticeSelected;
        let matterParties = itemData.stage.matterParties.slice();
        var index = matterParties.findIndex(v => v.partyId === partyItem.partyId);

        matterParties.splice(index, 1, {
            ...partyItem,
            noticeSelected: isNoticeSelected,
            registeredLastKnown: isNoticeSelected,
            registeredToTitle: isNoticeSelected,
            registeredToPoBox: isNoticeSelected,
            regularLastKnown: isNoticeSelected,
            copyEmail: isNoticeSelected,
            personalService: isNoticeSelected,
            substitutedService: isNoticeSelected,
        });

        let updatedData = {
            ...itemData,
            stage: {
                ...itemData.stage,
                matterParties: matterParties,
            },
        };

        props.updateRelatedInstructions(updatedData);
    }

    const handleInstructionSelected = (partyItem, instruction) => {

        let isNoticeSelected = partyItem.noticeSelected;
        let isChecked = !partyItem[instruction];
        let tempParty = {
            ...partyItem,
            [instruction]: isChecked,
        }

        updateParent(tempParty, partyItem);
    }

    const handleTextAreaChange = ({ target: { value } }, instruction, partyItem) => {
        let tempParty = {
            ...partyItem,
            [instruction]: value,
        }

        updateParent(tempParty, partyItem);
    }

    const handleDateChange = ({ value }, instruction, partyItem) => {
        let tempParty = {
            ...partyItem,
            [instruction]: value == null ? null : moment(value).local().toDate(),
        }

        updateParent(tempParty, partyItem);
    }

    const updateParent = (tempParty, partyItem) => {
        let updatedParty = {
            ...tempParty,
            noticeSelected: tempParty.registeredLastKnown || tempParty.registeredToTitle || tempParty.registeredToPoBox || tempParty.regularLastKnown || tempParty.copyEmail || tempParty.copyEmail || tempParty.personalService || tempParty.substitutedService || tempParty.servedDate || tempParty.serviceDetails,
        };
        let matterParties = itemData.stage.matterParties.slice();

        var index = matterParties.findIndex(v => v.partyId === partyItem.partyId);
        matterParties.splice(index, 1, updatedParty);

        let updatedData = {
            ...itemData,
            stage: {
                ...itemData.stage,
                matterParties: matterParties,
            },
        };

        props.updateRelatedInstructions(updatedData);
    }

    return (
        <GridItem sm={12}>
            <h5 className='related-title'>Service Instructions</h5>
            <GridContainer>

                {(itemData.stage.matterParties && itemData.stage.matterParties.length > 0) ? itemData.stage.matterParties.map(a => {

                    a.servedDate = a.servedDate ? moment(a.servedDate).toDate() : null;

                    return (

                        <GridItem key={`griditem-01-${a.partyId}`} sm={4}>
                            <Card key={`card-${a.partyId}`} className='related-card-wrapper'>
                                
                                <CardHeader className='related-card-header'>
                                    <h6>Party: {a.party.firstName} {a.party.lastName}</h6>
                                    <input type="checkbox" className='k-checkbox' checked={!!a.noticeSelected} onChange={() => handlePartySelected(a)} name={`partychecked-${a.partyId}`} id={`partychecked-${a.partyId}`} disabled={disabled} />
                                </CardHeader>
                                <CardBody className='related-card-body'>
                                    <GridContainer key={`gridcontainer-${a.partyId}`}>

                                        {itemList.includes('registeredlastknown') &&
                                            <GridItem key={`griditem-02-${a.partyId}`} className='d-flex' sm={12}>
                                            <input type="checkbox" className='k-checkbox' checked={!!a.registeredLastKnown} onChange={() => handleInstructionSelected(a, 'registeredLastKnown')} name={`registeredlastknown-${a.partyId}`} id={`registeredlastknown-${a.partyId}`} disabled={disabled}/>
                                                <label className="k-checkbox-label" htmlFor={`registeredlastknown-${a.partyId}`}>Registered post to last known address</label>
                                            </GridItem>}

                                        {itemList.includes('registeredtotitle') &&
                                            <GridItem key={`griditem-03-${a.partyId}`} className='d-flex' sm={12}>
                                            <input type="checkbox" className='k-checkbox' checked={!!a.registeredToTitle} onChange={() => handleInstructionSelected(a, 'registeredToTitle')} name={`registeredtotitle-${a.partyId}`} id={`registeredtotitle-${a.partyId}`} disabled={disabled}/>
                                                <label className="k-checkbox-label" htmlFor={`registeredtotitle-${a.partyId}`}>Registered post to address on title and on last known address</label>
                                            </GridItem>}

                                        {itemList.includes('registeredtopobox') &&
                                            <GridItem key={`griditem-04-${a.partyId}`} className='d-flex' sm={12}>
                                            <input type="checkbox" className='k-checkbox' checked={!!a.registeredToPoBox} onChange={() => handleInstructionSelected(a, 'registeredToPoBox')} name={`registeredtopobox-${a.partyId}`} id={`registeredtopobox-${a.partyId}`} disabled={disabled}/>
                                                <label className="k-checkbox-label" htmlFor={`registeredtopobox-${a.partyId}`}>Registered post to last known address and PO box </label>
                                            </GridItem>}

                                        {itemList.includes('regularlastknown') &&
                                            <GridItem key={`griditem-05-${a.cccc}`} className='d-flex' sm={12}>
                                            <input type="checkbox" className='k-checkbox' checked={!!a.regularLastKnown} onChange={() => handleInstructionSelected(a, 'regularLastKnown')} name={`regularlastknown-${a.partyId}`} id={`regularlastknown-${a.partyId}`} disabled={disabled}/>
                                                <label className="k-checkbox-label" htmlFor={`regularlastknown-${a.partyId}`}>Regular post to last known address</label>
                                            </GridItem>}

                                        {itemList.includes('copyemail') &&
                                            <GridItem key={`griditem-06-${a.partyId}`} className='d-flex' sm={12}>
                                            <input type="checkbox" className='k-checkbox' checked={!!a.copyEmail} onChange={() => handleInstructionSelected(a, 'copyEmail')} name={`copyemail-${a.partyId}`} id={`copyemail-${a.partyId}`} disabled={disabled}/>
                                                <label className="k-checkbox-label" htmlFor={`copyemail-${a.partyId}`}>Registered post to last known address and copy via email</label>
                                            </GridItem>}

                                        {itemList.includes('personalService') &&
                                            <GridItem key={`griditem-02-${a.partyId}`} className='d-flex' sm={6}>
                                            <input type="checkbox" className='k-checkbox' checked={!!a.personalService} onChange={() => handleInstructionSelected(a, 'personalService')} name={`personalService-${a.partyId}`} id={`personalService-${a.partyId}`} disabled={disabled}/>
                                                <label className="k-checkbox-label" htmlFor={`personalService-${a.partyId}`}>Personal service</label>
                                            </GridItem>}

                                        {itemList.includes('substitutedService') &&
                                            <GridItem key={`griditem-03-${a.partyId}`} className='d-flex' sm={6}>
                                            <input type="checkbox" className='k-checkbox' checked={!!a.substitutedService} onChange={() => handleInstructionSelected(a, 'substitutedService')} name={`substitutedService-${a.partyId}`} id={`substitutedService-${a.partyId}`} disabled={disabled}/>
                                                <label className="k-checkbox-label" htmlFor={`substitutedService-${a.partyId}`}>Substituted service </label>
                                            </GridItem>}

                                        {itemList.includes('serviceDetails') &&
                                            <GridItem sm={12} className='mt-3'>
                                                <span className='k-textbox-container'>
                                                    <label className='k-label'>Details of service</label>
                                                    <textarea
                                                        className='k-textarea w-100'
                                                        value={a.serviceDetails ? a.serviceDetails : '' }
                                                        onChange={(ev) => handleTextAreaChange(ev, 'serviceDetails', a)}
                                                        name={`serviceDetails-${a.partyId}`}
                                                        id={`serviceDetails-${a.partyId}`}
                                                        rows={3}
                                                        disabled={disabled}
                                                    />
                                                </span>
                                            </GridItem>
                                        }

                                        {itemList.includes('servedDate') &&
                                            <GridItem sm={12} className='mt-3'>
                                                <span className='k-textbox-container'>
                                                    <label className='k-label'>Served Date</label>
                                                    <DatePicker
                                                        format="dd/MM/yyyy"
                                                        name={`servedDate-${a.partyId}`}    
                                                        id={`servedDate-${a.partyId}`}    
                                                        onChange={(ev) => handleDateChange(ev, 'servedDate', a)}
                                                        value={a.servedDate}
                                                        label=""
                                                        disabled={disabled}
                                                    />
                                                </span>
                                            </GridItem>
                                        }
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                    );
                }) : <p>No data</p>}

            </GridContainer>
            <p style={{ marginBottom: '5px' }}>&nbsp;</p>
        </GridItem>); 
}

export default RelatedInstructionEditor;
