import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import moment from 'moment';

import api from "../../util/api"
//import KendoGridDateCell from "../../util/KendoGridDateCell";
import withRouterAndRef from '../../util/withRouterAndRef';
import handleErrors from '../../util/handleErrors';
import { withSnackbar } from 'notistack';

import { Grid, GridColumn } from '@progress/kendo-react-grid';

import { Button } from '@progress/kendo-react-buttons';
import { Switch } from '@progress/kendo-react-inputs';
import { ExcelExport } from '@progress/kendo-react-excel-export';

import TaskEditor from "./TaskEditor";
import TaskSummary from './TaskSummary';

//import { ConfirmDelete } from "../common/Dialog";
//import cellWithEditing from "../common/Grid/CellWithEditing";

import GenericHeader from "../common/Header/GenericHeader"; 
import LoadingPanel from '../common/Grid/LoadingPanel';
import CardIcon from "../common/MuiKit/Card/CardIcon";
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import ListIcon from "@material-ui/icons/List";

const saveTask = (dataItem, props, afterSave, setLoading) => {
    setLoading(true);   
        
        api.request({
            url: `/task`,
            method: dataItem.taskId === 0 ? 'POST' : 'PUT',
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(dataItem)
        }) 
        .then(json => {
            afterSave(json);
            
        })
        .catch(handleErrors(props))
}

const Task = forwardRef((props, ref) => {


    useImperativeHandle(ref, () => ({
        insert
    }));

    const [ tasks, setTasks ] = useState([]);
    const [ total, setTotal ] = useState(0);
    const initPage = { take: 10, skip: 0 };
    const [ page, setPage] = useState(initPage)
    const defaultSort = {object: [{field: "dueDate", dir: "desc"}], query: 'sort[0].Property=dueDate&sort[0].Direction=descending'};
    const [ sort, setSort ] = useState(defaultSort);
    const [ loading, setLoading ] = useState(false);
    const [ itemInEdit, setItemInEdit ] = useState(null);
    const [ itemInDelete, setItemInDelete] = useState(null);
    const [ itemSort, setItemSort] = useState(null); 
    const [ itemFilter, setItemFilter] = useState(null); 
    const [matterId] = useState(props.match.params.id ?? 0);
    const [ incomplete, setIncomplete ] = useState(true);
    const _export = useRef(null);
    const _grid = useRef(null);
    const tasksSummaryRef = useRef();

    useEffect(() => {
        if (!props.summary) {
            setLoading(true);        
            //console.log('sort: ', sort);
            api.get(`/task/${matterId}/paged/${(page.skip / page.take) + 1}/${page.take}/${incomplete}${sort.query ? `?${sort.query}` : ``}`, {                
                accept: 'application/json',
            }) 
            .then(json => {
                json.data.forEach(item => {
                    if (item.dueDate) {
                        item.dueDate = moment(item.dueDate).toDate();
                    }
                    if (item.taskDate) {
                        item.taskDate = moment(item.taskDate).toDate();
                    }
                    if (item.completedDate) {
                        item.completedDate = moment(item.completedDate).toDate();
                    }
                });

                setTasks(json.data);
                setTotal(json.totalRecords);
                setLoading(false);
            })
            .catch(handleErrors(props))
        }
    }, [page, sort, incomplete]);

    const edit = (dataItem) => {
        const task = Object.assign({}, dataItem)
        Object.keys(task).forEach(key => { if (task[key] == null) { task[key] = '' }})
        setItemInEdit(task);
    };

    const remove = (dataItem) => {
        setItemInDelete(dataItem);
    };

    const deleteItem = (dataItem) => {
        setLoading(true);
      
        api.delete(`/task/${dataItem.taskId}`, {
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
        .then(json => {
            setLoading(false);
            setItemInEdit(null);
            if (tasksSummaryRef && tasksSummaryRef.current) { 
                props.load();
            } else {
                setPage({ take: 10, skip: 0 });
            }
        })
        .catch(handleErrors(props))
    };
    
    const save = (dataItem) => {
        saveTask(dataItem, props, (json) => {
            if (tasksSummaryRef && tasksSummaryRef.current) { 
                props.load();             
                setItemInEdit(null);
            } else {
                let items = tasks.slice();
                if (dataItem.taskId === 0) {
                    dataItem.taskId = json.taskId;
                    items = [dataItem, ...items];
                } else {
                    var index = tasks.findIndex(v => v.taskId === json.taskId);            
                    items.splice(index, 1, dataItem);
                }
                
                setTasks(items);
                setTotal(items.length);
                setItemInEdit(null);
                setLoading(false);
            }
        }, setLoading);
    }

    const cancel = (ev, reload) => {
        setItemInEdit(null);
        setItemInDelete(null);
        setLoading(false);
    }

    const insert = () => {
        setItemInEdit({ taskId: 0, matterId: props.match.params.id });
    }

    const patch = (data, id) => {
        setLoading(true);
        api.request({
            url: `/task/${id}`,
            method: 'PATCH',
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        }) 
        .then(json => { 
            props.load();
            setItemInEdit(null);
            setLoading(false);             
        })
        .catch(handleErrors(props));
    }

    const filterChange = (ev) => {
        setItemFilter(ev.filter);
    }
    const sortChange = (ev) => {
        setItemSort(ev.sort);
    }

    const renderEditors = (forceEdit = false) => {
        return (
            <React.Fragment>
                {itemInEdit && <TaskEditor dataItem={itemInEdit} save={save} cancel={cancel} patch={patch} editMode={itemInEdit.taskId == 0 || forceEdit} delete={deleteItem} type={props.type} />}
                {/* {itemInDelete && <ConfirmDelete title={`Delete Task ${itemInDelete.title}?`} cancel={cancel} deleteItem={deleteItem} />} */}
            </React.Fragment>            
        )
    }

    const renderCard = () => {
        return (<>{!props.cards && <GenericHeader />}
            <Card style={{height: props.cardHeight, marginTop: !props.cards ? '35px' : '5px'}}>
                <CardHeader>
                    <CardIcon color='rose' style={{marginTop: '-30px'}}>
                        <ListIcon style={{color:'white'}} />
                    </CardIcon>
                    <h4 className='jm-card-title' style={{color: '#777'}}>Tasks</h4>
                    <GridContainer className='my-3'>
                        <GridItem xs={1}>
                            <Button
                                primary={true}
                                className='jm-action-btn jm-header-btn rounded-circle'
                                icon='plus'
                                title='Add New'
                                onClick={insert}
                                style={{ marginTop: '-4px' }}
                            />
                        </GridItem>
                        <GridItem xs={1}>
                            <Switch onLabel='Incomplete' offLabel='All' className='mb-1 w-100'
                                checked={incomplete}
                                onChange={(e) => {
                                    setIncomplete(!incomplete);
                                }}
                            />
                        </GridItem>
                        <GridItem xs={3}>
                            <ExcelExport ref={_export} />
                            <Button
                                primary={false}
                                className='jm-action-btn k-title'
                                onClick={() => {
                                    api.get(`/task/${matterId}/paged/1/10000/${incomplete}${sort.query ? `?${sort.query}` : ``}`, {
                                        accept: 'application/json',
                                    }).then(json => {
                                        json.data.forEach(item => {
                                            if (item.dueDate) {
                                                item.dueDate = moment(item.dueDate).format('d/MM/YYYY');
                                            }
                                            if (item.taskDate) {
                                                item.taskDate = moment(item.taskDate).format('d/MM/YYYY');
                                            }
                                            if (item.completedDate) {
                                                item.completedDate = moment(item.completedDate).format('d/MM/YYYY');
                                            }
                                        });
                                        _export.current.save(json, _grid.current.columns)
                                    })
                                }}
                                style={{ borderRadius: '8px' }}
                            >
                                EXPORT TO EXCEL
                                </Button>
                            <Grid ref={_grid} style={{ display: 'none' }}>
                                {matterId == 0 && <GridColumn field="matter.matterName" title="Matter" />}
                                <GridColumn field="dueDate" title="Due Date" />
                                <GridColumn field="title" title="Task" />
                                <GridColumn field="user.lookupValue" title="Assigned To" />
                                <GridColumn field="creatorName" title="Assigned By" />
                                <GridColumn field="taskDate" title="Assigned On" />
                                <GridColumn field="completedDate" title="Completed On" />
                            </Grid>
                        </GridItem>
                    </GridContainer>
                </CardHeader>
                <CardBody>
                    {renderGrid(false)}
                </CardBody>
            </Card></>
        );
    }

    const renderGrid = (forceEdit = false) => {
        return (
            <React.Fragment>
                <Grid
                    filterable={false}
                    filter={itemFilter}
                    sortable={{
                        allowUnsort: true,
                        mode: 'single'
                    }}
                    sort={sort ? sort.object : []}
                    onSortChange={(e) => {       
                        //console.log('e.sort: ', e.sort);
                        if (e.sort && e.sort.length > 0) {
                            var arr = e.sort.map((o, i) => {
                                if (o.field === "user.lookupValue") {
                                    return `sort[${i}].Property=user.firstName&sort[${i}].Direction=${o.dir == 'asc' ? 'ascending' : 'descending'}`;
                                }
                                else if (o.field === "creatorName") {
                                    return `sort[${i}].Property=creatorUser.firstName&sort[${i}].Direction=${o.dir == 'asc' ? 'ascending' : 'descending'}`;
                                }
                                else {
                                    return `sort[${i}].Property=${o.field}&sort[${i}].Direction=${o.dir == 'asc' ? 'ascending' : 'descending'}`;
                                }
                            });
                            var str = arr.join('&');
                            //console.log('str: ', str);
                            setSort({object: e.sort, query: str});
                        } else {
                            setSort({object: [], query: ''});
                        }
                    }}
                    pageable={props.pagerSettings}
                    data={tasks}
                    total={total}
                    take={page.take}
                    skip={page.skip}
                    onPageChange={(ev) => setPage(ev.page)}
                    onFilterChange={filterChange}
                    style={{ height: 'calc(100vh - 275px)' }}
                    onRowClick={({ dataItem }) => { edit(dataItem, false) }}
                    className='clickableRow'
                >
                    { matterId == 0 && <GridColumn field="matter.matterName" title="Matter" /> }
                    <GridColumn field="dueDate" title="Due Date" format="{0:dd/MM/yyyy}" />
                    <GridColumn field="title" title="Task" />
                    <GridColumn field="user.lookupValue" title="Assigned To" />
                    <GridColumn field="creatorName" title="Assigned By" />
                    <GridColumn field="taskDate" title="Assigned On" format="{0:dd/MM/yyyy}" />
                    <GridColumn field="completedDate" title="Completed On" format="{0:dd/MM/yyyy}" />

                    {/* <GridColumn field="taskStatus.lookupValue" title="Status" /> */}
                    {/* <GridColumn field="taskPriority.lookupValue" title="Priority" /> */}
                    {/* <GridColumn cell={cellWithEditing(edit, remove)} width={90} filterable={false}  /> */}
                </Grid>
                {loading && <LoadingPanel/>}
                {renderEditors(forceEdit)}
            </React.Fragment>
        );
    }

    return props.summary ?
        <TaskSummary {...props} edit={edit} load={props.load} deleteItem={deleteItem} renderEditors={renderEditors} ref={tasksSummaryRef} /> :
        props.matterStageId ?
            renderGrid() :
            renderCard();
});

export default withSnackbar(withRouterAndRef(Task));
export { saveTask };