import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { findDOMNode } from "react-dom";

import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import Dialog from "../common/Dialog";
import { Input } from '@progress/kendo-react-inputs';
import { ComboBox, AutoComplete } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';

import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import api from "../../util/api"
import moment from "moment";

import { withSnackbar } from 'notistack';
import handleErrors from '../../util/handleErrors';


const MatterEditor = (props) => {
    const [errors, setErrors] = useState({});
    const initLookupObject = { loading: false, list: [] };
    const originalItem = {
        ...props.dataItem,
        instructionDate: (props.dataItem.instructionDate == null || props.dataItem.instructionDate === "") ? moment().toDate() : moment(props.dataItem.instructionDate).toDate()
    };
    const [matter, setMatter] = useState(originalItem);
    const [originalMatter, setOriginalMatter] = useState(originalItem);

    const [matterTypes, setMatterTypes] = useState(initLookupObject);
    const [matterStatuses, setMatterStatuses] = useState(initLookupObject);
    const [users, setUsers] = useState(initLookupObject);

    const handleTextAreaChange = ({ target: { value, name } }) => {
        setMatter({ ...matter, [name]: value });
    }
    const handleInputChange = ({ value, target: { name } }) => {
        setMatter({...matter, [name]: value });
    }
    const handleSelectChange = ({ value, target: { name } }) => {
        const idField = `${name}Id`;
        setMatter({...matter, [name]: value, [idField]: value ? value.lookupId : null });
    }
    const handleDateChange = ({ value, target: { name } }) => {
        setMatter({ ...matter, [name]: value == null ? null : moment(value).local().toDate()});
    }
    const handleSensitiveMatter = ({ target: { value, name, checked } }) => {
        setMatter({ ...matter, 'isSensitiveMatter': checked });
    }

    const userAutoCompleteChanged = ({ value, ...rest }) => {
        
        setMatter({ ...matter, [rest.target.name]: { ...matter[rest.target.name], lookupValue: value } });

        if (value.length < 3) {
            return
        };

        if (!(rest.nativeEvent instanceof InputEvent)) {
            const user = _.find(users.list, o => o.lookupValue === value);
            if (user) {
                setMatter({ ...matter, [rest.target.name]: user, [rest.target.name + 'Id']: user.lookupId });
                setUsers([]);
                return;
            }
        }

        loadUsers(value);
    }

    const loadUsers = (value) => {
        setUsers({ list: [], loading: true });
        api.get(`/lookup/user/${value}`, {
            accept: 'application/json',
        })
        .then(json => {
            setUsers({ list: json || [], loading: false });
        })
        .catch(handleErrors(props))
    }

    const setLookup = (setFunc) => (json) => {
        setFunc({ loading: false, list: json });
        return json;
    }

    useEffect(() => {
        const axiosConfig = { accept: 'application/json' };
        const loading = { loading: true, list: [] };
        setMatterTypes(loading);
        setMatterStatuses(loading);

        let onLoadTasks = [
            api.get(`/lookup/mattertype`, axiosConfig).then(setLookup(setMatterTypes)), 
            api.get(`/lookup/matterstatus`, axiosConfig).then(setLookup(setMatterStatuses)), 
        ];

        if (matter && matter.matterId > 0) {
            onLoadTasks.push(api.post(`/matter/isviewed/${matter.matterId}`, axiosConfig))
        }

        Promise.all(onLoadTasks)
            .then(responses => {

                let tempItem = { ...matter };

                let matterType = tempItem.matterType;
                if (tempItem.matterTypeId && tempItem.matterTypeId > 0) {
                    matterType = responses[0].find(v => v.lookupId === tempItem.matterTypeId);
                }
                let matterStatus = tempItem.matterStatus;
                if (tempItem.matterStatusId && tempItem.matterStatusId > 0) {
                    matterStatus = responses[1].find(v => v.lookupId === tempItem.matterStatusId);
                }

                tempItem = { ...tempItem, matterType: matterType, matterStatus: matterStatus, matterStatusId: matterStatus ? matterStatus.lookupId : null  };
                setMatter(tempItem);
                setOriginalMatter(tempItem);
            })
            .catch(handleErrors(props))
    }, []);

    const validateForm = () => {
        var validForm = true;
        var formErrors = {};
        formErrors.matterNumber = (!matter.matterNumber || matter.matterNumber === '');
        //formErrors.loanNumber = (!matter.loanNumber || matter.loanNumber === '');
        formErrors.instructionDate = (!matter.instructionDate || matter.instructionDate === '');
        //formErrors.matterType = (!matter.matterType || matter.matterType === '' || !matter.matterType.lookupId);
        formErrors.matterStatus = (!matter.matterStatus || matter.matterStatus === '' || !matter.matterStatus.lookupId);
        formErrors.clientReference = (!matter.clientReference || matter.clientReference === '');
        formErrors.clientContact = (!matter.clientContact || matter.clientContact === '' || !matter.clientContact.lookupId);
        formErrors.manager = (!matter.manager || matter.manager === '' || !matter.manager.lookupId);
        console.log('formErrors: ', formErrors);

        setErrors(formErrors);

        const item = Object.assign({}, formErrors)
        validForm = !Object.keys(item).some(x => item[x]);

        return validForm;
    }
    //const handleSaveForm = () => {
       
    //    var validForm = true;
    //    var formErrors = {};
    //    formErrors.matterNumber = (!matter.matterNumber || matter.matterNumber === '');
    //    formErrors.instructionDate = (!matter.instructionDate || matter.instructionDate === '');
    //    formErrors.matterType = (!matter.matterType || matter.matterType === '' || !matter.matterType.lookupId);
    //    formErrors.matterStatus = (!matter.matterStatus || matter.matterStatus === '' || !matter.matterStatus.lookupId);
    //    formErrors.clientReference = (!matter.clientReference || matter.clientReference === '');
    //    formErrors.clientContact = (!matter.clientContact || matter.clientContact === '' || !matter.clientContact.lookupId);
    //    formErrors.manager = (!matter.manager || matter.manager === '' || !matter.manager.lookupId);

    //    setErrors(formErrors);

    //    const item = Object.assign({}, formErrors)
    //    validForm = !Object.keys(item).some(x => item[x]);

    //    if (validForm) { props.save(matter); }
    //}

    return (
        <Dialog 
        //onClose={props.cancel} 
            validate={validateForm}
            original={originalMatter}
            current={matter}
            saveChanges={props.save}
            cancelChanges={props.cancel}
            //deleteItem={props.delete}
            //deleteItemMessage="Are you sure you want to delete this Matter?"
            width={1200} title={`${matter.matterId === 0 ? 'Add' : 'Edit'} File`}>
            <form onSubmit={(ev) => ev.preventDefault()}>
                
                <GridContainer>
                    <GridItem xs={4} className='mt-1'>
                        <Input
                            width='100%'
                            label='Matter name'
                            value={matter.matterName ?? ""}
                            onChange={handleInputChange}
                            name="matterName"
                            id="matterName"
                            required={true}
                        />
                        {errors.matterName && <span className='text-danger'>Matter name is required.</span>}
                    </GridItem>
                    <GridItem xs={4} className='mt-1'>
                        <Input
                            width='100%'
                            label='Jacmac matter reference number'
                            value={matter.matterNumber}
                            onChange={handleInputChange}
                            name="matterNumber"
                            id="matterNumber"
                            required={true}
                        />
                        {errors.matterNumber && <span className='text-danger'>Jacmac matter reference number is required.</span>}
                    </GridItem>
                    <GridItem xs={4} className='mt-1'>
                        <Input
                            width='100%'
                            label='Client Reference'
                            value={matter.clientReference}
                            onChange={handleInputChange}
                            name="clientReference"
                            id="clientReference"
                            required={true}
                        />
                        {errors.clientReference && <span className='text-danger'>Client matter reference number is required.</span>}
                    </GridItem>

                    <GridItem xs={4} className='mt-1'>
                        <AutoComplete
                            loading={users.loading}
                            data={users.list}
                            value={matter.manager ? matter.manager.lookupValue : null}
                            onChange={userAutoCompleteChanged}
                            textField='lookupValue'
                            style={{ width: '100%', borderWidth: '1px' }}
                            name="manager"
                            id="manager"
                            label="File Manager"
                            required={true}
                        />
                        {errors.manager && <span className='text-danger'>File Manager is required.</span>}
                    </GridItem>

                    <GridItem xs={4} className='mt-1'>
                        {/*<Input*/}
                        {/*    width='100%'*/}
                        {/*    label='Loan Number'*/}
                        {/*    value={matter.loanNumber}*/}
                        {/*    onChange={handleInputChange}*/}
                        {/*    name="loanNumber"*/}
                        {/*    id="loanNumber"*/}
                        {/*    required={true}*/}
                        {/*/>*/}
                        {/*{errors.loanNumber && <span className='text-danger'>Loan number is required.</span>}*/}
                    </GridItem>

                    <GridItem xs={4} />
                    
                    <GridItem xs={4} className='mt-3'>
                        <AutoComplete
                            loading={users.loading}
                            data={users.list}
                            value={matter.clientContact ? matter.clientContact.lookupValue : null}
                            onChange={userAutoCompleteChanged}
                            textField='lookupValue'
                            style={{ width: '100%', borderWidth: '1px' }}
                            name="clientContact"
                            id="clientContact"
                            label="Instructing Officer"
                            required={true}
                        />
                        {errors.clientContact && <span className='text-danger'>Instructing Officer is required.</span>}
                    </GridItem>
                    <GridItem xs={8} />

                    <GridItem xs={12} className='mt-2'>
                        <label className='k-checkbox-label' style={{left: '-2px', cursor: 'default'}}>Further Information</label>
                    </GridItem>
                    {/* <GridItem xs={12} className='mt-3'>
                        <input type="checkbox" className='k-checkbox' defaultChecked={matter.isSensitiveMatter} onClick={handleSensitiveMatter} name="isSensitiveMatter" id="isSensitiveMatter" />
                        <label className="k-checkbox-label" htmlFor="isSensitiveMatter">Sensitive Matter</label>
                    </GridItem> */}
                    <GridItem xs={12} className='mt-4'>
                        <span className='k-textbox-container'>
                            <label className='k-label'>Sensitivities</label>
                            <textarea
                                className='k-textarea w-100'
                                value={matter.sensitivities}
                                onChange={handleTextAreaChange}
                                name="sensitivities"
                                id="sensitivities"
                                rows={7}
                            />
                        </span>
                    </GridItem>
                    <GridItem xs={12} className='mt-3'>
                        <span className='k-textbox-container'>
                            <label className='k-label'>Matter overview</label>
                            <textarea
                                className='k-textarea w-100'
                                value={matter.overview}
                                onChange={handleTextAreaChange}
                                name="overview"
                                id="overview"
                                rows={7}
                            />
                        </span>
                    </GridItem>
                    <GridItem xs={4} className='mt-3'>
                        <ComboBox
                            data={matterStatuses.list}
                            loading={matterStatuses.loading}
                            onFocus={(ev) => {if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click()}}
                            textField='lookupValue'
                            dataItemKey='lookupId'
                            value={matter.matterStatus}
                            onChange={handleSelectChange}
                            label="Matter Status"
                            name='matterStatus'
                            id='matterStatus'
                            required={true}
                        />
                        {errors.matterStatus && <span className='text-danger'>Matter Status is Required.</span>}
                    </GridItem>

                    {/* <GridItem sm={6}>
                        <GridContainer>
                            <GridItem sm={12}>
                                <Input
                                    width='100%'
                                    label='File Number'
                                    value={matter.matterNumber}
                                    onChange={handleInputChange}
                                    name="matterNumber"
                                    id="matterNumber"
                                    required={true}
                                />
                                {errors.matterNumber && <span className='text-danger'>File Number is Required.</span>}
                            </GridItem>
                            <GridItem sm={12}>
                                <Input
                                    width='100%'
                                    label='Loan Number'
                                    value={matter.loanNumber}
                                    onChange={handleInputChange}
                                    name="loanNumber"
                                    id="loanNumber"
                                />
                            </GridItem>
                            <GridItem sm={12}>
                                <span className='k-textbox-container'>
                                    <label className='k-label'>Instruction Date</label>
                                    <DatePicker
                                        defaultValue={new Date()}
                                        format={"dd/MM/yyyy"}
                                        name="instructionDate"
                                        id="instructionDate"
                                        value={matter.instructionDate}
                                        onChange={handleDateChange}
                                        label="Instruction Date"
                                        required={true}
                                    />
                                </span>
                                {errors.instructionDate && <span className='text-danger'>Instruction Date is Required.</span>}
                            </GridItem>
                            <GridItem sm={12}>
                                <ComboBox
                                    data={matterTypes.list}
                                    loading={matterTypes.loading}
                                    onFocus={(ev) => {
                                        if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click();
                                    }}

                                    textField='lookupValue'
                                    dataItemKey='lookupId'
                                    value={matter.matterType}
                                    onChange={handleSelectChange}
                                    label="File Type"
                                    name='matterType'
                                    id='matterType'
                                    required={true}
                                />
                                {errors.matterType && <span className='text-danger'>File Type is Required.</span>}
                            </GridItem>
                            <GridItem sm={12}>
                                <ComboBox
                                    data={matterStatuses.list}
                                    loading={matterStatuses.loading}
                                    onFocus={(ev) => {if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click()}}

                                    textField='lookupValue'
                                    dataItemKey='lookupId'
                                    value={matter.matterStatus}
                                    onChange={handleSelectChange}
                                    label="File Status"
                                    name='matterStatus'
                                    id='matterStatus'
                                    required={true}
                                />
                                {errors.matterStatus && <span className='text-danger'>File Status is Required.</span>}
                            </GridItem>

                            <GridItem sm={12}>
                                <Input
                                    width='100%'
                                    label='Client Reference'
                                    value={matter.clientReference}
                                    onChange={handleInputChange}
                                    name="clientReference"
                                    id="clientReference"
                                    required={true}
                                />
                                {errors.clientReference && <span className='text-danger'>Client Reference is Required.</span>}
                            </GridItem>

                        </GridContainer>
                    </GridItem>

                    <GridItem sm={6}>
                        <GridContainer>
                            
                            <GridItem sm={12}>

                                <AutoComplete
                                    loading={users.loading}
                                    data={users.list}
                                    value={matter.clientContact.lookupValue}
                                    onChange={userAutoCompleteChanged}
                                    textField='lookupValue'
                                    style={{ width: '100%', borderWidth: '1px' }}
                                    name="clientContact"
                                    id="clientContact"
                                    label="Client Contact"
                                    required={true}
                                />
                                {errors.clientContact && <span className='text-danger'>Client Contact is Required.</span>}
                            </GridItem>

                            <GridItem sm={12}>

                                <AutoComplete
                                    loading={users.loading}
                                    data={users.list}
                                    value={matter.manager.lookupValue}
                                    onChange={userAutoCompleteChanged}
                                    textField='lookupValue'
                                    style={{ width: '100%', borderWidth: '1px' }}
                                    name="manager"
                                    id="manager"
                                    label="Manager"
                                    required={true}
                                />
                                {errors.manager && <span className='text-danger'>Manager is Required.</span>}
                            </GridItem>

                            <GridItem sm={12}>
                                <Input
                                    width='100%'
                                    label='Customer No.'
                                    value={matter.customerNumber}
                                    onChange={handleInputChange}
                                    name="customerNumber"
                                    id="customerNumber"
                                />
                            </GridItem>
                            <GridItem sm={12}>
                                <Input
                                    width='100%'
                                    label='Contact No.'
                                    value={matter.contactNumber}
                                    onChange={handleInputChange}
                                    name="contactNumber"
                                    id="contactNumber"
                                />
                            </GridItem>

                            <GridItem sm={12}>
                                <Input
                                    width='100%'
                                    label='Email Address'
                                    value={matter.emailAddress}
                                    onChange={handleInputChange}
                                    name="emailAddress"
                                    id="emailAddress"
                                />
                            </GridItem>

                            <GridItem sm={12} style={{ marginBottom: '10px', paddingTop: '14px' }}>
                                <input type="checkbox" className='k-checkbox' defaultChecked={matter.isSensitiveMatter} onClick={handleSensitiveMatter} name="isSensitiveMatter" id="isSensitiveMatter" />
                                <label className="k-checkbox-label" htmlFor="isSensitiveMatter">Sensitive Matter</label>
                            </GridItem>

                        </GridContainer>
                    </GridItem>

                    <GridItem sm={12}>
                        <GridContainer>
                            <GridItem sm={6}>
                                <span className='k-textbox-container'>
                                    <label className='k-label'>Purpose of Instruction</label>
                                    <textarea
                                        className='k-textarea w-100'
                                        value={matter.purposeOfInstruction}
                                        onChange={handleTextAreaChange}
                                        name="purposeOfInstruction"
                                        id="purposeOfInstruction"
                                        rows={5}
                                    />
                                </span>
                            </GridItem>

                            <GridItem sm={6}>
                                <span className='k-textbox-container'>
                                    <label className='k-label'>Comments</label>
                                    <textarea
                                        className='k-textarea w-100'
                                        value={matter.comments}
                                        onChange={handleTextAreaChange}
                                        name="comments"
                                        id="comments"
                                        rows={5}
                                    />
                                </span>
                            </GridItem>

                            <GridItem sm={6}>
                                <span className='k-textbox-container'>
                                    <label className='k-label'>Sensitive Comments</label>
                                    <textarea
                                        className='k-textarea w-100'
                                        value={matter.sensitiveComments}
                                        onChange={handleTextAreaChange}
                                        name="sensitiveComments"
                                        id="sensitiveComments"
                                        rows={5}
                                    />
                                </span>
                            </GridItem>

                        </GridContainer>
                    </GridItem> */}

                </GridContainer>
                
                    
            </form>
            {/* <DialogActionsBar>
                <div>
                    <button
                        className="k-button m-0"
                        onClick={props.cancel}
                    >
                        Cancel
                    </button>
                    <button
                        className="k-button k-primary m-3"
                        onClick={(ev) => handleSaveForm()}
                    >
                        Save
                    </button>
                </div>                
            </DialogActionsBar> */}
        </Dialog>
    )
}

export default withSnackbar(MatterEditor);