import React from 'react';

export default class KendoGridBooleanCell extends React.Component {
    render() {
        const value = this.props.dataItem[this.props.field];
        return (
            <td>
                { value ? "Yes" : "No" }
            </td>
        );
    }
}