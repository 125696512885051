import React, { useState, useEffect, useRef } from 'react';
import { findDOMNode } from "react-dom";
import { NavLink } from "react-router-dom";
//import { Input } from '@progress/kendo-react-inputs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
//import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Button } from '@progress/kendo-react-buttons';
import { Editor, EditorTools, EditorUtils, ProseMirror } from '@progress/kendo-react-editor';
import { filterBy } from '@progress/kendo-data-query';
import Dialog from "../common/Dialog";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";

//import { editableField } from "../../util/editableField";
import api from "../../util/api"
import { requiresRole, policies } from '../../util/accessControl'
import moment from "moment";

import handleErrors from '../../util/handleErrors'; 
import { withSnackbar } from 'notistack';
import _ from "lodash";

const ConversationEditor = (props) => {
    const initLookupObject = { loading: false, list: [] };
    const [errors, setErrors] = useState({});
    const originalItem = {
        ...props.dataItem,
        reviewDate: (props.dataItem.reviewDate == null || props.dataItem.reviewDate === "") ? null : moment(props.dataItem.reviewDate).toDate(),
        createdOn: (props.dataItem.createdOn == null || props.dataItem.createdOn === "") ? moment().toDate() : moment(props.dataItem.createdOn).toDate(),
        from: props.dataItem.from ? props.dataItem.from : localStorage.getItem('Llama.displayName')
    };
    const [conversation, setConversation] = useState(originalItem);
    const [originalConversation, setOriginalConversation] = useState(originalItem);
    const [users, setUsers] = useState(initLookupObject);
    const [allUsers, setAllUsers] = useState(initLookupObject);
    const [editMode, setEditMode] = useState(!!props.editMode);

    const setLookup = (setFunc) => (json) => {
        setFunc({ loading: false, list: json });
        return json;
    }

    useEffect(() => {
        //console.log('useEffect');
        const axiosConfig = { accept: 'application/json' };
        const loading = { loading: true, list: [] };
        let responseUserIndex = 0;
        let responseViewedIndex = -1;
        let responseMatterIndex = -1;

        setUsers(loading);

        { /*Editor Styling*/ }
        if (editMode) {
            let editor = document.getElementsByClassName('k-editor')[0]
            let iFrame = editor.querySelector('iframe')
            iFrame.contentDocument.querySelector('.k-content').setAttribute("style", "font-family: 'Lato', sans-serif;")
        }
      
        let onLoadItems = [
            api.get(`/lookup/user`, axiosConfig).then((json) => {
                //setLookup(setUsers);
                setUsers({ loading: false, list: json });
                setAllUsers({ loading: false, list: json });
                return json;
            }), // Load Users
        ];

        if (conversation && conversation.conversationId > 0 && conversation.user && !conversation.viewed && localStorage.getItem('Llama.username') == conversation.user.identifier) {
            onLoadItems.push(api.post(`/conversation/isviewed/${conversation.conversationId}`, axiosConfig));
            responseViewedIndex = 1;
        }

        if (conversation && conversation.matterId && conversation.matterId > 0) {
            onLoadItems.push(api.get(`/matter/${conversation.matterId}`, axiosConfig));
            responseMatterIndex = responseViewedIndex > 0 ? responseViewedIndex + 1 : 1 ;
        }

        // if (conversation && conversation.responseId && conversation.responseId > 0) {

        // }

        Promise.all(onLoadItems)
            .then(responses => {
                let tempData = { ...conversation };
                let userItem = tempData.user;
                if (tempData.userId && tempData.userId > 0) {
                    userItem = responses[responseUserIndex].find(v => v.lookupId === tempData.userId);
                    
                    if (userItem){
                        //console.log('userItem: ', userItem);
                        tempData = { ...tempData, user: userItem };
                    }
                }
                // if (responseUserIndex > 0) {                    
                //     tempData = { ...tempData, user: userItem };
                // }
                if (responseViewedIndex > 0) {                    
                    tempData = { ...tempData, viewed: (responses[responseViewedIndex] ? true : tempData.viewed) };
                }
                if (responseMatterIndex > 0) {                    
                    tempData = { ...tempData, matter: responses[responseMatterIndex] };
                }
                setConversation(tempData);
                //console.log('tempData: ', tempData);
                setOriginalConversation(tempData);
            })
            .catch(handleErrors(props))
    }, [editMode]);

    // const handleInputChange = ({ value, target: { name }, ...rest }) => {
    //     setConversation({...conversation, [name]: value });
    // }
    
    // const handleDateChange = ({ value, target: { name } }) => {
    //     setConversation({ ...conversation, [name]: value == null ? null : moment(value).local().toDate() });
    // }
    const handleCheckChange = ({ target: { name, checked } }) => {
        //console.log('handleCheckChange', conversation);
        setConversation({ ...conversation, [name]: checked });

        if (name === "acknowledged" && conversation.conversationId > 0) {
            props.patch([{propertyName: 'Acknowledged', propertyValue: checked}], conversation.conversationId);
        }
    }
    const handleSelectChange = ({ value, target: { name } }) => {
        const idField = `${name}Id`;
        
        if (name === 'user') {
            setConversation({ ...conversation, [name]: value, [idField]: value ? value.lookupId : null, to: value ? value.lookupValue : null });
        }
        else {
            setConversation({ ...conversation, [name]: value, [idField]: value ? value.lookupId : null });
        }
    }
    const handleReply = (ev) => {
        const toUser = _.find(users.list, o => o.lookupValue === conversation.from);
        props.patch([{propertyName: 'Acknowledged', propertyValue: true}], conversation.conversationId, false);
        setConversation({ 
            conversationId: 0, 
            responseId: conversation.conversationId, 
            from: localStorage.getItem('Llama.displayName'), 
            user: toUser, 
            userId:  toUser ? toUser.lookupId : null,
            to: toUser ? toUser.lookupValue : '',
            matterId: conversation.matterId,
            matter: conversation.matter, 
            createdOn: moment().toDate(),
            replyMessage: conversation.message
        });
        setEditMode(true);
    }

    const validateForm = () => {
        var validForm = true;
        var formErrors = {};
        //formErrors.subject = (!conversation.subject || conversation.subject === '');
        //formErrors.to = (!conversation.to || conversation.to === '');
        //formErrors.from = (!conversation.from || conversation.from === '');
        //formErrors.conversationType = (!conversation.conversationType || conversation.conversationType === '');
        formErrors.user = (!conversation.user || conversation.user === '');
        //formErrors.reviewDate = (!conversation.reviewDate || conversation.reviewDate === '');

        setErrors(formErrors);

        const item = Object.assign({}, formErrors)
        validForm = !Object.keys(item).some(x => item[x]);
        let formData = { ...conversation, message: EditorUtils.getHtml(editorRef.current.view.state), from: conversation.from ? conversation.from : localStorage.getItem('Llama.displayName')};
        return validForm;
    }

    const {
        Bold,
        Italic,
        Underline,
        AlignLeft,
        AlignRight,
        AlignCenter,
        Indent,
        Outdent,
        OrderedList,
        UnorderedList,
        Undo,
        Redo
    } = EditorTools;

    const editorRef = useRef();

    let timeout;
    const filterChange = (event) => {

        clearTimeout(timeout);
        timeout = setTimeout(() => {
            const data = allUsers.list.slice();
            const filtered = filterBy(data, event.filter);
            console.log('filterChange: ', event, data, filtered);
            setUsers({ list: filtered, loading: false });
        }, 200);
        setUsers({ list: users.list, loading: true });

    }
    const currentRole = localStorage.getItem('Llama.role');
    const currentUser = localStorage.getItem('Llama.username');

    return (
        <Dialog
            validate={validateForm}
            original={originalConversation}
            current={conversation}
            saveChanges={props.save}
            cancelChanges={props.cancel}
            //onClose={props.cancel} 
            inEditMode={editMode}
            width={800}
            createMode={conversation.conversationId === 0}
            inEditMode={editMode}
            deleteItem={props.delete}
            deleteItemMessage="Are you sure you want to delete this Note?"
            title={
                <>
                    {`${conversation.responseId ? 'Reply to' : conversation.conversationId === 0 ? 'Add' : editMode ? 'Edit' : 'View'} Note`}
                    {requiresRole(<Button className='rounded-circle ml-2' primary={true} look="bare" icon={editMode ? 'lock' : 'edit'} onClick={(ev) => setEditMode(!editMode)} />, policies.edit)}
                </>
            }
            hideActions={!editMode}
        >
            <form onSubmit={(ev) => ev.preventDefault()}>
                <GridContainer>
                    <GridItem sm={9}>
                    {props.hyperlink 
                        ? <div className="font-size-14">{conversation.matter ? <NavLink to={`/files/${conversation.matter.matterId}`}>{conversation.matter.matterName} | {conversation.matter.matterNumber} | {conversation.matter.clientReference}</NavLink> : 'No Matter'}</div>
                        : <div className="font-size-14">{conversation.matter ? `${conversation.matter.matterName} | ${conversation.matter.matterNumber} | ${conversation.matter.clientReference}` : 'No Matter'}</div>}
                    </GridItem>

                    {editMode ? (
                        <>
                            <GridItem sm={3} className='d-flex justify-content-end'>
                                <div className="font-size-14">{conversation.createdOn ? moment(conversation.createdOn).format('DD/MM/YYYY') : ''}</div>
                            </GridItem>
                            <GridItem sm={6} className='mt-3'>
                                <ComboBox
                                    data={users.list}
                                    loading={users.loading}
                                    onFocus={(ev) => { if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click() }}
                                    textField='lookupValue'
                                    dataItemKey='lookupId'
                                    value={conversation.user}
                                    onChange={handleSelectChange}
                                    label="To"
                                    name='user'
                                    id='user'
                                    required={true}
                                    filterable={true}
                                    onFilterChange={filterChange}
                                />
                                {errors.user && <span className='text-danger'>To is Required.</span>}
                            </GridItem>
                            <GridItem sm={6} className='mt-3'>
                                <span className='k-textbox-container'>                                    
                                    <div className="mt-2 font-size-14">{ conversation.from }</div>
                                    <label className='k-label'>From</label>
                                </span>                                
                            </GridItem>
                            {
                                conversation.replyMessage && <GridItem sm={12} className='mt-3'>
                                    <div dangerouslySetInnerHTML={{__html:conversation.replyMessage}}></div>
                                </GridItem>
                            }
                            <GridItem sm={12} className='mt-3'>
                                <span className='k-textbox-container'>
                                    <label className='k-label'>Message</label>
                                    <Editor
                                        tools={[
                                            [Bold, Italic, Underline],
                                            [Undo, Redo],
                                            [AlignLeft, AlignCenter, AlignRight],
                                            [OrderedList, UnorderedList, Indent, Outdent]
                                        ]}
                                        contentStyle={{ height: 250 }}
                                        ref={editorRef}
                                        name="message"
                                        id="message"
                                        defaultContent={conversation.message}
                                        onExecute={({ transaction }) => {
                                            const nextState = ProseMirror.EditorState.create({
                                                doc: transaction.doc,
                                                selection: transaction.selection
                                            });
                                            setConversation({ ...conversation, message: EditorUtils.getHtml(nextState) })
                                        }}
                                    />
                                </span>
                            </GridItem>
                            {/* <GridItem sm={12} className='mt-3'>
                                <input type="checkbox" className='k-checkbox' defaultChecked={conversation.responseRequired} onClick={handleCheckChange} name="responseRequired" id="responseRequired" />
                                <label className="k-checkbox-label" htmlFor="responseRequired">Response Required</label>
                            </GridItem>
                            <GridItem sm={12} className='mt-3'>
                                <input type="checkbox" className='k-checkbox' defaultChecked={conversation.acknowledged} onClick={handleCheckChange} name="acknowledged" id="acknowledged" />
                                <label className="k-checkbox-label" htmlFor="acknowledged">Acknowledged</label>
                            </GridItem> */}
                        </>
                    ) : (
                        <>
                            <GridItem sm={3}></GridItem>
                            <GridItem sm={4} className='mt-3'>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Date sent:</label>
                                    <div className="font-size-14">{conversation.createdOn ? moment(conversation.createdOn).format('DD/MM/YYYY') : ''}</div>
                                </span>
                            </GridItem>    
                            <GridItem sm={4} className='mt-3'>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Addressed to:</label>
                                    <div className="font-size-14">{conversation.user ? conversation.user.lookupValue : ''}</div>
                                </span>
                            </GridItem> 
                            <GridItem sm={4} className='mt-3'>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>From:</label>
                                    <div className="font-size-14">{conversation.from}</div>
                                </span>
                            </GridItem>
                                {/*
                            <GridItem sm={3} className='mt-3'>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Response:</label>
                                    <div className="font-size-14">{`${conversation.responseRequired ? 'Required' : 'Not Required'}`}</div>
                                </span>                                
                            </GridItem>
                                */}
                            <GridItem sm={3} className='mt-3'>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Message:</label>
                                </span>                                
                            </GridItem> 
                            <GridItem sm={9} className='mt-3'>
                                <span className='k-textbox-container'>
                                <div className="font-size-14" dangerouslySetInnerHTML={{ __html: conversation.message }}></div>
                                </span>                                
                            </GridItem>
                           
                        </>
                    )}
                    <GridItem sm={3} className='mt-3 d-flex justify-content-end flex-column ml-auto'>                         
                            {((conversation.user && conversation.user.role === currentRole) || currentRole !== 'client')  && 
                                <div className='pb-2'>
                                    <input type="checkbox" className='k-checkbox' defaultChecked={conversation.acknowledged} onClick={handleCheckChange} name="acknowledged" id="acknowledged" />
                                    <label className="k-checkbox-label" htmlFor="acknowledged">Mark as Read</label>
                                </div>
                            }
                            {conversation.user && conversation.user.role === currentRole && !editMode &&
                                <Button className='px-5 mb-1' onClick={handleReply}>Reply</Button>
                            }
                    </GridItem>
                </GridContainer>
            </form>

        </Dialog>
    )
}

export default withSnackbar(ConversationEditor);