import React, { useState, useEffect, useRef, useContext }  from 'react';
import { withRouter, useRouteMatch } from "react-router-dom";
import { withSnackbar } from 'notistack';

import { ProfileContext } from '../store'

import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";

import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';

import api from "../../util/api";
import handleErrors from '../../util/handleErrors';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Slider, Input, Checkbox } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import emptyProfilePicture from './empty-profile-picture.png';
import { Label } from 'reactstrap';
import _ from 'lodash';
import { ConfirmDelete } from "../common/Dialog";
import Header from "../common/Header";

const base64ToArrayBuffer = (base64) => {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
 }

const Profile = (props) => {

    const 
    initWidth = 250,
    initHeight = 250,
    initPositionX = 0.5,
    initPositionY = 0.5,
    initScale = 1,
    initRotation = 0,
    initBorderWidth = 1,
    initBorderRadius = 125,
    scaleIncrement = 0.01,
    positionIncrement = 0.001;

    const allowedFileTypes = [
        {type: "JPEG", contentType: "image/jpeg"},
        {type: "JPG", contentType: "image/jpg"},
        {type: "PNG", contentType: "image/png"},
        {type: "BMP", contentType: "image/bmp"}
    ];
    
    const maxFileSize = 4760000;

    const calcPosition = (a, b, s) => {
        return parseFloat((a / (b * s) / 2).toFixed(3));
    }
    const validPosition = (min, max, current) => {
        if (min > current) {
          return min;
        }
        if (max < current) {
          return max;
        }
        return current;
    }
    const getPositions = (value) => {
        const minY = calcPosition(avatarEditor.height, avatarEditor.imageHeight, value);
        const minX = calcPosition(avatarEditor.width, avatarEditor.imageWidth, value);
        const maxY = parseFloat((initPositionY + (initPositionY - minY)).toFixed(3));
        const maxX = parseFloat((initPositionX + (initPositionX - minX)).toFixed(3));
    
        return { minY, minX, maxY, maxX };
    }

    // const [ state, dispatch ] = useReducer(reducer, profile);    
    const { dispatch } = useContext(ProfileContext);
    const [ userProfile, setUserProfile ] = useState({});
    const [ avatarChanged, setAvatarChanged ] = useState(false);
    const [ avatarEditor, setAvatarEditor ] = useState({
        image: emptyProfilePicture,
        rotate: initRotation,
        scale: initScale,
        scaleMin: 1,
        scaleMax: 2,
        positionx: initPositionX,
        positionxMax: initPositionX + (initPositionX - calcPosition(initWidth, initWidth, initScale)),
        positionxMin: calcPosition(initWidth, initWidth, initScale),
        positiony: initPositionY,
        positionyMax: initPositionY + (initPositionY - calcPosition(initHeight, initHeight, initScale)),
        positionyMin: calcPosition(initHeight, initHeight, initScale),
        imageWidth: initWidth,
        imageHeight: initHeight,
        width: initWidth,
        height: initHeight  
    });
    const avatarEditorRef = useRef();
    const dropzoneRef = useRef();
    const match = useRouteMatch();

    const [roles, setRoles] = useState([]);
    const [itemInDelete, setItemInDelete] = useState(null);
    const [loading, setLoading] = useState(false);

    const cancel = () => {
        setItemInDelete(null);
        setLoading(false);
    }

    const deleteItem = () => {
        setLoading(true);

        api.delete(`/user/${userProfile.email}`, {
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(json => {
                setLoading(false);
                setItemInDelete(null);
                props.enqueueSnackbar(`Account ${userProfile.email} has been deleted successfully.`, { variant: 'info' });
                props.history.push(match.params.id ? `/users` : `/`);
            })
            .catch(handleErrors(props))
    };
   
    useEffect(() => {    
        Promise.all([
            api.get(`/user/profile/${match.params.id ? match.params.id : 0}`, {accept: 'application/json'}),
            api.get(`/user/roles`, {accept: 'application/json'}) 
        ])        
        .then(response => {
            const json = response[0];
            Object.keys(json).forEach(key => json[key] = (json[key] ?? ''));

            const { avatar, avatarType, avatarName, ...rest } = json;
            console.log('rest: ',avatar, response);

            if (avatar && avatar.length > 0) {
                setAvatarEditor({
                    ...avatarEditor,
                    image: new File([base64ToArrayBuffer(avatar)], avatarName, { type: avatarType })
                });
            } else {
                setAvatarEditor({
                    ...avatarEditor,
                    image: emptyProfilePicture
                });
            }
            setRoles(response[1]);
            rest.role = response[1].find((value) => value.name == rest.role);
            setUserProfile(rest);
        })
        .catch(handleErrors(props));

    }, [match.params.id]);

    const onLoadFailure = (e) => {
        
    }
    const onLoadSuccess = (e) => {
        const y = calcPosition(avatarEditor.height, e.height, avatarEditor.scale);
        const x = calcPosition(avatarEditor.width, e.width, avatarEditor.scale);

        

        setAvatarEditor({
            ...avatarEditor,
            imageWidth: e.width,
            imageHeight: e.height,
            positionyMax: initPositionY + (initPositionY - y),
            positionyMin: y,
            positionxMax: initPositionX + (initPositionX - x),
            positionxMin: x,
            rotate: initRotation,
            scale: initScale,
            positionx: initPositionX,
            positiony: initPositionY
        })
    }
    const onImageReady = (e) => {
    }
    const handleInputChange = ({ value, target: { name } }) => {
        setUserProfile({...userProfile, [name]: value });
    }
    const handleScaleChange = ({ value }) => {
        const { minY, minX, maxY, maxX } = getPositions(value);
        
        setAvatarEditor({ 
            ...avatarEditor, 
            scale: value,
            positionyMax: maxY,
            positionyMin: minY,
            positiony: validPosition(minY, maxY, avatarEditor.positiony),
            positionxMax: maxX,
            positionxMin: minX,
            positionx: validPosition(minX, maxX, avatarEditor.positionx)
        });
        setAvatarChanged(true);
    }
    const handlePositionChange = ({ x, y }) => {
        setAvatarEditor({ ...avatarEditor, positionx: x, positiony: y });
        setAvatarChanged(true);
    }
    const handlePositionXChange = ({ value }) => {
        setAvatarEditor({ ...avatarEditor, positionx: value });
        setAvatarChanged(true);
    }
    const handlePositionYChange = ({ value }) => {
        setAvatarEditor({ ...avatarEditor, positiony: value });
        setAvatarChanged(true);
    }
    const handleRotateLeft = () => {
        setAvatarEditor({ ...avatarEditor, rotate: avatarEditor.rotate - 90 });
        setAvatarChanged(true);
    }
    const handleRotateRight = () => {
        setAvatarEditor({ ...avatarEditor, rotate: avatarEditor.rotate + 90 }); 
        setAvatarChanged(true);       
    }
    const handleDrop = (acceptedFiles) => {
        
        if (acceptedFiles && acceptedFiles.length > 0 && acceptedFiles[0]) {
            const uploadedFile = acceptedFiles[0];

            const isValidType = _.some(allowedFileTypes, (o) => uploadedFile.type === o.contentType);
            const isValidSize = uploadedFile.size <= maxFileSize;

            let errors = [];
            if (!isValidType) {
                errors.push(`Invalid file type: ${uploadedFile.type}. Only ${this.allowedFileTypes.map(f => f.contentType).join(', ')} file types are suported.`);                
            }        
            if (!isValidSize) {
                errors.push(`Invalid file size: ${(uploadedFile.size / 1000000).toFixed(2)}MB. Maximum file size supported is ${(this.maxFileSize / 1000000).toFixed(2)}MB.`);
            }

            if (errors.length > 0) {
                //enqueueSnackbar.....
            }
            else {
                setAvatarEditor({
                    ...avatarEditor,
                    image: uploadedFile,
                    
                });
                setAvatarChanged(true);
            }
        }
    }
    const handleSave = () => {
        const formData = new FormData();

        Object.keys(userProfile).forEach(key => {
            formData.append(key, key == "role" ? userProfile[key].name : userProfile[key]);
        });

        const save = (data, avatar, canvas) => {
            api.put(`/user/update`, data, {
                accept: 'application/json', 
            }) 
            .then(json => {
                const displayName = `${userProfile.firstName} ${userProfile.lastName}`;
                localStorage.setItem('Llama.displayName', displayName);
                if (avatar) {
                    const avatarDataUrl = canvas.toDataURL(avatar.type) //URL.createObjectURL(avatar);
                    dispatch({type: 'setProfile', payload: { name: displayName, avatar: avatarDataUrl }});
                    localStorage.setItem('Llama.avatar', avatarDataUrl);
                }
                else {                    
                    dispatch({type: 'setProfileName', payload: displayName})
                }
                props.history.push(match.params.id ? `/users` : `/`);
            })
            .catch(handleErrors(props))
        }

        if (avatarChanged) {
            const canvas = avatarEditorRef.current.getImageScaledToCanvas();
            
            canvas.toBlob((blob) => {
                
                const file = new File([blob], avatarEditor.image.name, { type: avatarEditor.image.type });
                formData.append('file', file);    
                save(formData, file, canvas);
            })
        }
        else {
            save(formData);
        }
    }
    const handleDelete = () => {
        setItemInDelete(userProfile);
    }
    const handleDropdownChange = ({ value, target: { name } }) => {
        setUserProfile({...userProfile, [name]: value });
    }
    const handleNotifTypeChange = ({ value, target: { name } }) => {
        setUserProfile({ ...userProfile, notifyOnceDaily: !userProfile.notifyOnceDaily });
    }

    return (
        <GridContainer className='profile'>
            <Header setLoading={setLoading} loading={loading} title="Profile" />
            <GridItem style={{flexGrow: '0'}}>
                {!match.params.id 
                ? 
                <Dropzone
                    ref={dropzoneRef}
                    onDrop={handleDrop}
                    multiple={false}
                    noClick={true}
                    maxSize={20000000}
                    style={{width: 'auto', height: 'auto', borderColor: '#ddd'}}
                >
                    {({ getRootProps, getInputProps, open }) => (
                        <React.Fragment>                            
                            <div {...getRootProps()}>  
                                <Button 
                                    onClick={open}
                                    style={{ width: `${avatarEditor.width + (initBorderWidth*2)}px`, display: 'block', whiteSpace: 'normal', marginBottom: '10px', padding: '10px 15px' }}
                                >
                                    Drag and drop a file here or click to select
                                </Button>
                                <div>                                
                                    <AvatarEditor   
                                        ref={avatarEditorRef}                 
                                        className={``}
                                        image={avatarEditor.image}
                                        rotate={avatarEditor.rotate}
                                        scale={parseFloat(avatarEditor.scale)}
                                        width={avatarEditor.width}
                                        height={avatarEditor.height}
                                        border={initBorderWidth}
                                        position={{ x:avatarEditor.positionx, y:avatarEditor.positiony }}
                                        onPositionChange={handlePositionChange}
                                        onLoadFailure={onLoadFailure}
                                        onLoadSuccess={onLoadSuccess}
                                        onImageReady={onImageReady}                    
                                        borderRadius={initBorderRadius}
                                        style={{borderRadius: '100%'}}
                                    />    
                                </div>
                                <input {...getInputProps()} />
                            </div>
                        </React.Fragment>                    
                    )}                    
                </Dropzone>
                :
                <div>                                
                    <AvatarEditor   
                        ref={avatarEditorRef}                 
                        className={``}
                        image={avatarEditor.image}
                        rotate={avatarEditor.rotate}
                        scale={parseFloat(avatarEditor.scale)}
                        width={avatarEditor.width}
                        height={avatarEditor.height}
                        border={initBorderWidth}
                        position={{ x:avatarEditor.positionx, y:avatarEditor.positiony }}
                        onPositionChange={handlePositionChange}
                        onLoadFailure={onLoadFailure}
                        onLoadSuccess={onLoadSuccess}
                        onImageReady={onImageReady}                    
                        borderRadius={initBorderRadius}
                        style={{borderRadius: '100%', cursor: 'not-allowed', pointerEvents: 'none'}}
                        
                    />    
                </div>
                }
                
                {!match.params.id && <GridContainer 
                    style={{paddingTop: '15px', flexDirection: 'column'}}
                    direction='column'
                >
                    <GridItem 
                        style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Label>Zoom</Label>
                        <Slider 
                            style={{width: '100%'}}
                            value={avatarEditor.scale} 
                            min={avatarEditor.scaleMin}
                            max={avatarEditor.scaleMax}
                            step={scaleIncrement}
                            buttons={true}
                            onChange={handleScaleChange}                    
                        />
                    </GridItem>
                    <GridItem
                        style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Label>Position X</Label>
                        <Slider 
                            style={{width: '100%'}}
                            value={avatarEditor.positionx} 
                            min={avatarEditor.positionxMin}
                            max={avatarEditor.positionxMax}
                            step={positionIncrement}
                            buttons={true}
                            onChange={handlePositionXChange}
                        />
                    </GridItem>
                    <GridItem
                        style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Label>Position Y</Label>
                        <Slider 
                            style={{width: '100%'}}
                            value={avatarEditor.positiony} 
                            min={avatarEditor.positionyMin}
                            max={avatarEditor.positionyMax}
                            step={positionIncrement}
                            buttons={true}
                            onChange={handlePositionYChange}
                        />
                    </GridItem>
                    <GridItem
                        style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Label>Rotate</Label>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <Button onClick={handleRotateLeft} icon="rotate" style={{ borderRadius: '50%', transform: 'rotateY(180deg)' }} />
                            <Button onClick={handleRotateRight} icon="rotate" style={{ borderRadius: '50%' }} />
                        </div>
                    </GridItem>
                </GridContainer>}
                
            </GridItem>
            
            <GridItem xs={4}>
                <GridContainer>
                    <GridItem xs={12} className='mt-3'>
                        <Input
                            width='100%'
                            label='User Name'
                            value={userProfile.email}
                            name="email"
                            id="email"
                            readOnly
                        />
                    </GridItem>
                    {localStorage.getItem('Llama.role') == 'admin' &&
                        <GridItem xs={12} className='mt-3'>
                            <DropDownList
                                data={roles}
                                textField="name"
                                dataItemKey="name"
                                width='100%'
                                label='Role'
                                value={userProfile.role}
                                onChange={handleDropdownChange}
                                name="role"
                                id="role"
                                required
                            />
                        </GridItem>
                    }
                    <GridItem xs={12} className='mt-3'>
                        <Input
                            width='100%'
                            label='First Name'
                            value={userProfile.firstName}
                            onChange={handleInputChange}
                            name="firstName"
                            id="firstName"
                            readOnly={!!match.params.id}
                        />
                    </GridItem>
                    <GridItem xs={12} className='mt-3'>
                        <Input
                            width='100%'
                            label='Last Name'
                            value={userProfile.lastName}
                            onChange={handleInputChange}
                            name="lastName"
                            id="lastName"
                            readOnly={!!match.params.id}
                        />
                    </GridItem>
                    <GridItem xs={12} className='mt-3'>
                        <Input
                            width='100%'
                            label='Title'
                            value={userProfile.title}
                            onChange={handleInputChange}
                            name="title"
                            id="title"
                            readOnly={!!match.params.id}
                        />
                    </GridItem>                    
                    <GridItem xs={12} className='mt-3'>
                        <Input
                            width='100%'
                            label='Phone Number'
                            value={userProfile.phoneNumber}
                            onChange={handleInputChange}
                            name="phoneNumber"
                            id="phoneNumber"
                            readOnly={!!match.params.id}
                        />
                    </GridItem>                    
                    <GridItem xs={12} className='mt-3'>
                        <Input
                            width='100%'
                            label='Address'
                            value={userProfile.address}
                            onChange={handleInputChange}
                            name="address"
                            id="address"
                            readOnly={!!match.params.id}
                        />
                    </GridItem>
                    <GridItem xs={12} className='mt-3'>
                        <label className='ro-label'>Preference</label>
                        <br/>
                        <span className="k-state-default">
                            <input type="checkbox" className="k-checkbox" id="notifyOnceDaily" name="notifyOnceDaily" checked={userProfile.notifyOnceDaily} onChange={handleNotifTypeChange}
                            disabled={!match.params.id} />
                            <label className="k-checkbox-label" for="notifyOnceDaily">Notify Once Daily</label>
                        </span>
                    </GridItem>
                </GridContainer>
                {(!match.params.id || localStorage.getItem('Llama.role') == 'admin') &&
                    <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '10px' }}>
                        <Button onClick={handleDelete}>Delete</Button>&nbsp;
                    <Button onClick={handleSave}>Save Changes</Button>
                </div>}
            </GridItem>
            {itemInDelete && <ConfirmDelete title={`Delete Profile?`} cancel={cancel} deleteItem={deleteItem} />}
        </GridContainer>
        
    )
}

export default withSnackbar(withRouter(Profile));
