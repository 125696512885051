import axios from "axios";

const api = axios.create({ baseURL: process.env.REACT_APP_API_URL });

const requestInterceptor = (request) => {
    
    if (request.data && !(request.data instanceof FormData)) {
        var data = typeof request.data === "string" ? request.data : JSON.stringify(request.data);
        var result = data.replace(/\{\s*\}/g, 'null');
        request.data = JSON.parse(result);
    }
    
    request.withCredentials = true;
    return request;
}

const successHandler = (response, history) => {
    //
    return new Promise((resolve, reject) => {
        if (response.status === 200) {
            if (Object.keys(response.headers).includes('content-disposition')) {
                resolve(response);
            } else {
                resolve(response.data);
            }
        }
    });
}

const errorHandler = (error, history) => {
    return new Promise((resolve, reject) => {
        //if (error.response.status === 401 || error.response.status === 403) {
        //    if ([401, 403].indexOf(error.response.status) !== -1) {
        //        history.push('/login');         
        //    }
        //    reject('auth');
        //}
        return reject(error);
    })
}

api.setup = (history) => {
    api.interceptors.request.use(request => requestInterceptor(request));

    api.interceptors.response.use(
        response => successHandler(response, history),
        error => errorHandler(error, history)
    )
}

export default api;