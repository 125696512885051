import React, { useState, useEffect } from 'react';
import { findDOMNode } from "react-dom";
import moment from "moment";
import { withSnackbar } from 'notistack';

import Dialog from "../common/Dialog";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";

import api from "../../util/api"
import { requiresRole, policies } from '../../util/accessControl'
import handleErrors from '../../util/handleErrors';

import { ComboBox } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Button } from '@progress/kendo-react-buttons';

const ActivityBlockEditor = (props) => {
    const [errors, setErrors] = useState({});
    const initLookupObject = { loading: false, list: [] };
    const [blockTypes, setBlockTypes] = useState(initLookupObject);
    const [activityTypes, setActivityTypes] = useState(initLookupObject);

    const originalItem = {
        ...props.dataItem,
        instructionDate: (props.dataItem.instructionDate == null || props.dataItem.instructionDate === "") ? moment().toDate() : moment(props.dataItem.instructionDate).toDate(),
    };
    const [itemData, setItemData] = useState(originalItem);
    const [originalItemData, setOriginalItemData] = useState(originalItem);
    const [editMode, setEditMode] = useState(!!props.editMode);

    const setLookup = (setFunc) => (json) => {
        setFunc({ loading: false, list: json });
        return json;
    }

    useEffect(() => {
        const axiosConfig = { accept: 'application/json' };
        const loading = { loading: true, list: [] };

        setBlockTypes(loading);
        setActivityTypes(loading);

        let onLoadTasks = [
            api.get(`/lookup/blocktype`, axiosConfig).then(setLookup(setBlockTypes)),
            api.get(`/lookup/activityType`, axiosConfig).then(setLookup(setActivityTypes)),
        ];

        Promise.all(onLoadTasks)
            .then(responses => {
                let tempData = { ...itemData };
                let blockType = tempData.blockType;
                if (tempData.blockTypeId && tempData.blockTypeId > 0) {
                    blockType = responses[0].find(v => v.lookupId === tempData.blockTypeId);
                }

                let activityType = tempData.activityType;
                if (tempData.activityTypeId && tempData.activityTypeId > 0) {
                    activityType = responses[1].find(v => v.lookupId === tempData.activityTypeId);
                }

                tempData = { ...tempData, blockType: blockType, activityType: activityType };
                setItemData(tempData);
                setOriginalItemData(tempData);
            })
            .catch(handleErrors(props))

    }, [editMode]);

    const handleSelectChange = ({ value, target: { name } }) => {
        const idField = `${name}Id`;
        setItemData({ ...itemData, [name]: value, [idField]: value ? value.lookupId : null });
    }

    const handleDateChange = ({ value, target: { name } }) => {
        setItemData({ ...itemData, [name]: value == null ? null : moment(value).local().toDate() });
    }

    const handleTextAreaChange = ({ target: { value, name } }) => {
        setItemData({ ...itemData, [name]: value });
    }

    const validateForm = () => {
        var validForm = true;
        var formErrors = {};
        formErrors.subject = (!itemData.blockType || itemData.blockType === '');
        formErrors.startDate = (!itemData.activityType || itemData.activityType === '');
        formErrors.endDate = (!itemData.instructionDate || itemData.instructionDate === '');
        setErrors(formErrors);

        const item = Object.assign({}, formErrors)
        validForm = !Object.keys(item).some(x => item[x]);
        return validForm;
    }

    return (
        <Dialog
            validate={validateForm}
            original={originalItemData}
            current={itemData}
            saveChanges={props.save}
            cancelChanges={props.cancel}
            width={1200}
            createMode={itemData.accountId === 0}
            inEditMode={editMode}
            deleteItem={props.delete}
            deleteItemMessage="Are you sure you want to delete this Block?"
            title={
                <>
                    {`${itemData.activityBlockId === 0 ? 'Add' : editMode ? 'Edit' : 'View'} Timeline`}
                    {requiresRole(<Button className='rounded-circle ml-2' primary={true} look="bare" icon={editMode ? 'lock' : 'edit'} onClick={(ev) => setEditMode(!editMode)} />, policies.edit)}
                </>}
        >
            <form onSubmit={(ev) => ev.preventDefault()}>
                <GridContainer>
                    {editMode ? (<>
                        <GridItem sm={12}>
                            <GridContainer>
                                <GridItem sm={6} className='mt-2'>
                                    <ComboBox
                                        data={blockTypes.list}
                                        loading={blockTypes.loading}
                                        onFocus={(ev) => { if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click() }}
                                        textField='lookupValue'
                                        dataItemKey='lookupId'
                                        value={itemData.blockType}
                                        onChange={handleSelectChange}
                                        label="Timeline Type"
                                        name='blockType'
                                        id='blockType'
                                        required={true}
                                    />
                                    {errors.blockType && <span className='text-danger'>Timeline Type is Required.</span>}
                                </GridItem>
                                <GridItem sm={6} className='mt-3' />
                                <GridItem sm={6} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='k-label'>Date</label>
                                        <DatePicker
                                            defaultValue={new Date()}
                                            format="dd/MM/yyyy"
                                            name='instructionDate'
                                            id='instructionDate'
                                            onChange={handleDateChange}
                                            value={itemData.instructionDate}
                                            label=""
                                            required={true}
                                        />
                                    </span>
                                    {errors.instructionDate && <span className='text-danger'>Date is Required.</span>}
                                </GridItem>
                                <GridItem sm={6} className='mt-3'>
                                    <ComboBox
                                        data={activityTypes.list}
                                        loading={activityTypes.loading}
                                        onFocus={(ev) => { if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click() }}
                                        textField='lookupValue'
                                        dataItemKey='lookupId'
                                        value={itemData.activityType}
                                        onChange={handleSelectChange}
                                        label="Milestone"
                                        name='activityType'
                                        id='activityType'
                                        required={true}
                                    />
                                    {errors.activityType && <span className='text-danger'>Milestone is Required.</span>}
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem sm={12} >
                            <h5>Comments</h5>
                            <GridContainer>
                                <GridItem sm={12}>
                                    <textarea
                                        className='k-textarea w-100'
                                        label='Comments'
                                        value={itemData.comments}
                                        onChange={handleTextAreaChange}
                                        name="comments"
                                        id="comments"
                                        rows={5}
                                    />
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </>) : (<>
                        <GridItem sm={12}>
                            <GridContainer>
                                <GridItem sm={6} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>Timeline Type:</label>
                                        <div className="font-size-14">{itemData.blockType ? itemData.blockType.lookupValue : '' }</div>
                                    </span>
                                </GridItem>
                                <GridItem sm={6} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>Milestone:</label>
                                        <div className="font-size-14">{itemData.activityType ? itemData.activityType.lookupValue : '' }</div>
                                    </span>
                                </GridItem>
                                <GridItem sm={6} className='mt-3'>
                                    <span className='k-textbox-container'>
                                        <label className='ro-label'>Instruction Date:</label>
                                        <div className="font-size-14">{itemData.instructionDate ? moment(itemData.instructionDate).format('D/MM/YYYY') : ''}</div>
                                    </span>
                                </GridItem>                                
                            </GridContainer>
                        </GridItem>
                        <GridItem sm={12} className='mt-3'>
                            <span className='k-textbox-container'>
                                <label className='ro-label'>Comments:</label>
                                <div className="font-size-14">{itemData.comments}</div>
                            </span>
                        </GridItem>
                    </>)}
                </GridContainer>
            </form>
        </Dialog>
    )
};

export default withSnackbar(ActivityBlockEditor);