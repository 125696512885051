import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import cx from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import ExitToApp from "@material-ui/icons/ExitToApp";

import SidebarWrapper from './SidebarWrapper';
import AdminNavbarLinks from "../MuiKit/Navbars/AdminNavbarLinks";

import sidebarStyle from "../../assets/jss/material-dashboard-pro-react/components/sidebarStyle";

//import avatar from "../../assets/img/faces/avatar.jpg";

class Sidebar extends React.Component {
  
    constructor(props) {
      super(props);
      this.state = {
        openAvatar: false,
        miniActive: true,
        ...this.getCollapseStates(props.routes)
      };
    }
    
    // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }
  getCreateLinksClassNames(prop, key) {
    const { classes, color, rtlActive } = this.props;
    return {
      navLinkClasses:
        classes.itemLink + 
        " " + 
        cx(
          prop.collapse ? {[" " + classes.collapseActive]: this.getCollapseInitialState(prop.views)} : {[" " + classes[color]]: this.activeRoute(prop.path)}
        ),
      itemText: 
        classes.itemText +
        " " +
        cx({
          [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
          [classes.itemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.itemTextRTL]: rtlActive
        }),
      collapseItemText:
        classes.collapseItemText +
        " " +
        cx({
          [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextRTL]: rtlActive
        }),
      itemIcon:
        classes.itemIcon +
        " " +
        cx({
          [classes.itemIconRTL]: rtlActive
        }),
      caret:
        classes.caret +
        " " +
        cx({
          [classes.caretRTL]: rtlActive
        }),
      collapseItemMini:
        classes.collapseItemMini +
        " " +
        cx({
          [classes.collapseItemMiniRTL]: rtlActive
        }),
      innerNavLinkClasses:
        classes.collapseItemLink +
        " " +
        cx({
          [" " + classes[color]]: this.activeRoute(prop.path)
        })
    }
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    const { classes } = this.props;
    return routes.map((prop, key) => {
      if (prop.redirect || prop.hide) {
        return null;
      }

      const {
        navLinkClasses,
        itemText,
        collapseItemText,
        itemIcon,
        caret,
        collapseItemMini,
        innerNavLinkClasses
      } = this.getCreateLinksClassNames(prop, key)

      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];

        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <NavLink
              to={"#"}
              className={navLinkClasses}
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>
                  {prop.mini}
                </span>
              )}
              <ListItemText
                primary={prop.name}
                secondary={
                  <b
                    className={
                      caret +
                      " " +
                      (this.state[prop.state] ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </NavLink>
            <Collapse in={this.state[prop.state]} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                {this.createLinks(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }
      
      return (
        <ListItem
          key={key}
          className={cx(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined }
          )}
        >
          <NavLink
            to={prop.layout + prop.path}
            className={cx(
              { [navLinkClasses]: prop.icon !== undefined },
              { [innerNavLinkClasses]: prop.icon === undefined }
            )}
          >
            {prop.icon !== undefined ? (
              typeof prop.icon === "string" ? (
                <Icon className={itemIcon}>{prop.icon}</Icon>
              ) : (
                <prop.icon className={itemIcon} />
              )
            ) : (
              <span className={collapseItemMini}>
                {prop.mini}
              </span>
            )}
            <ListItemText
              primary={prop.name}
              disableTypography={true}
              className={cx(
                { [itemText]: prop.icon !== undefined },
                { [collapseItemText]: prop.icon === undefined }
              )}
            />
          </NavLink>
        </ListItem>
      );
    });
  };

  getRenderClassNames() {
    const {
      classes,
      bgColor,
      rtlActive
    } = this.props;

    return {
      itemText:
        classes.itemText +
        " " +
        cx({
          [classes.itemTextMini]:     this.props.miniActive && this.state.miniActive,
          [classes.itemTextMiniRTL]:  rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.itemTextRTL]:      rtlActive
        }),
      collapseItemText:
        classes.collapseItemText +
        " " +
        cx({
          [classes.collapseItemTextMini]:     this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextMiniRTL]:  rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextRTL]:      rtlActive
        }),
      userWrapperClass:
        classes.user +
        " " +
        cx({
          [classes.whiteAfter]: bgColor === "white"
        }),
      caret:
        classes.caret +
        " " +
        cx({
          [classes.caretRTL]: rtlActive
        }),
      collapseItemMini:
        classes.collapseItemMini +
        " " +
        cx({
          [classes.collapseItemMiniRTL]: rtlActive
        }),
      photo:
        classes.photo +
        " " +
        cx({
          [classes.photoRTL]: rtlActive
        }),
      logoNormal:
        classes.logoNormal +
        " " +
        cx({
          [classes.logoNormalSidebarMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.logoNormalSidebarMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.logoNormalRTL]: rtlActive
        }),
      logoMini:
        classes.logoMini +
        " " +
        cx({
          [classes.logoMiniRTL]: rtlActive
        }),
      logoClasses:
        classes.logo +
        " " +
        cx({
          [classes.whiteAfter]: bgColor === "white"
        }),
      drawerPaper:
        classes.drawerPaper +
        " " +
        cx({
          [classes.drawerPaperMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.drawerPaperRTL]: rtlActive
        }),
      sidebarWrapper:
        classes.sidebarWrapper +
        " " +
        cx({
          [classes.drawerPaperMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.sidebarWrapperWithPerfectScrollbar]:
            navigator.platform.indexOf("Win") > -1
        })
    }
  }

  render() {

    const {
      classes,
      logo,
      image,
      logoText,
      routes,
      bgColor,
      rtlActive,
      avatar
    } = this.props;

    const {
      itemText, 
      userWrapperClass, 
      photo,
      logoNormal,
      logoMini,
      logoClasses,
      drawerPaper,
      sidebarWrapper
    } = this.getRenderClassNames();

    const user = (
      <div className={userWrapperClass}>
        <div className={photo}>
          <img src={avatar} className={classes.avatarImg} alt="..." />          
        </div>
        <List className={classes.list}>
          <ListItem className={classes.item}>
            <NavLink
              to={"/profile"}
              className={classes.itemLink + " " + classes.userCollapseButton}
              >
              <ListItemText
                primary={'PROFILE'}

                disableTypography={true}
                className={itemText}
              />
            </NavLink>
     
          </ListItem>
        </List>
      </div>
    );
    const links = (
      <List className={classes.list}>
        {this.createLinks(routes)}
        <ListItem className={classes.item}>
                  <NavLink
                    to='/logout'
                    className={
                      classes.itemLink
                    }
                  >
                     <ExitToApp className={classes.itemIcon}/>

                    <ListItemText
                      primary={"Logout"}
                      disableTypography={true}
                      className={itemText} // className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
      </List>
    );

    const brand = (
      <div className={logoClasses}>
        <a href="/" className={logoMini}>
          <img src={logo} alt="logo" className={classes.img} />
        </a>
        <a href="/" className={logoNormal}>
          {logoText}
        </a>
      </div>
    );
    return (
      <div ref="mainPanel">
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={rtlActive ? "left" : "right"}
            open={this.props.open}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              headerLinks={<AdminNavbarLinks rtlActive={rtlActive} />}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? "right" : "left"}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}


Sidebar.defaultProps = {
  bgColor: "black"
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue", "primary"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose", 
    "primary"
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  avatar: PropTypes.any,
  fullname: PropTypes.string
};

export default withStyles(sidebarStyle)(Sidebar);
