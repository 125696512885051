
const requiresRole = (Wrapped, roles) => {
    var role = localStorage.getItem('Llama.role');

    if (!role || !roles || roles.length === 0 || !roles.includes(role)) {
        return null
    }

    return Wrapped;
}
const canAccess = (roles) => {
    var role = localStorage.getItem('Llama.role');

    if (!role || !roles || roles.length === 0 || !roles.includes(role)) {
        return false
    }

    return true;
}

const policies = {
    admin: ['admin'],
    user: ['user'],
    client: ['client'],
    edit: ['admin', 'user'],
}

export {
    requiresRole,
    policies,
    canAccess
}