import React, { useState, useEffect, useRef } from 'react';
import { withRouter, Link } from "react-router-dom";
import _ from "lodash";
import { withSnackbar } from 'notistack';
import moment from 'moment';

import GavelIcon from "@material-ui/icons/Gavel";

import api from "../../util/api";
import { requiresRole, policies } from '../../util/accessControl';
import handleErrors from '../../util/handleErrors';

import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';

import LoadingPanel from '../common/Grid/LoadingPanel';
import CardIcon from "../common/MuiKit/Card/CardIcon";
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";
import { ConfirmDelete } from "../common/Dialog";
import GenericHeader from "../common/Header/GenericHeader"; 
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";

import ActivityBlockEditor from "./ActivityBlockEditor";

const ActivityBlock = (props) => {
    const [gridItems, setGridItems] = useState({});
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState({ take: 9999, skip: 0 })
    const [loading, setLoading] = useState(false);
    const [itemInEdit, setItemInEdit] = useState(null);
    const [itemInDelete, setItemInDelete] = useState(null);
    const [matterId] = useState(props.match.params.id);
    const defaultSort = { object: [], query: '' };
    const [sort, setSort] = useState(defaultSort); 
    const _export = useRef(null);
    const _grid = useRef(null);

    useEffect(() => {
        setLoading(true);

        api.get(`/activityBlock/${matterId}/paged/${(page.skip / page.take) + 1}/${page.take}${sort.query ? `?${sort.query}` : ``}`, {
            accept: 'application/json',
        })
        .then(json => {
            json.data.forEach(function(value) { value.instructionDate = moment(value.instructionDate).toDate(); });
            setGridItems(createAppState(json.data, { take: 9999, group: [{ field: 'blockType.lookupValue' }] }));
            setTotal(json.totalRecords);
            setLoading(false);
        })
        .catch(handleErrors(props))
    }, [page, sort]);

    const edit = (dataItem) => {
        dataItem.editMode = true;
        const item = Object.assign({}, dataItem)
        Object.keys(item).forEach(key => {
            if (item[key] == null) {
                if (key === 'blockTypeId' ||
                    key === 'activityTypeId' ||
                    key === 'instructionDate' ||
                    key === 'activityBlockId' ||
                    key === 'comments'
                ) {
                    item[key] = null;
                } else {
                    item[key] = '';
                }
            }
        })
        setItemInEdit(item);
    };

    const remove = (dataItem) => {
        setItemInDelete(dataItem);
    };

    const deleteItem = (dataItem) => {
        setLoading(true);

        api.delete(`/activityBlock/${dataItem.activityBlockId}`, {
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(json => {
            setItemInEdit(null);
            setPage({ take: page.take, skip: 0 });
        })
        .catch(handleErrors(props))
        .finally(setLoading(false))
    };

    const save = (dataItem) => {
        setLoading(true);

        api.request({
            url: `/activityBlock`,
            method: dataItem.activityBlockId === 0 ? 'POST' : 'PUT',
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(dataItem)
        })
        .then(json => {
            setItemInEdit(null);
            setPage({ take: page.take, skip: 0 });
        })
        .catch(handleErrors(props))
        .finally(setLoading(false))
    }

    const cancel = () => {
        if (itemInEdit) { setItemInEdit(null); }
        if (itemInDelete) { setItemInDelete(null); }

        setLoading(false);
    }

    const insert = () => {
        setItemInEdit({
            activityBlockId: 0,
            matterId: parseInt(matterId),
            editMode: true,
        });
    }

    const createAppState = (items, dataState) => {
        return {
            result: process(items, dataState),
            dataState: dataState
        };
    }

    const dataStateChange = (event) => {
        setGridItems(createAppState(gridItems.result, event.data));
    }

    const expandChange = (event) => {
        event.dataItem[event.target.props.expandField] = event.value;
        setGridItems({
            result: Object.assign({}, gridItems.result),
            dataState: gridItems.dataState
        });
    }

    return (
        <>
            {!props.fromMatter && <GenericHeader />}
            <Card style={{ height: !props.fromMatter ? props.cardHeight : '350px', marginTop: !props.fromMatter ? '35px' : '0px'  }}>
                <CardHeader>
                    <CardIcon color='primary' style={{ marginTop: '-30px' }}>
                        <GavelIcon style={{ color: 'white' }} />
                    </CardIcon>
                    <h4 className='jm-card-title' style={{ color: '#777' }}> {`Timeline (${total})`}</h4>

                    {!props.fromMatter && <GridContainer className='mt-3 mb-1'>
                        <GridItem xs={1}>
                            {requiresRole(<Button
                                primary={true}
                                className='rounded-circle ml-3 mt-3 mb-1'
                                icon='plus'
                                title='Add New'
                                onClick={insert}
                            />, policies.edit)}
                        </GridItem>

                        <GridItem xs={3}>
                            <ExcelExport ref={_export} />
                            <Button
                                primary={false}
                                className='jm-action-btn k-title mt-3 mb-1'
                                onClick={() => {
                                    api.get(`/activityBlock/${matterId}/paged/1/10000${sort.query ? `?${sort.query}` : ``}`, {
                                        accept: 'application/json',
                                    })
                                    .then(json => {
                                        json.data.forEach(item => {
                                            if (item.instructionDate) {
                                                item.instructionDate = moment(item.instructionDate).toDate();
                                            }
                                        });
                                        _export.current.save(json, _grid.current.columns)
                                    })
                                }}
                                style={{ borderRadius: '8px' }}
                            >
                                EXPORT TO EXCEL
                                </Button>
                            <Grid ref={_grid} style={{ display: 'none' }}>
                                <GridColumn field="blockType.lookupValue" title="Block Type" />
                                <GridColumn field="activityType.lookupValue" title="Milestone" />
                                <GridColumn field="instructionDate" title="Instruction Date" filter="date" format="{0:dd/MM/yyyy}" />
                                <GridColumn field="comments" title="Comments" />
                            </Grid>
                        </GridItem>

                    </GridContainer>}   

                    {!!props.fromMatter && requiresRole(<Button
                        primary={true}
                        className='rounded-circle ml-3 mb-1'
                        icon='plus'
                        title='Add New'
                        onClick={insert}
                    />, policies.edit)}

                    {!!props.fromMatter && <span style={{ float: 'right', marginTop: '5px' }}>
                        <Link to={`/activityBlocks/${matterId}`}>View All</Link>
                    </span>}

                </CardHeader>
                <CardBody>
                    <Grid
                        filterable={false}
                        sortable={{
                            allowUnsort: true,
                            mode: 'single'
                        }}
                        sort={sort ? sort.object : []}
                        onSortChange={({ sort }) => {
                            if (sort && sort.length > 0) {
                                var arr = sort.map((o, i) => {
                                    return `sort[${i}].Property=${o.field}&sort[${i}].Direction=${o.dir == 'asc' ? 'Ascending' : 'descending'}`;
                                });
                                var str = arr.join('&');
                                setSort({ object: sort, query: str });
                            } else {
                                setSort(defaultSort);
                            }
                        }}
                        onDataStateChange={dataStateChange}
                        groupable={true}
                        pageable={matterId > 0 ? false : props.pagerSettings}
                        className={`clickableRow activity-block-grid ${matterId > 0 ? '' : ''}`}
                        data={gridItems.result}
                        total={total}
                        take={page.take}
                        skip={page.skip}
                        onPageChange={(ev) => setPage(ev.page)}
                        onRowClick={({ dataItem }) => { edit(dataItem, false) }}
                        style={{ height: !props.fromMatter ? 'calc(100vh - 320px)' : '280px' }}
                        {...gridItems.dataState}
                        onExpandChange={expandChange}
                        expandField="expanded"
                    >
                        <GridColumn field="activityType.lookupValue" title="Milestone" />
                        <GridColumn field="instructionDate" title="Instruction Date" filter="date" format="{0:dd/MM/yyyy}" />
                        <GridColumn field="comments" title="Comments" />
                    </Grid>
                </CardBody>
                {loading && <LoadingPanel />}
                {itemInEdit && <ActivityBlockEditor dataItem={itemInEdit} save={save} cancel={cancel} editMode={itemInEdit.editMode ?? false} delete={deleteItem} />}
                {itemInDelete && <ConfirmDelete title={`Delete this Block ?`} cancel={cancel} deleteItem={deleteItem} />}
            </Card>
        </>
    );
}
export default withSnackbar(withRouter(ActivityBlock));