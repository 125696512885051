import React, { useState, useEffect } from 'react';
import LoadingPanel from '../common/Grid/LoadingPanel';
import CardIcon from "../common/MuiKit/Card/CardIcon";
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";
import ListIcon from "@material-ui/icons/List";
import handleErrors from '../../util/handleErrors';
import api from "../../util/api";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import moment from "moment";
import { withRouter, Link } from 'react-router-dom';
import { Badge } from "@material-ui/core";
import _ from 'lodash';
import cn from "classnames";
import {saveTask} from './index';
import TaskEditor from "./TaskEditor";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import 'react-perfect-scrollbar/dist/css/styles.css';

const TaskListView = (props) => {
    const [ tasks, setTasks ] = useState([]);
    const [ viewed, setViewed ] = useState(0);
    const [ loading, setLoading ] = useState(false);
    //const [ itemSort, setItemSort] = useState('newest'); 
    const [ itemInEdit, setItemInEdit ] = useState(null);

    const options = [
        { text: 'Due Date (Descending)', id: 'duedate' }, 
        { text: 'Due Date (Ascending)', id: 'duedateasc' }
    ]
    const [ sortOptions, setSortOptions ] = useState(options);
    const defaultOption = options[1];
    const [ sortBy, setSortBy ] = useState(defaultOption);

    const loadTasks = () => {
        setLoading(true);
        let request;
        if (props.team) {
            request = api.get(`/task/team/${props.team}/${sortBy.id}`, {
                accept: 'application/json',
            });
        } else {
            request = api.get(`/task/all/${sortBy.id}`, {
                accept: 'application/json',
            });
        }
         
        request.then(json => {
            
            const count = _.countBy(json, i => (i.taskStatus && i.taskStatus.lookupValue !== "Completed") ?? true);
            setViewed(count.true);
            setTasks(json);
            setLoading(false);
        })
        .catch(handleErrors(props))
    }

    useEffect(() => {
        loadTasks();
    }, [sortBy]);
    
    const handleSort = ({ target: { value } }) => {
        //setItemSort(value.replace(' ', ''));
        setSortBy(value);
    }
    const save = (dataItem) => {
        saveTask(dataItem, props, () => {
            setItemInEdit(null);
            loadTasks();
        }, setLoading)
    }
    const cancel = () => {
        setItemInEdit(null);
        loadTasks();
    }
    const patch = (data, id) => {
        setLoading(true);
        api.request({
            url: `/task/${id}`,
            method: 'PATCH',
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        }) 
        .then(() => { 
            loadTasks();
            setItemInEdit(null);
            setLoading(false);             
        })
        .catch(handleErrors(props));
    }
    const deleteItem = (dataItem) => {
        setLoading(true);
      
        api.delete(`/task/${dataItem.taskId}`, {
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
        .then(() => {
            setItemInEdit(null);
            loadTasks();
        })
        .catch(handleErrors(props))
    };
    return (
        <Card style={{height: props.cardHeight, marginTop: '35px'}}>
            <CardHeader>
                <CardIcon color='rose' style={{marginTop: '-30px', position: 'relative'}}>
                    {/* viewed > 0 && <Badge badgeContent={viewed} color="primary" style={{position: 'absolute', right: '2px', top: '2px'}}></Badge> */}                 
                    <ListIcon style={{color:'white'}} />                        
                </CardIcon>
                <h4 className='jm-card-title' style={{color: '#777'}}>Tasks {`(${tasks.length})`}</h4>
                <span className='float-right'>
                    <Link to={`/tasks`}>View All</Link>
                </span>
            </CardHeader>
            <CardBody style={{ height: 'calc(100% - 45px)', overflow: 'hidden' }}>
                <div className={`${tasks && tasks.length > 0 ? 'list-view-item-container' : 'list-view-item-container-empty'}`} >
                    {tasks && tasks.length > 0 
                    ? tasks.map((task, index) => {
                        const itemClasses = cn({
                            'color-based-task': true,
                            'list-view-item': true,
                            'font-weight-bold':  (task.taskStatus && task.taskStatus.lookupValue !== "Completed") ?? true
                        })
                        return (
                                <GridContainer key={`keycontainertasks-1-${task.taskId}`} className={itemClasses} onClick={() => {
                                    props.history.push(`/files/${task.matterId}`)
                                    //setItemInEdit(task);
                                }}>
                                    <GridItem key={`keytasks-2-${task.taskId}`} xs={12} sm={12}>
                                        <p className='mb-0' style={{lineHeight: '1.1'}}>
                                            <span>{task.dueDate ? moment(task.dueDate).format('DD/MM/YYYY') : ''}</span>
                                            <span> | </span>
                                            <span>{task.matter.matterName ? task.matter.matterName : "NA"} {task.matter ? task.matter.clientReference : ""}</span>
                                        </p>
                                    </GridItem>
                                    <GridItem key={`keytasks-3-${task.taskId}`} xs={12} sm={12}>
                                        <p className='font-italic mb-0' style={{lineHeight: '1.1'}}>{task.title}</p>                                    
                                    </GridItem>
                                </GridContainer>
                        )
                    })
                    : <div>
                        No records to display
                    </div>
                    }
                </div>
                <div className='list-view-footer'>
                    <span>
                        <DropDownList 
                            data={sortOptions}
                            value={sortBy}
                            textField="text"
                            dataItemKey="id"
                            style={{border: 'none', width: '230px'}}
                            onChange={handleSort}
                        >
                        </DropDownList>
                    </span>
                </div>
                {loading && <LoadingPanel/>}
            </CardBody>
            {itemInEdit && <TaskEditor dataItem={itemInEdit} save={save} cancel={cancel} patch={patch} editMode={itemInEdit.taskId === 0} delete={deleteItem} hyperlink={true} />}
        </Card>
    );
}

export default withRouter(TaskListView);