import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';

import { requiresRole, policies } from '../../util/accessControl';
import { Link } from "react-router-dom";
import api from "../../util/api";
import _ from "lodash";

import LoadingPanel from '../common/Grid/LoadingPanel';
import cellWithEditing from "../common/Grid/CellWithEditing";
import CardIcon from "../common/MuiKit/Card/CardIcon";
import Card from "../common/MuiKit/Card/Card";
import CardHeader from "../common/MuiKit/Card/CardHeader";
import CardBody from "../common/MuiKit/Card/CardBody";
import GavelIcon from "@material-ui/icons/Gavel";

import BlockEditor from "./BlockEditor";
import BlockDetail from "./BlockDetail";


import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn } from '@progress/kendo-react-grid';

//import { DialogActionsBar } from '@progress/kendo-react-dialogs';
//import Dialog, { ConfirmDelete } from "../common/Dialog";
import { ConfirmDelete } from "../common/Dialog";
import { DropDownList } from "@progress/kendo-react-dropdowns";


import { withSnackbar } from 'notistack';
import withRouterAndRef from '../../util/withRouterAndRef';
import handleErrors from '../../util/handleErrors';
import { Renderers } from '../common/Grid/Renderers';
import moment from 'moment';

const StatusEditor = (onEnterEdit, onExitEdit, options) => (props) => {
    const { dataItem, field } = props;

    let dataValue = '';
    if (field.includes(".")) {
        dataValue = _.reduce(field.split("."), (a, b) => {
            return typeof a === "string" && dataItem[a] ? dataItem[a][b] : a[b];
        })
    } else {
        dataValue = dataItem[field];
    }
    
    return (
        <td className='incellEdit'>
            {dataItem.inEdit ? (
                <DropDownList
                    ref={(r) => {
                        if (r) {
                            r.focus();
                        }
                    }}
                    style={{ width: "100px" }}
                    onChange={({ value }) => {
                        onExitEdit(value, dataItem);
                    }}
                    onBlur={() => {
                        onExitEdit();
                    }}
                    value={options.find(c => c.lookupValue === dataValue)}
                    data={options}
                    textField="lookupValue"
                />
            ) : (
                <span style={{display: 'flex', cursor: 'pointer'}} onClick={(ev) => {
                    onEnterEdit(dataItem, field);
                }}>{dataValue}</span>
            )}
        </td>
    );
}

const Block = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        insert,
    }));

    // const blockTypeNotice = 46;
    // const blockTypeCourtAction = 47;
    // const blockTypeEnforcement = 48;
    // const blockTypeHold = 77;
    // const blockTypeDischarge = 78;
    // const blockTypeMIPSale = 79;

    // const [itemInStageNotice, setItemInStageNotice] = useState(null);
    // const [itemInStageCourtAction, setItemInStageCourtAction] = useState(null);
    // const [itemInStageEnforcement, setItemInStageEnforcement] = useState(null);
    // const [itemInStageHold, setItemInStageHold] = useState(null);
    // const [itemInStageDischarge, setItemInStageDischarge] = useState(null);
    // const [itemInStageMIPSale, setItemInStageMIPSale] = useState(null);

    const [gridItems, setGridItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState({ take: 9999, skip: 0 })
    const [loading, setLoading] = useState(false);
    const [itemInEdit, setItemInEdit] = useState(null);
    const [itemInDelete, setItemInDelete] = useState(null);
    const [matterId] = useState(props.match.params.id);
    const gridRef = useRef();

    useEffect(() => {
        if (document.getElementsByClassName('k-detail-cell').length > 0) {
            document.getElementsByClassName('k-detail-cell')[0].setAttribute('colspan', '5');
        }
    }, [itemInEdit]);

    //const [editField, setEditField] = useState(undefined);

    const enterEdit = (dataItem, field) => {
        const data = gridItems.map(item => ({
                ...item,
                inEdit: item.blockId === dataItem.blockId ? field : undefined
            })
        );
        setGridItems(data);
        //setEditField(field);
    }

    const exitEdit = (selected, dataItem) => {
        

        if (!selected) {
            const data = gridItems.map(item => {
                return { ...item, inEdit: undefined }
            });
            setGridItems(data);
            //setEditField(undefined);
        } else {
            setLoading(true)
            api.request({
                url: `/block/${dataItem.blockId}`,
                method: 'PATCH',
                accept: 'application/json',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify([{propertyName: 'BlockStatusId', propertyValue: selected.lookupId}])
            }) 
            .then(json => {
                const data = gridItems.map(item => {
                    if (dataItem.blockId === item.blockId) {
                        return { ...item, inEdit: undefined, blockStatus: selected, blockStatusId: selected.lookupId }
                    }
                    return { ...item, inEdit: undefined }
                });
                setGridItems(data);
                //setEditField(undefined);
                setLoading(false);
            })
            .catch(handleErrors(props));
        }       
        
    }

    const renderers = new Renderers(enterEdit, exitEdit, 'inEdit');
    const [ blockStatuses, setBlockStatuses ] = useState([]);
    useEffect(() => {
        setLoading(true);

        Promise.all([
            api.get(`/block/${matterId}/paged/${(page.skip / page.take) + 1}/${page.take}?sort[0].direction=ascending&sort[0].property=blockname`, {
                accept: 'application/json',
            }),
            api.get(`/lookup/blockStatus`, {
                accept: 'application/json',
            })
        ]).then(responses => {
            setGridItems(responses[0].data);
            setTotal(responses[0].totalRecords);
            setBlockStatuses(responses[1]);
            setLoading(false)
        })
        .catch(handleErrors(props))
        //.finally(setLoading(false))

    }, [page]);

    const edit = (dataItem) => {
        const item = Object.assign({}, dataItem)
        Object.keys(item).forEach(key => {
            if (item[key] == null) {
                if (key === 'originalAmountAdvanced' ||
                    key === 'loanValueRatio' ||
                    key === 'currentInterestRate' ||
                    key === 'currentPaymentAmount' ||
                    key === 'currentBalance' ||
                    key === 'governmentCharges' ||
                    key === 'daysDelinquent' ||
                    key === 'dailyInterestAmount' ||
                    key === 'blockId'
                ) {
                    item[key] = null;
                } else {
                    item[key] = '';
                }
            }
        })
        setItemInEdit(item);
        
    };

    const remove = (dataItem) => {
        setItemInDelete(dataItem);
    };

    const deleteItem = () => {
        setLoading(true);

        api.delete(`/block/${itemInDelete.blockId}`, {
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(json => {

            setItemInDelete(null);
            setPage({ take: 10, skip: 0 });
        })
        .catch(handleErrors(props))
        .finally(setLoading(false))
    };

    const save = (dataItem) => {
        setLoading(true);

        api.request({
            url: `/block`,
            method: dataItem.blockId === 0 ? 'POST' : 'PUT',
            accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(dataItem)
        })
        .then(json => {
            
            let items = gridItems.slice();
            if (dataItem.blockId === 0) {
                items = [json, ...items];
            } else {
                var index = gridItems.findIndex(v => v.blockId === json.blockId);
                items.splice(index, 1, dataItem);
            }

            setGridItems(_.orderBy(items, ['blockName'], ['asc']));
            setTotal(items.length);
            setItemInEdit(null);
            
                
                
        })
        .catch(handleErrors(props))
        .finally(setLoading(false))
    }

    const cancel = () => {
        if (itemInEdit)             { setItemInEdit(null); }
        if (itemInDelete)           { setItemInDelete(null); }

        setLoading(false);
    }

    const insert = () => {

        setItemInEdit({
            blockId: 0,
            matterId: parseInt(matterId),
            blockName: `${props.matterNumber}-N`,
            matter: props.matter,
            createdOn: moment()
        });
    }

    const expandChange = (event, isAddingStage = false) => {
        let items = gridItems.map((o) => ({ ...o, expanded: false }));
        var index = items.findIndex(o => o.blockId === event.dataItem.blockId);        
        items.splice(index, 1, { ...event.dataItem, expanded: !event.dataItem.expanded })
        setGridItems(items);
    }

    const blockDetailRef = useRef();

    return (
        // <Card style={{height: 'calc(100% - 60px)', maxHeight: '600px'}}>
        <Card style={{height: '600px'}}>
            <CardHeader>
                <CardIcon color='primary' style={{ marginTop: '-30px' }}>
                    <GavelIcon style={{ color: 'white' }} />
                </CardIcon>
                <h4 className='jm-card-title' style={{ color: '#777' }}>Timeline</h4>
                {/* <Button
                    primary={true}
                    className='jm-action-btn jm-header-btn'
                    icon='plus'
                    title='Add New'
                    onClick={insert}
                /> */}
                {requiresRole(<Button
                    primary={true}
                    className='rounded-circle ml-3 mb-1'
                    icon='plus'
                    title='Add New'
                    onClick={insert}
                />, policies.edit)}


                    <span style={{ float: 'right', marginTop:'5px' }}>
                        <Link to={`/blocks/${matterId}`}>View All</Link>
                    </span>
                
            </CardHeader>
            <CardBody>
                <Grid
                    className=''
                    ref={gridRef}
                    filterable={false}
                    sortable={true}
                    pageable={false}
                    data={gridItems}
                    total={total}
                    take={page.take}
                    skip={page.skip}
                    onPageChange={(ev) => setPage(ev.page)}
                    style={{ height: '100%' }}
                    detail={({dataItem}) => { 
                        return (<BlockDetail dataItem={dataItem} ref={blockDetailRef} />);
                    }}
                    expandField="expanded"
                    onExpandChange={expandChange}
                    rowRender={renderers.rowRender}
                    //scrollable='none'
                    onRowClick={(ev) => {
                        expandChange(ev);
                    }}
                >
                    <GridColumn editable={false} field="blockName" title="Block Number" width={140} />
                    <GridColumn editable={false} field="blockType.lookupValue" title="Stage" cell={(p) => {
                        return (<td style={{ fontWeight: p.dataItem.blockStatus && p.dataItem.blockStatus.lookupValue === 'Active' ? 'bold' : 'normal' }}>{p.dataItem.blockType ? p.dataItem.blockType.lookupValue : ''}</td>);
                    }} />
                    {/* <GridColumn editable={false} cell={cellWithEditing(edit, remove)} width={90} /> */}
                    <GridColumn editable={false} cell={(col) => {
                       return (
                            <td>                        
                                {requiresRole(<Button
                                    primary={false}
                                    icon='pencil'
                                    onClick={() => { edit(col.dataItem); }}
                                    title='Edit'
                                    className='ml-1 rounded-circle'
                                >
                                </Button>, policies.edit)}

                                {/* {requiresRole(<Button
                                    primary={true}
                                    icon='plus'
                                    onClick={(ev) => { 
                                        blockDetailRef.current.addBlockStage(col.dataItem);
                                    }}
                                    title='Edit'
                                    className='ml-1 rounded-circle'
                                >
                                </Button>, policies.edit)} */}
                            </td>
                        );
                    }} width={90} />
                    <GridColumn 
                        field="blockStatus.lookupValue"
                        cell={StatusEditor(enterEdit, exitEdit, blockStatuses)}
                        title="Stage Status" 
                        width={160} 
                    />
                    
                </Grid>
                {/* <div className='grid-footer'>
                    <span style={{ float: 'right', marginTop:'5px' }}>
                        <Link to={`/blocks/${matterId}`}>View All</Link>
                    </span>
                </div> */}
            </CardBody>
            {loading && <LoadingPanel />}
            {itemInEdit && <BlockEditor dataItem={itemInEdit} save={save} cancel={cancel} editMode={true}/>}
            {itemInDelete && <ConfirmDelete title={`Delete Block ${itemInDelete.blockName}?`} cancel={cancel} deleteItem={deleteItem} />}
           
            {/* {itemInDelete && <Dialog title={"Delete confirmation"} onClose={cancel}>
                <p style={{ margin: "25px", textAlign: "center" }}>Delete Block "{itemInDelete.blockName}" ?</p>
                <DialogActionsBar>
                    <button className="k-button" onClick={cancel}>No</button>
                    <button className="k-button" onClick={deleteItem}>Yes</button>
                </DialogActionsBar>
            </Dialog>} */}
        </Card>
    )
});

export default withSnackbar(withRouterAndRef(Block));