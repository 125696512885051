import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import LoadingPanel from '../common/Grid/LoadingPanel';
import { withSnackbar } from 'notistack';
import TaskListView from "../task/TaskListView";
import ConversationListView from "../conversation/ConversationListView";
import GenericHeader from "../common/Header/GenericHeader"; 

const Team = (props) => {
    const [loading, setLoading] = useState(false);

    return (
        <>
            <GenericHeader />
            <GridContainer>
                <GridItem xs={12} lg={4}>
                    <TaskListView cardHeight='calc(100vh - 150px)' team={localStorage.getItem('Llama.role')} />
                </GridItem>
                <GridItem xs={12} lg={8}>
                    <ConversationListView cardHeight='calc(100vh - 150px)' team={localStorage.getItem('Llama.role')}/>
                </GridItem>
                {loading && <LoadingPanel />}
            </GridContainer>
        </>
    )
}

export default withSnackbar(withRouter(Team));