import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';

import { Link } from "react-router-dom";
import withRouterAndRef from '../../util/withRouterAndRef';
import api from "../../util/api";
import handleErrors from '../../util/handleErrors';
import { withSnackbar } from 'notistack';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import LoadingPanel from '../common/Grid/LoadingPanel';
import moment from 'moment';

const DocumentSummary = forwardRef((props, ref) => {
    const id = props.match.params.id;

    useImperativeHandle(ref, () => ({
        load,
    }));

    const [ documentsLoading, setDocumentsLoading ] = useState(false);
    const [ documents, setDocuments ] = useState([]);
    useEffect(() => {
        load();
    }, []);

    const load = () => {
        setDocumentsLoading(true);
        api.get(`/document/${id}/paged/1/10?sort[0].direction=descending&sort[0].property=createdon`, {
            accept: 'application/json',
        })
        .then(json => setDocuments(json.data))
        .catch(handleErrors(props))
        .finally(() => setDocumentsLoading(false));
    }

    return (
        <React.Fragment>
            
            <Grid
                className='hide-header clickableRow h-100'
                data={documents}
            >                                                                                    
                <GridColumn cell={(p) => {
                    if (!p.dataItem) return null;
                    
                    const { documentName, user, documentDate, documentId } = p.dataItem;
                    return (
                        <td onClick={() => props.edit(p.dataItem)}>
                            <GridContainer>
                                {/* <GridItem md={2} style={{whiteSpace: 'nowrap'}}>
                                    <p>{documentId}</p>
                                </GridItem> */}
                                <GridItem md={10}>
                                    <GridContainer style={{lineHeight: '16px' }}>
                                        <GridItem md={12}>
                                            {documentName}
                                        </GridItem>
                                        <GridItem md={12} className='font-italic'>                                                                                            
                                            {moment(documentDate).format("DD/MM/YYYY")} {/* {user ? user.lookupValue : "" } */}
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                        </td>
                    );                                                     
                }} />
            </Grid>
            {/* <div className='grid-footer'>
                <span style={{ float: 'right', marginTop:'5px' }}>
                    <Link to={`/documents/${id}`}>View All</Link>
                </span>
            </div> */}
            {documentsLoading && <LoadingPanel/>}
            {props.renderEditors()}
        </React.Fragment>
    );
});

export default withSnackbar(withRouterAndRef(DocumentSummary));