import React, { useState } from 'react';
import { findDOMNode } from "react-dom";
import api from "../../util/api"
import _ from 'lodash';

import { withRouter } from "react-router-dom";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";

import { Input } from '@progress/kendo-react-inputs';
import { ComboBox, AutoComplete } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';

import moment from "moment";
import { withSnackbar } from 'notistack';
import handleErrors from '../../util/handleErrors';

const MatterGeneralInfo = (props) => {
    const { itemData, setItemData, matterStatuses, matterTypes, formErrors } = props;
    
    const handleTextAreaChange = ({ target: { value, name } }) => {
        setItemData({ ...itemData, [name]: value });
    }
    const handleInputChange = ({ value, target: { name } }) => {
        setItemData({ ...itemData, [name]: value });
    }
    const handleSelectChange = ({ value, target: { name } }) => {
        const idField = `${name}Id`;
        setItemData({ ...itemData, [name]: value, [idField]: value ? value.lookupId : null });
    }

    const handleDateChange = ({ value, target: { name } }) => {
        setItemData({ ...itemData, [name]: value == null ? null : moment(value).local().toDate() });
    }

    const handleSensitiveMatter = ({ target: { value, name, checked } }) => {
        setItemData({ ...itemData, 'isSensitiveMatter': checked });
    }

    const [usersLoading, setUsersLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const userAutoCompleteChanged = ({ value, ...rest }) => {
        setItemData({ ...itemData, [rest.target.name]: { ...itemData[rest.target.name], lookupValue: value } });
        if (value.length < 3) {
            return
        };

        if (!(rest.nativeEvent instanceof InputEvent)) {
            const user = _.find(users, o => o.lookupValue === value);
            if (user) {
                setItemData({ ...itemData, [rest.target.name]: user, [rest.target.name + 'Id']: user.lookupId });
                setUsers([]);
                return;
            }
        }
        loadUsers(value);
    }

    const loadUsers = (value) => {
        setUsersLoading(true)
        api.get(`/lookup/user/${value}`, {
            accept: 'application/json',
        })
        .then(json => {
            setUsers(json || []);
        })
        .catch(handleErrors(props))
        .finally(() => setUsersLoading(false));
    }

    return (itemData && itemData.itemLoaded) ? (
        <div>
        {/* <h4 className='text-center' style={{ color: '#777', marginTop:'-5px' }}>File Information</h4> */}
        
        <GridContainer>
                    <GridItem xs={4} className='mt-4'>
                        <Input
                            width='100%'
                            label='Matter name'
                            value={itemData.matterName ?? ""}
                            onChange={handleInputChange}
                            name="matterName"
                            id="matterName"
                            required={true}
                        />
                        {formErrors.matterName && <span className='text-danger'>Matter name is required.</span>}
                    </GridItem>
                    <GridItem xs={4} className='mt-4'>
                        <Input
                            width='100%'
                            label='Jacmac matter reference number'
                            value={itemData.matterNumber}
                            onChange={handleInputChange}
                            name="matterNumber"
                            id="matterNumber"
                            required={true}
                        />
                        {formErrors.matterNumber && <span className='text-danger'>Jacmac matter reference number is required.</span>}
                    </GridItem>
                    <GridItem xs={4} className='mt-4'>
                        <Input
                            width='100%'
                            label='Client matter reference number'
                            value={itemData.clientReference}
                            onChange={handleInputChange}
                            name="clientReference"
                            id="clientReference"
                            required={true}
                        />
                        {formErrors.clientReference && <span className='text-danger'>Client matter reference number is required.</span>}
                    </GridItem>

                    <GridItem xs={4} className='mt-3'>
                        <AutoComplete
                            data={users}
                            value={itemData.manager ? itemData.manager.lookupValue : null}
                            onChange={userAutoCompleteChanged}
                            textField='lookupValue'
                            style={{ width: '100%', borderWidth: '1px' }}
                            name="manager"
                            id="manager"
                            label="File Manager"
                            required={true}
                        />
                        {formErrors.manager && <span className='text-danger'>File Manager is required.</span>}
                    </GridItem>
                    <GridItem xs={8} />
                    
                    <GridItem xs={4} className='mt-3'>
                        <AutoComplete
                            data={users}
                            value={itemData.clientContact ? itemData.clientContact.lookupValue : null}
                            onChange={userAutoCompleteChanged}
                            textField='lookupValue'
                            style={{ width: '100%', borderWidth: '1px' }}
                            name="clientContact"
                            id="clientContact"
                            label="Instructing Officer"
                            required={true}
                        />
                        {formErrors.clientContact && <span className='text-danger'>Instructing Officer is required.</span>}
                    </GridItem>
                    <GridItem xs={8} />

                    <GridItem xs={12} className='mt-2'>
                        <label className='k-checkbox-label' style={{left: '-2px', cursor: 'default'}}>Further Information</label>
                    </GridItem>
                    {/* <GridItem xs={12} className='mt-3'>
                        <input type="checkbox" className='k-checkbox' defaultChecked={itemData.isSensitiveMatter} onClick={handleSensitiveMatter} name="isSensitiveMatter" id="isSensitiveMatter" />
                        <label className="k-checkbox-label" htmlFor="isSensitiveMatter">Sensitive Matter</label>
                    </GridItem> */}
                    <GridItem xs={12} className='mt-4'>
                        <span className='k-textbox-container'>
                            <label className='k-label'>Sensitivities</label>
                            <textarea
                                className='k-textarea w-100'
                                value={itemData.sensitivities}
                                onChange={handleTextAreaChange}
                                name="sensitivities"
                                id="sensitivities"
                                rows={7}
                            />
                        </span>
                    </GridItem>
                    <GridItem xs={12} className='mt-3'>
                        <span className='k-textbox-container'>
                            <label className='k-label'>Matter overview</label>
                            <textarea
                                className='k-textarea w-100'
                                value={itemData.overview}
                                onChange={handleTextAreaChange}
                                name="overview"
                                id="overview"
                                rows={7}
                            />
                        </span>
                    </GridItem>
                    {/* <GridItem xs={4} className='mt-3'>
                        <ComboBox
                            data={matterStatuses}
                            onFocus={(ev) => {if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click()}}
                            textField='lookupValue'
                            dataItemKey='lookupId'
                            value={itemData.matterStatus}
                            onChange={handleSelectChange}
                            label="Matter Status"
                            name='matterStatus'
                            id='matterStatus'
                            required={true}
                        />
                        {formErrors.matterStatus && <span className='text-danger'>Matter Status is Required.</span>}
                    </GridItem> */}

                </GridContainer>
            
        {/* <GridContainer>
            <GridItem sm={4}>
                <GridContainer>
                    <GridItem sm={12}>
                        <Input
                            width='100%'
                            label='File Number'
                            value={itemData.matterNumber}
                            onChange={handleInputChange}
                            name="matterNumber"
                            id="matterNumber"
                            required={true}
                        />
                        {formErrors.matterNumber && <span className='text-danger'>File Number is Required.</span>}
                    </GridItem>

                    <GridItem sm={12}>
                        <Input
                            width='100%'
                            label='Loan Number'
                            value={itemData.loanNumber}
                            onChange={handleInputChange}
                            name="loanNumber"
                            id="loanNumber"
                        />
                    </GridItem>

                    <GridItem sm={12}>
                        <span className='k-textbox-container'>
                            <label className='k-label'>Instruction Date</label>
                            <DatePicker
                                defaultValue={new Date()}
                                format={"dd/MM/yyyy"}
                                name="instructionDate"
                                id="instructionDate"
                                value={itemData.instructionDate}
                                onChange={handleDateChange}
                                label="Instruction Date"
                                required={true}
                            />
                        </span>
                        {formErrors.instructionDate && <span className='text-danger'>Instruction Date is Required.</span>}
                    </GridItem>

                    <GridItem sm={12}>
                        <ComboBox onFocus={(ev) =>{ if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click()}}
                            data={matterTypes}
                            textField='lookupValue'
                            dataItemKey='lookupId'
                            value={itemData.matterType}
                            onChange={handleSelectChange}

                            label="File Type"
                            name='matterType'
                            id='matterType'
                            required={true}
                        />
                        {formErrors.matterType && <span className='text-danger'>File Type is Required.</span>}
                    </GridItem>

                </GridContainer>
            </GridItem>
            <GridItem sm={4}>
                <GridContainer>
                    <GridItem sm={12}>
                        <ComboBox onFocus={(ev) => {if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click()}}
                            data={matterStatuses}
                            textField='lookupValue'
                            dataItemKey='lookupId'
                            value={itemData.matterStatus}
                            onChange={handleSelectChange}

                            label="File Status"
                            name='matterStatus'
                            id='matterStatus'
                            required={true}
                        />
                        {formErrors.matterStatus && <span className='text-danger'>File Status is Required.</span>}
                    </GridItem>

                    <GridItem sm={12}>
                        <Input
                            width='100%'
                            label='Client Reference'
                            value={itemData.clientReference}
                            onChange={handleInputChange}
                            name="clientReference"
                            id="clientReference"
                        />
                    </GridItem>

                    <GridItem sm={12}>
                        <Input
                            width='100%'
                            label='Customer No.'
                            value={itemData.customerNumber}
                            onChange={handleInputChange}
                            name="customerNumber"
                            id="customerNumber"

                        />
                    </GridItem>

                    <GridItem sm={12}>

                        <AutoComplete
                            loading={usersLoading}
                            data={users}
                            value={itemData.clientContact.lookupValue}
                            onChange={userAutoCompleteChanged}

                            textField='lookupValue'
                            style={{ width: '100%', borderWidth: '1px' }}
                            name="clientContact"
                            id="clientContact"
                            label="Client Contact"
                            required={true}
                        />
                        {formErrors.clientContact && <span className='text-danger'>Client Contact is Required.</span>}
                    </GridItem>

                   
                </GridContainer>
            </GridItem>
            <GridItem sm={4}>
                <GridContainer>
                    <GridItem sm={12}>

                        <AutoComplete
                            loading={usersLoading}
                            data={users}
                            value={itemData.manager.lookupValue}
                            onChange={userAutoCompleteChanged}
                            textField='lookupValue'
                            style={{ width: '100%', borderWidth: '1px' }}
                            name="manager"
                            id="manager"
                            label="Manager"
                            required={true}
                        />
                        {formErrors.manager && <span className='text-danger'>Manager is Required.</span>}

                    </GridItem>

                    <GridItem sm={12}>
                        <Input
                            width='100%'
                            label='Contact No.'
                            value={itemData.contactNumber}
                            onChange={handleInputChange}
                            name="contactNumber"
                            id="contactNumber"

                        />
                    </GridItem>

                    <GridItem sm={12}>
                        <Input
                            width='100%'
                            label='Email Address'
                            value={itemData.emailAddress}
                            onChange={handleInputChange}
                            name="emailAddress"
                            id="emailAddress"
                        />
                    </GridItem>

                    <GridItem sm={12}>
                        <input type="checkbox" className='k-checkbox' defaultChecked={itemData.isSensitiveMatter} onClick={handleSensitiveMatter} name="isSensitiveMatter" id="isSensitiveMatter" />
                        <label className="k-checkbox-label" htmlFor="isSensitiveMatter">Sensitive Matter</label>
                    </GridItem>
                    
                </GridContainer>
            </GridItem>
            <GridItem sm={12}>
                <GridContainer>
                    <GridItem sm={4}>
                        <span className='k-textbox-container'>
                            <label className='k-label'>Purpose of Instruction</label>
                            <textarea
                                className='k-textarea w-100'
                                value={itemData.purposeOfInstruction}
                                onChange={handleTextAreaChange}
                                name="purposeOfInstruction"
                                id="purposeOfInstruction"
                                rows={3}
                            />
                        </span>
                    </GridItem>

                    <GridItem sm={4}>
                        <span className='k-textbox-container'>
                            <label className='k-label'>Comments</label>
                            <textarea
                                className='k-textarea w-100'
                                value={itemData.comments}
                                onChange={handleTextAreaChange}
                                name="comments"
                                id="comments"
                                rows={3}
                            />
                        </span>
                    </GridItem>

                    <GridItem sm={4}>
                        <span className='k-textbox-container'>
                            <label className='k-label'>Sensitive Comments</label>
                            <textarea
                                className='k-textarea w-100'
                                value={itemData.sensitiveComments}
                                onChange={handleTextAreaChange}
                                name="sensitiveComments"
                                id="sensitiveComments"
                                rows={3}
                            />
                        </span>
                    </GridItem>
                </GridContainer>
            </GridItem>
        </GridContainer> */}
        </div>
    ) : <div/>
}

export default withSnackbar(withRouter(MatterGeneralInfo));