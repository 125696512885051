import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Col } from 'reactstrap';

const style = {
  grid: {
    padding: "0 15px !important"
  }
};

function GridItem({ ...props }) {
  const { classes, children, className, ...rest } = props;
  return (
    <Col {...rest} className={className}>
       {children}
    </Col>
  )
}

export default withStyles(style)(GridItem);
