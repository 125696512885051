import React from 'react';
import GridItem from "../app/common/MuiKit/Grid/GridItem";

const editableField = (Wrapped, inEditMode, label, value, col = [4,8], isHtml = false) => {

    if (inEditMode) {
        return Wrapped;
    } else {
        if (isHtml) {
            return (
                <>
                    <GridItem sm={col[0]}>
                        <p className='h5'>{label}:</p>
                    </GridItem>
                    <GridItem sm={col[1]}>
                        <div className="lead" dangerouslySetInnerHTML={{ __html: value }}></div>
                    </GridItem>
                </>
            )
        }
        else {
            return (
                <>
                    <GridItem sm={col[0]}>
                        <p className='h5'>{label}:</p>
                    </GridItem>
                    <GridItem sm={col[1]}>
                        <p className='lead'>{Array.isArray(value) ? value.map(m => m.lookupValue).join(', ') : value}</p>
                    </GridItem>
                </>
            )
        }
        
    }
}

export {
    editableField
}