import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import Button from '@material-ui/core/Button';
import registerServiceWorker from './registerServiceWorker';
import { SnackbarProvider } from 'notistack';

const rootElement = document.getElementById('root');
const notistackRef = React.createRef();
const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
}

ReactDOM.render(
    <SnackbarProvider
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        ref={notistackRef}
        action={(key) => (
            <Button onClick={onClickDismiss(key)}>
                close
            </Button>
        )}
    >
        <App />
    </SnackbarProvider>, rootElement);

registerServiceWorker();
