import React, { useState, useEffect } from 'react';
import { findDOMNode } from "react-dom";
import { withRouter } from "react-router-dom";
import _ from 'lodash';
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import { ComboBox, AutoComplete, MultiSelect } from '@progress/kendo-react-dropdowns';
import Dialog from "../common/Dialog";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import api from "../../util/api"
import handleErrors from '../../util/handleErrors';
import { requiresRole, policies } from '../../util/accessControl'

import moment from "moment";
import { withSnackbar } from 'notistack';
import { func } from 'prop-types';

const SecurityEditor = (props) => {
    
    const initLookupObject = { loading: false, list: [] };
    const loading = { loading: true, list: [] };
    const axiosConfig = { accept: 'application/json' };
    const [errors, setErrors] = useState({});
    const originalItem = {
        ...props.dataItem,
        valuationDate: (props.dataItem.valuationDate == null || props.dataItem.valuationDate === "") ? null : moment(props.dataItem.valuationDate).toDate(),
    };
    const [itemData, setItemData] = useState(originalItem);
    const [originalItemData, setOriginalItemData] = useState(originalItem);
    const [matterId] = useState(props.match.params.id || (itemData.matterId || 0));
    const [matters, setMatters] = useState(initLookupObject);
    const [occupancyStatuses, setOccupancyStatuses] = useState(initLookupObject);
    const [lotTypes, setLotTypes] = useState(initLookupObject);
    const [extents, setExtents] = useState(initLookupObject);
    const [editMode, setEditMode] = useState(!!props.editMode);
    const [accounts, setAccounts] = useState(initLookupObject);
    const [parties, setParties] = useState(initLookupObject);
    const setLookup = (setFunc) => (json) => {
        setFunc({ loading: false, list: json });
        return json;
    }

    const isNewMatter = props.match.path === "/instruction/:id?"
    
    useEffect(() => {
        
        setOccupancyStatuses(loading);

        let onLoadTasks = [
            api.get(`/lookup/occupancyStatus`, axiosConfig).then(setLookup(setOccupancyStatuses)), 
            api.get(`/lookup/lotType`, axiosConfig).then(setLookup(setLotTypes)), 
            api.get(`/lookup/extent`, axiosConfig).then(setLookup(setExtents)), 
        ];
        if (matterId > 0) {
            onLoadTasks.push(api.get(`/account/${matterId}/lookup`, axiosConfig).then(setLookup(setAccounts)));
            onLoadTasks.push(api.get(`/party/${matterId}/lookup`, axiosConfig).then(setLookup(setParties)));
        }

        Promise.all(onLoadTasks)
            .then(responses => {

                let tempData = { ...itemData };
                let occupancyStatus = tempData.occupancyStatus;
                if (tempData.occupancyStatusId && tempData.occupancyStatusId > 0) {
                    occupancyStatus = responses[0].find(v => v.lookupId === tempData.occupancyStatusId);
                }
                let lotType = tempData.lotType;
                if (tempData.lotTypeId && tempData.lotTypeId > 0) {
                    lotType = responses[1].find(v => v.lookupId === tempData.lotTypeId);
                }
                let extent = tempData.extent;
                if (tempData.extentId && tempData.extentId > 0) {
                    extent = responses[2].find(v => v.lookupId === tempData.extentId);
                }

                tempData = { ...tempData, occupancyStatus: occupancyStatus, lotType: lotType, extent: extent };
                setItemData(tempData);
                setOriginalItemData(tempData);
            })
            .catch(handleErrors(props))

    }, []);

    const matterAutoCompleteChanged = ({ value, ...rest }) => {
        setItemData({ ...itemData, [rest.target.name]: { ...itemData[rest.target.name], lookupValue: value }, matterNumber: value, securityAccountLookups: [] });
        if (value.length < 3) { return };

        if (!(rest.nativeEvent instanceof InputEvent)) {
            const matter = _.find(matters.list, o => o.lookupValue === value);
            if (matter) {
                setItemData({ ...itemData, [rest.target.name]: matter, [rest.target.name + 'Id']: matter.lookupId, matterNumber: matter.lookupValue });
                setMatters([]);
                return;
            }
        }
        loadMatters(value);
    }

    const loadMatters = (value) => {
        setMatters({ list: [], loading: true });
        api.get(`/matter/lookup/contains/${value}`, {
            accept: 'application/json',
        })
        .then(json => {
            setMatters({ list: json || [], loading: false });
        })
        .catch(handleErrors(props))
    }

    const handleTextAreaChange = ({ target: { value, name } }) => {
        setItemData({ ...itemData, [name]: value });
    }

    const handleInputChange = ({ value, target: { name } }) => {
        setItemData({ ...itemData, [name]: value });
    }

    const handleSelectChange = ({ value, target: { name } }) => {
        const idField = `${name}Id`;
        setItemData({ ...itemData, [name]: value, [idField]: value ? value.lookupId : null });
    }

    const handleAccountChange = (event) => {
        setItemData({ ...itemData, 'securityAccountLookups': event.target.value});
    }
    //const handleOwnerChange = (event) => {
    //    setItemData({ ...itemData, 'securityOwnerLookups': event.target.value});
    //}
    const handleProprietorChange = (event) => {
        setItemData({ ...itemData, 'securityProprietorLookups': event.target.value});
    }

    const validateForm = () => {
        var validForm = true;
        var formErrors = {};
        //formErrors.registeredProprietor = (!itemData.registeredProprietor || itemData.registeredProprietor === '');
        formErrors.addressStreetNumber = (!itemData.addressStreetNumber || itemData.addressStreetNumber === '');
        formErrors.addressStreetName = (!itemData.addressStreetName || itemData.addressStreetName === '');
        formErrors.addressSuburb = (!itemData.addressSuburb || itemData.addressSuburb === '');
        formErrors.addressState = (!itemData.addressState || itemData.addressState === '');
        formErrors.addressPostCode = (!itemData.addressPostCode || itemData.addressPostCode === '');
        //formErrors.securityAccountLookups = (!itemData.securityAccountLookups || itemData.securityAccountLookups === '' || itemData.securityAccountLookups.length === 0);

        if (itemData.selectMatter) {
            formErrors.matter = (!itemData.matter || itemData.matter === '');
        }

        setErrors(formErrors);

        const item = Object.assign({}, formErrors)
        validForm = !Object.keys(item).some(x => item[x]);

        return validForm;
    }

    const concatLookups = (data) => {
        let result = '';
        if (data) {
            data.forEach(function (item) {
                result += ', ' + item.lookupValue;
            });
            return result.substring(2, result.length);
        }
        return result;
    }

    return (
        <Dialog
            validate={validateForm}
            original={originalItemData}
            current={itemData}
            saveChanges={props.save}
            cancelChanges={props.cancel}
            width={1200}
            createMode={itemData.securityId === 0}
            inEditMode={editMode}
            deleteItem={props.delete}
            deleteItemMessage="Are you sure you want to delete this Security?"
            title={
                <>
                    {`${itemData.securityId === 0 ? 'Add' : editMode ? 'Edit' : 'View'} Security`}
                    {requiresRole(<Button className='rounded-circle ml-2' primary={true} look="bare" icon={editMode ? 'lock' : 'edit'} onClick={(ev) => setEditMode(!editMode)} />, policies.edit)}
                </>}
        >
            <form onSubmit={(ev) => ev.preventDefault()}>
                <GridContainer>
                    <GridItem sm={12}>
                        <h5>Security Information</h5>
                    </GridItem>

                    {editMode ?
                        (<>
                            <GridItem sm={12}>
                                <GridContainer>

                                    {itemData.selectMatter &&
                                        <GridItem sm={6} className="mt-3">
                                            <AutoComplete
                                                loading={matters.loading}
                                                data={matters.list}
                                                value={itemData.matterNumber}
                                                onChange={matterAutoCompleteChanged}
                                                textField='lookupValue'
                                                style={{ width: '100%', borderWidth: '1px' }}
                                                name="matter"
                                                id="matter"
                                                label="Matter Number"
                                                required={true}
                                                disabled={itemData.securityId > 0}
                                            />
                                            {errors.matter && <span className='text-danger'>Matter Number is Required.</span>}
                                        </GridItem>
                                    }

                                    <GridItem sm={6} className="mt-3">
                                        <Input
                                            width='100%'
                                            label='Unit/Level/Shop'
                                            value={itemData.addressUnitNumber}
                                            onChange={handleInputChange}
                                            name="addressUnitNumber"
                                            id="addressUnitNumber"
                                        />
                                    </GridItem>

                                    <GridItem sm={6} className="mt-3">
                                        <Input
                                            width='100%'
                                            label='Street Number'
                                            value={itemData.addressStreetNumber}
                                            onChange={handleInputChange}
                                            name="addressStreetNumber"
                                            id="addressStreetNumber"
                                            required
                                        />
                                        {errors.addressStreetNumber && <span className='text-danger'>Street Number is Required.</span>}
                                    </GridItem>

                                    <GridItem sm={6} className="mt-3">
                                        <Input
                                            width='100%'
                                            label='Street Name'
                                            value={itemData.addressStreetName}
                                            onChange={handleInputChange}
                                            name="addressStreetName"
                                            id="addressStreetName"
                                            required
                                        />
                                        {errors.addressStreetName && <span className='text-danger'>Street Name is Required.</span>}
                                    </GridItem>

                                    <GridItem sm={6} className="mt-3">
                                        <Input
                                            width='100%'
                                            label='Suburb'
                                            value={itemData.addressSuburb}
                                            onChange={handleInputChange}
                                            name="addressSuburb"
                                            id="addressSuburb"
                                            required
                                        />
                                        {errors.addressSuburb && <span className='text-danger'>Suburb is Required.</span>}
                                    </GridItem>

                                    <GridItem sm={6} className="mt-3">
                                        <Input
                                            width='100%'
                                            label='State'
                                            value={itemData.addressState}
                                            onChange={handleInputChange}
                                            name="addressState"
                                            id="addressState"
                                            required
                                        />
                                        {errors.addressState && <span className='text-danger'>State is Required.</span>}
                                    </GridItem>

                                    <GridItem sm={6} className="mt-3">
                                        <Input
                                            width='100%'
                                            label='Postcode'
                                            value={itemData.addressPostCode}
                                            onChange={handleInputChange}
                                            name="addressPostCode"
                                            id="addressPostCode"
                                            required
                                        />
                                        {errors.addressPostCode && <span className='text-danger'>Postcode is Required.</span>}
                                    </GridItem>

                                    <GridItem sm={6} className="mt-3">
                                        <ComboBox
                                            data={occupancyStatuses.list}
                                            loading={occupancyStatuses.loading}
                                            onFocus={(ev) => { if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click() }}
                                            textField='lookupValue'
                                            dataItemKey='lookupId'
                                            value={itemData.occupancyStatus}
                                            onChange={handleSelectChange}
                                            label="Occupancy Status"
                                            name='occupancyStatus'
                                            id='occupancyStatus'
                                        />
                                    </GridItem>

                                    {/* <GridItem sm={6} className="mt-3">
                                        <Input
                                            width='100%'
                                            label='Registered Proprietor'
                                            value={itemData.registeredProprietor}
                                            onChange={handleInputChange}
                                            name="registeredProprietor"
                                            id="registeredProprietor"
                                            required
                                        />
                                        {errors.registeredProprietor && <span className='text-danger'>Registered Proprietor is Required.</span>}
                                    </GridItem> */}
                                    <GridItem sm={6} className="mt-3">
                                        <MultiSelect
                                            label='Proprietors'
                                            data={parties.list}
                                            onChange={handleProprietorChange}
                                            loading={parties.loading}
                                            value={itemData.securityProprietorLookups}
                                            textField="lookupValue"
                                            dataItemKey="lookupId"
                                            required
                                        />
                                        {errors.securityProprietorLookups && <span className='text-danger'>Proprietor(s) is Required.</span>}
                                    </GridItem>
                                    {/*
                                    <GridItem sm={6} className="mt-3">
                                        <MultiSelect
                                            label='Owners'
                                            data={parties.list}
                                            onChange={handleOwnerChange}
                                            loading={parties.loading}
                                            value={itemData.securityOwnerLookups}
                                            textField="lookupValue"
                                            dataItemKey="lookupId"
                                            required
                                        />
                                        {errors.securityOwnerLookups && <span className='text-danger'>Owner(s) is Required.</span>}
                                    </GridItem>
                                    */}

                                </GridContainer>
                            </GridItem>

                            <GridItem sm={12}>
                                <h5 className='mt-4'>Lot Details</h5>
                                <GridContainer>

                                <GridItem sm={6} className="mt-3">
                                    <Input
                                        width='100%'
                                        label='Lot Number'
                                        value={itemData.lotNumber}
                                        onChange={handleInputChange}
                                        name="lotNumber"
                                        id="lotNumber"
                                    />
                                </GridItem>
                                <GridItem sm={6} className="mt-3">
                                    <ComboBox
                                            data={lotTypes.list}
                                            loading={lotTypes.loading}
                                            onFocus={(ev) => { if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click() }}
                                            textField='lookupValue'
                                            dataItemKey='lookupId'
                                            value={itemData.lotType}
                                            onChange={handleSelectChange}
                                            label="Lot Type"
                                            name='lotType'
                                            id='lotType'
                                        />
                                </GridItem>
                                <GridItem sm={6} className="mt-3">
                                    <Input
                                        width='100%'
                                        label='Lot Type Number'
                                        value={itemData.lotTypeNumber}
                                        onChange={handleInputChange}
                                        name="lotTypeNumber"
                                        id="lotTypeNumber"
                                    />
                                </GridItem>
                                <GridItem sm={6} className="mt-3">
                                    <Input
                                        width='100%'
                                        label='Volume'
                                        value={itemData.volume}
                                        onChange={handleInputChange}
                                        name="volume"
                                        id="volume"
                                    />
                                </GridItem>
                                <GridItem sm={6} className="mt-3">
                                    <Input
                                        width='100%'
                                        label='Folio'
                                        value={itemData.folio}
                                        onChange={handleInputChange}
                                        name="folio"
                                        id="folio"
                                    />
                                </GridItem>
                                <GridItem sm={6} className="mt-3">
                                    <ComboBox
                                            data={extents.list}
                                            loading={extents.loading}
                                            onFocus={(ev) => { if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click() }}
                                            textField='lookupValue'
                                            dataItemKey='lookupId'
                                            value={itemData.extent}
                                            onChange={handleSelectChange}
                                            label="Extent"
                                            name='extent'
                                            id='extent'
                                        />
                                </GridItem>
                                <GridItem sm={6} className="mt-3">
                                    <Input
                                        width='100%'
                                        label='Mortgage Number'
                                        value={itemData.mortgageNumber}
                                        onChange={handleInputChange}
                                        name="mortgageNumber"
                                        id="mortgageNumber"
                                    />
                                </GridItem>
                                <GridItem sm={6} className="mt-3">
                                    <Input
                                        width='100%'
                                        label='MP Number'
                                        value={itemData.mpNumber}
                                        onChange={handleInputChange}
                                        name="mpNumber"
                                        id="mpNumber"
                                    />
                                </GridItem>    

                                    {/* <GridItem sm={12} className="mt-3">
                                        <span className='k-textbox-container' style={{ height: '95%' }}>
                                            <label className='k-label'>Lot Details</label>
                                            <textarea
                                                className='k-textarea w-100 h-100'
                                                value={itemData.titleReference}
                                                onChange={handleTextAreaChange}
                                                name="titleReference"
                                                id="titleReference"
                                                rows={3}
                                            />
                                        </span>
                                    </GridItem>

                                    <GridItem sm={6} className="mt-3">
                                        <Input
                                            width='100%'
                                            label='Registered Mortgage No.'
                                            value={itemData.mortgageNumber}
                                            onChange={handleInputChange}
                                            name="mortgageNumber"
                                            id="mortgageNumber"
                                        />
                                    </GridItem>

                                    <GridItem sm={6} className="mt-3">
                                        <Input
                                            width='100%'
                                            label='CTVD / MP No.'
                                            value={itemData.ctvNumber}
                                            onChange={handleInputChange}
                                            name="ctvNumber"
                                            id="ctvNumber"
                                        />
                                    </GridItem> */}



                                    {!isNewMatter && 
                                    <GridItem sm={6} className="mt-3">
                                        <MultiSelect
                                            label='Accounts Secured'
                                            data={accounts.list}
                                            onChange={handleAccountChange}
                                            loading={accounts.loading}
                                            value={itemData.securityAccountLookups}
                                            textField="lookupValue"
                                            dataItemKey="lookupId"
                                            required
                                        />
                                        {errors.securityAccountLookups && <span className='text-danger'>Account(s) is Required.</span>}
                                    </GridItem>
                                    }



                                    {/*
                                    <GridItem sm={6} className="mt-3">
                                        <Input
                                            width='100%'
                                            label='Mortgage Provision'
                                            value={itemData.mortgageName}
                                            onChange={handleInputChange}
                                            name="mortgageName"
                                            id="mortgageName"
                                        />
                                    </GridItem>

                                    <GridItem sm={6} className="mt-3">
                                        <MultiSelect
                                            label='Accounts Secured'
                                            data={accounts.list}
                                            onChange={handleAccountChange}
                                            loading={accounts.loading}
                                            value={itemData.securityAccountLookups}
                                            textField="lookupValue"
                                            dataItemKey="lookupId"
                                            required
                                        />
                                        {errors.securityAccountLookups && <span className='text-danger'>Account(s) is Required.</span>}
                                    </GridItem>

                                    <GridItem sm={6} className="mt-3">
                                        <NumericTextBox
                                            width='100%'
                                            label='Valuation Amount'
                                            value={itemData.valuationAmount}
                                            onChange={handleInputChange}
                                            format="c2"
                                            spinners={false}
                                            name="valuationAmount"
                                            id="valuationAmount"
                                        />
                                    </GridItem>

                                    <GridItem sm={6} className="mt-3">
                                        <span className='k-textbox-container'>
                                            <label className='k-label'>Date of Valuation</label>
                                            <DatePicker
                                                defaultValue={new Date()}
                                                format="dd/MM/yyyy"
                                                name='valuationDate'
                                                id='valuationDate'
                                                onChange={handleDateChange}
                                                value={itemData.valuationDate}
                                                label=""
                                            />
                                        </span>
                                    </GridItem>

                                    <GridItem sm={12}>
                                        <GridContainer>
                                            <GridItem sm={12} className="mt-3">
                                                <h5 style={{ paddingTop: '5px' }}>Additional Information</h5>
                                                <textarea
                                                    className='k-textarea w-100'
                                                    label='Additional Information'
                                                    value={itemData.additionalInformation}
                                                    onChange={handleTextAreaChange}
                                                    name="additionalInformation"
                                                    id="additionalInformation"
                                                    rows={5}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                    */}
                                </GridContainer>
                            </GridItem>

                        </>) :

                        (<>
                            <GridItem sm={12}>
                                <GridContainer>
                                    <GridItem sm={12} className='mt-3'>
                                        <p>{itemData.fullAddress}</p>
                                    </GridItem>

                                    <GridItem sm={6} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Unit/Level/Shop:</label>
                                            <div className="font-size-14">{itemData.addressUnitNumber}</div>
                                        </span>
                                    </GridItem>

                                    <GridItem sm={6} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Street Number:</label>
                                            <div className="font-size-14">{itemData.addressStreetNumber}</div>
                                        </span>
                                    </GridItem>

                                    <GridItem sm={6} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Street Name:</label>
                                            <div className="font-size-14">{itemData.addressStreetName}</div>
                                        </span>
                                    </GridItem>

                                    <GridItem sm={6} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Suburb:</label>
                                            <div className="font-size-14">{itemData.addressSuburb}</div>
                                        </span>
                                    </GridItem>

                                    <GridItem sm={6} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>State:</label>
                                            <div className="font-size-14">{itemData.addressState}</div>
                                        </span>
                                    </GridItem>

                                    <GridItem sm={6} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Postcode:</label>
                                            <div className="font-size-14">{itemData.addressPostCode}</div>
                                        </span>
                                    </GridItem>

                                    <GridItem sm={6} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Occupancy Status:</label>
                                            <div className="font-size-14">{itemData.occupancyStatus ? itemData.occupancyStatus.lookupValue : ''}</div>
                                        </span>
                                    </GridItem>

                                    <GridItem sm={6} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Registered Proprietor:</label>
                                            <div className="font-size-14">{concatLookups(itemData.securityProprietorLookups)}</div>
                                        </span>
                                    </GridItem>

                                    {/* <GridItem sm={12} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Lot Details:</label>
                                            <div className="font-size-14">{itemData.titleReference ? itemData.titleReference : ''}</div>
                                        </span>
                                    </GridItem>

                                    <GridItem sm={6} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Registered Mortgage No.:</label>
                                            <div className="font-size-14">{itemData.mortgageNumber}</div>
                                        </span>
                                    </GridItem>

                                    <GridItem sm={6} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>CTVD / MP No.:</label>
                                            <div className="font-size-14">{itemData.ctvNumber}</div>
                                        </span>
                                    </GridItem> */}

                                    <GridItem sm={6} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Lot Number:</label>
                                            <div className="font-size-14">{itemData.lotNumber}</div>
                                        </span>
                                    </GridItem>

                                    <GridItem sm={6} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Lot Type:</label>
                                            <div className="font-size-14">{itemData.lotType ? itemData.lotType.lookupValue : ''}</div>
                                        </span>
                                    </GridItem>

                                    <GridItem sm={6} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Lot Type Number:</label>
                                            <div className="font-size-14">{itemData.lotTypeNumber}</div>
                                        </span>
                                    </GridItem>
                                    <GridItem sm={6} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Volume:</label>
                                            <div className="font-size-14">{itemData.volume}</div>
                                        </span>
                                    </GridItem>
                                    <GridItem sm={6} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Folio:</label>
                                            <div className="font-size-14">{itemData.folio}</div>
                                        </span>
                                    </GridItem>

                                    <GridItem sm={6} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Extent:</label>
                                            <div className="font-size-14">{itemData.extent ? itemData.extent.lookupValue : ''}</div>
                                        </span>
                                    </GridItem>

                                    <GridItem sm={6} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Mortgage Number:</label>
                                            <div className="font-size-14">{itemData.mortgageNumber}</div>
                                        </span>
                                    </GridItem>
                                    <GridItem sm={6} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>MP Number:</label>
                                            <div className="font-size-14">{itemData.mpNumber}</div>
                                        </span>
                                    </GridItem>

                                    <GridItem sm={6} className='mt-3'>
                                        <span className='k-textbox-container'>
                                            <label className='ro-label'>Accounts Secured:</label>
                                            <div className="font-size-14">{concatLookups(itemData.securityAccountLookups)}</div>
                                        </span>
                                    </GridItem>

                                </GridContainer>
                            </GridItem>

                        </>)
                    }

                </GridContainer>
            </form>
           
        </Dialog>
    )
};

export default withSnackbar(withRouter(SecurityEditor));