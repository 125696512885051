import React, { useState, useEffect } from 'react';
import { findDOMNode } from "react-dom";

import { Button } from '@progress/kendo-react-buttons';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';

import Dialog from "../common/Dialog";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";

import moment from "moment";
import { withSnackbar } from 'notistack';

import handleErrors from '../../util/handleErrors';
import api from "../../util/api"
import { editableField } from "../../util/editableField";
import { requiresRole, policies } from '../../util/accessControl';

import RelatedAccountsEditor from "./RelatedAccountsEditor";
import RelatedSecuritiesEditor from "./RelatedSecuritiesEditor";
import RelatedInstructionEditor from "./RelatedInstructionEditor";

import AddressFormatter from "../../util/formatAddress";
const NoticeEditor = (props) => {
    //const noticeDefaultNotice = 80;
    //const noticeDemand = 81;
    //const noticeTermination = 82;
    //const noticeGuarantor = 83;
    //const noticeForm17 = 84;
    const initLookupObject = { loading: false, list: [] };
    const originalItem = { 
        ...props.dataItem,
        noticeDate: (props.dataItem.noticeDate == null || props.dataItem.noticeDate === "") ? null : moment(props.dataItem.noticeDate).toDate(),
        expirationDate: (props.dataItem.expirationDate == null || props.dataItem.expirationDate === "") ? null : moment(props.dataItem.expirationDate).toDate(),
    };
    const [itemData, setItemData] = useState(originalItem);
    const [originalItemData, setOriginalItemData] = useState(originalItem);
    const [noticeTypes, setNoticeTypes] = useState(initLookupObject);
    const [editMode, setEditMode] = useState(!!props.editMode);
    const [errors, setErrors] = useState({});

    const editStageQuery = `/stage/notice/${itemData.stageId}`;
    const newStageQuery = `/stage/block/${itemData.stage.blockId}`;

    const handleSelectChange = ({ value, target: { name } }) => {
        const idField = `${name}Id`;
        setItemData({ ...itemData, [name]: value, [idField]: value ? value.lookupId : null });
    }

    const handleDateChange = ({ value, target: { name } }) => {
        setItemData({ ...itemData, [name]: value == null ? null : moment(value).local().toDate() });
    }
    
    const updateRelatedEditorsData = (updatedData) => {
        setItemData(updatedData);
    }

    const validateForm = (ev) => {
        var validForm = true;
        var formErrors = {};

        let account = itemData.stage.matterAccounts.find(v => v.noticeSelected === true);
        if (!account) {
            props.enqueueSnackbar('Related Account/s required.', { variant: 'error' });
            return false;
        }

        let security = itemData.stage.matterSecurities.find(v => v.noticeSelected === true);
        if (!security) {
            props.enqueueSnackbar('Related Security Properties required.', { variant: 'error' });
            return false;
        }
        
        let matterParties = itemData.stage.matterParties.find(v => v.noticeSelected === true);
        if (!matterParties) {
            props.enqueueSnackbar('Service Instructions required.', { variant: 'error' });
            return false;
        }

        formErrors.noticeDate = (!itemData.noticeDate || itemData.noticeDate === '');
        formErrors.expirationDate = (!itemData.expirationDate || itemData.expirationDate === '');

        setErrors(formErrors);

        const item = Object.assign({}, formErrors)
        validForm = !Object.keys(item).some(x => item[x]);

        return validForm;
    }

    const setLookup = (setFunc) => (json) => {
        setFunc({ loading: false, list: json });
        return json;
    }

    useEffect(() => {
        const axiosConfig = { accept: 'application/json' };
        const loading = { loading: true, list: [] };
        setNoticeTypes(loading);
        
        let onLoadTasks = [
            api.get((itemData.stageId > 0) ? editStageQuery : newStageQuery, axiosConfig), 
            api.get(`/lookup/noticetype`, axiosConfig).then(setLookup(setNoticeTypes)), 
        ];

        Promise.all(onLoadTasks)
            .then(responses => {
                let tempData = { ...itemData };
                let stage = responses[0];
                let notices = responses[1]

                if (tempData.stageId == 0) {
                    tempData = {
                        ...itemData,
                        noticeType: notices[0],
                        noticeTypeId: notices[0].lookupId,
                        stage: stage,
                    };
                } else {
                    tempData = {
                        ...stage,
                        noticeDate: (stage.noticeDate == null || stage.noticeDate === "") ? null : moment(stage.noticeDate).toDate(),
                        expirationDate: (stage.expirationDate == null || stage.expirationDate === "") ? null : moment(stage.expirationDate).toDate(),
                    };
                }
                setItemData(tempData);
                setOriginalItemData(tempData);
            })
            .catch(handleErrors(props))
    }, []);

    return (
        <Dialog
            validate={validateForm}
            original={originalItemData}
            current={itemData}
            saveChanges={props.save}
            cancelChanges={props.cancel}
            contentStyle={{ width: 'calc(100vw - 80px)', maxWidth: '1200px' }}
            createMode={itemData.stageId === 0}
            title={
                <>
                    {`${itemData.accountId === 0 ? 'Add' : editMode ? 'Edit' : 'View'} Notice`}
                    {requiresRole(<Button className='rounded-circle ml-2' primary={true} look="bare" icon={editMode ? 'lock' : 'edit'} onClick={(ev) => setEditMode(!editMode)} />, policies.edit)}
                </>}
        >
            <form onSubmit={(ev) => ev.preventDefault()}>
                <GridContainer className="mt-3">

                    {editMode ? (
                        <>
                            <GridItem md={4} xs={12}>
                                <ComboBox
                                    data={noticeTypes.list}
                                    loading={noticeTypes.loading}
                                    onFocus={(ev) => {if (!ev.target.state.opened) findDOMNode(ev.target.element).querySelector(".k-select").click()}}
                                    textField='lookupValue'
                                    dataItemKey='lookupId'
                                    value={itemData.noticeType || noticeTypes.list[0] }
                                    onChange={handleSelectChange}
                                    label="Notice Type"
                                    name='noticeType'
                                    id='noticeType'
                                />                                
                            </GridItem>
                            <GridItem md={4} xs={12}>
                                <span className='k-textbox-container'>
                                    <label className='k-label'>Date notice issued</label>
                                    <DatePicker
                                        format="dd/MM/yyyy"
                                        name='noticeDate'
                                        id='noticeDate'
                                        onChange={handleDateChange}
                                        value={itemData.noticeDate}
                                        label=""
                                        required={true}
                                    />
                                </span>
                                {errors.noticeDate && <span className='text-danger'>Date notice issued is Required.</span>}
                            </GridItem>
                            <GridItem md={4} xs={12}>
                                <span className='k-textbox-container'>
                                    <label className='k-label'>Expiry date</label>
                                    <DatePicker
                                        format="dd/MM/yyyy"
                                        name='expirationDate'
                                        id='expirationDate'
                                        onChange={handleDateChange}
                                        value={itemData.expirationDate}
                                        label=""
                                        required={true}
                                    />
                                    {errors.expirationDate && <span className='text-danger'>Expiry date is Required.</span>}
                                </span>
                            </GridItem>

                            {itemData.stage.matterAccounts && <RelatedAccountsEditor stageData={itemData} updateRelatedAccounts={updateRelatedEditorsData} editMode={editMode} /> }

                            {itemData.stage.matterSecurities && <RelatedSecuritiesEditor stageData={itemData} updateRelatedSecurities={updateRelatedEditorsData} editMode={editMode} />}

                            {(itemData.stage.matterParties) && <RelatedInstructionEditor stageData={itemData} updateRelatedInstructions={updateRelatedEditorsData} instructionsList={['registeredlastknown', 'registeredtotitle', 'registeredtopobox', 'regularlastknown', 'copyemail']} editMode={editMode} />}
                        </>
                    ) : (
                        <>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Notice Type:</label>
                                    <div className="font-size-14">{itemData.noticeType ? itemData.noticeType.lookupValue : ''}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Date notice issued:</label>
                                    <div className="font-size-14">{moment(itemData.noticeDate).format('D/MM/YYYY')}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={4} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Expiry date:</label>
                                    <div className="font-size-14">{moment(itemData.expirationDate).format('D/MM/YYYY')}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={12} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Related Accounts:</label>
                                    <div className="font-size-14">{itemData.stage.matterAccounts && itemData.stage.matterAccounts.length > 0 ? itemData.stage.matterAccounts.filter(val => !!val.noticeSelected).map((val, ind, arr) => val.accountNumber + ((ind + 1) < arr.length ? ', ' : '')) : ''}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={12} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Related Security Properties:</label>
                                    <div className="font-size-14">{itemData.stage.matterSecurities && itemData.stage.matterSecurities.length > 0 ? itemData.stage.matterSecurities.filter(val => !!val.noticeSelected).map((val, ind, arr) => AddressFormatter.format(val).address + ((ind + 1) < arr.length ? ', ' : '')) : ''}</div>
                                </span>
                            </GridItem>
                            <GridItem sm={12} className=''>
                                <span className='k-textbox-container'>
                                    <label className='ro-label'>Service Instructions:</label>
                                    {(itemData.stage.matterParties && itemData.stage.matterParties.length > 0) 
                                    ? itemData.stage.matterParties.filter(val => !!val.noticeSelected).map((val, ind, arr) => {
                                        const opts = { 
                                            'registeredLastKnown': 'Registered post to last known address', 
                                            'registeredToTitle': 'Registered post to address on title and on last known address', 
                                            'registeredToPoBox': 'Registered post to last known address and PO box', 
                                            'regularLastKnown': 'Regular post to last known address', 
                                            'copyEmail': 'Registered post to last known address and copy via email'
                                        };
                                        
                                    return <><div className="font-size-14 font-weight-bolder">{val.party.firstName} {val.party.lastName}:</div>{Object.keys(opts).map(v => !!val[v] && <div className="font-size-14">{opts[v]}</div>)}</>
                                    })
                                    : null}                                
                                </span>
                            </GridItem>
                        </>
                    )}
                    
                </GridContainer>
            </form>
        </Dialog>
    )
}

export default withSnackbar(NoticeEditor);